import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { ErrorMsg } from '../../Utils/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { BikerProfileUpdate, GetAllBikers } from '../../redux/actions/Bikers';
import { IoCloseSharp } from "react-icons/io5";
import { IoPencil } from "react-icons/io5";

const UpdateOffDays = ({ selectID }) => {



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [selectedDays, setSelectedDays] = useState([]);

    const dispatch = useDispatch();

    const handleDays = (event) => {
        // Handle the selection of days and update the selectedDays state
        const selectedDay = event.target.value;
        if (!selectedDays.includes(selectedDay)) {
            setSelectedDays([...selectedDays, selectedDay]);
        }
    };
    const dayMapping = {
        "1": "الاتنين",
        "2": "الثلاثاء",
        "3": "الاربعاء",
        "4": "الخميس",
        "5": "الجمعه",
        "6": "السبت",
        "7": "الاحد",
    };

    const removeDay = (dayToRemove) => {
        // Remove the selected day from the array
        const updatedDays = selectedDays.filter((day) => day !== dayToRemove);
        setSelectedDays(updatedDays);
    };
    //Update Biker Profile 

    const [loading, setLoading] = useState(false);


    const handleUpdateProfile = async () => {
        try {
            setLoading(true);

            // Ensure selectedDays is an array
            if (!Array.isArray(selectedDays)) {
                console.error('Selected days must be an array.');
                return;
            }


            await dispatch(BikerProfileUpdate({
                id: selectID,
                off_days: `${selectedDays.map((day) => day)}`,
            }));
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
            handleClose();
            setSelectedDays([]);
            dispatch(GetAllBikers())
        }
    };

    return (
        <div>
            <IoPencil size="20" style={{ cursor: "pointer" }} onClick={handleShow} />
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>اضافة عطلات دورية للبايكر</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>يمكنك اختبار مدة وايام محددة بالتاريخ واليوم </span>
                    <div className='mt-3'>

                    </div>
                    <div className='mt-3'>
                        <Form.Select aria-label="Default select example" onChange={handleDays} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>حدد الايام ( محلوظة يمكنك اختيار اكتر من يوم )</option>
                            <option value="1">الاتنين</option>
                            <option value="2">الثلاثاء</option>
                            <option value="3">الاربعاء</option>
                            <option value="4">الخميس</option>
                            <option value="5">الجمعه</option>
                            <option value="6">السبت</option>
                            <option value="7">الاحد</option>
                        </Form.Select>
                        <div className='mt-4 d-flex'>
                            {selectedDays.length > 0 && selectedDays.map((dayMaps) => (
                                <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
                                    {dayMapping[dayMaps]}
                                    <IoCloseSharp onClick={() => removeDay(dayMaps)} />
                                </p>
                            ))}
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleUpdateProfile}>
                        اضافه
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateOffDays
