import baseUrl from "../../Api/baseURL";
import { ADD_NEW_BIKER, BIKER_PROFILE, DELETE_BIKER, GET_ALL_BIKERS, GET_ALL_BIKERS_DATA, GET_ALL_MANAGE_BIKERS, GET_ERRORS, GET_ORDERS_BIKERS, PROFILE_UPDATE_BIKER, PROFILE_UPDATE_BIKER_DATA, SEARCH_BIKER, UPDATE_STATUS } from "../Type";
import { GET_ORDERS } from "../Type"
import { ErrorMsg, InfoMsg, success } from '../../Utils/Toast';
const GetAllBikers = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/biker/all-bikers?page=1&limit=100', config)
            dispatch({ type: GET_ALL_BIKERS, payload: res.data.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: GET_ALL_BIKERS, payload: e.res })
        }
    }
}
const GetAllBikersData = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1


            if (data.active === null) {
                let res = await baseUrl.get(`v1/biker/all-bikers?page=${data.pageCount}&limit=10&sortByDate=${data.sort}`, config)
                dispatch({ type: GET_ALL_BIKERS_DATA, payload: res.data.data })
            } else if (data.active !== null) {
                let res = await baseUrl.get(`v1/biker/all-bikers?page=${data.pageCount}&limit=10&is_active=${data.active}&sortByDate=${data.sort}`, config)
                dispatch({ type: GET_ALL_BIKERS_DATA, payload: res.data.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_BIKERS_DATA, payload: e.res })
        }
    }
}

//Sort Bikers
const GetAllBikersSort = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/biker/all-bikers?page=${data.page}&limit=10&sortByDate=${data.sort}`, config)

            dispatch({ type: GET_ALL_BIKERS_DATA, payload: res.data.data })
        } catch (e) {
            dispatch({ type: GET_ALL_BIKERS_DATA, payload: e.res })
        }
    }
}

//Active Bikers
const GetAllBikersActive = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/biker/all-bikers?page=${data.page}&limit=10&is_active=${data.active}`, config)

            dispatch({ type: GET_ALL_BIKERS_DATA, payload: res.data.data })
        } catch (e) {
            dispatch({ type: GET_ALL_BIKERS_DATA, payload: e.res })
        }
    }
}


//Active Bikers & sort
const GetAllBikersActiveAndSort = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/biker/all-bikers?page=1&limit=10&is_active=${data.active}&sortByDate=${data.sort}`, config)

            dispatch({ type: GET_ALL_BIKERS_DATA, payload: res.data.data })
        } catch (e) {
            dispatch({ type: GET_ALL_BIKERS_DATA, payload: e.res })
        }
    }
}



const GetBikersOrders = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        try {
            if (data.date === "" && data.status === "") {
                let res = await baseUrl.get(`v1/slots/slots-orders`, config)
                console.log("جبت كل الداتا");
                dispatch({ type: GET_ORDERS, payload: res.data.data })
            } else if (data.date !== "" && data.status === "") {
                let res = await baseUrl.get(`v1/slots/slots-orders?date=${data.date}`, config)
                console.log("جبت التاريخ");
                dispatch({ type: GET_ORDERS, payload: res.data.data })
            } else if (data.status !== "" && data.date === "") {
                let res = await baseUrl.get(`v1/slots/slots-orders?status=${data.status}`, config)
                console.log("جبت الحاله");
                dispatch({ type: GET_ORDERS, payload: res.data.data })
            } else {
                let res = await baseUrl.get(`v1/slots/slots-orders?status=${data.status}&date=${data.date}`, config)
                console.log("جبت كل التاريخ و الداتا");
                dispatch({ type: GET_ORDERS, payload: res.data.data })
            }
        } catch (e) {
            dispatch({ type: GET_ORDERS, payload: e.res })
        }
    }
}
// const GetBikersOrdersDate = (data) => {
//     return async (dispatch) => {
//         const config = {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("token")}`
//             }
//         }
//         try {
//             let res = await baseUrl.get(`v1/slots/slots-orders`, config)
//             console.log(res);
//             dispatch({ type: GET_ORDERS, payload: res.data.data })
//         } catch (e) {
//             dispatch({ type: GET_ORDERS, payload: e.res })
//         }
//     }
// }

const BakerReservations = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        try {
            if (data.id !== "" && data.date === "") {
                let res = await baseUrl.get(`v1/biker/biker-orders/${data.id}`, config)

                dispatch({ type: GET_ORDERS_BIKERS, payload: res.data.data })
            } else if (data.id !== "" && data.date !== "") {
                let res = await baseUrl.get(`v1/biker/biker-orders/${data.id}?date=${data.date}`, config)

                dispatch({ type: GET_ORDERS_BIKERS, payload: res.data.data })
            }
        } catch (e) {
            dispatch({ type: GET_ORDERS_BIKERS, payload: e.res })
        }
    }
}

const GetAllManageBikers = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        try {
            let res = await baseUrl.get(`v1/biker/all-bikers?page=1&limit=10&is_active=${data.activation}&sortByDate=${data.order}`, config)

            dispatch({ type: GET_ALL_MANAGE_BIKERS, payload: res.data.data })
        } catch (e) {
            dispatch({ type: GET_ALL_MANAGE_BIKERS, payload: e.res })
        }
    }
}



const UpdateBikerStatus = (data) => {

    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/biker/${data.id}/active-biker/${data.active}`, data, config)
            dispatch({ type: UPDATE_STATUS, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: GET_ERRORS, payload: e.res })
            console.log(e.response)
            if (e.response.data.statusCode === 400) {
                InfoMsg(e.response.data.message)
                let res = await baseUrl.put(`v1/biker/${data.id}/active-biker/${data.active}`, data, config)
                dispatch({ type: UPDATE_STATUS, payload: res.data })
            } else if (e.response.status === 404) {
                InfoMsg(e.response.data.message)
            }

        }
    }
}
const DeleteBiker = (data) => {
    // console.log(data.id)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/biker/${data.id}`, config)
            dispatch({ type: DELETE_BIKER, payload: res })
            // console.log(res)
            if (res.status === 200) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: DELETE_BIKER, payload: e.res })
            // console.log(e)
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}

const GetBikerProfile = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/users/profile/${data.id}?role=${data.role}`, config)
            dispatch({ type: BIKER_PROFILE, payload: res.data })
        } catch (e) {
            dispatch({ type: BIKER_PROFILE, payload: e.res })

        }
    }
}

const BikerProfileUpdate = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/biker/profile-biker/${data.id}`, data, config)
            dispatch({ type: PROFILE_UPDATE_BIKER, payload: res.data });
            console.log(res)
            if (res.status === 200) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: PROFILE_UPDATE_BIKER, payload: e.res })
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }
            console.log(e);
        }
    }
}
const BikerProfileUpdateData = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/biker/profile-biker/${data.id}`, data, config)
            dispatch({ type: PROFILE_UPDATE_BIKER_DATA, payload: res.data });
            console.log(res)
            if (res.status === 200) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: PROFILE_UPDATE_BIKER_DATA, payload: e.res })
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }
            console.log(e);
        }
    }
}


const Add_NewBiker = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/auth/register`, data, config)
            dispatch({ type: ADD_NEW_BIKER, payload: res.data })
            console.log(res)
            if (res.data.statusCode === 201) {
                success(`تم اضافة  ${res.data.data.first_name} بنجاح`)
            }
        } catch (e) {
            dispatch({ type: ADD_NEW_BIKER, payload: e.res })
            ErrorMsg(e.response.data.message.message[0])
        }
    }
}
//Search Biker
const SearchBiker = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/biker/all-bikers?page=1&limit=10&searchByName=${data.name}`, config)
            dispatch({ type: GET_ALL_BIKERS_DATA, payload: res.data.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: GET_ALL_BIKERS_DATA, payload: e.res })
            console.log(e);
        }
    }
}









export { GetAllBikers, GetBikersOrders, BikerProfileUpdateData, BakerReservations, GetAllManageBikers, UpdateBikerStatus, DeleteBiker, GetBikerProfile, BikerProfileUpdate, Add_NewBiker, SearchBiker, GetAllBikersSort, GetAllBikersData, GetAllBikersActive, GetAllBikersActiveAndSort }