import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { Add_NewBiker } from '../../redux/actions/Bikers';
import LocatiobStart from './LocationStart'
import { ErrorMsg } from '../../Utils/Toast';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // استيراد ملف الستايلات إذا لزم الأمر

const AddNewBiker = () => {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
    const [coordinates, setCoordinates] = useState(null);
    const [startLat, setStartLat] = useState(null);
    const [startLng, setStartLng] = useState(null);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState();
    const [phoneNumber, setPhoneNumber] = useState('');

    //Show Or Hide Map
    const handleShow = () => {
        setShow(!show)
    }



    //Setl Lat , lng For Start_latitude , start_longitude

    const handleCoordinatesChange = (newCoordinates) => {
        setCoordinates(newCoordinates);
    };

    useEffect(() => {

        if (coordinates !== null) {
            setStartLat(coordinates.lat)
            setStartLng(coordinates.lng)
        }

    }, [coordinates]);




    const addBiker = async () => {
        try {
            setLoading(true);
            if (!firstName || !lastName || !phoneNumber || !email) {
                ErrorMsg('Data Missed')
                setLoading(true)
                return;
            }
            if (!startLat || !startLng) {
                ErrorMsg('قم بتعيين نقطة انطلاق للبايكر')
                setLoading(true)
                return
            }
            await dispatch(Add_NewBiker({
                first_name : firstName,
                last_name : lastName,
                email: email,
                phone: `+${phoneNumber}`,
                // password: password,
                role: "BIKER",
                start_latitude: startLat,
                start_longitude: startLng
            }))
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }


    }


    const resAddBiker = useSelector(state => state.GetBikers.addBikers)

    useEffect(() => {
        if (resAddBiker && resAddBiker.statusCode) {
            console.log(resAddBiker.statusCode)
            if (resAddBiker.statusCode === 201) {
                setFirstName('');
                setLastName('');
                setEmail('');
                setPhoneNumber('');
                setPassword('');
                setStartLat(null)
                setStartLng(null)
                setTimeout(() => {
                    window.location.href = "/admin/managebiker";
                }, 1500)
            }
        }
    }, [resAddBiker])

    const [error, setError] = useState('');

    const handlePhoneNumberChange = (value, country, e, formattedValue) => {
        // التحقق من أن الرقم يبدأ بـ "9665" ويتكون من 8 أرقام
        if (/^9665\d{8}$/.test(value)) {
            setPhoneNumber(value);
            setError('');
        } else {
            setError('رقم الهاتف غير صحيح، يرجى إدخال رقم هاتف صحيح يبدأ بـ "9665" ويتكون من 9 أرقام.');
        }
    };
    console.log(phoneNumber);

    return (
        <div className='font'>
            <div className='flexItem mt-5'>
                <div>
                    <p>
                        <span style={{ color: "#BDBDBD" }}>اضافة البايكرز &gt;</span>
                    </p>
                </div>

                <div>
                    <button className='btn  w-100' style={{ background: "#FECB44", color: "black" }} onClick={addBiker}>تاكيد</button>
                </div>
            </div>
            <div className='mt-3'>
                <div className='bikerEditTop'>
                    المعلومات الاساسيه
                </div>
                <div className='flexItem mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                    <div>
                        <div>
                            <label>الاسم الاول : </label>
                            <input type='text' className='w-100 mt-2 textInputBiker' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </div>
                        <div className='mt-1'>
                            <label>الاسم الاخير : </label>
                            <input type='text' className='w-100 mt-2 textInputBiker' value={lastName} onChange={(e) => setLastName(e.target.value)} />
                        </div>
                        {/* <div className='mt-1'>
                            <label>كلمة السر : </label>
                            <input type='password' className='w-100 mt-2 textInputBiker' value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div> */}
                    </div>
                    <div>
                        <div className='mt-1'>
                            <label>رقم الهاتف : </label>
                            <PhoneInput
                                country={'sa'}
                                countryCodeEditable={false}
                                disableDropdown={true}
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                            {error && <small style={{ color: 'red' }}>{error}</small>}

                        </div>
                        <div className='mt-1'>
                            <label>البريد الالكتروني : </label>
                            <input type='email' className='w-100 mt-2 textInputBiker' value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-3'>
                <div className='bikerEditTop flexItem'>
                    <div>نقطة الانطلاق</div>
                    <div className='w-25'>
                        <button className='btn submitButton w-100' onClick={handleShow}>تعيين نقطة انطلاق البايكر</button>
                    </div>
                </div>
                {
                    show ? (<div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <LocatiobStart onCoordinatesChange={handleCoordinatesChange} />
                    </div>) : null
                }
            </div>
        </div>
    )
}

export default AddNewBiker
