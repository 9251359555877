import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import input from "../../Images/Input.png"
import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
import { DeleteReviewData, GetAllReviews } from '../../redux/actions/RateAction';

const DeleteReview = ({ idData, searchValue,
    rate,
    sort, page }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();


    const handleDelete = async () => {
        try {
            setLoading(true);
            await dispatch(DeleteReviewData({
                id: idData
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleCloseEdit();
            await dispatch(GetAllReviews({
                sort: sort,
                pageCount: page,
                searchValue: searchValue,
                rate: rate
            }))
        }
    }

    return (
        <div>
            <MdDelete onClick={handleShowEdit} cursor={'pointer'} size={"20"} color='#EB5757' />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
                <Modal.Body className='text-center py-3'>
                    <img src={input} alt="delete" />

                    <p className='mt-4 fs-4'>هل تريد حذف  التعليق ؟؟</p>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#ea5756", color: "white" }} onClick={handleDelete}>
                        تاكيد الحذف
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteReview
