import baseUrl from "../../Api/baseURL";
import { ErrorMsg } from "../../Utils/Toast";
import { GET_ALL_BANNERS, POST_NEW_BANNER, DELETE_BANNER, UPDATE_BANNER, GET_SINGLE_BANNER } from '../Type'

//get All Areas
const GetAllBanners = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.name === "" || data.name === undefined) {
                let res = await baseUrl.get(`v1/banner/admin?page=${data.page}&limit=10&sortBy=created_at%3D${data.sort}`, config)
                dispatch({ type: GET_ALL_BANNERS, payload: res.data })
            }else if(data.name !== ""){
                let res = await baseUrl.get(`v1/banner/admin?page=${data.page}&limit=10&filters=name%3D${data.name}&sortBy=created_at%3D${data.sort}`, config)
                dispatch({ type: GET_ALL_BANNERS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_BANNERS, payload: e.res })
            console.log(e)
        }
    }
}
//Add New Banner
const PostBanner = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/banner`, data, config)

            dispatch({ type: POST_NEW_BANNER, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: POST_NEW_BANNER, payload: e.res })
            console.log(e)
            if(e.response.status === 422){
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
//Delete Banner
const DeleteBanner = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/banner/${data.id}`, data, config)

            dispatch({ type: DELETE_BANNER, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: DELETE_BANNER, payload: e.res })
            console.log(e)
        }
    }
}
//Update Banner
const UpdateBanner = (data, id) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/banner/${id}`, data, config)
            dispatch({ type: UPDATE_BANNER, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_BANNER, payload: e.res })
            console.log(e)
        }
    }
}
//Update Banner Active
const UpdateBannerActive = (data , id) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/banner/${id}`, data, config)

            dispatch({ type: UPDATE_BANNER, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_BANNER, payload: e.res })
            console.log(e)
        }
    }
}
// Get Single Banner
const getSingleBanner = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/banner/admin?filters=id%3D${data.id}&isDeleted=false`, config)

            dispatch({ type: GET_SINGLE_BANNER, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_SINGLE_BANNER, payload: e.res })
            console.log(e)
        }
    }
}


export { GetAllBanners, PostBanner, DeleteBanner, UpdateBanner  , UpdateBannerActive , getSingleBanner}