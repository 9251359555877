import React, { useEffect, useState } from 'react';
import Search from '../DashboardComponents/BookingAndSubscriptions/Search';
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Pagination from '../../Utils/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteUser, GetAllUsersClients, GetAllUsersSearch } from '../../redux/actions/Users';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import DeleteUserData from '../Modals/DeleteUser';
const AppUserAccounts = () => {



    //Dispatch To Get Clients 

    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState("");
    const dispatch = useDispatch();

    const getUsersClients = async () => {
        await dispatch(GetAllUsersClients({
            role: "CLIENT",
            pageCount: 1
        }));
    };

    useEffect(() => {
        const fetchData = async () => {
            await getUsersClients();
        };
        fetchData();
    }, []);

    const UsersClients = useSelector(state => state.GetUsers.Get_Users);




    //Searh
    const [searchValue, setSearchValue] = useState('');
    // const [filteredUsers, setFilteredUsers] = useState([]);


    const handleSearch = async () => {
        try {
            setLoading(true)
            await dispatch(GetAllUsersSearch(
                {
                    role: "CLIENT",
                    pageCount: 1,
                    searchWord: searchValue
                }
            ))
            if (searchValue !== "") {
                setSearchData("data")
            } else {
                setSearchData("")
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true);
        }
    };


    // useEffect(() => {
    //     setFilteredUsers(UsersClients.data ? UsersClients.data.clients : []);
    // }, [UsersClients.data]);



    //Get Page For Pagination
    const [currentPage, setCurrentPage] = useState(1);

    const getPage = async (page) => {
        setCurrentPage(page)
        await dispatch(GetAllUsersClients({
            role: "CLIENT",
            pageCount: page
        }));
    };

    // console.log(currentPage);




    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث .....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={handleSearch} />
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>اسم المستخدم</th>
                            {/* <th>الصلاحية</th> */}
                            <th>رقم الجوال</th>
                            <th>البريد الالكتروني</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            UsersClients.data && UsersClients.data.clients ? (
                                UsersClients.data.clients.map((client, index) => {
                                    // console.log(client)
                                    if (client.user !== null) {
                                        return (
                                            <tr key={client.id}>
                                                <td>{index + 1}</td>
                                                {client.user.first_name !== null && client.user.first_name !== " " ? (
                                                    <td>{client.user.first_name} {client.user.last_name}</td>
                                                ) : (
                                                    <td>لا يوجد اسم</td>
                                                )}
                                                {/* <td>{client.user_id}</td> */}
                                                <td>{client.user.phone}</td>
                                                {
                                                    client.user.email !== null && client.user.email !== "" && client.user.email !== " " ? (
                                                        <td> {client.user.email} </td>
                                                    ) : (<td>لا يوجد ايميل</td>)
                                                }
                                                <td>
                                                    <Link to={`/admin/usersetting/${client.user_id}/${client.user.roles[0]}`}>
                                                        <IoPencil size="20" style={{ cursor: "pointer" }} />
                                                    </Link>
                                                </td>
                                                {/* <td style={{ cursor: "pointer" }}><MdDelete style={{ color: "#EB5757" }} onClick={() => handleDelete(client.user_id, client.user.roles[0])} size="20" /></td> */}
                                                <td style={{ cursor: "pointer" }}>
                                                    <DeleteUserData user_id={client.user_id} role={client.user.roles[0]} page={currentPage} />
                                                </td>
                                            </tr>
                                        );
                                    } else {
                                        return null; // Do not render the row if user is null
                                    }
                                })
                            ) : null
                        }

                    </tbody>
                </Table>
            </div>
            {
                UsersClients.data ? searchData === "" ? ((<Pagination onPress={getPage} countsPage={UsersClients.data.clientsCount / 10} />)) : null : null
            }
        </div>
    );
};

export default AppUserAccounts;
