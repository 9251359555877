import baseUrl from "../../Api/baseURL";
import { success } from "../../Utils/Toast";
import { ADD_PREMISSION} from '../Type'

//get All Areas
const AddPremission = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post('v1/users/admin-premessions',data, config)
            dispatch({ type: ADD_PREMISSION, payload: res.data })
            console.log(res);
            if(res.status === 201){
                success("تم اضافة الصلاحيات")
            }
        } catch (e) {
            dispatch({ type: ADD_PREMISSION, payload: e.res })
            console.log(e)
        }
    }
}
const RemovePremission = (data) => {
    // console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post('v1/users/remove-admin-premessions',data, config)
            dispatch({ type: ADD_PREMISSION, payload: res.data })
            console.log(res);
            if(res.status === 201){
                success("تم حذف الصلاحيات")
            }
        } catch (e) {
            dispatch({ type: ADD_PREMISSION, payload: e.res })
            console.log(e)
        }
    }
}

export {AddPremission , RemovePremission}