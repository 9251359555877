import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import input from "../../Images/Group.png"
import { useDispatch, useSelector } from 'react-redux'
import { GetPrivacy } from '../../redux/actions/PrivacyPolicy';
import ModalEditPolicy from '../Modals/ModalEditPolicy';
const Policy = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true)
    }

    const dispatch = useDispatch();

    const fetchDataPrivacy = async () => {
        await dispatch(GetPrivacy())
    }

    const privacyData = useSelector(state => state.PrivacyReducer.privacy);


    useEffect(() => {
        fetchDataPrivacy();
    }, [dispatch])


    if (privacyData) {
        console.log(privacyData)
    }

    return (
        <div className='font ' >
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <p>السياسة والخصوصية</p>
                <div>
                    {/* <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={handleShow}>حفظ جميع التغييرات</button> */}
                </div>
            </div>

            <div className='bickerMab bg-light mt-3'>
                <div className='d-flex justify-content-between align-items-center p-3'>
                    <div>
                        <p>محتوي الصفحة</p>
                    </div>
                    {/* <div className='d-flex justify-content-between'>
                        <p>إظهار هذا القسم بالتطبيق</p>
                        <Form className='mx-3'>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                            />
                        </Form>
                    </div> */}
                </div>
            </div>


            <div className='bickerMab p-3 mt-3' style={{ background: "white" }}>
                <div style={{ color: "rgba(171, 175, 177, 1)", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                    <p>سياسة الخصوصية</p>
                </div>
                <div className='mt-2'>
                    <span>تفاصيل واجابة السؤال</span>
                </div>
                {
                    privacyData && privacyData.data ? (
                        privacyData.data.map((data) => {
                            console.log(data)
                            return (
                                <div style={{ marginTop: "10px", color: "rgba(171, 175, 177, 1)", padding: "10px", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                                    <ModalEditPolicy titleEn={data.title_en} titleAr={data.title} descAr={data.description} descEn={data.description_en} idData={data.id} />
                                    <p className='fs-6'>{data.title}</p>
                                    <p>{data.description}</p>
                                </div>
                            )
                        })
                    ) : null
                }
            </div>


            <Modal show={show} onHide={handleClose} className='font py-5' centered>
                <Modal.Body className='text-center py-3'>
                    <img src={input} alt="delete" />

                    <p className='mt-4 fs-4'>هل انت متأكد من حفظ التعديلات؟ </p>
                    <span>سيتم حفظ جميع التعديلات، وسيتم تحديثها عند تأكيد الحفظ</span>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white" }}>
                        تاكيد الحفظ
                    </button>
                    <button className='btn' onClick={handleClose} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Policy
