import { DELETE_USERS, GET_ALL_USERS_ADMINS, GET_ALL_USERS_CLIENTS, DELETE_USER_SUCCESS } from "../Type";
const initial = {
    Get_Users: [],
    Get_Admins: [],
    DeletedUsers: [],
    loading: true,
}

const GetUsers = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_USERS_CLIENTS:
            return {
                ...state,
                Get_Users: action.payload,
                loading: false
            }
        case GET_ALL_USERS_ADMINS:
            return {
                ...state,
                Get_Admins: action.payload,
                loading: false
            }
        case DELETE_USERS:
            return {
                ...state,
                DeletedUsers: action.payload,
                loading: false
            }
        case 'UPDATE_USERS':
            return {
                ...state,
                Get_Users: {
                    ...state.Get_Users,
                    data: {
                        ...state.Get_Users.data,
                        clients: action.payload.clients
                    }
                }
            };
        case 'UPDATE_ADMINS':
            return {
                ...state,
                Get_Admins: {
                    ...state.Get_Admins,
                    data: {
                        ...state.Get_Admins.data,
                        admins: action.payload.admins
                    }
                }
            };
        default:
            return state;
    }
}

export default GetUsers