import baseUrl from "../../Api/baseURL";
import { GET_ALL_NOTIFICATIONS, GET_ALL_NOTIFICATIONS_BAQA, GET_ALL_NOTIFICATIONS_BAQA_END,UPDATE_NOTIFICATION, UPDATE_NOTIFICATION_ACTIVE , NOTIFICATION_USERS } from "../Type"
const GetNotifications = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=scheduled-washing-order', config)

            dispatch({ type: GET_ALL_NOTIFICATIONS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_NOTIFICATIONS, payload: e.res })
        }
    }
}
const GetNotificationsBaqa = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=subscription-extentded', config)

            dispatch({ type: GET_ALL_NOTIFICATIONS_BAQA, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_NOTIFICATIONS_BAQA, payload: e.res })
        }
    }
}
const GetNotificationsBaqaEnd = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=subscription-expiry', config)
            dispatch({ type: GET_ALL_NOTIFICATIONS_BAQA_END, payload: res.data })
            
        } catch (e) {
            dispatch({ type: GET_ALL_NOTIFICATIONS_BAQA_END, payload: e.res })
        }
    }
}
const UpdateNotifications = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/notification_messages/update?title_en=${data.title_en}&title_ar=${data.title_ar}&message_en=${data.message_en}&message_ar=${data.message_ar}&message_type=${data.type}`, data, config)

            dispatch({ type: UPDATE_NOTIFICATION, payload: res.data })
        } catch (e) {
            dispatch({ type: UPDATE_NOTIFICATION, payload: e.res })
        }
    }
}
const UpdateActiveNotifications = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/notification_messages/update/active-message/${data.active}?message_type=${data.type}`, data, config)

            dispatch({ type: UPDATE_NOTIFICATION_ACTIVE, payload: res.data })
        } catch (e) {
            dispatch({ type: UPDATE_NOTIFICATION_ACTIVE, payload: e.res })
        }
    }
}





export { GetNotifications, GetNotificationsBaqa, UpdateNotifications, UpdateActiveNotifications  , GetNotificationsBaqaEnd}