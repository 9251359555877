import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { MdCatchingPokemon, MdDelete } from "react-icons/md";
import { DeleteService, GetAllServices } from '../../redux/actions/ServicesAction';

const DeleteServiceModal = ({ serviceId }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);


    const dispatch = useDispatch();

    const handleDeleteData = async () => {
        try {
            setLoading(true)
            await dispatch(DeleteService({
                id: serviceId,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleCloseEdit("");
            dispatch(GetAllServices())
        }
    }
    return (
        <div>
            <MdDelete style={{ color: "#EB5757" }} size="20" cursor={"pointer"} onClick={handleShowEdit} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>الغاء الطلب  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد الغاء الطلب</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={handleDeleteData} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تاكيد الالغاء
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteServiceModal
