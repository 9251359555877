import baseUrl from "../../../Api/baseURL";
import { GET_LOGIN_USER } from "../../Type";

const LoginUser = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        try {
            let res = await baseUrl.post('/v1/auth/signin', data, config)

            dispatch({ type: GET_LOGIN_USER, payload: res })
        } catch (e) {
            dispatch({ type: GET_LOGIN_USER, payload: e.res })
        }
    }
}


export default LoginUser