import React from 'react'
import AdminStaticsDashboard from './Statics/AdminStaticsDashboard';
import Map from './Map';


const AdminDashboard = () => {
    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }
    const mapStyle = {
        width: '100%',
        height: '450px',
        border: '0',
    };


    return (
        <div className='font mt-3'>
            <div className='mt-2'>
                <Map />
            </div>
            {/* statistics */}
            <div className='mt-5 bg-light p-3 bickerMab'>
                <p>الاحصائيات</p>
            </div>


            {
                adminLogIn && adminLogIn.premessions ? (
                    adminLogIn.premessions.map((pre) => {
                        return (
                            pre === "user" ? (
                                <AdminStaticsDashboard />
                            ) : (null)
                        )
                    })
                ) : null
            }
        </div>
    )
}

export default AdminDashboard
