import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { SettingInvoiceData, UpdateSettingInvoiceData, UploadPhoto } from '../../redux/actions/InvoiceAction';
import { GetAboutUs, GetSocialMedia, UpdateAboutUs, UploadPhotoAbout } from '../../redux/actions/PrivacyPolicy';
import Frame from '../../Images/Frame.png'
import BackImage from "../../Images/Upload Image.png"
import EditSocial from '../Modals/EditSocialMedia';
import EditQA from '../Modals/ModalEditQA';
const WhoUsCom = () => {
    const [images, setImages] = useState([]);
    const handleImageUpload = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);
    }
    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };

    const [urlData, setUrl] = useState("")
    const dispatch = useDispatch();
    useEffect(() => {
        const formatData = new FormData();
        if (images.length > 0) {
            formatData.append("file", images[0])
            dispatch(UploadPhoto(formatData))
        }
    }, [images])

    const pathOfImages = useSelector(state => state.InvoiceReducer.UploadPhotoData);

    useEffect(() => {
        if (pathOfImages && pathOfImages.data) {
            dispatch(UpdateSettingInvoiceData({
                logo_app: pathOfImages.data.path
            }))
            dispatch(SettingInvoiceData())
            setImages([])
        }
    }, [pathOfImages])

    const setting = async () => {
        try {
            await dispatch(SettingInvoiceData())
            await dispatch(GetAboutUs())
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    useEffect(() => {
        setting();
    }, [dispatch])


    const settings = useSelector(state => state.InvoiceReducer.setting);
    const about = useSelector(state => state.PrivacyReducer.about);


    useEffect(() => {
        if (settings && settings.data) {
            setUrl(settings.data.logo_app)
        }
    }, [settings])

    const deleteData = async () => {
        try {
            await dispatch(UpdateSettingInvoiceData({
                logo_app: "string"
            }))
        } catch (e) {
            console.log(e);
        } finally {
            await dispatch(SettingInvoiceData());
            setImages([])
        }
    }

    const fetchSocial = async () => {
        try {
            await dispatch(GetSocialMedia())
        } catch (e) {
            console.log(e);
        }
    }


    useEffect(() => {
        fetchSocial();
    }, [dispatch])


    const Connects = useSelector(state => state.PrivacyReducer.Social)




    const [tiktok, setTikTok] = useState("");
    const [twitter, setTwitrer] = useState("");
    const [instgram, setInstgram] = useState("");
    const [snap, setSnap] = useState("");
    const [isEdited, setIsEdited] = useState(false);
    const [isEditedTwo, setIsEditedTwo] = useState(false);
    const [isEditedThree, setIsEditedThree] = useState(false);

    useEffect(() => {
        if (Connects && Connects.data) {
            setTikTok(Connects.data[0].link)
            setTwitrer(Connects.data[1].link)
            setInstgram(Connects.data[2].link)
            setSnap(Connects.data[3].link)
        }
    }, [Connects])


    const [imagesBack, setImagesBack] = useState([]);
    const handleImageUploadBack = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImagesBack(selectedImages);
    }
    const handleLabelClickBack = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('filedata');
        fileInput.click();
    };



    useEffect(() => {
        const formatDatadata = new FormData();
        if (imagesBack.length > 0) {
            formatDatadata.append("file", imagesBack[0])
            dispatch(UploadPhotoAbout(formatDatadata))
        }
    }, [imagesBack])

    const pathOfImagesBack = useSelector(state => state.PrivacyReducer.uploadPhotoData);
    // console.log(pathOfImagesBack);

    useEffect(() => {
        if (pathOfImagesBack && pathOfImagesBack.data) {
            dispatch(UpdateAboutUs({
                background_image_url: pathOfImagesBack.data.path
            }))
            // dispatch(SettingInvoiceData())
            dispatch(GetAboutUs())
            setImagesBack([])
        }
    }, [pathOfImagesBack])

    const [editedConnects, setEditedConnects] = useState([]);


    
    return (
        <div className='font'>
            <div className='flexItem'>
                <div className='font d-flex justify-content-between align-items-center'>
                    <div>
                        <p>شعار الشركه</p>
                        <span style={{ color: "#8B8D97" }}>سيتم عرض هذا في ملفك الشخصي وملخص الفاتورة.</span>
                    </div>
                    <div className="file-input">
                        <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                        {/* <img src={Logo} alt="Select images" onClick={handleLabelClick} /> */}
                        {
                            images.length !== 0 ? (
                                <div className="image-container">
                                    {images.map((image, index) => (
                                        <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                            style={{ width: "200px", height: "100px" }} />
                                    ))}
                                </div>
                            ) : (<div>
                                {
                                    settings && settings.data ? (
                                        <div style={{ backgroundImage: `url(${urlData})`, width: "220px", height: "220px", borderRadius: "50%", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}></div>
                                    ) : (<span>Data</span>)
                                }
                            </div>)

                        }
                    </div>



                </div>
                <div>
                    <button className='btn' onClick={handleLabelClick} style={{ color: "#828282" }}>تعديل </button>
                    <button className='btn' style={{ color: "#EB5757" }} onClick={deleteData}>حذف</button>
                </div>
            </div>



            {/* Back Ground For Mobile */}
            <div>
                <div>
                    <p>الخلفيه</p>
                    <span style={{ color: "#8B8D97" }}>سيتم عرضها في صفحة من نحن الخاصة بالتطبيق.</span>
                </div>

                <div className="image-container">
                    <input type="file" id="filedata" accept="image/*" multiple={false} max={`4`} onChange={handleImageUploadBack} style={{ visibility: "hidden" }} />
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            {
                                imagesBack.length !== 0 ? (
                                    <div className="image-container">
                                        {imagesBack.map((image, index) => (
                                            <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                                style={{ width: "200px", height: "100px" }} />
                                        ))}
                                    </div>
                                ) : (<div>
                                    {
                                        about && about.data ? (
                                            <div style={{ backgroundImage: `url(${about.data.background_image_url})`, width: "50vw", height: "20vh", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}></div>
                                        ) : (<span>Data</span>)
                                    }
                                </div>)

                            }
                        </div>
                        <div onClick={handleLabelClickBack} style={{ cursor: "pointer", padding: "10px", textAlign: "center", background: "white", borderRadius: "10px" }}>
                            <img src={Frame} alt="Frame" />
                            <p><span>Upload a cover image for your product</span></p>
                        </div>
                    </div>
                </div>
            </div>



            <div>

                <div className='bickerMab bg-light mt-3'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div>
                            <p>محتوي الصفحة</p>
                        </div>
                    </div>
                </div>


                <div className='bickerMab p-3 mt-3' style={{ background: "white" }}>
                    {
                        about.data ? (<EditQA
                            questionAr={about.data.title}
                            answerAr={about.data.title_en}
                            question_en={about.data.description_en}
                            answer_en={about.data.description}
                            section={"aboutUs"}
                        />) : null
                    }
                    {
                        about && about.data ? (
                            <div>
                                <div style={{ color: "rgba(171, 175, 177, 1)", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                                    <p>{about.data.title}</p>
                                </div>
                                <div className='mt-2'>
                                    <span>تفاصيل واجابة السؤال</span>
                                </div>

                                <div style={{ color: "rgba(171, 175, 177, 1)", padding: "10px", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                                    <p>
                                        {about.data.description}
                                    </p>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
            </div>




            <div>
                <div className='bickerMab bg-light mt-3'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div>
                            <p>روابط التواصل</p>
                        </div>
                    </div>
                </div>


                {
                    Connects && Connects.data ? (
                        Connects.data.map((connect) => {
                            console.log(connect)
                            return (
                                <div className='bickerMab bg-light mt-3 p-3'>
                                    <div className='mt-2 d-flex align-items-center'>
                                        <label><img src={connect.icon} alt="data" /></label>
                                        <input type="text" value={connect.scheme} className='mt-2' style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رقم الهاتف للتواصل' />
                                        <EditSocial icon={connect.icon} text={connect.scheme} idData={connect.id} />
                                    </div>

                                </div>
                            )
                        })
                    ) : null
                }
            </div>

        </div>
    )
}

export default WhoUsCom
