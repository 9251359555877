import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { FaFilePdf } from "react-icons/fa";
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useDispatch, useSelector } from 'react-redux'
import { DonwloadInvoice, GetAllInvoices } from '../../redux/actions/InvoiceAction';
import { FaSearch } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import Pagination from '../../Utils/Pagination';
import axios from 'axios';
import fileDownload from 'js-file-download'; // استيراد المكتبة


const UsersInvoice = () => {



    //Primitive Const
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('DESC');
    const [currentPage, setCurrentPage] = useState(1);

    //Import All Data Invoices
    const dispatch = useDispatch();

    const fetchAllInvoices = async (sort, searchValue, page) => {
        await dispatch(GetAllInvoices({ sortType: sort, searchValue: searchValue, pageCount: page }))
    }

    useEffect(() => {
        if (searchValue === "") {
            fetchAllInvoices(sort, searchValue, currentPage);
        } else if (searchValue !== "") {
            fetchAllInvoices(sort, searchValue, currentPage);
        }
    }, [dispatch, sort, currentPage])


    const invoices = useSelector(state => state.InvoiceReducer.invoices)


    const getPage = (page) => {
        setCurrentPage(page);
    };


    const searchInvouceNumber = async () => {
        try {
            setCurrentPage(1)
            fetchAllInvoices(sort, searchValue, currentPage)
        } catch (e) {
            console.log(e);
        }
    }



    //Download Invoice
    const DownloadPdf = async (id) => {
        console.log(id);
        try {
            await dispatch(DonwloadInvoice({
                id: id
            }))
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    const PdfUrlData = useSelector(state => state.InvoiceReducer.Download);



    useEffect(() => {
        if (PdfUrlData && PdfUrlData.data) {
            try {
                const link = document.createElement('a');
                link.href = PdfUrlData.data;
                link.setAttribute('download', 'invoice.pdf'); // تحديد اسم الملف
                link.setAttribute('target', '_blank')

                // إضافة العنصر إلى الوثيقة والنقر عليه للتنزيل
                document.body.appendChild(link);
                link.click();

                // إزالة العنصر بعد التنزيل
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
    }, [PdfUrlData])


    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={searchInvouceNumber} />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" value={sort} onChange={(e) => setSort(e.target.value)}>
                        <option hidden> الترتيب تنازلي</option>
                        <option value="DESC"> تنازلي</option>
                        <option value="ASC"> تصاعدي</option>
                    </Form.Select>
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>رقم الفاتورة</th>
                            <th>اسم المستخدم</th>
                            <th>رقم الجوال</th>
                            <th>نسبة الضريبة</th>
                            <th>المجموع الفرعي</th>
                            <th>المجموع النهائي</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            invoices && invoices.data ? (
                                invoices.data.map((invoice, index) => {
                                    // console.log(invoice)
                                    return (
                                        <tr key={invoice.id}>
                                            <td>{index + 1}</td>
                                            <td>{invoice.invoice_number}</td>
                                            {/* <td>{invoice.id}</td> */}
                                            {
                                                invoice.customer && invoice.customer.user ? (<td>{invoice.customer.user.first_name} {invoice.customer.user.last_name}</td>) : (<td>لا يوجد</td>)
                                            }
                                            {
                                                invoice.customer && invoice.customer.user ? (<td>{invoice.customer.user.phone}</td>) : (<td>لا يوجد</td>)
                                            }
                                            <td>{invoice.vat}%</td>
                                            <td>{invoice.total_price} ر.س</td>
                                            <td>{invoice.total_price_net} ر.س</td>
                                            <td></td>
                                            <td><FaFilePdf cursor={"pointer"} onClick={() => { DownloadPdf(invoice.id) }} /></td>
                                        </tr>
                                    )
                                })
                            ) : (null)
                        }
                    </tbody>
                </Table>
                {invoices && invoices.meta && (
                    <Pagination onPress={getPage} countsPage={invoices.meta.total / 10} />
                )}
            </div>
        </div>
    )
}

export default UsersInvoice
