import { ADD_CODE, GET_ALL_CODES, DELETE_CODE, UPDATE_CODE, GET_SINGLE_CODE, UPDATE_MAIN_CODE } from "../Type";
const initial = {
    PromoCodes: [],
    AddCodes: [],
    DeleteCode: [],
    UpdateCode: [],
    SingleCode: [],
    MainCode: [],
    loading: true,
}

const PromoCodesReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_CODES:
            return {
                ...state,
                PromoCodes: action.payload,
                loading: false
            }
        case ADD_CODE:
            return {
                ...state,
                AddCodes: action.payload,
                loading: false
            }
        case DELETE_CODE:
            return {
                ...state,
                DeleteCode: action.payload,
                loading: false
            }
        case UPDATE_CODE:
            return {
                ...state,
                UpdateCode: action.payload,
                loading: false
            }
        case GET_SINGLE_CODE:
            return {
                ...state,
                SingleCode: action.payload,
                loading: false
            }
        case UPDATE_MAIN_CODE:
            return {
                ...state,
                MainCode: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default PromoCodesReducer