import { CREATE_NEW_BAQA, DELETE_BAQA, GET_ALL_BAQA, UPDATE_BAQA,EDIT_COUNT_SERVICES, UPDATE_BAQA_DATA, UPLOAD_PHOTO ,UPDATE_BAQA_DATA_ACTIVE, POST_NEW_SERVICES_PACKAGE, DELETE_SERVIES} from "../Type";
const initial = {
    Baqa: [],
    CreateBaqa: [],
    DeleteBaqa: [],
    UpdateData: [],
    UpdateDataInfo:[],
    UploadPhotoData:[],
    activeBaqa:[],
    getSinglepackage:[],
    DeleteServices:[],
    addServices:[],
    countServices:[],
    loading: true,
}

const BaqaReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_BAQA:
            return {
                ...state,
                Baqa: action.payload,
                loading: false
            }
        case CREATE_NEW_BAQA:
            return {
                ...state,
                CreateBaqa: action.payload,
                loading: false
            }
        case DELETE_BAQA:
            return {
                ...state,
                DeleteBaqa: action.payload,
                loading: false
            }
        case UPDATE_BAQA:
            return {
                ...state,
                UpdateData: action.payload,
                loading: false
            }
        case UPDATE_BAQA_DATA:
            return {
                ...state,
                UpdateDataInfo: action.payload,
                loading: false
            }
        case UPDATE_BAQA_DATA_ACTIVE:
            return {
                ...state,
                activeBaqa: action.payload,
                loading: false
            }
        case UPLOAD_PHOTO:
            return {
                ...state,
                UploadPhotoData: action.payload,
                loading: false
            }
        case DELETE_SERVIES:
            return {
                ...state,
                DeleteServices: action.payload,
                loading: false
            }
        case POST_NEW_SERVICES_PACKAGE:
            return {
                ...state,
                addServices: action.payload,
                loading: false
            }
        case EDIT_COUNT_SERVICES:
            return {
                ...state,
                countServices: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default BaqaReducer