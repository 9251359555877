import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UsersInvoice from './UsersInvoice';
import SettingInvoice from './SettingInvoice';
const InvoiceManage = () => {
    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }


    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة الفواتير</p>
                </div>
                {/* <div>
                    <button className='btn' style={{ color: "black", background: "#FECB44" }}>اصدار فاتورة مجمعه</button>
                </div> */}
            </div>
            <div className='mt-4'>
                <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3 tabContainer"
                >
                    {
                        adminLogIn && adminLogIn.premessions ? (
                            adminLogIn.premessions.map((pre) => {
                                return (
                                    pre === "order-invoice" ? (
                                        <Tab eventKey="home" className='font' title="فواتير المستخدمين">
                                            <UsersInvoice />
                                        </Tab>
                                    ) : null
                                )
                            })
                        ) : null
                    }
                    {/* <Tab eventKey="home" className='font' title="فواتير المستخدمين">
                        <UsersInvoice />
                    </Tab> */}
                    {/* <Tab eventKey="profile" className='font' title="اعدادات الفواتير">
                        <SettingInvoice/>
                    </Tab> */}
                    {
                        adminLogIn && adminLogIn.premessions ? (
                            adminLogIn.premessions.map((pre) => {
                                return (
                                    pre === "app-constants" ? (
                                        <Tab eventKey="profile" className='font' title="اعدادات الفواتير">
                                            <SettingInvoice />
                                        </Tab>
                                    ) : null
                                )
                            })
                        ) : null
                    }
                </Tabs>
            </div>
        </div>
    )
}

export default InvoiceManage
