import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import input from "../../Images/Input.png"
import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
import { GetSocialMedia, UpdateSocialMedia } from '../../redux/actions/PrivacyPolicy';

const EditSocial = ({ icon , text , idData }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();

    const [urlData , setUrlData] = useState(text)


    const handleUpdate = async()=>{
        try{
            await dispatch(UpdateSocialMedia({
                id:idData,
                scheme:urlData,
            }))
        }catch(e){
            console.log(e);
        }finally{
            handleCloseEdit();
            await dispatch(GetSocialMedia())
        }
    }



    return (
        <div>
            <button className='btn' onClick={handleShowEdit} style={{ fontWeight: "bolder", color: "rgba(168, 89, 35, 1)" }}>تعديل</button>
            <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
                <Modal.Body className='text-center py-3'>
                <img src={icon} alt="data"/>
                    <p className='mt-4 fs-4'>هل تريد تعديل  رابط المنصه  ؟؟</p>
                    <input type="text" value={urlData} className='mt-2'
                    onChange={(e)=>setUrlData(e.target.value)}
                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white"  }} onClick={handleUpdate}>
                        تاكيد الحفظ
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditSocial
