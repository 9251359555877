import { GET_ALL_APP_CONSTANTS, UPDATE_ALL_APP_CONSTANTS } from "../Type";
const initial = {
    Constants: [],
    ConstantsUpdates: [],
    loading: true,
}

const ConstantsApp = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_APP_CONSTANTS:
            return {
                ...state,
                Constants: action.payload,
                loading: false
            }
        case UPDATE_ALL_APP_CONSTANTS:
            return {
                ...state,
                ConstantsUpdates: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default ConstantsApp