import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { AddPromoCode, DeletePromoCode, DeleteSingleCode, GetAllPromoCodes } from '../../redux/actions/PromoCodeAction';
import Pagination from '../../Utils/Pagination';
import { GetAllBaqa } from '../../redux/actions/BaqaAction';
import { IoCloseSharp } from "react-icons/io5";
import DeleteCode from '../Modals/DeleteCode';
import UpdatePromoCode from '../Modals/UpdatePromoCode';
import { ErrorMsg, success } from '../../Utils/Toast';
import { FaRegCopy } from "react-icons/fa6";
import { RiChatDeleteFill } from "react-icons/ri";
import DateTime from 'react-datetime';
import moment from 'moment';


const Discount = () => {
    const [searchValue, setSearchValue] = useState('');
    const [prevPage, setPrevPage] = useState(1);


    const handleSearch = (e) => {

        setSearchValue(e.target.value);

    };

    //Manage Date with Time For Banners
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateEnd, setSelectedDateEnd] = useState("");

    const handleDateChange = (date) => {
        console.log(date._d.toISOString());
        // const selectedDate = new Date(date._d);
        // selectedDate.setHours(selectedDate.getHours() + 2);
        // const modifiedDate = selectedDate.toISOString();
        setSelectedDate(date._d.toISOString());
    };

    const handleDateChangeEnd = (date) => {
        console.log(date._d.toISOString());
        // const selectedDateEnd = new Date(date._d);
        // selectedDateEnd.setHours(selectedDateEnd.getHours() + 2);
        // const modifiedDate = selectedDateEnd.toISOString();
        setSelectedDateEnd(date._d.toISOString());
    };



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [baqaId, setBaqaId] = useState('');
    const [lengthPackages, setLengthpackages] = useState('');
    const [codeId, setCodeId] = useState('');

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (id, count, codeGeneral) => {
        setShowEdit(true);
        setBaqaId(id)
        console.log(count);
        console.log(codeGeneral);
        setLengthpackages(count)
        setCodeId(codeGeneral);
    };

    //Get All codes
    const dispatch = useDispatch();
    const [sort, setSort] = useState("DESC");
    const [currentPage, setCurrentPage] = useState(1);

    const fetchAllCodes = async (page, sort, searchValue) => {
        await dispatch(GetAllPromoCodes({ page: page, sort: sort, search: searchValue }));
    }


    useEffect(() => {
        fetchAllCodes(currentPage, sort, searchValue)
    }, [dispatch, sort, currentPage, searchValue])


    const codes = useSelector(state => state.PromoCodesReducer.PromoCodes);

    // console.log(codes)


    const handleCopyCode = (code) => {
        navigator.clipboard.writeText(code);
        success(` ${code} تم نسخ اسم الكود`)
    };



    const getPage = (page) => {
        if (page !== prevPage) {
            setCurrentPage(page);
            // if (searchValue === "") {
            //     fetchAllCodes(page, sort, searchValue);
            // }
            setPrevPage(page); // تحديث الصفحة السابقة
        }
    };


    const fetchAllBaqa = async () => {
        await dispatch(GetAllBaqa());
    }

    useEffect(() => {
        if (show === true) {
            fetchAllBaqa();
        }
    }, [show])



    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);





    //Add Promo Code
    const [selectedBaqat, setSelectedBaqat] = useState([]); // قائمة لتخزين الباقات المحددة

    const handleBaqa = async (e) => {
        const serviceId = e.target.value;
        setSelectBaqa(e.target.value);
        // console.log(e.target.value)
        if (!selectedBaqat.includes(serviceId)) {
            setSelectedBaqat([...selectedBaqat, serviceId]);
        }
    }
    const handleRemoveService = (serviceIdToRemove) => {
        const updatedServices = selectedBaqat.filter(serviceId => serviceId !== serviceIdToRemove);
        setSelectedBaqat(updatedServices);
    };


    const [nameCode, setNameCode] = useState('');
    const [selectBaqa, setSelectBaqa] = useState(null);
    const [discount, setDiscount] = useState("");
    const [max_use_by_users, setMax_use_by_users] = useState('');
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");

    const [baqaDiscounts, setBaqaDiscounts] = useState({});

    const handleBaqaDiscountChange = (serviceId, discount) => {
        setBaqaDiscounts({ ...baqaDiscounts, [serviceId]: parseInt(discount) });

    };


    const addPromoCode = async () => {
        const promoCodePackageRequest = selectedBaqat.map(pkg => ({
            package_id: pkg,
            discount: (baqaDiscounts[pkg])
        }));
        try {
            if (selectedDateEnd !== "" && selectBaqa !== null && max_use_by_users !== "" && selectedDate !== "" && nameCode !== "") {
                // console.log(promoCodePackageRequest)
                await dispatch(AddPromoCode({
                    start_time: selectedDate,
                    end_time: selectedDateEnd,
                    code: nameCode,
                    promoCodePackageRequest: promoCodePackageRequest,
                    max_use_by_users: parseInt(max_use_by_users)
                }))
            } else {
                if (selectedDateEnd === "") {
                    ErrorMsg("يرجي ادخال وقت النهايه");
                    return;
                }
                if (selectedDate === "") {
                    ErrorMsg("يرجي ادخال وقت بدايه");
                    return;
                }
                if (nameCode === "") {
                    ErrorMsg("اسم الكود مطلوب");
                    return;
                }

                if (promoCodePackageRequest.length === 0) {
                    ErrorMsg("يرجي ادخال الباقه بالخصم");
                    return;
                }

                if (selectBaqa === "") {
                    ErrorMsg("يرجي اختيار الياقه");
                    return;
                }

                if (max_use_by_users === 0 || max_use_by_users === "") {
                    ErrorMsg("يرجي ادخال عدد  مرات الاستخدام");
                    return;
                }

            }
        } catch (e) {
            console.log(e);
        } finally {
            if (selectedDateEnd !== "" && selectBaqa !== null && max_use_by_users !== "" && selectedDate !== "" && nameCode !== "") {
                handleClose();
                fetchAllCodes(currentPage, sort);
                setNameCode("");
                setSelectBaqa([]);
                setSelectedBaqat([]);
                setDiscount("");
                setMax_use_by_users("");
                setStartTime("");
                setEndTime("");
            }
        }
    }



    const deleteSingleBaqa = async () => {
        try {
            if (lengthPackages === 1) {

                await dispatch(DeletePromoCode({
                    id: codeId
                }))
            } else {
                await dispatch(DeleteSingleCode({
                    id: baqaId
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            handleCloseEdit();
            fetchAllCodes(currentPage, sort, searchValue)
        }
    }


    const validDate = (current) => {
        return current.isAfter(moment().subtract(1, 'days')); // Ensure current date is after today
    };

    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة الاكواد</p>
                </div>
                <div>
                    <button onClick={handleShow} className='btn' style={{ color: "black", background: "#FECB44" }}>اضافة كود خصم جديد</button>
                </div>
            </div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={handleSearch} />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setSort(e.target.value)}>
                        <option hidden>تاريخ الانشاء: تنازلي</option>
                        <option value="DESC">تنازلي</option>
                        <option value="ASC">تصاعدي</option>
                    </Form.Select>
                </div>

            </div>

            <div className='mt-4'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>اسم الكوبون</th>
                            <th>حالة الكوبون</th>
                            <th>عدد الاستخدامات الحاليه</th>
                            <th>عدد مرات الاستخدام</th>
                            <th>اسم الباقه</th>
                            <th>نسبة الخصم</th>
                            <th>تاريخ الانشاء</th>
                            <th>تاريخ بداية الكوبون</th>
                            <th>تاريخ نهاية الكوبون</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            codes && codes.data ? (
                                codes.data.map((code, index) => {
                                    console.log(code)
                                    const createdAt = code ? code.created_at.split('T')[0] : 'لايوجد معاد';
                                    const start_time = code ? code.start_time.split('T')[0] : 'لايوجد معاد';
                                    const end_time = code ? code.end_time.split('T')[0] : 'لايوجد معاد';
                                    return (
                                        <tr key={index}>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <div className="cell-content">
                                                    <div>{code.code}</div>
                                                    <button onClick={() => handleCopyCode(code.code)} style={{ marginLeft: "25px", padding: "5px", borderRadius: "5px", border: "none", cursor: 'pointer' }}>
                                                        <FaRegCopy />
                                                    </button>
                                                </div>
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                {
                                                    code.is_valid ? (<span>ساري</span>) : (<span>منتهي</span>)
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{code.current_uses}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{code.max_use_by_users}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                {
                                                    code.promo_code_packages ? (
                                                        code.promo_code_packages.map((baqa, index) => {
                                                            // console.log(baqa);
                                                            return (
                                                                <p>{baqa.package.name} <RiChatDeleteFill onClick={() => handleShowEdit(baqa.id, code.promo_code_packages.length, code.id)} cursor={"pointer"} title={`حذف الباقه ${baqa.package.name}`} /></p>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                {
                                                    code.promo_code_packages ? (
                                                        code.promo_code_packages.map((baqa, index) => {
                                                            return (
                                                                <p>{baqa.discount * 100}%</p>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{createdAt}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{start_time}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{end_time}</td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <UpdatePromoCode idData={code.id} currentPage={currentPage} sort={sort} searchWord={searchValue} discountData={code.discount} packageID={code.package_id} StartTime={code.start_time} EndTime={code.end_time} />
                                            </td>
                                            <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>
                                                <DeleteCode idData={code.id} currentPage={currentPage} sort={sort} searchWord={searchValue} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : null
                        }
                    </tbody>
                </Table>
            </div>


            {codes && codes.meta && (
                <Pagination onPress={getPage} countsPage={codes.meta.total / 10} />
            )}


            {/* Modal For Add  Promo Code */}

            <Modal show={show} centered onHide={handleClose} className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة كود خصم</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>حدد كود الخصم</span>
                    <div className='mt-3'>
                        <input type="text" value={nameCode} onChange={(e) => setNameCode(e.target.value)} className='w-100' placeholder="اسم الكوبون" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" value={selectBaqa} onChange={handleBaqa} style={{ background: "#EFF1F9" }}>
                            <option hidden> الباقات</option>
                            {
                                dataBaqat && dataBaqat.data ? (
                                    dataBaqat.data.map((baqa, index) => {
                                        return (
                                            <option value={baqa.id} key={baqa.id}>{baqa.name} </option>
                                        )
                                    })
                                ) : (null)
                            }
                        </Form.Select>
                        {selectedBaqat.length > 0 && (
                            <div className="mt-4">
                                {selectedBaqat.map((serviceId, index) => (
                                    <div className=" mx-1 my-2" key={index} style={{ background: "#FFFAEC", padding: "10px", borderRadius: "10px" }}>
                                        <p className="mb-0">{dataBaqat.data.find(baqa => baqa.id === serviceId).name}</p>
                                        <input
                                            type="number"
                                            style={{ border: "1px solid", outline: "none", marginTop: "5px", borderRadius: "10px", padding: "5px" }}
                                            min="0"
                                            value={baqaDiscounts[serviceId] || ""}
                                            onChange={(e) => handleBaqaDiscountChange(serviceId, e.target.value)}
                                            className="mx-2"
                                            placeholder="Discount"
                                        />
                                        <IoCloseSharp onClick={() => handleRemoveService(serviceId)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <div className='mt-4'>
                        <input type="number" min="1" value={max_use_by_users} onChange={(e) => setMax_use_by_users(e.target.value)} className='w-100' placeholder="عدد مرات الاستخدام" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <label>تاريخ بداية الكوبون</label>
                        <DateTime className='w-100 mt-2 textInputBiker'
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                            utc={false} isValidDate={validDate} onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />

                    </div>
                    <div className='mt-3'>
                        <label>تاريخ انتهاء الكوبون</label>
                        <DateTime className='w-100 mt-2 textInputBiker'
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                            utc={false} isValidDate={validDate} onChange={handleDateChangeEnd} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={addPromoCode}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>



            {/* Modal For Confirm delete package */}
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>حذف الباقه الخاصه بالكود</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد حذف  الباقه الخاصه بالكود</h2>
                    <p>يرجي العلم ان لو هذه هي الباقه الوحيده سيتم حذف الكوبون معها ايضا</p>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={deleteSingleBaqa} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تاكيد الحذف
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Discount