import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { ADD_NEW_COLOR, DELETE_COLOR, GET_ALL_COLORS, GET_SINGLE_COLOR, UPDATE_COLOR } from '../Type'

//get All Colors
const GetAllColors = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/color', config)

            dispatch({ type: GET_ALL_COLORS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_COLORS, payload: e.res })
            console.log(e)
        }
    }
}


//Delete Colors
const DeleteColor = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/color/${data.id}/delete-single-color`, config)
            dispatch({ type: DELETE_COLOR, payload: res.data })
        } catch (e) {
            dispatch({ type: DELETE_COLOR, payload: e.res })
            console.log(e)
        }
    }
}


//Add New Color
const AddNewColor = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post('v1/color', data, config)
            dispatch({ type: ADD_NEW_COLOR, payload: res.data })
            console.log(res);
            if (res.status === 201) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: ADD_NEW_COLOR, payload: e.res })
            console.log(e)
            if (e.response.status === 422) {
                ErrorMsg(`${e.response.data.message}`)
            }
        }
    }
}

//Update Color
const UpdateColor = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/color/${data.id}/update-single-color`, data, config)
            dispatch({ type: UPDATE_COLOR, payload: res.data })

        } catch (e) {
            dispatch({ type: UPDATE_COLOR, payload: e.res })
            if (e.response.status === 422) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
const GetSingleColor = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/color/${data.id}/get-single-color`, data, config)
            dispatch({ type: GET_SINGLE_COLOR, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_SINGLE_COLOR, payload: e.res })
        }
    }
}

export { GetAllColors, AddNewColor, DeleteColor, UpdateColor  , GetSingleColor}