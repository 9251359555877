import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import input from "../../Images/Input.png"
import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
import { GetAboutUs, GetQuestionsAndAnswers, GetSocialMedia, UpdateAboutUs, UpdateQA, UpdateSocialMedia } from '../../redux/actions/PrivacyPolicy';
import { CiEdit } from "react-icons/ci";

const EditQA = ({
    idData,
    questionAr,
    answerAr,
    question_en,
    answer_en,
    section }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();

    const [quAr, setQuar] = useState(questionAr)
    const [anAr, setAnar] = useState(answerAr)
    const [quEn, setQuen] = useState(question_en)
    const [anEn, setAnen] = useState(answer_en)


    const handleUpdate = async () => {
        try {
            if (section !== "aboutUs") {
                await dispatch(UpdateQA({
                    id: idData,
                    question_ar: quAr,
                    answer_ar: anAr,
                    question_en: quEn,
                    answer_en: anEn
                }))
            } else {
                await dispatch(UpdateAboutUs({
                    title_ar: quAr,
                    title_en: anAr,
                    description_ar: anEn,
                    description_en: quEn,
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            handleCloseEdit();
            if (section !== "aboutUs") {
                await dispatch(GetQuestionsAndAnswers());
            } else {
                await dispatch(GetAboutUs())
            }
        }
    }



    return (
        <div>
            {/* <button className='btn' onClick={handleShowEdit} style={{ fontWeight: "bolder", color: "rgba(168, 89, 35, 1)" }}>تعديل</button> */}
            <CiEdit onClick={handleShowEdit}  size={"20"}  cursor={"pointer"} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
                {
                    section !== "aboutUs" ? (
                        <Modal.Body className='text-center py-3'>

                            <div>
                                <label>السؤال بالعربي</label>
                                <input type="text" value={quAr} className='mt-2'
                                    onChange={(e) => setQuar(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>
                            <div>
                                <label>الاجابه بالعربي</label>
                                <input type="text" value={anAr} className='mt-2'
                                    onChange={(e) => setAnar(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>
                            <div>
                                <label>السؤال بالانجليزي</label>
                                <input type="text" value={quEn} className='mt-2'
                                    onChange={(e) => setQuen(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>
                            <div>
                                <label>الاجابه بالانجليزي</label>
                                <input type="text" value={anEn} className='mt-2'
                                    onChange={(e) => setAnen(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>



                        </Modal.Body>
                    ) : (
                        <Modal.Body className='text-center py-3'>

                            <div>
                                <label>العنوان بالعربي</label>
                                <input type="text" value={quAr} className='mt-2'
                                    onChange={(e) => setQuar(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>
                            <div>
                                <label> العنوان بالانجليزي</label>
                                <input type="text" value={anAr} className='mt-2'
                                    onChange={(e) => setAnar(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>
                            <div>
                                <label>الوصف بالانجليزي</label>
                                <input type="text" value={quEn} className='mt-2'
                                    onChange={(e) => setQuen(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>
                            <div>
                                <label>الوصف بالعربي</label>
                                <input type="text" value={anEn} className='mt-2'
                                    onChange={(e) => setAnen(e.target.value)}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                            </div>



                        </Modal.Body>
                    )
                }
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white" }} onClick={handleUpdate}>
                        تاكيد الحفظ
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditQA
