import React from 'react'
import { Col, Row } from "react-bootstrap";
import AdminSideBar from '../../Components/DashboardComponents/AdminSideBar';
import NavBarDashboard from '../../Components/DashboardComponents/NavBarDashboard';
import MessageMain from '../../Components/DashboardComponents/MessageComponents/MessageMain';
const ManageMEssagePage = () => {
    return (
        <div>

            <Row className="py-3">
                <Col sm="5" xs="3" md="2" lg="2">
                    <AdminSideBar />
                </Col>
                <Col sm="7" xs="9" className='px-5' md="10" lg="10">
                    <NavBarDashboard />
                    <MessageMain />
                </Col>
            </Row>

        </div>
    )
}

export default ManageMEssagePage
