import baseUrl from "../../Api/baseURL";
import { ErrorMsg } from "../../Utils/Toast";
import { GET_ALL_SMS, DELETE_SMS, ADD_SMS } from '../Type'

//get All Sms Messages
const GetAllSms = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.searchValue === "") {
                // let res = await baseUrl.get(`v1/sms?page=${data.pageCount}&limit=10&sortBy=created_at%3D${data.sortType}`, config)
                let res = await baseUrl.get(`v1/sms?page=${data.pageCount}&limit=10&filters=type%3Dsend-to-users-from-admin&sortBy=created_at%3D${data.sortType}`, config)
                dispatch({ type: GET_ALL_SMS, payload: res.data })
            } else if (data.searchValue !== "") {
                let res = await baseUrl.get(`v1/sms?page=1&limit=10&filters=user.phone%3D${data.searchValue}&sortBy=created_at%3D${data.sortType}`, config)
                dispatch({ type: GET_ALL_SMS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SMS, payload: e.res })
            console.log(e)
        }
    }
}

//Delete Sms Message
const DeleteSms = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/sms/${data.id}`, config)

            dispatch({ type: DELETE_SMS, payload: res.data })
        } catch (e) {
            dispatch({ type: DELETE_SMS, payload: e.res })
            console.log(e)
        }
    }
}
const AddSms = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.phone === "") {
                let res = await baseUrl.post(`v1/sms/users?sender_id=${data.senderKey}&description=${data.desc}`, config)
                dispatch({ type: ADD_SMS, payload: res.data })
            } else if (data.phone !== "") {
                let res = await baseUrl.post(`v1/sms/users?sender_id=${data.senderKey}&description=${data.desc}&phone=%2B${data.phone}`, config)
                dispatch({ type: ADD_SMS, payload: res.data })
                console.log(res)
            }
            
        } catch (e) {
            dispatch({ type: ADD_SMS, payload: e.res })
            console.log(e)
            if(e.response.status === 422){
                ErrorMsg(e.response.data.message.message[0])
            }else if(e.response.status === 404){
                ErrorMsg(e.response.data.message)
            }
        }
    }
}

export { GetAllSms, DeleteSms, AddSms }