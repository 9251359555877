import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { MdDelete } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import AddServicesModal from '../Modals/AddServicesModal';
import EditServicesModal from '../Modals/EditServicesModal';
import { useDispatch, useSelector } from 'react-redux'
import { DeleteService, GetAllServices, SearchService, UpdateService } from '../../redux/actions/ServicesAction';
import { FaSearch } from "react-icons/fa";
import DeleteServiceModal from '../Modals/DeleteService';

const ServiceComponent = () => {

    const dispatch = useDispatch();
    const [services, setServices] = useState([]);



    const getServices = async () => {
        await dispatch(GetAllServices());
    }


    useEffect(() => {
        getServices();
    }, [])

    const resultServices = useSelector(state => state.ServicesReducer.allServices)

    useEffect(() => {
        if (resultServices && resultServices.data) {
            setServices(resultServices.data);
        }
    }, [resultServices])


    const handleServiceAdded = () => {
        getServices();
    };


    const handleDeleteService = async (id) => {
        await dispatch(DeleteService({
            id,
        }))

        // dispatch(GetAllServices());
        setServices(prevServices => prevServices.filter(service => service.id !== id));

    }



    //handle Search 

    const [searchValue, setSearchValue] = useState('');

    const handleSearch = () => {
            dispatch(SearchService({
                name: searchValue
            }))
    };

    const updateServiceLocally = async (updatedService) => {
        setServices(prevServices =>
            prevServices.map(service =>
                service.id === updatedService.id ? updatedService : service
            )
        );
        await dispatch(GetAllServices())
    };

    const UpdateActive = async (name,nameEn, price, id, active) => {
        try {
            // تحديث حالة الخدمة في قاعدة البيانات
            await dispatch(UpdateService({
                service_id: id,
                name_ar: `${name}`,
                name_en: `${nameEn}`,
                duration_by_minute: 10,
                price: parseInt(price),
                is_active: !active
            }));

            // تحديث حالة الخدمة في الواجهة
            setServices(prevServices =>
                prevServices.map(service =>
                    service.id === id ? { ...service, is_active: !active } : service
                )
            );
        } catch (error) {
            console.error("Error updating service:", error);
        }
    }

    return (
        <div className='font'>
            <div className='flexItem font py-3'>
                <div>
                    <p>اضافة خدمه جديده</p>
                </div>
                <div>
                    <AddServicesModal onServiceAdded={handleServiceAdded} />
                </div>
            </div>
            <div>
                <div className='flexItem justify-content-start mt-4'>
                    <div className='mx-3 search-container'>
                        <input
                            type="search"
                            className='form-control search-input'
                            name="search"
                            id="search"
                            placeholder='ابحث عن.....'
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <FaSearch className="search-icon" onClick={handleSearch} />
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>اسم الخدمة</th>
                            <th>سعر الخدمة</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            services ? (
                                services.map((service, index) => {
                                    // console.log(service);
                                    return (
                                        <tr key={service.id}>
                                            <td>{index + 1}</td>
                                            <td>{service.name}</td>
                                            <td>{service.price} ر.س</td>
                                            {/* <td>{service.id}</td> */}
                                            <td>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={service.is_active}
                                                    onClick={() => UpdateActive(service.name,service.name_en, service.price, service.id, service.is_active)}
                                                />
                                            </td>
                                            <td style={{ cursor: "pointer" }}><EditServicesModal serviceName={service.name} serviceNameEn={service.name_en} servicePrice={service.price} serviceId={service.id} service={service} updateServiceLocally={updateServiceLocally} /></td>
                                            <td>
                                                <DeleteServiceModal serviceId={service.id} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : null
                        }



                    </tbody>
                </Table>
            </div>
            {/* <MdDelete style={{ color: "#EB5757" }} size="20" cursor={"pointer"} onClick={() => handleDeleteService(service.id)} /> */}








        </div>
    )
}

export default ServiceComponent
