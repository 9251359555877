import './App.css'
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from './Pages/LoginPage/Login'
import Dashboard from './Pages/Dashboard/Dashboard';
import AdminBooking from './Pages/Dashboard/AdminBooking';
import UsersAccounts from './Pages/Dashboard/UsersAccounts';
import CarsDetailsPage from './Pages/Dashboard/CarsDetailsPage';
import SlotsPage from './Pages/Dashboard/SlotsPage';
import DiscountPage from './Pages/Dashboard/DiscountPage';
import Invoice from './Pages/Dashboard/Invoice';
import ManageBycker from './Pages/Dashboard/ManageBycker';
import BikerDetails from './Components/ManageBikers/BikerDetails';
import BikerSetting from './Pages/Dashboard/BikerSetting';
import AreaManage from './Pages/Dashboard/AreaManage';
import { ToastContainer, toast } from 'react-toastify';
import NewBiker from './Pages/Dashboard/NewBiker';
import Service from './Pages/Dashboard/Service';
import Baqu from './Pages/Dashboard/Baqu';
import AdsPage from './Pages/Dashboard/AdsPage';
import GiftPage from './Pages/Dashboard/GiftPage';
import PointsPage from './Pages/Dashboard/PointsPage'
import ManageMEssagePage from './Pages/Dashboard/ManageMessagePage';
import RateManagement from './Pages/Dashboard/RateManagement';
import Notification from './Pages/Dashboard/Notification';
import OTPInput from './Utils/OtpPage';
import EditArea from './Components/Area/EditArea';
import { useEffect, useState } from 'react';
import AutoLogout from './Utils/AutoLogout';
import UpdateAreaData from './Components/Area/UpdateArea';
import AllPoints from './Pages/Dashboard/Allpoints';
import Aboutus from './Pages/Dashboard/AboutUs';
import PolicyPage from './Pages/Dashboard/PolicyPage';
import Terms from './Pages/Dashboard/Terms';
import WhoUs from './Pages/Dashboard/WhoUs';

function App() {

  const timeoutMinutes = 15;

  return (
    <div className="App">
      <AutoLogout timeoutMinutes={timeoutMinutes} />

      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route index element={<LoginPage />} />
          <Route path='/admin/dashboard' element={<Dashboard />} />
          <Route path='/admin/booking' element={< AdminBooking />} />
          <Route path='/admin/verify-otp' element={< OTPInput />} />
          <Route path='/admin/users' element={< UsersAccounts />} />
          <Route path='/admin/cars' element={< CarsDetailsPage />} />
          <Route path='/admin/slots' element={< SlotsPage />} />
          <Route path='/admin/discount' element={< DiscountPage />} />
          <Route path='/admin/invoice' element={<Invoice />} />
          <Route path='/admin/managebiker' element={<ManageBycker />} />
          <Route path='/admin/bikersetting/:id/:role' element={<BikerSetting />} />
          <Route path='/admin/usersetting/:id/:role' element={<BikerSetting />} />
          <Route path='/admin/newbiker' element={<NewBiker />} />
          <Route path='/admin/areamanage' element={<AreaManage />} />
          <Route path='/admin/service' element={<Service />} />
          <Route path='/admin/baqu' element={<Baqu />} />
          <Route path='/admin/ads' element={<AdsPage />} />
          <Route path='/admin/gift' element={<GiftPage />} />
          <Route path='/admin/points' element={<PointsPage />} />
          <Route path='/admin/managemessage' element={<ManageMEssagePage />} />
          <Route path='/admin/ratemanage' element={<RateManagement />} />
          <Route path='/admin/notification' element={<Notification />} />
          <Route path='/admin/add-area' element={<EditArea />} />
          <Route path='/admin/edit-area/:id' element={<UpdateAreaData />} />
          <Route path='/admin/all-points/:id' element={<AllPoints />} />
          <Route path='/admin/about' element={<Aboutus />} />
          <Route path='/admin/policy' element={<PolicyPage />} />
          <Route path='/admin/terms' element={<Terms />} />
          <Route path='/admin/us' element={<WhoUs/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
