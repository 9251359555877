import baseUrl from "../../../Api/baseURL"
import { ErrorMsg } from "../../../Utils/Toast";
import { SEND_OTP, VERIFY_OTP } from "../../Type"

const VerifyOtp = (data) => {
    console.log(data);
    return async (dispatch) => {
        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem("token")}`
        //     }
        // }
        try {
            let res = await baseUrl.post('v1/auth/verify-otp', data)
            dispatch({ type: VERIFY_OTP, payload: res })
            // console.log(res);
        } catch (e) {
            dispatch({ type: VERIFY_OTP, payload: e.res })
            console.log(e)
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
const SendOtp = (data) => {
    console.log(data);
    return async (dispatch) => {
        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem("token")}`
        //     }
        // }
        try {
            let res = await baseUrl.post('v1/auth/send-otp', data)
            dispatch({ type: SEND_OTP, payload: res })
            console.log(res);
        } catch (e) {
            dispatch({ type: SEND_OTP, payload: e.res })
            console.log(e)
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}

export { VerifyOtp, SendOtp }