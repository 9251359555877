import React from 'react'
import Form from 'react-bootstrap/Form';
import Saud from '../../Images/saud.png'
import { IoMdNotificationsOutline } from "react-icons/io";

const NavBarDashboard = () => {
    const navStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px',
        backgroundColor: '#FFFFFF',
        color: 'white',
        maxWidth: '100%',
        margin: "auto",
    };
    var userData = {};
    if(localStorage.getItem("user")){
        userData = JSON.parse(localStorage.getItem("user"))
    }
    return (
        <nav style={navStyle} className='navBar'>
            <div className='w-50 mx-auto'>
                <input type='search' style={{ background: "#F3F4F6" }} placeholder='بحث' className='font form-control' />
            </div>
            
            <div className='mx-4 font' style={{width:"15%"}}>
                <Form.Select aria-label="Default select example" style={{ border: "none", color: "#80808E" }}>
                    <option style={{ color: "#80808E" }} value="اللغه العربيه"> <img src={Saud} alt="saud" /> اللغه العربيه</option>
                    <option style={{ color: "#80808E" }} value="اللغه الانجليزيه">اللغه الانجليزيه</option>
                </Form.Select>
            </div>
            <div>
                <IoMdNotificationsOutline style={{color:"#FECB44" , marginLeft:"35px"}}  size={"30"}/>
            </div>
            <div className='circle'>
                <p>AD</p>
            </div>
        </nav>
    )
}

export default NavBarDashboard
