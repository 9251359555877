import { GET_ALL_NOTIFICATIONS, GET_ALL_NOTIFICATIONS_BAQA, GET_ALL_NOTIFICATIONS_BAQA_END, UPDATE_NOTIFICATION_ACTIVE } from "../Type";
const initial = {
    Notify: [],
    NotifyBaqa: [],
    NotifyBaqaEnd: [],
    UpdateActive: [],
    loading: true,
}

const GetUsers = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_NOTIFICATIONS:
            return {
                ...state,
                Notify: action.payload,
                loading: false
            }
        case GET_ALL_NOTIFICATIONS_BAQA:
            return {
                ...state,
                NotifyBaqa: action.payload,
                loading: false
            }
        case GET_ALL_NOTIFICATIONS_BAQA_END:
            return {
                ...state,
                NotifyBaqaEnd: action.payload,
                loading: false
            }
        case UPDATE_NOTIFICATION_ACTIVE:
            return {
                ...state,
                UpdateActive: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default GetUsers