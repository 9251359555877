import baseUrl from "../../Api/baseURL";
import { GET_ALL_APP_CONSTANTS , UPDATE_ALL_APP_CONSTANTS } from '../Type'

const GetConstantsApp = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/app-constants', config)

            dispatch({ type: GET_ALL_APP_CONSTANTS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_APP_CONSTANTS, payload: e.res })
        }
    }
}
const PutConstantsApp = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put('v1/app-constants', data, config)

            dispatch({ type: UPDATE_ALL_APP_CONSTANTS, payload: res.data })
        } catch (e) {
            dispatch({ type: UPDATE_ALL_APP_CONSTANTS, payload: e.res })
            console.log(e)
        }
    }
}

export { GetConstantsApp, PutConstantsApp }