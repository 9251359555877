import { ADD_NEW_SLOT, DELETE_SLOT, GET_ALL_SLOTS, GET_VARIABLE, UPDATE_SLOT, UPDATE_USER, UPDATE_VARIABLE } from "../Type";
const initial = {
    Get_Slots: [],
    Add_Slot: [],
    Del_slot: [],
    UpdateSlot: [],
    GetVar: [],
    UpdateVar: [],
    updateUserData: [],
    loading: true,
}

const GetSlots = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_SLOTS:
            return {
                ...state,
                Get_Slots: action.payload,
                loading: false
            }
        case ADD_NEW_SLOT:
            return {
                ...state,
                Add_Slot: action.payload,
                loading: false
            }
        case DELETE_SLOT:
            return {
                ...state,
                Del_slot: action.payload,
                loading: false
            }
        case UPDATE_SLOT:
            return {
                ...state,
                UpdateSlot: action.payload,
                loading: false
            }
        case GET_VARIABLE:
            return {
                ...state,
                GetVar: action.payload,
                loading: false
            }
        case UPDATE_VARIABLE:
            return {
                ...state,
                UpdateVar: action.payload,
                loading: false
            }
        case UPDATE_USER:
            return {
                ...state,
                updateUserData: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default GetSlots