import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetBikersOrders } from '../../redux/actions/Bikers';
import Spinner from 'react-bootstrap/Spinner';
import { OrderStatus } from '../../redux/actions/OrdersAdmin';

const SubsList = () => {
    
    const currentDate = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const formattedDate = currentDate.toLocaleDateString('en-GB', options).split('/').reverse().join('-');

    const [dateCalender, setDateCalendar] = useState(formattedDate);
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(GetBikersOrders());
    }, [])

    useEffect(() => {
        setLoading(true);
        dispatch(GetBikersOrders({
            status: status,
            date: dateCalender
        }))
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });

    }, [status, dateCalender, dispatch]);

    const OrdersBikers = useSelector(state => state.GetBikers.Orders);





    const groupedOrdersByBiker = {};
    if (OrdersBikers) {
        OrdersBikers.forEach((order_biker) => {
            const bikerName = order_biker.name;
            if (!groupedOrdersByBiker[bikerName]) {
                groupedOrdersByBiker[bikerName] = [];
            }
            order_biker.orders.forEach((orderDetails) => {
                groupedOrdersByBiker[bikerName].push(orderDetails);
            });
        });
    }

    const bikerNamesWithDivs = Object.keys(groupedOrdersByBiker).filter(bikerName => groupedOrdersByBiker[bikerName].length >= 0);
    const noOrdersForToday = Object.keys(groupedOrdersByBiker).length === 0;

    const getBeforeElementStyle = (backgroundColor) => ({
        content: '""',
        position: 'absolute',
        right: 0,
        top: 0,
        marginLeft: '15px',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        width: '5px',
        height: '98%',
        backgroundColor,
    });

    const getStatusColor = (status) => {
        switch (status) {
            case "CREATED":
                return '#828282';
            case "CANCELLED":
                return '#EB5757';
            case "COMPLETED":
                return 'rgb(60, 196, 65)';
            case "BIKER_ON_THE_WAY":
                return '#AD6423';
            case "BIKER_ARRIVED":
                return '#6D5743';
            case "STARTED":
                return '#E87610';
            default:
                return '#000000';
        }
    };

    const getBackGroundColor = (status) => {
        switch (status) {
            case "CREATED":
                return '#AD642329';
            case "CANCELLED":
                return '#EB575729';
            case "COMPLETED":
                return '#3CC44129';
            case "BIKER_ON_THE_WAY":
                return '#AD642329';
            case "BIKER_ARRIVED":
                return '#6D574329';
            case "STARTED":
                return '#E8761029';
            default:
                return '#000000';
        }
    };

    useEffect(() => {
        dispatch(OrderStatus());
    }, [dispatch])

    const ordersStatusData = useSelector(state => state.OrdersAdminReducer.OrdersStatus)

    // {
    //     ordersStatusData ? (
    //       ordersStatusData.map((orderData , index)=>{
    //         return(
    //           <option key={index+1} value={orderData.value}>{orderData.name}</option>
    //         )
    //       })
    //     ):(null)
    //   }

    const [selectedStatus, setSelectedStatus] = useState(""); // حالة تحديد الزرار

    return (
        <div className='font list' style={{ background: "white", borderRadius: "10px", padding: "10px" }}>
            <div className='flexItem'>
                <div>
                    <p style={{ fontSize: "20px" }}>قائمة الحجوزات والاوقات المتاحة</p>
                </div>

                <div>

                    {
                        ordersStatusData ? (
                            ordersStatusData.map((orderData, index) => {
                                return (
                                    // <option key={index + 1} value={orderData.value}>{orderData.name}</option>
                                    <button
                                        key={index + 1}
                                        className={`btn subsButton ${selectedStatus === `${orderData.value}` ? 'selected' : ''}`}
                                        style={{ color: getStatusColor(orderData.value), background: getBackGroundColor(orderData.value), border: selectedStatus === `${orderData.value}` ? '2px solid #AD6423' : 'none' }}
                                        value={orderData.value}
                                        onClick={(e) => {
                                            if (selectedStatus === `${orderData.value}`) {
                                                setStatus("");
                                                setSelectedStatus("");
                                            } else {
                                                setStatus(e.target.value);
                                                setSelectedStatus(`${orderData.value}`);
                                            }
                                        }}
                                    >
                                        {orderData.name}
                                    </button>
                                )
                            })
                        ) : null
                    }



                </div>

                <div>
                    <input type="date" value={dateCalender} onChange={(e) => setDateCalendar(e.target.value)} className='w-100' min="2005-01-01" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "10px", borderRadius: "10px" }} />
                </div>
            </div>

            {/* Time Available Byckers */}
            <div className='flexItem mt-3 byckerAvailable px-2'>
                {loading && <Spinner animation="border" style={{ textAlign: "center", margin: "auto" }} role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}
                {!loading && noOrdersForToday && <p>No orders available for today.</p>}
                {!loading && !noOrdersForToday && (
                    <div className='BigInfoBycker'>
                        <div className=''>
                            {bikerNamesWithDivs.map((bikerName) => (
                                <div key={bikerName} className=''>
                                    <div className='row justify-content-start align-items-center'>
                                        <div className='col-lg-1 py-4'>{bikerName}</div>
                                        <div className='d-flex col-lg-11' style={groupedOrdersByBiker[bikerName].length > 5 ? { width: "90%", overflowX: "scroll" } : {}}>
                                            {
                                                groupedOrdersByBiker[bikerName].length > 0 ? (
                                                    groupedOrdersByBiker[bikerName].map((orderDetails) => (
                                                        <div className='col-lg-3' key={orderDetails.number}>
                                                            <div className='byckerInfo w-100 mb-5 mt-5'>
                                                                <div style={getBeforeElementStyle(getStatusColor(orderDetails.status))}></div>
                                                                {orderDetails.biker !== null ? (
                                                                    <p style={{ color: '#7E8299', fontSize: '20px' }}><span style={{ fontSize: '14px' }}>البايكر: </span>{orderDetails.biker.user.first_name} {orderDetails.biker.user.last_name}</p>
                                                                ) : <p style={{ color: '#7E8299', fontSize: '20px' }}><span style={{ fontSize: '14px' }}>البايكر: </span>لا يوجد اسم للبايكر</p>}
                                                                <p style={{ color: '#7E8299', fontSize: '18px' }}><span style={{ fontSize: '14px' }}>رقم الطلب: </span>{orderDetails.number}</p>
                                                                <p style={{ color: getStatusColor(orderDetails.status) }}><span style={{ fontSize: '14px' }}>حالة الطلب: </span>{orderDetails.status}</p>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <span>لا توجد بيانات</span>
                                                )
                                            }

                                        </div>

                                    </div>
                                </div>
                            ))}
                            {
                                (!loading && !noOrdersForToday && bikerNamesWithDivs.length === 0) &&
                                <div style={{ width: "90%", margin: "auto" }}>
                                    <p className='text-center'>
                                        لاتوجد اي طلبات
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubsList;
