import { GET_ALL_BIKERS, GET_ORDERS, GET_ORDERS_BIKERS, GET_ALL_MANAGE_BIKERS, UPDATE_STATUS, GET_ERRORS, DELETE_BIKER, BIKER_PROFILE, PROFILE_UPDATE_BIKER, ADD_NEW_BIKER, GET_ALL_BIKERS_DATA, PROFILE_UPDATE_BIKER_DATA } from "../Type";
const initial = {
    AllBikers: [],
    AllBikersDATA: [],
    Orders: [],
    Bikers_Orders: [],
    Get_all_Manage_Bikers: [],
    bikerStatus: [],
    Delete_Bikers: [],
    Biker_Profile: [],
    Profile_Update_Bikers: [],
    Errors: [],
    addBikers: [],
    Profile_Update_Bikers_Data:[],
    loading: true,
}

const GetBikers = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_BIKERS:
            return {
                ...state,
                AllBikers: action.payload,
                loading: false
            }
        case GET_ALL_BIKERS_DATA:
            return {
                ...state,
                AllBikersDATA: action.payload,
                loading: false
            }
        case GET_ORDERS:
            return {
                ...state,
                Orders: action.payload,
                loading: false
            }
        case GET_ORDERS_BIKERS:
            return {
                ...state,
                Bikers_Orders: action.payload,
                loading: false
            }
        case GET_ALL_MANAGE_BIKERS:
            return {
                ...state,
                AllBikers: action.payload,
                loading: false
            }
        case UPDATE_STATUS:
            return {
                ...state,
                bikerStatus: action.payload,
                loading: false
            }
        case BIKER_PROFILE:
            return {
                ...state,
                Biker_Profile: action.payload,
                loading: false
            }
        case DELETE_BIKER:
            return {
                ...state,
                // AllBikers: state.AllBikers.filter(biker => biker.user_id !== action.payload),
                Delete_Bikers: action.payload,
                loading: false
            }
        case PROFILE_UPDATE_BIKER:
            return {
                ...state,
                Profile_Update_Bikers: action.payload,
                loading: false
            }
        case PROFILE_UPDATE_BIKER_DATA:
            return {
                ...state,
                Profile_Update_Bikers_Data: action.payload,
                loading: false
            }
        case ADD_NEW_BIKER:
            return {
                ...state,
                addBikers: action.payload,
                loading: false
            }
        case GET_ERRORS:
            return {
                ...state,
                Errors: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default GetBikers