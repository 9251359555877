import baseUrl from "../../Api/baseURL";
import { DELETE_SUPPORT, GET_ABOUT, GET_ALL_PRIVACY,ADD_QA, GET_ALL_QA, GET_ALL_SOCIAL, GET_ALL_SUPPORT, GET_ALL_TERMS, UPDATE_ABOUT, UPDATE_ALL_CONNECTS, UPDATE_PRIVACY, UPDATE_QA, UPDATE_SOCIAL_MEDIA, UPDATE_TERMS, UPLADO_DATA_ABOUT } from '../Type'

//get All Areas
const GetPrivacy = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get('v1/privacy-policy', config)

            dispatch({ type: GET_ALL_PRIVACY, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_PRIVACY, payload: e.res })
            console.log(e)
        }
    }
}
const GetTermsConditions = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get('v1/terms-conditions', config)

            dispatch({ type: GET_ALL_TERMS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_TERMS, payload: e.res })
            console.log(e)
        }
    }
}
const GetQuestionsAndAnswers = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get('v1/questions-answers/all-questions', config)

            dispatch({ type: GET_ALL_QA, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_QA, payload: e.res })
            console.log(e)
        }
    }
}
const GetSupport = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get('v1/support', config)

            dispatch({ type: GET_ALL_SUPPORT, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_SUPPORT, payload: e.res })
            console.log(e)
        }
    }
}
const UpdateConnects = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.put(`v1/support/${data.id}/update-support`, data, config)

            dispatch({ type: UPDATE_ALL_CONNECTS, payload: res.data })
        } catch (e) {
            dispatch({ type: UPDATE_ALL_CONNECTS, payload: e.res })
            console.log(e)
        }
    }
}


const GetSocialMedia = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get(`v1/social-media`, data, config)

            dispatch({ type: GET_ALL_SOCIAL, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_SOCIAL, payload: e.res })
            console.log(e)
        }
    }
}
const GetAboutUs = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get(`v1/about-us`, config)

            dispatch({ type: GET_ABOUT, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ABOUT, payload: e.res })
            console.log(e)
        }
    }
}
const UpdateAboutUs = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.put(`v1/about-us`, data, config)

            dispatch({ type: UPDATE_ABOUT, payload: res.data })
        } catch (e) {
            dispatch({ type: UPDATE_ABOUT, payload: e.res })
            console.log(e)
        }
    }
}


const UploadPhotoAbout = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.post(`v1/storage`, data, config)
            dispatch({ type: UPLADO_DATA_ABOUT, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPLADO_DATA_ABOUT, payload: e.res })
            console.log(e)
        }
    }
}
const UpdateSocialMedia = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.put(`v1/social-media/${data.id}/Update-single-SocialMedia`, data, config)
            dispatch({ type: UPDATE_SOCIAL_MEDIA, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_SOCIAL_MEDIA, payload: e.res })
            console.log(e)
        }
    }
}




const UpdateQA = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.patch(`v1/questions-answers/${data.id}/update-question`, data, config)
            dispatch({ type: UPDATE_QA, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_QA, payload: e.res })
            console.log(e)
        }
    }
}



const UpdatePrivacy = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.put(`v1/privacy-policy/${data.id}/update-privacy-policy`, data, config)
            dispatch({ type: UPDATE_PRIVACY, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_PRIVACY, payload: e.res })
            console.log(e)
        }
    }
}
const UpdateTerms = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.put(`v1/terms-conditions/${data.id}/update-terms-conditions`, data, config)
            dispatch({ type: UPDATE_TERMS, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_TERMS, payload: e.res })
            console.log(e)
        }
    }
}



const DeleteSupport = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.delete(`v1/questions-answers/${data.id}/delete-question`, config)
            dispatch({ type: DELETE_SUPPORT, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: DELETE_SUPPORT, payload: e.res })
            console.log(e)
        }
    }
}

const AddQuestionData = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                // "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.post(`v1/questions-answers/create-question`,data, config)
            dispatch({ type: ADD_QA, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: ADD_QA, payload: e.res })
            console.log(e)
        }
    }
}






export { GetPrivacy, GetTermsConditions, UpdatePrivacy, UpdateTerms, GetQuestionsAndAnswers, GetSupport,AddQuestionData, UpdateQA,DeleteSupport, UpdateConnects, GetSocialMedia, GetAboutUs, UpdateAboutUs, UploadPhotoAbout, UpdateSocialMedia }