// import React, { useEffect, useState } from 'react';

// const AutoLogout = ({ timeoutMinutes }) => {
//     const [timeoutId, setTimeoutId] = useState(null);
//     useEffect(() => {
//         const resetTimeout = () => {
//             clearTimeout(timeoutId);
//             startTimeout();
//         };

//         const startTimeout = () => {
//             setTimeoutId(setTimeout(logout, timeoutMinutes * 60 * 1000));
//         };

//         const logout = () => {
//             // Perform the logout actions here
//             // For example, redirect the user to the logout page or clear session data
//             // window.location.href = '/logout';
//             // ...
//             localStorage.removeItem('user')
//             localStorage.removeItem('token')
//             window.location.href = '/';
//         };

//         const handleUserActivity = () => {
//             resetTimeout();
//         };

//         // Attach event listeners
//         document.addEventListener('mousemove', handleUserActivity);
//         document.addEventListener('keypress', handleUserActivity);
//         // Add more event listeners as needed for your specific application

//         startTimeout();

//         // Clean up event listeners when the component unmounts
//         return () => {
//             clearTimeout(timeoutId);
//             document.removeEventListener('mousemove', handleUserActivity);
//             document.removeEventListener('keypress', handleUserActivity);
//         };
//     }, [timeoutMinutes, timeoutId]);

//     return null; // AutoLogout component doesn't render anything
// };

// export default AutoLogout;



import React, { useEffect, useRef } from 'react';

const AutoLogout = ({ timeoutMinutes }) => {
    const timeoutIdRef = useRef(null);

    useEffect(() => {
        const resetTimeout = () => {
            clearTimeout(timeoutIdRef.current);
            startTimeout();
        };

        const startTimeout = () => {
            timeoutIdRef.current = setTimeout(logout, timeoutMinutes * 60 * 1000);
        };

        const logout = () => {
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            window.location.href = '/';
        };

        const handleUserActivity = () => {
            resetTimeout();
        };

        // Attach event listeners
        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keypress', handleUserActivity);
        // Add more event listeners as needed for your specific application

        startTimeout();

        // Clean up event listeners when the component unmounts
        return () => {
            clearTimeout(timeoutIdRef.current);
            document.removeEventListener('mousemove', handleUserActivity);
            document.removeEventListener('keypress', handleUserActivity);
        };
    }, [timeoutMinutes]);

    return null; // AutoLogout component doesn't render anything
};

export default AutoLogout;
