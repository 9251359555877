import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useSpring, animated } from 'react-spring';
import Cars from './Cars';
import ManageColors from './ManageColors';
import { Link } from 'react-router-dom';
import AddNewBrand from '../Modals/AddNewBrand';
import Modal from 'react-bootstrap/Modal'
import { AddNewBrandData, GetAllBrands, GetBrandsDetails } from '../../redux/actions/CarActions';
import { ErrorMsg } from '../../Utils/Toast';
import { useDispatch, useSelector } from 'react-redux'
import Brand from './Brand';


const AnimatedTabContent = ({ isActive, children }) => {
    const props = useSpring({
        opacity: isActive ? 1 : 0,
        transform: isActive ? 'translateY(0)' : 'translateY(20px)',
    });

    return <animated.div style={props}>{children}</animated.div>;
};
const ManageCars = () => {
    // Animation
    const [activeTab, setActiveTab] = useState('home');
    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };



    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");

    const handleClose = () => {
        setShow(false);
        setNameAr("");
        setNameEn("");
        setImages([]);
        setValidTitleEn(false);
        setValidTitleAr(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const [images, setImages] = useState([]);
    const handleImageUpload = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);
    }
    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };



    const dispatch = useDispatch();

    const UploadNewBrand = async () => {
        const formData = new FormData();


        try {
            if (nameAr !== "" && nameEn !== "" && images.length !== 0) {
                setLoading(true);
                formData.append("name_ar", nameAr);
                formData.append("name_en", nameEn);
                formData.append("logo", images[0]); // استخدام الصورة الأولى
                await dispatch(AddNewBrandData(formData));
            } else {
                if (images.length === 0) {
                    ErrorMsg('الصوره مفقوده');
                } else if (nameAr === "" && nameEn === "") {
                    ErrorMsg('الاسماء مفقودة');
                } else if (nameAr === "") {
                    ErrorMsg('الاسم بالعربي مفقود');
                } else if (nameEn === "") {
                    ErrorMsg('الاسم الانجليزي مفقود');
                }

            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            if (nameAr !== "" && nameEn !== "" && images.length !== 0) {
                handleClose();
                setNameAr("");
                setNameEn("");
                setImages([]);
                setValidTitleEn(false);
                setValidTitleAr(false)
            }
            // handleClose();
                dispatch(GetAllBrands());
                dispatch(GetBrandsDetails({
                    pageCount: 1,
                    sort:"DESC",
                    searchWord:"",
                }));
            
        }
    }


    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);

    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }


    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة معلومات السيارة</p>
                </div>
                <div className='d-flex'>
                    <button onClick={handleShow} className='btn' style={{ color: "black", background: "#FECB44" }}>اضافة براند جديدة</button>
                    <AddNewBrand />
                </div>
            </div>
            <div className='mt-4'>
                <Tabs
                    activeKey={activeTab}
                    onSelect={handleTabSelect}
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="mb-3 tabContainer"
                >
                    <Tab eventKey="home" className='font' title="ادارة  السيارات">
                        <AnimatedTabContent isActive={activeTab === 'home'}>
                            {
                                adminLogIn && adminLogIn.premessions ? (
                                    adminLogIn.premessions.map((pre) => {
                                        return (
                                            pre === "vehicle" ? (
                                                <Cars />
                                            ) : null
                                        )
                                    })
                                ) : null
                            }
                            {/* <Cars /> */}
                        </AnimatedTabContent>
                    </Tab>
                    <Tab eventKey="profile" className='font' title="ادارة الالوان السيارات">
                        <AnimatedTabContent isActive={activeTab === 'profile'}>
                            {
                                adminLogIn && adminLogIn.premessions ? (
                                    adminLogIn.premessions.map((pre) => {
                                        return (
                                            pre === "color" ? (
                                                <ManageColors />
                                            ) : null
                                        )
                                    })
                                ) : null
                            }
                            {/* <ManageColors /> */}
                        </AnimatedTabContent>
                    </Tab>
                    <Tab eventKey="brands" className='font' title="ادارة البراندات">
                        {/* <AnimatedTabContent isActive={activeTab === 'brand'}> */}
                            <Brand/>
                        {/* </AnimatedTabContent> */}
                    </Tab>
                </Tabs>
            </div>



            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة براند جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>معلومات سيارة</span>
                    <div className=''>
                        <div>
                            <div className="w-100" style={{ padding: "5px", borderRadius: "10px", width: "100%" }}>
                                {images.map((image, index) => (
                                    <div>
                                        <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                            style={{ width: "400px", borderRadius: "10px" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="file-input" style={{ padding: "20px" }}>
                            <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                            <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameAr}
                            onChange={(e) => {
                                setNameAr(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidTitleAr(true)
                                } else {
                                    setValidTitleAr(false);
                                }
                            }}
                            placeholder='اسم البراند بالعربي' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                    }
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameEn}
                            onChange={(e) => {
                                setNameEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidTitleEn(true)
                                } else {
                                    setValidTitleEn(false);
                                }
                            }}
                            placeholder='اسم البراند بالانجليزية' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={UploadNewBrand}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>



            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }
        </div>
    )
}

export default ManageCars
