import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoEllipseSharp, IoPencil } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux'
import { UpdateService } from '../../redux/actions/ServicesAction';
import { findAllByPlaceholderText } from '@testing-library/react';
import { ErrorMsg } from '../../Utils/Toast';

const EditServicesModal = ({ service, serviceId, updateServiceLocally, serviceName, servicePrice , serviceNameEn }) => {

    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    // if (service) {
    //     console.log(service);
    // }

    const [nameServiceAr, setNameServiceAr] = useState(serviceName);
    const [nameServiceEn, setNameServiceEn] = useState(serviceNameEn);
    const [price, setPrice] = useState(servicePrice);
    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);

    const dispatch = useDispatch();
    const updateService = async () => {
        try {
            setLoading(true)
            if (nameServiceAr !== "" && nameServiceEn !== "" && price !== "" && !validTitleAr && !validTitleEn) {
                await dispatch(UpdateService({
                    service_id: serviceId,
                    name_ar: `${nameServiceAr}`,
                    name_en: `${nameServiceEn}`,
                    duration_by_minute: 10,
                    price: parseFloat(price),

                }))
                const updatedService = {
                    ...service,
                    name_ar: nameServiceAr,
                    name_en: nameServiceEn,
                    price: parseFloat(price),
                };

                if (updateServiceLocally) {
                    updateServiceLocally(updatedService);
                }
            } else {
                ErrorMsg("Data Missed")
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            setNameServiceAr("");
            setNameServiceEn("");
            setPrice("");
            handleCloseEdit();
        }
    }
    return (
        <div>
            {/*Edit  Modal */}
            <IoPencil size="20" onClick={handleShowEdit} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>تعديل خدمة  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameServiceAr} onChange={(e) => {
                            setNameServiceAr(e.target.value);
                            if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                setValidTitleAr(true)
                            } else {
                                setValidTitleAr(false);
                            }
                        }} placeholder='اسم الخدمه بالعربيه'  style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                    }
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameServiceEn} onChange={(e) => {
                            setNameServiceEn(e.target.value);
                            if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                setValidTitleEn(true)
                            } else {
                                setValidTitleEn(false);
                            }
                        }} placeholder='اسم الخدمه بالانجليزيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                    }
                    <div className='mt-3'>
                        <input type="number" className='w-100' value={price}
                            onChange={(e) => {
                                const inputPrice = parseFloat(e.target.value);
                                if (inputPrice >= 0) {
                                    setPrice(inputPrice);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace' || e.key === 'Delete') {
                                    setPrice(''); // قم بتعيين القيمة إلى فارغة عندما يتم استخدام مفاتيح المسح
                                }
                            }}
                            placeholder='سعر الخدمه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateService}>
                        اضافه
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleCloseEdit}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditServicesModal
