import React from 'react'
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import recieveMoney from "../../../Images/money-recive.png"
import recieveMoney1 from "../../../Images/money-recive1.png"
import recieveMoney2 from "../../../Images/money-recive2.png"
const AdminApplication = () => {
    return (
        <div>
            <Container>
                {/* <Row className="py-3">
                    <Col sm="12" xs="12" md="6" lg="4">
                        <div className='statics'>
                            <div className='font p-4 d-flex justify-content-around align-items-center'>
                                <div style={{ background: "#F5F5F8", width: "50px", height: "50px", borderRadius: "50%" }}>
                                    <img className='w-100' src={recieveMoney} alt="static" />
                                </div>
                                <div>
                                    <p>اخر معاملة علي التطبيق</p>
                                    <small className=''>88.00 ر.س</small>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='p-1 text-center font'>
                                <p>كامل الرصيد الموجود في حسابك الان</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" xs="12" md="6" lg="4">
                        <div className='statics'>
                            <div className='font p-4 d-flex justify-content-around align-items-center'>
                                <div style={{ background: "#F5F5F8", width: "50px", height: "50px", borderRadius: "50%" }}>
                                    <img className='w-100' src={recieveMoney1} alt="static" />
                                </div>
                                <div>
                                    <p>اجمالي عدد الطلبات بالتطبيق</p>
                                    <small className=''>140.00 ر.س</small>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='p-1 text-center font'>
                                <p>كامل الرصيد الموجود في حسابك الان</p>
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" xs="12" md="6" lg="4">
                    <div className='statics'>
                            <div className='font p-4 d-flex justify-content-around align-items-center'>
                                <div style={{background:"#F5F5F8" , width:"50px" , height:"50px" , borderRadius:"50%"}}>
                                    <img className='w-100' src={recieveMoney2} alt="static" />
                                </div>
                                <div>
                                    <p>الرصيد الإجمالي للمعاملات</p>
                                    <small className=''>1,400.00 ر.س</small>
                                </div>
                            </div>
                            <hr></hr>
                            <div className='p-1 text-center font'>
                                <p>كامل الرصيد الموجود في حسابك الان</p>
                            </div>
                        </div>
                    </Col>
                </Row> */}
            </Container>
        </div>
    )
}

export default AdminApplication
