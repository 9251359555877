import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { MdDelete } from "react-icons/md";
import { DeleteArea, GetAllAreas } from '../../redux/actions/AreaActions';


const DeletedArea = ({ areaId, onDelete }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    if (showEdit) {
        console.log(areaId);
    }

    const dispatch = useDispatch();

    const handleDeleteArea = async () => {
        try {
            setLoading(true);

            await onDelete(areaId);

        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleCloseEdit();
            // dispatch(GetAllAreas())
        }
    }

    return (
        <div>
            <MdDelete onClick={handleShowEdit} style={{ cursor: "pointer" }} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Body>
                    <h2>هل تريد حذف المنطقه</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleDeleteArea}>
                        تاكيد الحذف
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeletedArea
