import { GET_LOGIN_USER, SEND_OTP, VERIFY_OTP } from "../../Type"

const initial = {
    Verify: [],
    otpSend:[],
    loading: true,
}

const VerifyReducer = (state = initial, action) => {
    switch (action.type) {
        case VERIFY_OTP:
            return {
                ...state,
                Verify: action.payload,
                loading: false
            }
        case SEND_OTP:
            return {
                ...state,
                otpSend: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default VerifyReducer