import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SendOtp, VerifyOtp } from '../redux/actions/Login/VerifyOtpAction';
import { ErrorMsg, success } from './Toast';

function OTPInput() {
    const [otp, setOTP] = useState(['', '', '', '']);
    const [seconds, setSeconds] = useState(59);
    const [otpVerify, setOtpVerify] = useState('');
    const inputsRef = useRef([]);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    var user = {};

    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user'));
    }

    useEffect(() => {
        // Focus on the first input field when the component mounts
        inputsRef.current[0].focus();
    }, []);

    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else {
            setSeconds(0);
        }
    }, [seconds]);

    const handleChange = (index, event) => {
        const value = event.target.value;
        if (!isNaN(value) && value !== '') {
            const newOTP = [...otp];
            newOTP[index] = value;
            setOTP(newOTP);

            if (index < otp.length - 1) {
                inputsRef.current[index + 1].focus();
            }

            const allFieldsFilled = newOTP.every(val => val !== '');
            if (allFieldsFilled) {
                enableButton();
            }
        }
    };

    const enableButton = () => {
        const button = document.getElementById('submitButton');
        if (button) {
            button.disabled = false;
        }
    };



    const handleKeyUp = (index, event) => {
        if (event.keyCode === 8) {
            // If Backspace key is pressed
            const newOTP = [...otp];
            newOTP[index] = ''; // Clear the content of the current input field
            setOTP(newOTP);

            if (index > 0) {
                // Move cursor to the previous input field
                inputsRef.current[index - 1].focus();
            }
        }
    };


    const ToHomePage = async () => {
        setLoading(true);
        await dispatch(VerifyOtp({
            type: "phone",
            username: user.phone,
            code: otp.join(''),
        }));
        setLoading(false)
    };

    const ResendOtp = async () => {
        setLoading(true);
        await dispatch(SendOtp({
            type: "phone",
            username: user.phone,
            code: otp.join(''),
        }));
        setLoading(false)
        setResend(false);
        setSeconds(59)
    };

    const data = useSelector(state => state.VerifyOtpReducer.Verify);

    useEffect(() => {
        if (loading === false) {
            if (data && data.data) {
                console.log(data.data);
                if (data.data.statusCode === 200) {
                    localStorage.setItem('token', data.data.data.access_token);
                    localStorage.setItem('user', JSON.stringify(data.data.data));
                    success('تم تسجيل الدخول')
                    setTimeout(() => {
                        window.location.href = "/admin/dashboard";
                    }, 1500)
                }
            }
        }
    }, [loading])

    const [resend, setResend] = useState(false);
    useEffect(() => {
        if (seconds === 0) {
            setResend(true);
        }
    }, [seconds]);

    return (
        <div className='allOtb font'>
            <div>
                <p className='text-center' style={{ position: "absolute", top: "30%", left: "42%" }}>ادخل رمز التحقق المرسل على رقم الجوال الخاص بك</p>
                <div className="otp-container shadow">
                    {otp.slice().reverse().map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            value={digit}
                            maxLength={1}
                            onChange={(e) => handleChange(otp.length - 1 - index, e)}
                            onKeyUp={(e) => handleKeyUp(otp.length - 1 - index, e)}
                            className='input-otp form-control mx-auto'
                            ref={(input) => inputsRef.current[otp.length - 1 - index] = input}
                        />
                    ))}
                    <div className="text-center btnLoginData" style={{ position: 'absolute', top: "60%" }}>
                        {resend ? (
                            <button className="btn resendButton w-100"  onClick={ResendOtp}>
                                إعادة الإرسال
                            </button>
                        ) : (
                            <p>الثواني المتبقية: {seconds}</p>
                        )}
                        {
                            resend === false ? (<button onClick={ToHomePage} className="btn submitButton w-100" id="submitButton">الدخول مباشرة</button>) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OTPInput;
