import React from 'react'
import { Col, Row } from "react-bootstrap";
import AdminSideBar from '../../Components/DashboardComponents/AdminSideBar';
import NavBarDashboard from '../../Components/DashboardComponents/NavBarDashboard';
import Ads from '../../Components/AdsComponents/Ads';
const AdsPage = () => {
    return (
        <div>
            <Row className="py-3">
                <Col sm="5" xs="3" md="2" lg="2">
                    <AdminSideBar defData={"0"}/>
                </Col>
                <Col sm="7" xs="9" className='px-5' md="10" lg="10">
                    <NavBarDashboard />
                    <Ads/>
                </Col>
            </Row>
        </div>
    )
}

export default AdsPage
