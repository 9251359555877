import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { CREATE_NEW_BAQA, DELETE_BAQA, GET_ALL_BAQA,EDIT_COUNT_SERVICES, UPDATE_BAQA, UPDATE_BAQA_DATA, UPLOAD_PHOTO , UPDATE_BAQA_DATA_ACTIVE, POST_NEW_SERVICES_PACKAGE, DELETE_SERVIES } from '../Type'

//get All Areas
const GetAllBaqa = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/package/all-package?all=true', config)

            dispatch({ type: GET_ALL_BAQA, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_BAQA, payload: e.res })
            console.log(e)
        }
    }
}

//Post New Baqa
const CreateNewBaqa = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post('v1/package/create-package', data, config)
            dispatch({ type: CREATE_NEW_BAQA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: CREATE_NEW_BAQA, payload: e.res })
            console.log(e)
        }
    }
}
//Delete Package
const DeleteBackage = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/package/${data.id}/delete-package`, config)
            dispatch({ type: DELETE_BAQA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_BAQA, payload: e.res })
            if (e.response.status === 422) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
//Update baqa
const UpdateBaqaData = (data) => {
    // console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.get(`v1/package/${data.id}/single-package`, config)
            dispatch({ type: UPDATE_BAQA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_BAQA, payload: e.res })
            console.log(e);
            if (e.response.status === 422) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
//Update baqa
const UpdateBaqaFormat = (data, id) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                // "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.put(`v1/package/${id}/update-package`, data, config)
            dispatch({ type: UPDATE_BAQA_DATA, payload: res.data })
            console.log(res);
            if(res.status === 200){
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: UPDATE_BAQA_DATA, payload: e.res })
            console.log(e);
        }
    }
}

const UpdateBaqaFormatActive = (data , id) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                // "Accept-Language": "ar"
            },
        }
        try {
            let res = await baseUrl.put(`v1/package/${id}/update-package`, data, config)
            dispatch({ type: UPDATE_BAQA_DATA_ACTIVE, payload: res.data })
            console.log(res);
            
        } catch (e) {
            dispatch({ type: UPDATE_BAQA_DATA_ACTIVE, payload: e.res })
            console.log(e);
        }
    }
}


const CreatePhotoPackage = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/storage`, data, config)
            dispatch({ type: UPLOAD_PHOTO, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPLOAD_PHOTO, payload: e.res })
            console.log(e);
        }
    }
}



const DeletePackageServicesData = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/package-services/${data.package_service_id}/delete-package`, config)
            dispatch({ type: DELETE_SERVIES, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_SERVIES, payload: e.res })
            console.log(e);
        }
    }
}

const AddNewServiceToPackageData = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/package-services/add-service-to-package`,data, config)
            dispatch({ type: POST_NEW_SERVICES_PACKAGE, payload: res.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: POST_NEW_SERVICES_PACKAGE, payload: e.res })
            console.log(e);
        }
    }
}
const UpdateCountService = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/package-services/update-package-service`,data, config)
            dispatch({ type: EDIT_COUNT_SERVICES, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: EDIT_COUNT_SERVICES, payload: e.res })
            console.log(e);
        }
    }
}

export { GetAllBaqa, CreateNewBaqa, DeleteBackage, UpdateBaqaData, UpdateBaqaFormat, CreatePhotoPackage , UpdateCountService  , UpdateBaqaFormatActive , DeletePackageServicesData , AddNewServiceToPackageData}