import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';

const MapManage = ({ areas }) => {
    let map;

    const [curvedPath, setCurvedPath] = useState([]);

    if(areas){
        console.log(areas)
    }

    const [circleOptions, setCircleOptions] = useState({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FFFFFF",
        fillOpacity: 0.35,
        radius: 1000, // تحديد نصف قطر الدائرة بالأمتار
    });

    const initMap = () => {
        map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: { lat: 24.7136, lng: 46.6753 },
        });

        new window.google.maps.Polyline({
            path: curvedPath,
            geodesic: true,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            map: map,
        });

        areas.forEach((area, index) => {
            new window.google.maps.Marker({
                position: { lat: area.latitude, lng: area.longitude },
                title: `${area.name}`,
                map: map,
            });

            const circleOptions = {
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FFFFFF",
                fillOpacity: 0.35,
                radius: area.range * 1000, // تحويل النطاق من كيلومترات إلى أمتار
            };

            new window.google.maps.Circle({
                center: { lat: area.latitude, lng: area.longitude },
                ...circleOptions,
                map: map,
            });
        });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
        script.async = true;
        script.defer = true;
        script.onload = initMap;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (areas) {
            const newPath = areas.map(area => ({ lat: area.latitude, lng: area.longitude }));
            setCurvedPath(newPath);
        }
    }, [areas]);

    return (
        <div>
            <div id="map" style={{ height: '500px', width: '100%' }}>
            </div>
        </div>
    );
};

export default MapManage;



















// import React, { useEffect, useState } from 'react';
// import { GoogleMap, LoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';

// const MapManage = ({ areas }) => {
//     let map;

//     const [curvedPath, setCurvedPath] = useState([]);

//     const initMap = () => {
//         map = new window.google.maps.Map(document.getElementById('map'), {
//             zoom: 10,
//             center: { lat: 24.7136, lng: 46.6753 },
//         });

//         areas.forEach((area, index) => {
//             new window.google.maps.Marker({
//                 position: { lat: area.latitude, lng: area.longitude },
//                 title: `${area.name}`,
//                 map: map,
//             });

//             // تحديد نصف قطر الدائرة بالأمتار باستخدام قيمة النطاق
//             const circleOptions = {
//                 strokeColor: "#FF0000",
//                 strokeOpacity: 0.8,
//                 strokeWeight: 2,
//                 fillColor: "#FFFFFF",
//                 fillOpacity: 0.35,
//                 radius: area.range * 1000, // تحويل النطاق من كيلومترات إلى أمتار
//             };

//             new window.google.maps.Circle({
//                 center: { lat: area.latitude, lng: area.longitude },
//                 ...circleOptions,
//                 map: map,
//             });
//         });

//         // رسم الخط المتصل بين المواقع
//         new window.google.maps.Polyline({
//             path: curvedPath,
//             geodesic: true,
//             strokeColor: '#FF0000',
//             strokeOpacity: 0.8,
//             strokeWeight: 2,
//             map: map,
//         });
//     };

//     useEffect(() => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
//         script.async = true;
//         script.defer = true;
//         script.onload = initMap;
//         document.head.appendChild(script);

//         return () => {
//             document.head.removeChild(script);
//         };
//     }, []);

//     useEffect(() => {
//         if (areas) {
//             const newPath = areas.map(area => ({ lat: area.latitude, lng: area.longitude }));
//             setCurvedPath(newPath);
//         }
//     }, [areas]);

//     return (
//         <div>
//             <div id="map" style={{ height: '500px', width: '100%' }}></div>
//         </div>
//     );
// };

// export default MapManage;
