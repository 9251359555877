import { DELETE_REVIEW, GET_ALL_REVIEWS, UPDATE_ACTIVE_RATE } from "../Type";
const initial = {
    reviews: [],
    updateReview: [],
    reviewDel: [],
    loading: true,
}

const RateReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_REVIEWS:
            return {
                ...state,
                reviews: action.payload,
                loading: false
            }
        case UPDATE_ACTIVE_RATE:
            return {
                ...state,
                updateReview: action.payload,
                loading: false
            }
        case DELETE_REVIEW:
            return {
                ...state,
                reviewDel: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default RateReducer