import React, { useEffect, useState } from 'react'
import Logo from '../../Images/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import LoginUser from '../../redux/actions/Login/LoginAction'
import { success, ErrorMsg } from '../../Utils/Toast'
const Login = () => {
    const [username, setEmail] = useState("")
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const onChangeEmail = (event) => {
        setEmail(event.target.value)
    }
    const onChangePassword = (event) => {
        setPassword(event.target.value)
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        await dispatch(LoginUser({
            username,
            password,

        }));
        setLoading(false)
    }
    const data = useSelector(state => state.LoginReducer.LoginUser);
    useEffect(() => {
        if (loading === false) {
            if (data) {
                if (data.data.statusCode === 200) {
                    localStorage.setItem('token', data.data.data.access_token)
                    localStorage.setItem('user', JSON.stringify(data.data.data));
                    // success('تم تسجيل الدخول');
                    setTimeout(() => {
                        window.location.href = "/admin/verify-otp";
                    }, 100)
                }
                setLoading(true)
            } else {
                ErrorMsg('خطا في البيانات')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
            }
        }
    }, [loading])


    useEffect(() => {
        const handleBackButton = (event) => {
            if (event.type === 'popstate') {
                if (window.location.pathname === "/") {
                    // Do not allow going back to the login screen
                    window.history.forward(); // Move forward in history to avoid going back
                }
            }
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);






    return (
        <div className='back-logo'>
            <div className='login font'>
                <div className='text-center m-auto'>
                    <img src={Logo} alt="logo" />
                    <p className='mt-4'>تسجيل الدخول</p>
                </div>
                <form>
                    <div>
                        <label className='text-left'>البريد الالكتروني</label>
                        <span className="text-danger">*</span>
                        <br />
                        <input type='email' value={username} onChange={onChangeEmail} className="form-control  filedInputs" id="email" name="email" />
                    </div>
                    <div className='mt-4'>
                        <label className='text-left'>كلمة المرور</label>
                        <span className="text-danger">*</span>
                        <br />
                        <input value={password} onChange={onChangePassword} type='password' className="form-control filedInputs" id="password" name="password" />
                    </div>
                    <div className='mt-4'>
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                        <label className="form-check-label mx-2" for="flexCheckChecked">
                            تذكرني مستقبلا
                        </label>
                    </div>
                    <div className='text-center'>
                        <button className='btn w-75 submitButton' onClick={onSubmit} >تسجيل الدخول</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login


