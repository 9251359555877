import { ADD_NEW_GIFT, GET_ALL_GIFTS, SETTING_GIFT } from "../Type";
const initial = {
    AllGifts: [],
    PostGift: [],
    settingGift:[],
    loading: true,
}

const GiftReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_GIFTS:
            return {
                ...state,
                AllGifts: action.payload,
                loading: false
            }
        case ADD_NEW_GIFT:
            return {
                ...state,
                PostGift: action.payload,
                loading: false
            }
        case SETTING_GIFT:
            return {
                ...state,
                settingGift: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default GiftReducer