// src/reducers/rootReducer.js

import { combineReducers } from 'redux';
import LoginReducer from './Login/LoginReducer';
import VerifyOtpReducer from './Login/VerifyOtpReducer'
import StaticsReducer from './StateicsReducer';
import GetBikers from './GetallbiketReducer';
import GetUsers from './GetAllReducers';
import GetSlots from './SlotsReducer';
import NotifyReducer from './NotifyReducer'
import OrdersAdminReducer from './OrdersAdminReducer';
import ConstantsApp from './ConstantsReducer';
import AreaReducer from './AreaReducer';
import ServicesReducer from './Servicesreducer';
import CarReducer from './CarReducers';
import ColorReducer from './ColorsReducer';
import BaqaReducer from './BqaReducer';
import GiftReducer from './GiftsReducer';
import PointsReducer from './PointsReducer';
import BannerReducer from './BannerReducer';
import PromoCodesReducer from './PromoCodesRedcuer';
import NotificationReducer from './NotificationReducer';
import InvoiceReducer from './InvoiceReducer'
import SmsReducer from './SmsReducer'
import RateReducer from './RateReducer';
import PrivacyReducer from './PrivacyReducer';
import PremissionReducer from './PremissionReducer';

const rootReducer = combineReducers({
    LoginReducer,
    StaticsReducer,
    GetBikers,
    GetUsers,
    GetSlots,
    OrdersAdminReducer,
    VerifyOtpReducer,
    NotifyReducer,
    ConstantsApp,
    AreaReducer,
    ServicesReducer,
    CarReducer,
    ColorReducer,
    BaqaReducer,
    GiftReducer,
    PointsReducer,
    BannerReducer,
    PromoCodesReducer,
    NotificationReducer,
    InvoiceReducer,
    SmsReducer,
    RateReducer,
    PrivacyReducer,
    PremissionReducer,
});

export default rootReducer;
