import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserPoints from './UserPoints';
import BikersPoints from './BikersPoints';
import ManagePoints from './ManagePoints';
import { useSpring, animated } from 'react-spring';
import { MdAvTimer } from 'react-icons/md';
import Form from 'react-bootstrap/Form';
import PhoneInput from 'react-phone-input-2';
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux';
import { GetAllUsersClients } from '../../redux/actions/Users';
import { AddPoints, SearchAddPoints } from '../../redux/actions/PointsAction';
import Spinner from 'react-bootstrap/Spinner';

import { RiChatCheckFill } from 'react-icons/ri';


const AnimatedTabContent = ({ isActive, children }) => {
    const props = useSpring({
        opacity: isActive ? 1 : 0,
        transform: isActive ? 'translateY(0)' : 'translateY(20px)',
    });

    return <animated.div style={props}>{children}</animated.div>;
};


const PointsMainComponent = () => {

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState('home');
    const handleTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);
    };



    //Form Childs 
    const [dataFromChild, setDataFromChild] = useState('');

    const handleDataFromChild = (data) => {
        setDataFromChild(data);
    };



    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const [error, setError] = useState('');
    const [role, setRole] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneNumberChange = (value, country, e, formattedValue) => {
        // التحقق من أن الرقم يبدأ بـ "9665" ويتكون من 8 أرقام
        if (/^9665\d{8}$/.test(value)) {
            setPhoneNumber(value);
            // setError('');
        } else {
            // setError('رقم الهاتف غير صحيح، يرجى إدخال رقم هاتف صحيح يبدأ بـ "9665" ويتكون من 8 أرقام.');
        }
    };

    const getUsersClients = async (role, phone) => {
        await dispatch(SearchAddPoints({
            role: role,
            phone: phone,
        }));
    };

    useEffect(() => {
        if (role !== "" && phoneNumber !== "") {
            getUsersClients(role, phoneNumber)
        }
    }, [phoneNumber, role]);

    const UsersClients = useSelector(state => state.PointsReducer.searchPhonePoint);

    if (UsersClients) {
        console.log(UsersClients);
    }

    const [user, setUser] = useState('');
    const [point, setPoint] = useState('');
    const [loading, setLoading] = useState(false)


    const addNewPoint = async () => {
        setLoading(true)
        try {
            await dispatch(AddPoints({
                users_id: [user],
                points: parseInt(point)
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
        }
    }


    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة النقاط</p>
                </div>


                {
                    activeTab === 'home' || activeTab === 'profile' ? (
                        <div>
                            <button className='btn mx-2' style={{ color: "black", background: "#FECB44" }} onClick={handleShow}>اضافة نقاط جديده</button>
                        </div>
                    ) : null
                }
            </div>
            <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 tabContainer"
            >
                <Tab eventKey="home" className='font' title="نقاط المستخدمين ">
                    <AnimatedTabContent isActive={activeTab === 'home'}>
                        <UserPoints role={"CLIENT"} />
                    </AnimatedTabContent>
                </Tab>
                <Tab eventKey="profile" className='font' title="نقاط البايكرز">
                    <AnimatedTabContent isActive={activeTab === 'profile'}>
                        <BikersPoints role={"BIKER"} />
                    </AnimatedTabContent>
                </Tab>
                <Tab eventKey="bikers" className='font' title="ادارة عدد النقاط">
                    <AnimatedTabContent isActive={activeTab === 'bikers'}>
                        <ManagePoints />
                    </AnimatedTabContent>
                </Tab>
            </Tabs>

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة نقاط جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>سيتم اضافة تلك النقاط الي المستخدم الذي تحدده</span>

                    <Form.Select aria-label="Default select example" className='mySelectClass mt-2' style={{ background: "#EFF1F9" }} onChange={(e) => setRole(e.target.value)}>
                        <option value="" hidden>حدد نوع المستخدم</option>
                        <option value="CLIENT">مستخدم</option>
                        <option value="BIKER">بايكر</option>
                    </Form.Select>

                    <div className='mt-3'>
                        <PhoneInput
                            country={'sa'}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                        />
                        {error && <small style={{ color: 'red' }}>{error}</small>}
                    </div>

                    {
                        UsersClients.data && (UsersClients.data.clients || UsersClients.data.bikers) ? (
                            <Form.Select aria-label="Default select example" className='mySelectClass mt-2' style={{ background: "#EFF1F9" }} onChange={(e) => setUser(e.target.value)}>
                                <option value="" hidden> اختر المستخدم</option>
                                {
                                    UsersClients.data && UsersClients.data.clients && role === "CLIENT" ? (
                                        UsersClients.data.clients.map((client, index) => {
                                            return (
                                                <option key={index + 1} value={client.user_id}>{client.user.first_name}</option>
                                            )
                                        })
                                    ) : UsersClients.data && UsersClients.data.bikers && role === "BIKER" ? (
                                        UsersClients.data.bikers.map((client, index) => {
                                            return (
                                                <option key={index + 1} value={client.user_id}>{client.user.first_name}</option>
                                            )
                                        })
                                    ) : null
                                }
                            </Form.Select>) : (
                            phoneNumber !== "" && role !== "" ? (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>) : null

                        )
                    }


                    <div className='mt-3'>
                        <input type="number" onChange={(e) => setPoint(e.target.value)} min="1" className='w-100' placeholder='اضف عدد النقاط المراد اضافتها' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={addNewPoint}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }
        </div>
    )
}

export default PointsMainComponent
