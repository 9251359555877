import { ADD_POINTS, GET_ALL_POINTS, SETTING_POINTS ,GET_ALL_POINTS_BIKER, GET_ALL_SEARCH, SPECIFIC_POINT } from "../Type";
const initial = {
    Points: [],
    PointsBiker: [],
    postPoints:[],
    searchPhonePoint:[],
    specificPoint:[],
    loading: true,
}

const PointsReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_POINTS:
            return {
                ...state,
                Points: action.payload,
                loading: false
            }
        case GET_ALL_POINTS_BIKER:
            return {
                ...state,
                PointsBiker: action.payload,
                loading: false
            }
        case ADD_POINTS:
            return {
                ...state,
                postPoints: action.payload,
                loading: false
            }
        case GET_ALL_SEARCH:
            return {
                ...state,
                searchPhonePoint: action.payload,
                loading: false
            }
        case SETTING_POINTS:
            return {
                ...state,
                Setting: action.payload,
                loading: false
            }
        case SPECIFIC_POINT:
            return {
                ...state,
                specificPoint: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default PointsReducer