import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { DeleteUser, GetAllUsersClients } from '../../redux/actions/Users';

const DeleteUserData = ({ user_id, role , page }) => {
    const [showEdit, setShowEdit] = useState(false);
    const dispatch = useDispatch();
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const [loading, setLoading] = useState(false);




    const handleDelete = async (id, roleUser) => {
        try {
            setLoading(true);
            await dispatch(DeleteUser({
                id,
                roleUser
            }));
            
        } catch (error) {
            console.error('Error handling delete:', error);
        } finally {
            setLoading(false);
            handleCloseEdit();
            const roleToPass = role === "ADMIN" ? "ADMIN" : "CLIENT";
            const currentPage = page;
            dispatch(GetAllUsersClients({ role: roleToPass, pageCount: currentPage }));
        }
    };

    return (
        <div>

            <MdDelete style={{ color: "#EB5757" }} size="20" onClick={handleShowEdit} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title> هل انت متاكد من حذف المستخدم </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={() => handleDelete(user_id, role)}>
                        تاكيد الالغاء
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteUserData
