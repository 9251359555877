import { CREATE_SERVICES, DELETE_SERVICE, GET_ALL_SERVICES } from "../Type";
const initial = {
    allServices: [],
    postService:[],
    deleteService:[],
    loading: true,
}

const ServicesReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_SERVICES:
            return {
                ...state,
                allServices: action.payload,
                loading: false
            }
        case CREATE_SERVICES:
            return {
                ...state,
                postService: action.payload,
                loading: false
            }
        case DELETE_SERVICE:
            return {
                ...state,
                deleteService: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default ServicesReducer