import React, { useEffect, useState } from 'react'
import Search from './Search'
import Table from 'react-bootstrap/Table';
import ExtendingPackage from '../../Modals/ExtendingPackage';
import { useDispatch, useSelector } from 'react-redux'
import { FilterByBaqa, GetAllSubs, GetSortSubs, GetSortSubsStatus, GetSpesificSubs } from '../../../redux/actions/OrdersAdmin';
import Pagination from '../../../Utils/Pagination';
import DeleteSubs from '../../Modals/DeleteSubs';
import { FaSearch } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import { GetAllBaqa } from '../../../redux/actions/BaqaAction';

const ManageSubs = () => {


    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);

    const [PaginationPage, setPaginationPage] = useState(1);
    const [searchData, setSearchData] = useState("");
    const [count, setSubscriptionCount] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('DESC');
    const subs = useSelector(state => state.OrdersAdminReducer.SubsAdmin)
    const [isFilteredByBaqa, setIsFilteredByBaqa] = useState(false);
    const [statusData, setStatusData] = useState("")
    const dispatch = useDispatch();




    const [selectBaqa, setSelectBaqa] = useState("");



    const handleSearch = async () => {
        
        if (searchValue !== "") {
            await dispatch(GetSpesificSubs({
                searchvalue: searchValue,
                ordersCount: count
            }))
            setSearchData("data")
        } else {
            await dispatch(GetAllSubs({
                pageCount: 1,
                sort: sort,
                status: "",
                baqa: "",
                count: 10,
            }))
            setSearchData("")
        }

    };

    useEffect(() => {
        if (subs) {
            setSubscriptionCount(subs.subscriptionCount)
        }
    }, [subs])



    const getPage = async (page) => {
        setPaginationPage(page)
    };


    const fetchAllSubs = async (page, sort, status, baqa) => {
        await dispatch(GetAllSubs({
            pageCount: page,
            sort: sort,
            status: status,
            baqa: baqa,
            count: count,
        }))
    }



    useEffect(() => {
        dispatch(GetAllBaqa());
        fetchAllSubs(PaginationPage, sort, statusData, selectBaqa)
    }, [dispatch, sort, PaginationPage, statusData, selectBaqa])
    return (
        <div>
            <div>
                <div className='flexItem justify-content-start mt-4'>
                    <div className='mx-3 search-container'>
                        <input
                            type="search"
                            className='form-control search-input'
                            name="search"
                            id="search"
                            placeholder='ابحث عن.....'
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <FaSearch className="search-icon" onClick={handleSearch} />
                    </div>
                    <div className='mx-3'>
                        <Form.Select aria-label="Default select example" onChange={(e) => setSort(e.target.value)}>
                            <option hidden>ترتيب الاشتراك: تنازلي</option>
                            <option value="ASC">تصاعدي</option>
                            <option value="DESC">تنازلي</option>
                        </Form.Select>
                    </div>
                    <div className='mx-3'>
                        <Form.Select aria-label="Default select example" value={selectBaqa} onChange={(e) => setSelectBaqa(e.target.value)}>
                            <option hidden> الباقات</option>
                            <option value=""> عرض الباقات</option>
                            {
                                dataBaqat && dataBaqat.data ? (

                                    dataBaqat.data.map((baqa, index) => {
                                        return (
                                            <option value={baqa.id} key={baqa.id}>{baqa.name} </option>
                                        )
                                    })
                                ) : (null)
                            }
                        </Form.Select>
                    </div>

                    <div className='mx-3'>
                        <Form.Select aria-label="Default select example" value={statusData} onChange={(e) => setStatusData(e.target.value)}>
                            <option hidden value="">حالة الاشتراك	</option>
                            <option value="">الكل</option>
                            <option value="Active">Active</option>
                            <option value="deleted">deleted</option>
                            <option value="EMPTY">EMPTY</option>
                            <option value="expired">expired</option>
                            <option value="gift">gift</option>
                            <option value="rescheduled">rescheduled</option>
                        </Form.Select>
                    </div>
                </div>
            </div>


            <div className='mt-5'>
                <Table bordered style={{ borderRadius: "20px" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>رقم الفاتوره</th>
                            <th>اسم العميل</th>
                            <th>تليفون العميل</th>
                            <th>الباقة</th>
                            <th>تاريخ الاشتراك</th>
                            <th>تاريخ انتهاء الباقة</th>
                            <th>رصيد الباقة</th>
                            <th>المتبقي</th>
                            <th>حالة الاشتراك</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ color: " #4F4F4F", whiteSpace: "nowrap" }} >
                        {
                            subs && subs.subscriptions && subs.subscriptions.length > 0 ? (
                                subs.subscriptions.map((sub, index) => {
                                    // console.log(sub)
                                    const showButtons = sub.status === "Active" || sub.status === "gift" || sub.status === "rescheduled";
                                    const expiryDate = new Date(sub.expiry_date);
                                    const formattedExpiryDate = `${expiryDate.getDate().toString().padStart(2, '0')}-${(expiryDate.getMonth() + 1).toString().padStart(2, '0')}-${expiryDate.getFullYear()}`;
                                    const created_at = new Date(sub.created_at);
                                    const created_atData = `${created_at.getDate().toString().padStart(2, '0')}-${(created_at.getMonth() + 1).toString().padStart(2, '0')}-${created_at.getFullYear()}`;
                                    return (
                                        <tr className='mb-1' key={index}>
                                            <td className="py-3">{index + 1}</td>
                                            <td className="py-3">{sub.order_invoice.invoice_number}</td>
                                            <td className="py-3" style={{ whiteSpace: 'nowrap' }}>
                                                {
                                                    sub.customer && sub.customer.user ? (
                                                        <td>{sub.customer.user.first_name} {sub.customer.user.last_name}</td>
                                                    ) : null
                                                }
                                            </td>
                                            <td className="py-3">
                                                {
                                                    sub.customer && sub.customer.user ? (
                                                        <td>{sub.customer.user.phone}</td>
                                                    ) : null
                                                }
                                            </td>
                                            <td className="py-3">{sub.name}</td>
                                            <td className="py-3">{created_atData}</td>
                                            <td className="py-3">{formattedExpiryDate}</td>
                                            <td className="py-3">{sub.total_was_count} غسله</td>
                                            <td className="py-3"> {sub.wash_count} غسلة</td>
                                            <td className="py-3">{sub.status}</td>
                                            {showButtons ? (
                                                <>
                                                    <td><ExtendingPackage

                                                        sort={sort}
                                                        status={statusData}
                                                        baqa={selectBaqa}
                                                        count={count}
                                                        searchKey={searchValue}
                                                        idSub={sub.id} PaginationPage={PaginationPage} dateData={formattedExpiryDate} /></td>
                                                    <td>
                                                        <DeleteSubs idSub={sub.id}
                                                            sort={sort}
                                                            status={statusData}
                                                            baqa={selectBaqa}
                                                            count={count}
                                                            searchKey={searchValue}
                                                            PaginationPage={PaginationPage} />
                                                    </td>
                                                </>
                                            ) : (
                                                <>
                                                    <td colSpan={2}></td>
                                                </>
                                            )}
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={12}>لا توجد اشتراكات</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            {
                subs && statusData === "" && selectBaqa === "" && searchData === "" && !isFilteredByBaqa ? (<Pagination onPress={getPage} countsPage={subs.subscriptionCount / 10} />) : null
            }
        </div>
    )
}

export default ManageSubs
