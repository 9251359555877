import { ADD_NEW_COLOR, DELETE_COLOR, GET_ALL_COLORS, UPDATE_COLOR  , GET_SINGLE_COLOR} from "../Type";
const initial = {
    AllColors: [],
    AddColors: [],
    DeleteColors: [],
    UpdateColor: [],
    getInfoSingleColor:[],
    loading: true,
}

const ColorReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_COLORS:
            return {
                ...state,
                AllColors: action.payload,
                loading: false
            }
        case ADD_NEW_COLOR:
            return {
                ...state,
                AddColors: action.payload,
                loading: false
            }
        case DELETE_COLOR:
            return {
                ...state,
                DeleteColors: action.payload,
                loading: false
            }
        case UPDATE_COLOR:
            return {
                ...state,
                UpdateColor: action.payload,
                loading: false
            }
        case GET_SINGLE_COLOR:
            return {
                ...state,
                getInfoSingleColor: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default ColorReducer