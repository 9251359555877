import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { CancelOrderReschudle, GetAllOrdersAdmin } from '../../redux/actions/OrdersAdmin';
import { DeleteBackage, GetAllBaqa } from '../../redux/actions/BaqaAction';
import { MdDelete } from "react-icons/md";
import { DeleteBanner, GetAllBanners } from '../../redux/actions/BannerAction';

const DeleteBannerCom = ({ idData, currentPage, sort  ,nameValue}) => {


    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();

    const cancelOrder = async () => {
        try {
            setLoading(false);
            await dispatch(DeleteBanner({
                id: idData
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true);
            handleCloseEdit();
            await dispatch(GetAllBanners({ page: currentPage, sort: sort  , name:nameValue}));
        }
    }
    return (
        <div>
            <MdDelete onClick={handleShowEdit} cursor={'pointer'} size={"20"} color='#EB5757' />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>حذف الاعلان</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد حذف  الاعلان</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={cancelOrder} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تاكيد الحذف
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteBannerCom
