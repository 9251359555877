import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FaPencilAlt } from "react-icons/fa"
import { useDispatch, useSelector } from 'react-redux'
import { GetConstantsApp, PutConstantsApp } from '../../redux/actions/ConstantsApp'
function SettingMessage() {
    const [show, setShow] = useState(false);
    const [ReschudleTime, setReschudleTime] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    const UpdateReschudleTime = async () => {
        setLoading(false);
        await dispatch(PutConstantsApp({
            reschadule_time: parseInt(ReschudleTime),
        }))
        setLoading(true);
        dispatch(GetConstantsApp())
        setReschudleTime('');
        handleClose();
    }

    return (
        <div>
            <FaPencilAlt onClick={handleShow} />

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>ضبط مدة السماح للجدولة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-4'>
                        <input type="number" className='w-100' onChange={(e) => setReschudleTime(e.target.value)} value={ReschudleTime} placeholder='توقيت الجدوله' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={UpdateReschudleTime}>
                        تاكيد الضبط
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default SettingMessage;