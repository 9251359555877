import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { ADD_POINTS, GET_ALL_POINTS, GET_ALL_POINTS_BIKER, GET_ALL_SEARCH, SETTING_POINTS, SPECIFIC_POINT } from '../Type'

//GetAllPoints
const GetAllPointsData = (data) => {

    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            let res = await baseUrl.get(`v1/point/user?page=${page}&limit=10&filters=user.roles%3DCLIENT&sortBy=created_at%3D${data.sort}`, config)
            dispatch({ type: GET_ALL_POINTS, payload: res.data })

        } catch (e) {
            dispatch({ type: GET_ALL_POINTS, payload: e.res })
            console.log(e)
        }
    }
}

//Add Points
const AddPoints = (data) => {

    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/point/send-point-to-users`, data, config)
            dispatch({ type: ADD_POINTS, payload: res.data })
            console.log(res);
            if (res.status === 201) {
                success(res.data.data)
            }
        } catch (e) {
            dispatch({ type: ADD_POINTS, payload: e.res })
            console.log(e)
        }
    }
}
//Gt All Bikers
const GetAllPointsDataBikers = (data) => {

    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            let res = await baseUrl.get(`v1/point/user?page=${page}&limit=10&filters=user.roles%3DBIKER&sortBy=created_at%3D${data.sort}`, config)
            dispatch({ type: GET_ALL_POINTS_BIKER, payload: res.data })

        } catch (e) {
            dispatch({ type: GET_ALL_POINTS_BIKER, payload: e.res })
            console.log(e)
        }
    }
}


//get all Clients Search
const SearchByPhone = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.phone !== "") {
                let res = await baseUrl.get(`v1/point/user?page=${data.pageCount}&limit=10&filters=user.phone%3D${data.phone}&sortBy=created_at%3D${data.sort}`, config)
                dispatch({ type: GET_ALL_POINTS, payload: res.data })
            } else if (data.phone === "") {
                let res = await baseUrl.get(`v1/point/user?page=1&limit=10&filters=user.roles%3DCLIENT&sortBy=created_at%3D${data.sort}`, config)
                dispatch({ type: GET_ALL_POINTS, payload: res.data })
            }

        } catch (e) {
            dispatch({ type: GET_ALL_POINTS, payload: e.res })
            console.log(e)
        }
    }
}

//Search Bikers
const SearchByPhoneBiker = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.phone !== "") {
                let res = await baseUrl.get(`v1/point/user?page=${data.pageCount}&limit=10&filters=user.phone%3D${data.phone}&sortBy=created_at%3D${data.sort}`, config)
                dispatch({ type: GET_ALL_POINTS_BIKER, payload: res.data })
            } else if (data.phone === "") {
                let res = await baseUrl.get(`v1/point/user?page=1&limit=10&filters=user.roles%3DBIKER&sortBy=created_at%3DDESC`, config)
                dispatch({ type: GET_ALL_POINTS_BIKER, payload: res.data })
            }


        } catch (e) {
            dispatch({ type: GET_ALL_POINTS_BIKER, payload: e.res })
            console.log(e)
        }
    }
}

const SearchAddPoints = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.role !== "" && data.phone !== "") {
                // v1/users?page=1&limit=10&role=BIKER&searchByName=966547014439&sortByDate=ASC
                let res = await baseUrl.get(`v1/users?page=1&limit=10&role=${data.role}&searchByName=${data.phone}`, config)
                dispatch({ type: GET_ALL_SEARCH, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SEARCH, payload: e.res })
            console.log(e)
        }
    }
}


const SettingsPointData = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.get(`v1/point/admin-points-data`, config)
            dispatch({ type: SETTING_POINTS, payload: res.data })
            console.log(res);

        } catch (e) {
            dispatch({ type: SETTING_POINTS, payload: e.res })
            console.log(e)
        }
    }
}

//Get Spesific Points To user
const SpecificUserPoint = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.get(`v1/point/user?page=1&limit=10&filters=user.id%3D${data.id}`, config)
            dispatch({ type: SPECIFIC_POINT, payload: res.data })
            console.log(res);

        } catch (e) {
            dispatch({ type: SPECIFIC_POINT, payload: e.res })
            console.log(e)
        }
    }
}
//Update Redeeme Points
const UpdateRedeemePoints = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.put(`v1/app-constants`, data, config)
            dispatch({ type: SETTING_POINTS, payload: res.data })
            console.log(res);
            dispatch(SettingsPointData())

        } catch (e) {
            dispatch({ type: SETTING_POINTS, payload: e.res })
            console.log(e)
        }
    }
}


export { GetAllPointsData, SearchByPhone, GetAllPointsDataBikers, SearchByPhoneBiker, AddPoints, SearchAddPoints, SettingsPointData, SpecificUserPoint, UpdateRedeemePoints }