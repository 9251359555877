import React, { useEffect } from 'react'
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import staticImage from '../../../Images/Group 154.png'
import { FaLongArrowAltUp } from "react-icons/fa";
import { FaLongArrowAltDown } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux'
import GetStatics from '../../../redux/actions/StaticsRedux';
import { Link } from "react-router-dom";
const AdminStaticsDashboard = () => {


    const dispatch = useDispatch();


    const fetchData = async () => {
        await dispatch(GetStatics());
    }

    useEffect(() => {
        fetchData();
    }, [])

    const data = useSelector(state => state.StaticsReducer.Statics)


    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }


    return (
        <div>
            <Container>
                <Row className="py-3">
                    <Col sm="12" xs="12" md="6" lg="3">
                        <div className='statics'>
                            <div className='font p-2 d-flex justify-content-between align-items-center'>
                                {
                                    data ? (
                                        data.statusCode === 200 ? (
                                            <div>
                                                <p>عدد المستخدمين</p>
                                                <h4>{data.data.uesrsCount}</h4>
                                                {
                                                    data.data.usersCountBeforeLastMonth < data.data.uesrsCount ? (<small className='smallUpStatic'>{(((data.data.uesrsCount - data.data.usersCountLastMonth) / data.data.usersCountLastMonth) * 100).toFixed(2)}%<FaLongArrowAltUp />(عن اخر شهر)</small>) : (<small className='smallUpStatic'>{(((data.data.uesrsCount - data.data.usersCountLastMonth) / data.data.usersCountLastMonth) * 100).toFixed(2)}%<FaLongArrowAltUp />(عن اخر شهر)</small>)
                                                }
                                            </div>
                                        ) : (<p className='font'>عذرا هناك خطا في الاتصال</p>)
                                    ) : (<p className='font'>عذرا هناك خطا في الاتصال</p>)
                                }
                                <div>
                                    <img src={staticImage} alt="static" />
                                </div>
                            </div>
                            <div className='p-1 manage' style={{ cursor: "pointer" }}>
                                {/* <div className='p-1 manage'> */}
                                    {
                                        adminLogIn && adminLogIn.premessions ? (
                                            adminLogIn.premessions.map((pre) => {
                                                return (
                                                    pre === "user" ? (
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <Link to='/admin/users' style={{ color: "black", textDecoration: "none", }}>
                                                                <p>ادارة عدد المستخدمين</p>
                                                            </Link>
                                                            <p><FaArrowLeft /></p>
                                                        </div>
                                                    ) : (
                                                        null
                                                    )
                                                )
                                            })
                                        ) : <div>

                                        </div>
                                    }

                                {/* </div> */}
                            </div>
                        </div>
                    </Col>
                    <Col sm="12" xs="12" md="6" lg="3">
                        <div className='statics'>
                            <div className='font p-2 d-flex justify-content-between align-items-center'>
                                {
                                    data ? (
                                        data.statusCode === 200 ? (
                                            <div>
                                                <p>عدد الطلبات</p>
                                                <h4>{data.data.ordersCount}</h4>
                                                {
                                                    data.data.ordersCount > data.data.ordersCountBeforeLastMonth ? (
                                                        <small className='smallUpStatic'>{((((data.data.ordersCount - data.data.ordersCountLastMonth) / data.data.ordersCountLastMonth) * 100).toFixed(2))} <FaLongArrowAltUp />(عن اخر شهر)</small>
                                                    ) : (
                                                        <small className='smallDownStatic'>{((((data.data.ordersCount - data.data.ordersCountLastMonth) / data.data.ordersCountLastMonth) * 100).toFixed(2))}<FaLongArrowAltDown />(عن اخر شهر)</small>
                                                    )
                                                }
                                            </div>
                                        ) : <p className='font'>عذرا هناك خطا في الاتصال</p>
                                    ) : (<p>هناك خطا في الاتصال </p>)
                                }
                                <div>
                                    <img src={staticImage} alt="static" />
                                </div>
                            </div>

                            <div className='p-1 manage'>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                (pre === "order") ? (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <Link to='/admin/booking' style={{ color: "black", textDecoration: "none", }}>
                                                            <p>ادارة عدد الطلبات</p>
                                                        </Link>
                                                        <p><FaArrowLeft /></p>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            )
                                        })
                                    ) : <div>

                                    </div>
                                }

                            </div>
                        </div>
                    </Col>
                    <Col sm="12" xs="12" md="6" lg="3">
                        <div className='statics'>
                            <div className='font p-2 d-flex justify-content-between align-items-center'>
                                {
                                    data ? (
                                        data.statusCode === 200 ? (
                                            <div className='w-75'>
                                                {console.log(data)}
                                                <p style={{ fontSize: "13px" }}>عدد الخدمات الاضافيه المستخدمه</p>
                                                <h4>{data.data.addtionServicesUsesCount}</h4>
                                                {
                                                    !isNaN(data.data.addtionServicesUsesCount) && isFinite(data.data.addtionServicesUsesCount) && data.data.addtionServicesUsesCountLastMonth !== 0 ? (
                                                        data.data.addtionServicesUsesCount > data.data.addtionServicesUsesCountBeforeLastMonth ? (
                                                            <small className='smallUpStatic'> {((((data.data.addtionServicesUsesCount - data.data.addtionServicesUsesCountLastMonth) / data.data.addtionServicesUsesCountLastMonth) * 100).toFixed(2))} <FaLongArrowAltUp />(عن اخر شهر)</small>
                                                        ) : (
                                                            <small className='smallDownStatic'> {((((data.data.addtionServicesUsesCount - data.data.addtionServicesUsesCountLastMonth) / data.data.addtionServicesUsesCountLastMonth) * 100).toFixed(2))}  <FaLongArrowAltDown />(عن اخر شهر)</small>
                                                        )
                                                    ) : (
                                                        <small className='smallStatic'>0%(عن اخر شهر)</small>
                                                    )
                                                }
                                            </div>
                                        ) : (<p className='font'>عذرا هناك خطا في الاتصال</p>)
                                    ) : (<p className='font'>عذرا هناك خطا في الاتصال</p>)
                                }
                                <div className='w-25'>
                                    <img src={staticImage} alt="static" />
                                </div>
                            </div>
                            <div className='p-1 manage'>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "service" ? (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <Link to='/admin/service' style={{ color: "black", textDecoration: "none", }}>
                                                            <p> ادارة عدد الخدمات الاضافيه المستخدمه </p>
                                                        </Link>
                                                        <p><FaArrowLeft /></p>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            )
                                        })
                                    ) : <div>

                                    </div>
                                }

                            </div>
                        </div>
                    </Col>

                    <Col sm="12" xs="12" md="6" lg="3">
                        <div className='statics'>
                            <div className='font p-2 d-flex justify-content-between align-items-center'>
                                {
                                    data ? (
                                        data.statusCode === 200 ? (
                                            <div>
                                                <p>عدد الباقات المنتهيه</p>
                                                <h4>{data.data.packageExpiredCount}</h4>
                                                {
                                                    data.data.packageExpiredCount > data.data.packageExpiredCountBeforeLastMonth ? (
                                                        <small className='smallUpStatic'> {((data.data.packageExpiredCount - data.data.packageExpiredCountBeforeLastMonth) / (data.data.packageExpiredCountBeforeLastMonth + 1) * 100).toFixed(1)} <FaLongArrowAltUp />(عن اخر شهر)</small>
                                                    ) : (
                                                        <small className='smallDownStatic'> {((((data.data.packageExpiredCount - data.data.packageExpiredCountBeforeLastMonth + 1))).toFixed(1))}<FaLongArrowAltDown />(عن اخر شهر)</small>
                                                    )
                                                }
                                            </div>
                                        ) : (
                                            <p className='font'>عذرا هناك خطا في الاتصال</p>
                                        )
                                    ) : (<p className='font'>عذرا هناك خطا في الاتصال</p>)
                                }
                                <div>
                                    <img src={staticImage} alt="static" />
                                </div>
                            </div>
                            <div className='p-1 manage'>
                                {
                                    adminLogIn && adminLogIn.premessions ? (
                                        adminLogIn.premessions.map((pre) => {
                                            return (
                                                pre === "package" ? (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <Link to='/admin/baqu' style={{ color: "black", textDecoration: "none", }}>
                                                            <p>ادارة الباقات</p>
                                                        </Link>
                                                        <p><FaArrowLeft /></p>
                                                    </div>
                                                ) : (
                                                    null
                                                )
                                            )
                                        })
                                    ) : <div>

                                    </div>
                                }

                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AdminStaticsDashboard
