import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { ORDER_START, ORDER_CANCELED, BIKER_ON_THE_WAY, BIKER_ARRIVED, BIKER_ASSIGN, ORDER_COMPLETED, UPDATE_ACTIVE, SEND_TO_PERSON, UPDATE_NOTIFY, NOTIFICATION_USERS } from '../Type'

//get All Areas
const GetNotificationORDER_START = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=ORDER_START', config)
            dispatch({ type: ORDER_START, payload: res.data })

        } catch (e) {
            dispatch({ type: ORDER_START, payload: e.res })
            console.log(e)
        }
    }
}
const GetNotificationORDER_CANCELED = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=ORDER_CANCELED', config)
            dispatch({ type: ORDER_CANCELED, payload: res.data })

        } catch (e) {
            dispatch({ type: ORDER_CANCELED, payload: e.res })
            console.log(e)
        }
    }
}
const GetNotificationBIKER_ON_THE_WAY = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=BIKER_ON_THE_WAY', config)
            dispatch({ type: BIKER_ON_THE_WAY, payload: res.data })

        } catch (e) {
            dispatch({ type: BIKER_ON_THE_WAY, payload: e.res })
            console.log(e)
        }
    }
}
const GetNotificationBIKER_ARRIVED = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=BIKER_ARRIVED', config)
            dispatch({ type: BIKER_ARRIVED, payload: res.data })

        } catch (e) {
            dispatch({ type: BIKER_ARRIVED, payload: e.res })
            console.log(e)
        }
    }
}
const GetNotificationBIKER_ASSIGN = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=BIKER_ASSIGN', config)
            dispatch({ type: BIKER_ASSIGN, payload: res.data })

        } catch (e) {
            dispatch({ type: BIKER_ASSIGN, payload: e.res })
            console.log(e)
        }
    }
}
const GetNotificationORDER_COMPLETED = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/notification_messages/single-message?message_type=ORDER_COMPLETED', config)
            dispatch({ type: ORDER_COMPLETED, payload: res.data })

        } catch (e) {
            dispatch({ type: ORDER_COMPLETED, payload: e.res })
            console.log(e)
        }
    }
}



const UpdateActivation = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/notification_messages/update/active-message/${data.is_active}?message_type=${data.message_type}`, data, config)
            dispatch({ type: UPDATE_ACTIVE, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_ACTIVE, payload: e.res })
            console.log(e)
        }
    }
}



const SendNotificationToPerson = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.phone !== "") {
                let res = await baseUrl.post(`v1/notification/users?title=${data.title}&description=${data.description}&phone=%2B${data.phone}`, data, config)
                dispatch({ type: SEND_TO_PERSON, payload: res.data })
                console.log(res);
                if (res.status === 201) {
                    success(`تم ارسال الرساله بنجاح `)
                }
            } else {
                let res = await baseUrl.post(`v1/notification/users?title=${data.title}&description=${data.description}`, data, config)
                dispatch({ type: SEND_TO_PERSON, payload: res.data })
                console.log(res);
                if (res.status === 201) {
                    success(res.data.data)
                }
            }
        } catch (e) {
            dispatch({ type: SEND_TO_PERSON, payload: e.res })
            // console.log(e);

            if (e.response.status === 404) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}




const UpdateNotifyData = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/notification_messages/update?title_en=${data.title_en}&title_ar=${data.title_ar}&message_en=${data.message_en}&message_ar=${data.message_ar}&message_type=${data.message_type}`, data, config)
            dispatch({ type: UPDATE_NOTIFY, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_NOTIFY, payload: e.res })
            console.log(e)
        }
    }
}


//Get Notification User
const GetNotificationUsers = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        //v1/notification?page=1&limit=10&filters=type%3Dsend-to-users&sortBy=created_at%3DDESC&isDeleted=false
        try {
            let res = await baseUrl.get(`v1/notification/admin?page=${data.pageCount}&limit=10&filters=type%3Dsend-to-users-from-admin&sortBy=created_at%3DDESC&isDeleted=false`, config)
            // console.log(res);
            dispatch({ type: NOTIFICATION_USERS, payload: res.data })
        } catch (e) {
            dispatch({ type: NOTIFICATION_USERS, payload: e.res })
        }
    }
}




export { GetNotificationORDER_START, GetNotificationORDER_COMPLETED, GetNotificationBIKER_ASSIGN,GetNotificationUsers, GetNotificationBIKER_ARRIVED, GetNotificationBIKER_ON_THE_WAY, GetNotificationORDER_CANCELED, UpdateActivation, SendNotificationToPerson, UpdateNotifyData }