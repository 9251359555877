import { GET_STATICS } from "../Type";

const initial = {
    Statics: [],
    loading: true,
}

const StaticsReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_STATICS:
            return {
                ...state,
                Statics: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default StaticsReducer