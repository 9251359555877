import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import { IoMdSettings } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from '../../Utils/Pagination';

import { DeleteBiker, GetAllBikers, GetAllBikersActive, GetAllBikersActiveAndSort, GetAllBikersData, GetAllBikersSort, GetAllManageBikers, SearchBiker, UpdateBikerStatus } from '../../redux/actions/Bikers';
import { ErrorMsg, success } from '../../Utils/Toast';

const BikersList = () => {
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState("ASC");
    const [activation, setActivation] = useState(null);
    const [filter, setFilter] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [del, setDel] = useState(false)

    const handleOrderChange = (e) => {
        console.log(e.target.value)
        setOrder(e.target.value);
    };



    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllBikersData({
            pageCount: currentPage,
            active: activation,
            sort: order
        }));
    }, [dispatch, activation, order, currentPage]);

    const dataAll = useSelector(state => state.GetBikers.AllBikersDATA);

    useEffect(() => {

        if (dataAll && dataAll.bikers) {
            setFilter(dataAll.bikers);
        }

    }, [dataAll, del]);


    //Search 
    const [searchTerm, setNameSearch] = useState("");
    const handleSearch = async () => {
        if (dataAll) {
            await dispatch(SearchBiker({
                name: searchTerm,
                count: dataAll.bikersCount
            }))
        }
    };



    //Change Active
    const handleSwitchChange = async (id, active) => {
        try {
            setLoading(true);
            await dispatch(UpdateBikerStatus({
                id,
                active: !active,
            }));
            const updatedData = filter.map(item => {
                if (item.user_id === id) {
                    return {
                        ...item,
                        is_active: !active,
                    };
                }
                return item;
            });
            setFilter(updatedData)
            // dispatch(GetAllBikersData({
            //     pageCount: currentPage
            // }));
        } catch (error) {
            console.error('Error updating biker status:', error);
        } finally {
            setLoading(false);
        }
    };

    //Delete
    const handleDelete = async (id) => {
        try {
            setLoading(true);
            await dispatch(DeleteBiker({ id }));
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
            await dispatch(GetAllBikersData({
                pageCount: currentPage,
                active: activation,
                sort: order
            }));
            setDel(true)
            // const updatedData = (dataAll.bikers && dataAll.bikers.filter(item => item.user_id !== id)) || [];
            // setFilter(updatedData)
        }
    };






    const getPage = async (page) => {
        setCurrentPage(page)
    };


    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        onChange={(e) => setNameSearch(e.target.value)}

                    />
                    <FaSearch className="search-icon" onClick={handleSearch} />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={handleOrderChange}>
                        <option hidden>تاريخ الانشاء: تصاعدي</option>
                        <option value="ASC">تصاعدي</option>
                        <option value="DESC">تنازلي</option>
                    </Form.Select>
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setActivation(e.target.value)}>
                        <option hidden>عرض حسب الحاله</option>
                        <option value="true">نشط</option>
                        <option value="false">غير نشط</option>
                    </Form.Select>
                </div>
            </div>
            <div className='mt-5'>
                <Table bordered style={{ borderRadius: "20px" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>الاسم </th>
                            {/* <th>الاسم الاخير</th> */}
                            {/* <th>المدينة</th> */}
                            {/* <th>نقطة الانطلاق</th> */}
                            <th>رقم الجوال</th>
                            <th>الحالة</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody style={{ color: " #4F4F4F" }}>
                        {
                            filter && dataAll.bikers ? (
                                filter.map((item, index) => {
                                    return (
                                        <tr className='mb-1' key={index}>
                                            <td>{index + 1}</td>
                                            {/* <td>{item.user_id}</td> */}
                                            <td>{item?.user?.first_name} {item?.user?.last_name}</td>
                                            {/* <td>{item.user.last_name || "لا يوجد اسم"}</td> */}
                                            {/* <td>{item.locationInfo}</td> */}
                                            <td>{item?.user?.phone}</td>
                                            <td>
                                                {item.is_active ? (
                                                    <span style={{ color: "#3CC441" }}>نشط</span>
                                                ) : (
                                                    <span style={{ color: "red" }}>غير نشط</span>
                                                )}
                                            </td>
                                            {item?.user?.phone !== "user deleted" && item?.deleted_at === null ? (
                                                <>
                                                    <td>
                                                        <Form>
                                                            <Form.Check
                                                                type="switch"
                                                                id={`custom-switch`}
                                                                checked={item.is_active}
                                                                onClick={() => handleSwitchChange(item.user_id, item.is_active)}
                                                            />
                                                        </Form>
                                                    </td>
                                                    <td>
                                                        <Link to={`/admin/bikersetting/${item.user_id}/${"BIKER"}`}>
                                                            <IoMdSettings />
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <MdDelete
                                                            style={{ color: "#EB5757", cursor: "pointer" }}
                                                            size="20"
                                                            onClick={() => handleDelete(item.user_id)}
                                                        />
                                                    </td>
                                                </>
                                            ) : <td colSpan={3}></td>}
                                        </tr>
                                    )
                                })
                            ) : (null)
                        }
                    </tbody>
                </Table>
            </div>
            {
                dataAll ? (
                    activation === null ? (<Pagination onPress={getPage} countsPage={dataAll.bikersCount / 10} />) : null
                ) : null
            }
        </div>
    );
};

export default BikersList;
