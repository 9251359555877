import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
import { GetAllPointsData, SearchByPhone } from '../../redux/actions//PointsAction'
import Pagination from '../../Utils/Pagination';
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";

const UserPoints = ({role}) => {

    const [points, setPoints] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState("ASC");
    const [searchedPoints, setSearchedPoints] = useState([]);

    const dispatch = useDispatch();

    const fetchAllpointsUser = async (page, sort) => {
        await dispatch(GetAllPointsData({ pageCount: page, sort  }))
    }

    const fetchAllpointsSearch = async (page , sort) => {
        await dispatch(SearchByPhone({ phone: searchValue, pageCount: page  , sort:sort}))
    }

    useEffect(() => {
        if (searchValue === "") {
            fetchAllpointsUser(currentPage, sort);
        } else {
            fetchAllpointsSearch(currentPage  , sort); // جلب الصفحة الأولى عند البحث
        }
    }, [currentPage, dispatch, sort])

    useEffect(() => {
        if (searchValue === "") {
            fetchAllpointsUser(currentPage, sort);
        } else {
            setCurrentPage(1); // إعادة تعيين الصفحة الحالية إلى الصفحة الأولى بمجرد العثور على نتائج البحث
            setPoints(searchedPoints); // تحديث النقاط بناءً على النتائج المسترجعة من البحث
        }
    }, [searchedPoints])

    const pointsUser = useSelector(state => state.PointsReducer.Points);

    useEffect(() => {
        if (pointsUser && pointsUser.data) {
            setPoints(pointsUser.data)
        }
    }, [pointsUser])

    const getPage = (page) => {
        setCurrentPage(page);
        if (searchValue === "") {
            fetchAllpointsUser(page, sort);
        } else {
            fetchAllpointsSearch(page , sort);
        }
    };

    const searchByValue = async () => {
        try {
            await dispatch(SearchByPhone({ phone: searchValue }));
            setCurrentPage(1);
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={searchByValue} />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example"
                        onChange={(e) => setSort(e.target.value)}>
                        <option hidden> الترتيب تصاعدي</option>
                        <option value="DESC">تنازلي</option>
                        <option value="ASC">تصاعدي</option>
                    </Form.Select>
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>اسم المستخدم</th>
                            <th>رقم الجوال</th>
                            <th>اخر النقاط المكتسبه</th>
                            <th>نوع الغسله</th>
                            <th>تاريخ اخر غسله</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            points && points.length > 0 ? (
                                
                                points.map((point, index) => {
                                    // console.log(points)
                                    const UpdateAt = point.updated_at ? point.updated_at.split('T')[0] : 'لايوجد معاد';
                                    return (
                                        <tr key={point.id}>
                                            <td>{index + 1}</td>
                                            <td>{point.user.name}</td>
                                            <td>{point.user.phone}</td>
                                            <td>{point.points} نقطه</td>
                                            <td>{point.type}</td>
                                            <td>{UpdateAt}</td>
                                            <td>
                                                <Link id="userPoints" to={`/admin/all-points/${point.user_id}`}>
                                                    عرض جميع نقاط المستخدم
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (<tr><td colSpan={8}>لاتوجد نقاط</td></tr>)
                        }
                    </tbody>
                </Table>

                {
                    pointsUser && pointsUser.meta ? (<Pagination onPress={getPage} countsPage={pointsUser.meta.total / 10} />) : null
                }
            </div>

        </div>
    )
}

export default UserPoints
