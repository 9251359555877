import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { DeleteSubData, GetAllSubs, GetSpesificSubs } from '../../redux/actions/OrdersAdmin';
const DeleteSubs = ({ idSub, PaginationPage, sort, status, baqa, count, searchKey }) => {


    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    if (showEdit) {
        console.log(idSub);
    }

    const dispatch = useDispatch();

    const [loader, setLoader] = useState(false);

    const handleSubsDelete = async () => {
        try {
            setLoading(false);
            setLoader(true);
            await dispatch(DeleteSubData({
                id: idSub,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true)
            handleCloseEdit();
            if (searchKey === "") {
                dispatch(GetAllSubs({
                    pageCount: PaginationPage,
                    sort: sort,
                    status: status,
                    baqa: baqa,
                    count: count
                }))
            } else {
                await dispatch(GetSpesificSubs({
                    searchvalue: searchKey
                }))
            }
            setLoader(false);
        }
    }
    return (
        <div>
            <button onClick={handleShowEdit} style={{ color: "#EB5757", background: "#EB575733" }} className='btn'>إلغاء الباقة</button>
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>الغاء الطلب  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد الغاء الطلب</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={handleSubsDelete} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تاكيد الالغاء
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

            {
                loader ? (
                    <div className="loader-overlay">
                        <span className="loader"></span>
                    </div>
                ) : null
            }
        </div>
    )
}

export default DeleteSubs
