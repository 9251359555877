import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { MdEdit } from "react-icons/md";
import Colorful from '@uiw/react-color-colorful';
import { hsvaToHex } from '@uiw/color-convert';
import { ChromePicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux'
import { GetAllColors, GetSingleColor, UpdateColor } from '../../redux/actions/Colors';

const UpdateColors = ({ idData, color, nameArData, nameEnData, name }) => {
    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [order, setOrder] = useState("");

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        await dispatch(GetSingleColor({
            id: idData
        }))
        setShow(true);
    }

    const getSingleInfoColor = useSelector(state => state.ColorReducer.getInfoSingleColor);


    useEffect(() => {
        if (getSingleInfoColor && getSingleInfoColor.data) {
            setNameEn(getSingleInfoColor.data.name_en);
            setNameAr(getSingleInfoColor.data.name_ar);
            setOrder(getSingleInfoColor.data.order_by);
            setHexaColor(getSingleInfoColor.data.hex);
            // setHexaColor(getSingleInfoColor.data.hex)
        }
    }, [getSingleInfoColor, getSingleInfoColor.data])


    const [hsva, setHsva] = useState({ h: 0, s: 0, v: 68, a: 1 });
    const [disableAlpha, setDisableAlpha] = useState(true);
    const [hexaColor, setHexaColor] = useState('');
    const [selectedColor, setSelectedColor] = useState('#ffffff');


    const dispatch = useDispatch();
    const UpdateColorData = async () => {
        try {

            await dispatch(UpdateColor({
                id: idData,
                name_ar: nameAr,
                name_en: nameEn,
                hex: hexaColor,
                order_by: `${order}`
            }))


        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            dispatch(GetAllColors())
        }
    }

    return (
        <div>
            <MdEdit cursor={"pointer"} onClick={handleShow} color='#6B7280' />
            <Modal show={show} centered className='font modalUpdateColor' onHide={handleClose} >
                <Modal.Header>
                    <Modal.Title>تعديل الالوان</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='w-25'>
                            <span>اللون اللمختار</span>
                            <div style={{ width: "80px", height: "80px", borderRadius: "50%", background: `${hexaColor}` }}></div>
                        </div>
                        <div style={{ width: "70%" }}>
                            <Colorful
                                color={hsva}
                                disableAlpha={disableAlpha}
                                style={{ width: "100%" }}
                                onChange={(color) => {
                                    setHsva(color.hsva);
                                    const hexa = hsvaToHex(color.hsva);
                                    setHexaColor(hexa);
                                }}
                            />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' onChange={(e) => setNameEn(e.target.value)} value={nameEn} placeholder='اسم اللون بالانجليزية' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />

                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameAr} onChange={(e) => setNameAr(e.target.value)} placeholder='اسم اللون بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={hexaColor} onChange={(e) => setHexaColor(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={UpdateColorData} className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} >
                        تعديل
                    </Button>
                    <Button variant="primary" onClick={handleClose} className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateColors



