import { GET_ALL_BANNERS, POST_NEW_BANNER, DELETE_BANNER , UPDATE_BANNER, GET_SINGLE_BANNER } from "../Type";
const initial = {
    AllBanners: [],
    PostBanner: [],
    DeleteBanner: [],
    UpdatedBanner: [],
    SingleBanner:[],
    loading: true,
}

const AreaReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_BANNERS:
            return {
                ...state,
                AllBanners: action.payload,
                loading: false
            }
        case POST_NEW_BANNER:
            return {
                ...state,
                PostBanner: action.payload,
                loading: false
            }
        case DELETE_BANNER:
            return {
                ...state,
                DeleteBanner: action.payload,
                loading: false
            }
        case UPDATE_BANNER:
            return {
                ...state,
                UpdatedBanner: action.payload,
                loading: false
            }
        case GET_SINGLE_BANNER:
            return {
                ...state,
                SingleBanner: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default AreaReducer