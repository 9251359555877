import baseUrl from '../../Api/baseURL'
import { GET_STATICS } from "../Type";

const GetStatics = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }
        try {
            let res = await baseUrl.get('v1/users/statistics',config)

            dispatch({ type: GET_STATICS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_STATICS, payload: e.res })
        }
    }
}

export default GetStatics