import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { IoPencil } from "react-icons/io5";
import { UpdateCarModal } from '../../redux/actions/CarActions';
const UpdateModalCar = ({ idCar, carNameEn, carNameAr , onUpdate  }) => {
    const [show, setShow] = useState(false);

    const [nameAr, setNameAr] = useState(carNameAr);
    const [nameEn, setNameEn] = useState(carNameEn);
    const [brandId, setBrandId] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const dispatch = useDispatch();

    const updateCarModalData = async () => {
        try {
            setLoading(true);
            await dispatch(UpdateCarModal({
                id: idCar,
                name_ar: nameAr,
                name_en: nameEn,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            onUpdate(idCar, nameAr, nameEn);
            handleClose();
        }
    }

    return (
        <div>
            <IoPencil size="20" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل السياره</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='mt-3'>

                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' onChange={(e) => setNameEn(e.target.value)} value={nameEn} placeholder='اسم مودل بالانجليزية' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />

                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameAr} onChange={(e) => setNameAr(e.target.value)} placeholder='اسم مودل بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateCarModalData} >
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateModalCar
