import {
    BIKER_ASSIGN,
    ORDER_CANCELED,
    BIKER_ON_THE_WAY,
    BIKER_ARRIVED,
    ORDER_START,
    ORDER_COMPLETED,
    UPDATE_ACTIVE,
    SEND_TO_PERSON,
    UPDATE_NOTIFY,
    NOTIFICATION_USERS
} from "../Type";
const initial = {
    orderStart: [],
    bikerAssign: [],
    orderCancel: [],
    bikerArrived: [],
    orderComplete: [],
    bikeronTheWay: [],
    updateActive: [],
    updateNotify: [],
    users: [],
    loading: true,
}

const NotificationReducer = (state = initial, action) => {
    switch (action.type) {
        case ORDER_START:
            return {
                ...state,
                orderStart: action.payload,
                loading: false
            }
        case BIKER_ASSIGN:
            return {
                ...state,
                bikerAssign: action.payload,
                loading: false
            }
        case ORDER_CANCELED:
            return {
                ...state,
                orderCancel: action.payload,
                loading: false
            }
        case BIKER_ARRIVED:
            return {
                ...state,
                bikerArrived: action.payload,
                loading: false
            }
        case ORDER_COMPLETED:
            return {
                ...state,
                orderComplete: action.payload,
                loading: false
            }
        case BIKER_ON_THE_WAY:
            return {
                ...state,
                bikeronTheWay: action.payload,
                loading: false
            }
        case UPDATE_ACTIVE:
            return {
                ...state,
                updateActive: action.payload,
                loading: false
            }
        case SEND_TO_PERSON:
            return {
                ...state,
                updateActive: action.payload,
                loading: false
            }
        case UPDATE_NOTIFY:
            return {
                ...state,
                updateNotify: action.payload,
                loading: false
            }
        case NOTIFICATION_USERS:
            return {
                ...state,
                users: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default NotificationReducer