import { ADD_PREMISSION} from "../Type";
const initial = {
    Premission:[],
    loading: true,
}

const PremissionReducer = (state = initial, action) => {
    switch (action.type) {
        case ADD_PREMISSION:
            return {
                ...state,
                Premission: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default PremissionReducer