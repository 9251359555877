import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import { IoMdAddCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux'
import { CreateNewBaqa, CreatePhotoPackage, GetAllBaqa, UpdateBaqaFormatActive } from '../../redux/actions/BaqaAction';
import { ErrorMsg } from '../../Utils/Toast';
import DeleteBaqa from '../Modals/DeleteBaqa';
import UpdateBaqa from '../Modals/UpdateBaqa';
import { GetAllServices } from '../../redux/actions/ServicesAction';
import { IoCloseSharp } from "react-icons/io5";
import { act } from 'react-dom/test-utils';
// import Form from 'react-bootstrap/Form';

const Baqua = () => {
    const [showService, setShowService] = useState(false);
    const handkeShowService = () => {
        setShowService(!showService)
    }

    const [services, setServices] = useState([]);
    const [service, setService] = useState('');
    const [selectedServices, setSelectedServices] = useState([]);


    // const handleServiceChange = (e) => {
    //     const serviceId = e.target.value;
    //     const serviceCount = 0;
    //     setService(e.target.value);
    //     // console.log(e.target.value)
    //     if (!selectedServices.includes(serviceId)) {
    //         setSelectedServices([...selectedServices, { id: serviceId, service_count: parseInt(serviceCount), is_active: true }]);
    //     }
    // };
    const handleServiceChange = (e) => {
        const serviceId = e.target.value;
        const serviceCount = 0;
        setService(e.target.value);
        if (!selectedServices.some(service => service.id === serviceId)) { // Check if service is not already selected
            setSelectedServices([...selectedServices, { id: serviceId, service_count: parseInt(serviceCount), is_active: true }]);
        }
    };
    const handleRemoveService = (serviceIdToRemove) => {
        const updatedServices = selectedServices.filter(service => service.id !== serviceIdToRemove);
        setSelectedServices(updatedServices);
    };

    const handleCountChange = (e, index) => {
        const { value } = e.target;
        const updatedServices = [...selectedServices];
        updatedServices[index].service_count = parseInt(value);
        setSelectedServices(updatedServices);
    };


    const getServices = async () => {
        await dispatch(GetAllServices());
    }

    //Modal
    const [show, setShow] = useState(false);


    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true)
    }
    const [showEdit, setShowEdit] = useState(false);


    const [images, setImages] = useState([]);
    const [uploadError, setUploadError] = useState(null);

    const handleImageUpload = async (event) => {
        const selectedImage = event.target.files[0];
        setImages([selectedImage]);
        setUploadError(null); // Reset upload error
        const formData = new FormData();
        formData.append('file', selectedImage);

    }

    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();

    };





    //Get APi Data Baqa

    const dispatch = useDispatch();
    const fetchAllBaqa = async () => {
        await dispatch(GetAllBaqa());
    }


    useEffect(() => {
        fetchAllBaqa();
        getServices();
    }, [])

    const resultServices = useSelector(state => state.ServicesReducer.allServices)

    useEffect(() => {
        if (resultServices && resultServices.data) {
            setServices(resultServices.data);
        }
    }, [resultServices]);

    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);


    //Create New Baqa

    const [loading, setLoading] = useState(false);
    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [washSingle, setPriceWashSingle] = useState();
    const [total_price_package, setTotal_Price_Package] = useState();
    const [wash_count, setWash_Count] = useState();
    const [description_ar, setDescription_ar] = useState("");
    const [description_en, setDescription_en] = useState("");
    const [expiry_date_in_days, setExpiry_date_in_days] = useState();
    const [buy_by_points, setBuy_by_points] = useState();
    const [order_by, setorder_by] = useState();
    const [loader, setLoader] = useState(false);


    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);


    const AddNewBaqa = async () => {
        // formData.append('package_services', [{
        //     id: "96df95bb-b1b6-481c-ba5b-082f215b97cf",
        //     service_count: 5,
        //     is_active: true
        // }])
        // formData.append('buy_by_points', parseInt(buy_by_points))
        // formData.append('package_service', selectedServices)
        const formData = new FormData();
        try { 
            setLoading(true);
            setLoader(true);
            if (
                nameAr !== "" && nameEn !== "" && wash_count !== undefined && expiry_date_in_days !== undefined
                &&
                order_by !== undefined
                && images.length > 0
            ) {
                formData.append('name_ar', nameAr)
                formData.append('name_en', nameEn)
                formData.append('order_by', parseInt(order_by))
                formData.append('price_wash_single', parseFloat(washSingle).toFixed(2))
                formData.append('wash_count', parseFloat(wash_count).toFixed(2))
                formData.append('total_price_package', parseFloat((wash_count) * (washSingle)).toFixed(2))
                formData.append('description_ar', description_ar)
                formData.append('description_en', description_en)
                formData.append('expiry_date_in_days', parseInt(expiry_date_in_days))
                formData.append('file', images[0])
                formData.append('package_services', JSON.stringify(selectedServices))
                await dispatch(CreateNewBaqa(formData))
            } else {
                ErrorMsg("Data Missed");
            }

        } catch (e) {
            console.log(e);
        } finally {

            if (nameAr !== "" && nameEn !== "" && wash_count !== undefined && expiry_date_in_days !== undefined
                &&
                order_by !== undefined && images.length > 0) {
                handleClose();
                dispatch(GetAllBaqa());
                setNameAr('');
                setNameEn('');
                setPriceWashSingle('');
                setTotal_Price_Package('');
                setWash_Count('');
                setDescription_ar('');
                setDescription_en('');
                setExpiry_date_in_days('');
                setBuy_by_points('');
                setorder_by('');
                setImages([]);
                setSelectedServices([])
            }
            setLoader(false);
            setLoading(false);
        }
    }


    const updateActiveBaqa = async (active, id) => {
        console.log(active);
        const formData = new FormData();
        try {
            setLoading(true);
            formData.append('is_active', !active)
            await dispatch(UpdateBaqaFormatActive(formData, id))
        } catch (e) {
            console.log(e);
        } finally {
            // setLoading(false);
            dispatch(GetAllBaqa());
        }
    }





    return (
        <div className='font'>
            <div className='flexItem font py-3'>
                <div>
                    <p>الباقات</p>
                </div>
                <div>
                    <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={handleShow}>اضافة باقة جديدة</button>
                </div>
            </div>
            {/* <Search /> */}

            {/* Table */}
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>صورة الباقة</th>
                            <th>اسم الباقة</th>
                            <th>عدد الغسلات</th>
                            <th>الصلاحية</th>
                            <th>الخدمة الاضافية</th>
                            <th>سعر الغسلة الواحدة</th>
                            <th>السعر الاجمالي</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            dataBaqat && dataBaqat.data ? (

                                dataBaqat.data.map((baqa, index) => {

                                    return (
                                        <tr key={baqa.id}>
                                            <td>{index + 1}</td>
                                            <td style={{ width: "75px", height: "75px" }}>
                                                <div style={{ backgroundImage: `url(${baqa.background_url})`, width: "100%", height: "100%", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                            </td>
                                            <td>{baqa.name}</td>
                                            <td>{baqa.wash_count}</td>
                                            <td>{baqa.expiry_date_in_days} يوم</td>
                                            {/* <td>{baqa.id}</td> */}
                                            <td>
                                                <td style={{}}>
                                                    {
                                                        baqa.package_service && baqa.package_service.length > 0 ? (
                                                            baqa.package_service.map((service, index) => {
                                                                return (
                                                                    <span style={{ display: "block" }} key={index}>{service.service.name} x{service.service_count}</span>
                                                                );
                                                            })
                                                        ) : (
                                                            <span>لا توجد خدمات اضافيه</span>
                                                        )
                                                    }
                                                </td>
                                            </td>
                                            <td style={{ color: "#A85923" }}>{baqa.price_wash_single} ر.س</td>
                                            <td style={{ color: "#A85923" }}>{baqa.total_price_package} ر.س</td>
                                            <td>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id={`custom-switch`}
                                                        checked={baqa.is_active}
                                                        onClick={() => updateActiveBaqa(baqa.is_active, baqa.id)}
                                                    />
                                                </Form>
                                            </td>
                                            <td style={{ cursor: "pointer" }}>
                                                <UpdateBaqa idData={baqa.id} name={baqa.name} washCount={baqa.wash_count} background={baqa.background_url} points={baqa.buy_by_points} days={baqa.expiry_date_in_days} order={baqa.order_by} priceSingle={baqa.price_wash_single} desc={baqa.description} totalPrice={baqa.total_price_package} />
                                            </td>
                                            <td style={{ cursor: "pointer" }}>
                                                <DeleteBaqa idData={baqa.id} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={11}>لايوجد باقات</td>
                                </tr>

                            )
                        }
                    </tbody>
                </Table>
            </div>


            {/* Modal */}
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة باقه جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>ارفع صورة الباقه</span>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <div className="w-100" style={{ background: "#EFF1F9", padding: "20px", borderRadius: "10px", width: "40%" }}>
                                {images.map((image, index) => (
                                    <div>
                                        <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                            style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="file-input" style={{ padding: "20px", textAlign: "left" }}>
                            <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                            <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <label>اسم الباقة بالعربي</label>
                        <input type="text" className='w-100' value={nameAr}
                            onChange={(e) => {
                                setNameAr(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidTitleAr(true)
                                } else {
                                    setValidTitleAr(false);
                                }
                            }}
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                    }
                    <div className='mt-3'>
                        <label> اسم الباقة بالانجليزي</label>
                        <input type="text" className='w-100' value={nameEn}
                            onChange={(e) => {
                                setNameEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidTitleEn(true)
                                } else {
                                    setValidTitleEn(false);
                                }
                            }}
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                    }
                    <div className='mt-3'>
                        <label> وصف الباقة بالعربيه</label>
                        <input type="text" className='w-100' placeholder='وصف الباقة بالعربيه' value={description_ar} onChange={(e) => setDescription_ar(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>وصف الباقة بالانجليزيه </label>
                        <input type="text" className='w-100' value={description_en} onChange={(e) => setDescription_en(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>ترتيب الباقه  </label>
                        <input type="number" min="1" className='w-100' value={order_by} onChange={(e) => setorder_by(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>



                    <div className='mt-4'>
                        <label>الخدمات الاضافيه</label>
                        <Form.Select aria-label="Default select example" onChange={handleServiceChange} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>الخدمات الاضافيه</option>
                            {
                                services ? (
                                    services.map((service, index) => {
                                        return (
                                            <option value={service.id} key={service.id}>{service.name_en}</option>
                                        )
                                    })
                                ) : (null)
                            }
                        </Form.Select>
                    </div>


                    {selectedServices.length > 0 && (
                        <div className="mt-4 my-3">
                            {selectedServices.map((service, index) => (
                                <div key={index} className="d-flex align-items-center">
                                    <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
                                        {services.find(item => item.id === service.id)?.name_en} {/* Use optional chaining (?) */}
                                    </p>
                                    <input
                                        type="number"
                                        min="1"
                                        value={service.service_count}
                                        onChange={(e) => handleCountChange(e, index)} // Assuming you have a function handleCountChange
                                        style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "8px", borderRadius: "5px", marginRight: "5px" }}
                                    />
                                    <IoCloseSharp onClick={() => handleRemoveService(service.id)} />
                                </div>
                            ))}
                        </div>
                    )}

                    <div className='mt-3'>
                        <label>صلاحية الباقه</label>
                        <input type="number" min="1" className='w-100' value={expiry_date_in_days} onChange={(e) => setExpiry_date_in_days(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {/* <div className='mt-3'>
                        <label>عدد النقاط</label>
                        <input type="number" min="1" className='w-100' value={buy_by_points} onChange={(e) => setBuy_by_points(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div> */}

                    <div className='mt-3'>
                        <label>حدد عدد الغسلات </label>
                        <input type="number" min="1" className='w-100' value={wash_count} onChange={(e) => setWash_Count(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />

                    </div>

                    <div className='mt-3'>
                        <label>سعر الغسلة الواحدة</label>
                        <div className='d-flex'>
                            <input type="number" min="1" className='w-100' value={washSingle} onChange={(e) => setPriceWashSingle(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                            <div className='shadow' style={{ marginRight: "-50px", width: "50px", height: "48px", textAlign: "center", lineHeight: "50px", borderRadius: "10px", background: "#EFF1F9" }}>رس</div>
                        </div>

                    </div>
                    <div className='mt-3'>
                        <label>إجمالي سعر الباقة</label>
                        <div className='d-flex'>
                            <input type="number" min="1" className='w-100' readOnly value={parseFloat((wash_count) * (washSingle)).toFixed(2)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                            <div className='shadow' style={{ marginRight: "-50px", width: "50px", height: "48px", textAlign: "center", lineHeight: "50px", borderRadius: "10px", background: "#EFF1F9" }}>رس</div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={AddNewBaqa}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>



            {
                loader ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }

        </div>
    )
}

export default Baqua
