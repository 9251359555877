import baseUrl from "../../Api/baseURL";
import { ErrorMsg } from "../../Utils/Toast";
import { DELETE_REVIEW, GET_ALL_REVIEWS, UPDATE_ACTIVE_RATE } from '../Type'

const GetAllReviews = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": 'ar'
            },
        }
        try {
            if (data.searchValue === "" && data.rate === "") {
                let res = await baseUrl.get(`v1/review-order/all-reviews?page=${data.pageCount}&limit=10&sortBy=created_at%3D${data.sort}`, config)

                dispatch({ type: GET_ALL_REVIEWS, payload: res.data })
            } else if (data.searchValue !== "" && data.rate === "") {
                let res = await baseUrl.get(`v1/review-order/all-reviews?page=${data.pageCount}&limit=10&filters=order.number%3D${data.searchValue}&sortBy=created_at%3D${data.sort}`, config)

                dispatch({ type: GET_ALL_REVIEWS, payload: res.data })
            } else if (data.searchValue === "" && data.rate !== "") {
                let res = await baseUrl.get(`v1/review-order/all-reviews?page=${data.pageCount}&limit=10&filters=rate%3D${data.rate}&sortBy=created_at%3D${data.sort}`, config)

                dispatch({ type: GET_ALL_REVIEWS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_REVIEWS, payload: e.res })
            console.log(e)
        }
    }
}
const ChangeActive = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": 'ar'
            },
        }
        try {
            let res = await baseUrl.put(`v1/review-order/${data.id}/active-review/${data.active}`, data, config)
            dispatch({ type: UPDATE_ACTIVE_RATE, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPDATE_ACTIVE_RATE, payload: e.res })
            console.log(e)
        }
    }
}
const DeleteReviewData = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": 'ar'
            },
        }
        try {
            let res = await baseUrl.delete(`v1/review-order/${data.id}`, config)
            dispatch({ type: DELETE_REVIEW, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: DELETE_REVIEW, payload: e.res })
            console.log(e)
        }
    }
}


export { GetAllReviews, ChangeActive  , DeleteReviewData}