import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { GetAllSubs, GetSpesificSubs, UpdateDateSubs } from '../../redux/actions/OrdersAdmin';
import DateTime from 'react-datetime';

function ExtendingPackage({ idSub, PaginationPage, dateData, onUpdateDone, sort, status, baqa, count, searchKey }) {
    const [show, setShow] = useState(false);
    const [selectTime, setSelectTime] = useState('');
    const [selectedDate, setSelectedDate] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [loading, setLoading] = useState(false);


    const handleSelectTime = (event) => {
        setSelectTime(event.target.value);
    };


    const handleDateChange = (date) => {
        // console.log(date.toISOString());
        setSelectedDate(date.toISOString());
    };



    const dispatch = useDispatch();

    const handleSubsUpdate = async () => {
        try {
            setLoading(true)
            await dispatch(UpdateDateSubs({
                id: idSub,
                date: selectedDate
            }))
            if (searchKey === "") {
                dispatch(GetAllSubs({
                    pageCount: PaginationPage,
                    sort: sort,
                    status: status,
                    baqa: baqa,
                    count: count
                }))
            } else {
                await dispatch(GetSpesificSubs({
                    searchvalue: searchKey
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true)
            handleClose();

        }
    }



    const reverseDate = (dateString) => {
        const parts = dateString.split('-');
        return parts[2] + '-' + parts[1] + '-' + parts[0];
    };

    // console.log(reverseDate(`${dateData}`)); // يطبع: 2025-12-11

    const validDate = (current) => {
        // Disable dates prior to today by comparing with the current date
        return current.isSameOrAfter(new Date(), 'day');
    };


    return (
        <>
            <button onClick={handleShow} style={{ color: "#A85923", background: "#A8592333" }} className='btn'>تمديد صلاحية الباقة</button>

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>
                        تمديد صلاحية الباقة
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>حدد التاريخ الذي تريد تمديد الباقة له</span>
                    <div className='mt-3'>
                        <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} // Set a function to determine valid dates
                            onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                        {/* <input type="date" onChange={handleSelectTime} value={selectTime} className='w-100' min={reverseDate(`${dateData}`)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} /> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleSubsUpdate}>
                        تمديد الباقه
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ExtendingPackage;