import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { CreateServices } from '../../redux/actions/ServicesAction';
import { ErrorMsg } from '../../Utils/Toast';



const AddServicesModal = ({ onServiceAdded }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [pricenum, setPrice] = useState("");
    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);

    const addNewService = async () => {
        if (nameAr !== "" && nameEn !== "" && pricenum !== "" && !validTitleAr && !validTitleEn) {
            await dispatch(CreateServices({
                name_ar: `${nameAr}`,
                name_en: `${nameEn}`,
                duration_by_minute: 10,
                price: parseFloat(pricenum),
            }))
            if (onServiceAdded) {
                onServiceAdded();
            }
            handleClose();
            setNameAr("");
            setNameEn("");
            setPrice("");
        } else {
            ErrorMsg("Data Misssed")
        }
    }



    return (
        <div>
            <button className='btn' onClick={handleShow} style={{ color: "black", background: "#FECB44" }}>اضافة خدمه جديده</button>
            {/* Modal */}
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>اضافة خدمة اضافية جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameAr} onChange={(e) => {
                            setNameAr(e.target.value);
                            if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                setValidTitleAr(true)
                            } else {
                                setValidTitleAr(false);
                            }
                        }} placeholder='  اسم الخدمه بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                    }



                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameEn} onChange={(e) => {
                            setNameEn(e.target.value);
                            if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                setValidTitleEn(true)
                            } else {
                                setValidTitleEn(false);
                            }
                        }} placeholder='  اسم الخدمه بالانجليزيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                    }
                    <div className='mt-3'>
                        <input
                            type="number"
                            className='w-100'
                            value={pricenum}
                            onChange={(e) => {
                                const inputPrice = parseFloat(e.target.value);
                                if (inputPrice >= 0) {
                                    setPrice(inputPrice);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace' || e.key === 'Delete') {
                                    setPrice(''); // قم بتعيين القيمة إلى فارغة عندما يتم استخدام مفاتيح المسح
                                }
                            }}
                            placeholder='سعر الخدمه'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={addNewService} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        اضافه
                    </button>
                    <button className='btn' onClick={handleClose} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddServicesModal
