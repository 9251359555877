import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BoohingMenu from './BookingMenu';
import ManageSubs from './ManageSubs';
import SettingBooking from './SettingBooking';
const AdminBookingSubs = () => {

    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }

    return (
        <div>
            <p className='font fs-3 mt-5 mb-5'>ادارة الاشتركات والحجوزات</p>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 tabContainer"
            >
                {
                    adminLogIn && adminLogIn.premessions ? (
                        adminLogIn.premessions.map((pre) => {
                            return (
                                pre === "order" ? (
                                    <Tab eventKey="home" className='font' title="قائمة الحجوزات">
                                        <BoohingMenu />
                                    </Tab>
                                ) : null
                            )
                        })
                    ) : null
                }

                {
                    adminLogIn && adminLogIn.premessions ? (
                        adminLogIn.premessions.map((pre) => {
                            return (
                                pre === "subscription" ? (
                                    <Tab eventKey="profile" className='font' title="ادارة الاشتراكات">
                                        <ManageSubs />
                                    </Tab>
                                ) : null
                            )
                        })
                    ) : null
                }

                {/* <Tab eventKey="contact" className='font' title="اعدادات الاشتراكات والحجوزات">
                    <SettingBooking />
                </Tab> */}
                {
                    adminLogIn && adminLogIn.premessions ? (
                        adminLogIn.premessions.map((pre) => {
                            return (
                                pre === "app-constants" ? (
                                    <Tab eventKey="contact" className='font' title="اعدادات الاشتراكات والحجوزات">
                                        <SettingBooking />
                                    </Tab>
                                ) : null
                            )
                        })
                    ) : null
                }
            </Tabs>
        </div>
    )
}

export default AdminBookingSubs
