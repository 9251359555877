import React, { useEffect, useState } from 'react'
import Search from '../../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ErrorMsg, success } from '../../../Utils/Toast'
import { FaSearch, FaUserFriends } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput from "react-phone-input-2";
import { AddSms, DeleteSms, GetAllSms } from '../../../redux/actions/Sms';
import Pagination from '../../../Utils/Pagination';
import DeleteSmsCom from '../../Modals/DeleteSms';
const MessageMain = () => {
    const [showMessageData, setShowMessageData] = useState(false);
    const handleMessageData = () => {
        setShowMessageData(!showMessageData)
    }


    //For Modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //For Set Data Modal
    const [phoneNumber, setPhoneNumber] = useState('');
    const handleOnChange = (value, country, e, formattedValue) => {

        setPhoneNumber(value);
    };


    const [typeMessage, setTypeMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [typeGift, setTypeGift] = useState('');
    const [typeUser, setTypeUser] = useState('');
    const [email, setEmail] = useState('');
    const [textMessage, setTextMessage] = useState('');
    const [sort, setSort] = useState("DESC");
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState({});
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
    const [senderKey, setSenderKey] = useState("");

    const handleData = () => {
        if (typeMessage !== "" && typeGift !== "" && typeUser !== "" && email !== "" && textMessage !== "") {
            setData({
                typeMessage,
                typeGift,
                typeUser,
                email,
                textMessage,
                phoneNumber,
            })
            success('تم ارسال هديه الجديده');
            handleClose();
            setTypeMessage("")
            setTypeGift("")
            setTypeUser("")
            setEmail("")
            setTextMessage("")
        } else {
            ErrorMsg('هناك خطا في البيانات');
            handleClose();
        }
    }

    //Dispatch Get All Data Messages
    const dispatch = useDispatch();

    const getAllSmsMessages = async (sort, currentPage, searchValue) => {
        await dispatch(GetAllSms({
            sortType: sort,
            pageCount: currentPage,
            searchValue: searchValue
        }))
    }

    useEffect(() => {
        getAllSmsMessages(sort, currentPage, searchValue);
    }, [dispatch, sort, currentPage])

    const sms = useSelector(state => state.SmsReducer.Sms);
    const handleToggleMessage = (index) => {
        setSelectedMessageIndex(index === selectedMessageIndex ? null : index);
    };


    const getPage = (page) => {
        setCurrentPage(page);
    };

    const Search = async () => {
        try {
            setCurrentPage(1)
            getAllSmsMessages(sort, currentPage, searchValue);
        } catch (e) {

        }
    }


    const addSmsData = async () => {
        try {
            setLoading(true);
            await dispatch(AddSms({
                senderKey: senderKey,
                desc: textMessage,
                phone: phoneNumber,
            }))
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
            handleClose();
            setPhoneNumber('');
            setSenderKey("")
            dispatch(GetAllSms({
                sortType: sort,
                pageCount: currentPage,
                searchValue: searchValue
            }))
        }
    }


    return (
        <div className='font'>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة الرسايل النصية</p>
                </div>
                <div>
                    <button className='btn' onClick={handleShow} style={{ color: "black", background: "#FECB44" }}>ارسال رسالة نصية</button>
                </div>
            </div>

            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={Search} />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setSort(e.target.value)}>
                        <option hidden> الترتيب تنازلي</option>
                        <option value="DESC"> تنازلي</option>
                        <option value="ASC"> تصاعدي</option>
                    </Form.Select>
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>نوع الرسالة</th>
                            <th>اسم المستخدم</th>
                            <th>رقم الجوال</th>
                            <th>نص الرسالة</th>
                            <th>Sender ID</th>
                            <th>تاريخ الارسال</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sms && sms.data ? (
                                sms.data.map((message, index) => {
                                    return (
                                        <tr key={message.id}>
                                            <td>{index + 1}</td>
                                            {/* <td>{message.id}</td> */}
                                            <td>
                                                {
                                                    message.type === "send-to-users-from-admin" ? (<span>رساله نصيه</span>) : (<span>هديه</span>)
                                                }
                                            </td>
                                            <td>{
                                                message.user ? (<span>{message.user.first_name} {message.user.last_name}</span>) : null
                                            }</td>
                                            <td>{
                                                message.user ? (<span>{message.user.phone}</span>) : null
                                            }</td>
                                            <td className='showMessage' onClick={() => handleToggleMessage(index)}>
                                                <span>{selectedMessageIndex === index ? <span style={{ color: "red" }}>اغلاق الرساله</span> : 'عرض الرسالة'}</span>
                                                {selectedMessageIndex === index && (
                                                    <div className='infoShowMessage'>
                                                        <small>{message.text}</small>
                                                    </div>
                                                )}
                                            </td>
                                            <td>{message.sender_id}</td>
                                            <td>{message.created_at}</td>
                                            <td>
                                                <DeleteSmsCom idData={message.id} page={currentPage} sort={sort} searchData={searchValue} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={"10"}>لا يوجد رسائل حاليا</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
            </div>
            {sms && sms.meta && (
                <Pagination onPress={getPage} countsPage={sms.meta.total / 10} />
            )}
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>ارسال رسالة نصية</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        {/* <Form.Select aria-label="Default select example" onChange={(e) => setTypeMessage(e.target.value)} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>نوع الرساله نصيه</option>
                        </Form.Select> */}

                        <p style={{ background: "#EFF1F9" , padding:"10px" , borderRadius:"10px"}}>نوع الرساله نصيه</p>
                    </div>
                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" onChange={(e) => setTypeUser(e.target.value)} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>نوع المستخدم</option>
                            <option value="all">رساله للكل</option>
                            <option value="single">رساله لشخص</option>
                        </Form.Select>
                    </div>

                    {
                        typeUser === "single" ? (
                            <div className='mt-4'>
                                <PhoneInput
                                    country={'sa'}
                                    value={phoneNumber}
                                    onChange={handleOnChange}
                                    enableSearch={false}
                                />
                            </div>
                        ) : null
                    }

                    <div className='mt-4 w-100' onChange={(e) => setTextMessage(e.target.value)}>
                        <textarea cols={"57"} rows={"2"} className='textMessage w1-00' placeholder='نص الرساله' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}>

                        </textarea>
                    </div>
                    <div className='mt-4'>
                        <label>Sender Id</label>
                        <br></br>
                        <Form.Select aria-label="Default select example" value={senderKey}  onChange={(e) => setSenderKey(e.target.value)} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>Add Sender Id</option>
                            <option value="QuickySA">QuickySA</option>
                            <option value="QuickySA-AD">QuickySA-AD</option>
                        </Form.Select>
                        {/* <input type='text' className='w-100' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} value={senderKey} onChange={(e) => setSenderKey(e.target.value)} /> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' onClick={addSmsData} style={{ width: "45%", background: "#FECB44", color: "black" }} >
                        اضافه
                    </button>
                    <button className='btn' onClick={handleClose} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}  >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default MessageMain
