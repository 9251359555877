import React, { useEffect, useState } from 'react'
import { BsFillSendFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoIosAddCircle } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from 'react-redux'
import { GetAllNotification, GetNotificationBIKER_ARRIVED, GetNotificationBIKER_ASSIGN, GetNotificationBIKER_ON_THE_WAY, GetNotificationORDER_CANCELED, GetNotificationORDER_COMPLETED, GetNotificationORDER_START, SendNotificationToPerson, UpdateActivation } from '../../redux/actions/NotificationAction';
import UpdateNotify from '../Modals/UpdateNotify';


const OrdersStatus = ['ORDER_START', 'ORDER_COMPLETED', 'ORDER_CANCELED', 'BIKER_ON_THE_WAY', 'BIKER_ARRIVED', "BIKER_ASSIGN"];

const NotificationBikers = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [title, setTitle] = useState('');
    const [messagText, setMessageText] = useState('');

    const [notifyUser, setNotifyUser] = useState('');
    const [phone, setPhone] = useState('');

    const handlePhone = (newPhoneNumber) => {
        setPhone(newPhoneNumber)
    }




    const fetchNotify = async () => {
        await dispatch(GetNotificationORDER_START())
        await dispatch(GetNotificationORDER_CANCELED())
        await dispatch(GetNotificationBIKER_ON_THE_WAY())
        await dispatch(GetNotificationBIKER_ARRIVED())
        await dispatch(GetNotificationBIKER_ASSIGN())
        await dispatch(GetNotificationORDER_COMPLETED())
    }

    const orderStart = useSelector(state => state.NotificationReducer.orderStart);
    const bikerAssign = useSelector(state => state.NotificationReducer.bikerAssign);
    const orderCancel = useSelector(state => state.NotificationReducer.orderCancel);
    const bikerArrived = useSelector(state => state.NotificationReducer.bikerArrived);
    const orderComplete = useSelector(state => state.NotificationReducer.orderComplete);
    const bikeronTheWay = useSelector(state => state.NotificationReducer.bikeronTheWay);

    useEffect(() => {
        fetchNotify()
    }, [dispatch])


    const updateActiveNotify = async (type, active) => {
        console.log(type, active);
        try {
            await dispatch(UpdateActivation({
                message_type: type,
                is_active: `${!active}`
            }))
        } catch (e) {
            console.log(e);
        } finally {
            if (type === "ORDER_START") {
                await dispatch(GetNotificationORDER_START())

            } else if (type === "ORDER_COMPLETED") {
                await dispatch(GetNotificationORDER_COMPLETED())

            } else if (type === "ORDER_CANCELED") {
                await dispatch(GetNotificationORDER_CANCELED())

            } else if (type === "BIKER_ON_THE_WAY") {
                await dispatch(GetNotificationBIKER_ON_THE_WAY())

            } else if (type === "BIKER_ARRIVED") {
                await dispatch(GetNotificationBIKER_ARRIVED())

            } else if (type === "BIKER_ASSIGN") {
                await dispatch(GetNotificationBIKER_ASSIGN())

            }
        }
    }



    //Send To Person
    const sendMessageToPerson = async () => {
        setLoading(true)
        try {
            await dispatch(SendNotificationToPerson({
                title: title,
                description: messagText,
                phone: phone,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            setTitle('');
            setPhone('');
            setMessageText("")
            setLoading(false)
        }
    }


    return (
        <div>
            {/* <div className='flexItem font py-3'>
                <div>
                    <p>ادارة اشعارات النظام</p>
                </div>
                <div>
                    <button className='btn' onClick={handleShow} style={{ color: "black", background: "#FECB44" }}>ارسال إشعار جديد</button>
                </div>
            </div> */}


            {/* Notification For Users */}
            {/* <div>
                <div className='font notify'>
                    <p className='fs-6'>اشعارات المستخدمين</p>
                </div>
                <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}>
                    <div className='col-lg-10'>
                        <p className='fs-5'>عنوان الرسالة يظهر هنا</p>
                        <span style={{ color: "#A0AEC0" }}>
                            نص ومحتوي الرسالة يظهر هنا، وهي رسالة ترويجية أو تستخدم لاغراض اعلانية للمستخدمين
                        </span>
                    </div>
                    <div className='col-lg-2 d-flex'>
                        <div style={{ marginLeft: "5px", padding: "10px" }}><MdModeEdit size={"20"} color='#6B7280' /></div>
                        <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                            type="switch"
                            id={`custom-switch`}
                        /></div>
                    </div>
                </div>
            </div> */}




            {/* Notification For Bikers */}
            <div className='mt-5'>
                {/* <div className='font notify'>
                    <p className='fs-6'>اشعارات البايكرز</p>
                </div> */}

                {
                    orderStart && orderStart.data ? (
                        <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}  >
                            <div className='col-lg-10'>
                                <p className='fs-5'>{orderStart.data.title_ar}</p>
                                <span style={{ color: "#A0AEC0" }}>
                                    {orderStart.data.message_ar}
                                </span>
                            </div>
                            <div className='col-lg-2 d-flex'>
                                <div style={{ marginLeft: "5px", padding: "10px" }}>
                                    <UpdateNotify type={orderStart.data.type} messageAr={orderStart.data.message_ar} messageEN={orderStart.data.message_en}
                                        titleAr={orderStart.data.title_ar}
                                        titleEn={orderStart.data.title_en}
                                    />
                                </div>
                                <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                                    type="switch"
                                    id={`custom-switch`}
                                    checked={orderStart.data.is_active}
                                    onClick={() => { updateActiveNotify(orderStart.data.type, orderStart.data.is_active) }}
                                /></div>
                            </div>
                        </div>
                    ) : null
                }

                {
                    bikerAssign && bikerAssign.data ? (
                        <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}  >
                            <div className='col-lg-10'>
                                <p className='fs-5'>{bikerAssign.data.title_ar}</p>
                                <span style={{ color: "#A0AEC0" }}>
                                    {bikerAssign.data.message_ar}
                                </span>
                            </div>
                            <div className='col-lg-2 d-flex'>
                                <div style={{ marginLeft: "5px", padding: "10px" }}>
                                    <UpdateNotify type={bikerAssign.data.type} messageAr={bikerAssign.data.message_ar} messageEN={bikerAssign.data.message_en}
                                        titleAr={bikerAssign.data.title_ar}
                                        titleEn={bikerAssign.data.title_en}
                                    />
                                </div>
                                <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                                    type="switch"
                                    id={`custom-switch`}
                                    checked={bikerAssign.data.is_active}
                                    onClick={() => { updateActiveNotify(bikerAssign.data.type, bikerAssign.data.is_active) }}
                                /></div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    orderCancel && orderCancel.data ? (
                        <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}  >
                            <div className='col-lg-10'>
                                <p className='fs-5'>{orderCancel.data.title_ar}</p>
                                <span style={{ color: "#A0AEC0" }}>
                                    {orderCancel.data.message_ar}
                                </span>
                            </div>
                            <div className='col-lg-2 d-flex'>
                                <div style={{ marginLeft: "5px", padding: "10px" }}>
                                    <UpdateNotify type={orderCancel.data.type} messageAr={orderCancel.data.message_ar} messageEN={orderCancel.data.message_en}
                                        titleAr={orderCancel.data.title_ar}
                                        titleEn={orderCancel.data.title_en}
                                    />
                                </div>
                                <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                                    type="switch"
                                    id={`custom-switch`}
                                    checked={orderCancel.data.is_active}
                                    onClick={() => { updateActiveNotify(orderCancel.data.type, orderCancel.data.is_active) }}
                                /></div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    bikerArrived && bikerArrived.data ? (
                        <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}  >
                            <div className='col-lg-10'>
                                <p className='fs-5'>{bikerArrived.data.title_ar}</p>
                                <span style={{ color: "#A0AEC0" }}>
                                    {bikerArrived.data.message_ar}
                                </span>
                            </div>
                            <div className='col-lg-2 d-flex'>
                                <div style={{ marginLeft: "5px", padding: "10px" }}>
                                    <UpdateNotify type={bikerArrived.data.type} messageAr={bikerArrived.data.message_ar} messageEN={bikerArrived.data.message_en}
                                        titleAr={bikerArrived.data.title_ar}
                                        titleEn={bikerArrived.data.title_en}
                                    />
                                </div>
                                <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                                    type="switch"
                                    id={`custom-switch`}
                                    checked={bikerArrived.data.is_active}
                                    onClick={() => { updateActiveNotify(bikerArrived.data.type, bikerArrived.data.is_active) }}
                                /></div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    orderComplete && orderComplete.data ? (
                        <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}  >
                            <div className='col-lg-10'>
                                <p className='fs-5'>{orderComplete.data.title_ar}</p>
                                <span style={{ color: "#A0AEC0" }}>
                                    {orderComplete.data.message_ar}
                                </span>
                            </div>
                            <div className='col-lg-2 d-flex'>
                                <div style={{ marginLeft: "5px", padding: "10px" }}>
                                    <UpdateNotify type={orderComplete.data.type} messageAr={orderComplete.data.message_ar} messageEN={orderComplete.data.message_en}
                                        titleAr={orderComplete.data.title_ar}
                                        titleEn={orderComplete.data.title_en}
                                    />
                                </div>
                                <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                                    type="switch"
                                    id={`custom-switch`}
                                    checked={orderComplete.data.is_active}
                                    onClick={() => { updateActiveNotify(orderComplete.data.type, orderComplete.data.is_active) }}
                                /></div>
                            </div>
                        </div>
                    ) : null
                }
                {
                    bikeronTheWay && bikeronTheWay.data ? (
                        <div className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}  >
                            <div className='col-lg-10'>
                                <p className='fs-5'>{bikeronTheWay.data.title_ar}</p>
                                <span style={{ color: "#A0AEC0" }}>
                                    {bikeronTheWay.data.message_ar}
                                </span>
                            </div>
                            <div className='col-lg-2 d-flex'>
                                <div style={{ marginLeft: "5px", padding: "10px" }}>
                                    <UpdateNotify type={bikeronTheWay.data.type} messageAr={bikeronTheWay.data.message_ar} messageEN={bikeronTheWay.data.message_en}
                                        titleAr={bikeronTheWay.data.title_ar}
                                        titleEn={bikeronTheWay.data.title_en}
                                    />
                                </div>
                                <div style={{ marginLeft: "25px", padding: "10px" }}> <Form.Check
                                    type="switch"
                                    id={`custom-switch`}
                                    checked={bikeronTheWay.data.is_active}
                                    onClick={() => { updateActiveNotify(bikeronTheWay.data.type, bikeronTheWay.data.is_active) }}
                                /></div>
                            </div>
                        </div>
                    ) : null
                }
            </div>


            {/* Modal For Notify */}
            <Modal show={show} onHide={handleClose} dir="rtl" centered className='font'>
                <Modal.Header>
                    <Modal.Title>ارسال إشعار جديد</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" style={{ background: "#EFF1F9" }} onChange={(e) => setNotifyUser(e.target.value)}>
                            <option hidden>ارسال اشعار</option>
                            <option value="اشعارات المستخدمين">ارسال اشعار للكل</option>
                            <option value="اشعارات غير المستخدمين" >ارسال اشعار لشخص</option>
                        </Form.Select>
                    </div>
                    {
                        notifyUser === "اشعارات غير المستخدمين" ? (
                            <div className='mt-4'>
                                <PhoneInput
                                    country={'sa'}
                                    value={phone}
                                    onChange={handlePhone}
                                    enableSearch={false}
                                />
                            </div>
                        ) : (null)
                    }

                    <div className='mt-3'>
                        <input type="text" value={title} onChange={(e) => { setTitle(e.target.value) }} className='w-100' placeholder='عنوان الرساله' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <span style={{ color: "#8B8D97" }}>نص الرساله</span>
                        <textarea value={messagText} onChange={(e) => { setMessageText(e.target.value) }} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px", resize: "none", width: "100%" }}>

                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={sendMessageToPerson}>
                        ارسال
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }
        </div>
    )
}

export default NotificationBikers
