import React, { useEffect, useState } from 'react'
import { FaSearch } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllBrands, GetBrandsDetails } from '../../redux/actions/CarActions';
import Pagination from '../../Utils/Pagination';
import DeleteCars from '../Modals/DeleteCars';
import UpdateModalCar from '../Modals/UpdateModalCar';
import DeleteBrand from './DeleteBrand';
import UpdateBrand from './UpdateBrand';

const Brand = () => {
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('DESC');
    const [currentPage, setCurrentPage] = useState(1);

    const handleSearchInputChange = (e) => {
        const searchWord = e.target.value.trim();
        setSearchValue(searchWord);
    }
    const searchCar = async (e) => {
        dispatch(GetBrandsDetails({
            pageCount: 1,
            sort:sort,
            searchWord:searchValue,
        }));
    }


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetBrandsDetails({
            pageCount: currentPage,
            sort:sort,
            searchWord:""
        }));
    }, [dispatch , currentPage , sort]);


    const brands = useSelector(state => state.CarReducer.AllBrandsDetails);

    // if (brands) {
    //     console.log(brands);
    // }



    const getPage = async (page) => {
        setCurrentPage(page)
        await dispatch(GetBrandsDetails({
            pageCount: page,
            sort:sort,
            searchWord:searchValue
        }));
    };


    return (
        <div>
            <div className='font'>
                <div>
                    <div className='flexItem justify-content-start mt-4'>
                        <div className='mx-3 search-container'>
                            <input
                                type="search"
                                className='form-control search-input'
                                name="search"
                                id="search"
                                placeholder='البحث '
                                value={searchValue}
                                onChange={handleSearchInputChange}
                            />
                            <FaSearch className="search-icon" onClick={searchCar} />
                        </div>
                        <div className='mx-3'>
                            <Form.Select aria-label="Default select example" value={sort} onChange={(e) => setSort(e.target.value)}>
                                <option hidden>تاريخ الانشاء</option>
                                <option value="ASC">تصاعدي</option>
                                <option value="DESC">تنازلي</option>
                                {/* <option value="3">Three</option> */}
                            </Form.Select>
                        </div>
                    </div>
                </div>

                <div className='mt-3'>
                    <Table bordered>
                        <thead>
                            <tr className='text-center'>
                                <th>#</th>
                                <th>صورة البراند</th>
                                <th>اسم البراند بالانجليزي</th>
                                <th>اسم البراند بالعربي</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                brands && brands.data ? (
                                    brands.data.map((car, index) => {
                                        // console.log(car)
                                        return (
                                            <tr key={car.id} className='text-center'>
                                                <td className='' style={{ width: "10%" }}>{index + 1}</td>
                                                <td className=' text-right' style={{ width: "10%", height: "5px" }}>
                                                    <img className='w-100 ' src={car.logo} alt="car" style={{ objectFit: 'contain', height: "50px" }} />
                                                </td>
                                                <td>{car.name}</td>
                                                <td>{car.name_ar}</td>
                                                <td>
                                                    <UpdateBrand idCar={car.id} carNameEn={car.name} carNameAr={car.name_ar} logo={car.logo} 
                                                    page={currentPage} searchValue={searchValue} sort={sort}
                                                    />
                                                </td>
                                                <td>
                                                    <DeleteBrand carId={car.id} page={currentPage} searchValue={searchValue} sort={sort}/>
                                                </td>
                                            </tr>
                                        )
                                    })
                                ) : (null)
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            {
                brands && brands.meta ? (<Pagination onPress={getPage} countsPage={brands.meta.total / 10} />) : null
            }
        </div>
    )
}

export default Brand
