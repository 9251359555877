import React, { useEffect, useState } from 'react';
import Search from '../DashboardComponents/BookingAndSubscriptions/Search';
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Pagination from '../../Utils/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllUsersClients, DeleteUser, GetAllUsersSearch } from '../../redux/actions/Users';
import { Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import DeleteUserData from '../Modals/DeleteUser';
const AccountManager = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState("");

    const getAllUsersAdmin = async () => {
        await dispatch(GetAllUsersClients({ role: "ADMIN", pageCount: 1 }));
    };

    useEffect(() => {
        const fetchData = async () => {
            await getAllUsersAdmin();
        };
        fetchData();
    }, []);

    const usersAdmin = useSelector(state => state.GetUsers.Get_Admins);

    const [currentPage, setCurrentPage] = useState(1);
    const getPage = async (page) => {
        setCurrentPage(page)
        // dispatch(GetAllUsersClients(page))
        await dispatch(GetAllUsersClients({
            role: "ADMIN",
            pageCount: page
        }
        ))
    }

    const UsersAdmins = useSelector(state => state.GetUsers.Get_Admins);

    //Handle Delete


    //Searh
    const [searchValue, setSearchValue] = useState('');

    const handleSearch = async () => {
        try {
            setLoading(true)
            await dispatch(GetAllUsersSearch(
                {
                    role: "ADMIN",
                    pageCount: 1,
                    searchWord: searchValue
                }
            ))
            if(searchValue !== ""){
                setSearchData("data")
            }else{
                setSearchData("")
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true);
        }
    };

    if (UsersAdmins) {
        console.log(UsersAdmins)
    }



    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={handleSearch} />
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>اسم المستخدم</th>
                            {/* <th>الصلاحية</th> */}
                            <th>رقم الجوال</th>
                            <th>البريد الالكتروني</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersAdmin.data && usersAdmin.data.admins ? (
                            usersAdmin.data.admins.map((admin, index) => (

                                <tr key={admin.id}>

                                    <td>{index + 1}</td>
                                    {admin.user.first_name !== null && admin.user.first_name !== " " ? (
                                        <td>{admin.user.first_name} {admin.user.last_name}</td>
                                    ) : (
                                        <td>لا يوجد اسم</td>
                                    )}
                                    <td>{admin.user.phone}</td>
                                    {admin.user.email !== null && admin.user.email !== "" && admin.user.email !== " " ? (
                                        <td>{admin.user.email}</td>
                                    ) : (
                                        <td>لا يوجد ايميل</td>
                                    )}
                                    <td>
                                        <Link to={`/admin/usersetting/${admin.user_id}/${admin.user.roles[0]}`}>
                                            <IoPencil size="20" style={{ cursor: "pointer" }} />
                                        </Link>
                                    </td>
                                    <td style={{ cursor: "pointer" }}>
                                        <DeleteUserData user_id={admin.user_id} role={admin.user.roles[0]} page={currentPage} />
                                    </td>
                                    {/* <td>{admin.user.id}</td> */}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">لا توجد بيانات</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            {
                UsersAdmins.data ? searchData === "" ? ((<Pagination onPress={getPage} countsPage={UsersAdmins.data.adminsCount / 10} />)) : null
                    : null
            }

        </div>
    );
}

export default AccountManager;
