import React, { useEffect } from 'react'
import { Col, Row } from "react-bootstrap";
import AdminSideBar from '../../Components/DashboardComponents/AdminSideBar';
import AdminDashboard from '../../Components/DashboardComponents/AdminDashboard';
import NavBarDashboard from '../../Components/DashboardComponents/NavBarDashboard';
import useSidebar from '../../Components/DashboardComponents/Play';
import AdminApplication from '../../Components/DashboardComponents/Statics/AdminApplication';
import SchudleBooking from '../../Components/DashboardComponents/BookingSchedule/SchudleBooking';
import CalenderBickers from '../../Components/DashboardComponents/BookingSchedule/CalenderBickers';
import SubsList from '../../Components/DashboardComponents/SubsList';
const Dashboard = () => {
    const { sidebarOpen, toggleSidebar } = useSidebar();
    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }
    return (
        <Row className="py-3">
            <Col sm="5" xs="3" md="2" lg="2">
                <AdminSideBar />
            </Col>
            <Col sm="12" xs="9" className='px-5' md="12" lg="10">
                <NavBarDashboard />
                <AdminDashboard />
                <AdminApplication />
                <SubsList />
                    {/* {
                        adminLogIn && adminLogIn.premessions ? (
                            adminLogIn.premessions.map((pre) => {
                                return (
                                    pre === "slot" ? (
                                        <SubsList />
                                    ) : (null)
                                )
                            })
                        ) : null
                    } */}
                <CalenderBickers />
                <SchudleBooking />
            </Col>
        </Row>
    )
}

export default Dashboard


