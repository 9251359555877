import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { ADD_NEW_GIFT, GET_ALL_GIFTS, SETTING_GIFT } from '../Type'

const GetAllGifts = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            if (data.baqa === "" && data.phone === "") {
                let res = await baseUrl.get(`v1/gift/all-gifts/admin?page=${page}&limit=10&sortByDate=${data.sort}`, config)
                dispatch({ type: GET_ALL_GIFTS, payload: res.data })
            } else if (data.baqa !== "" && data.phone === "") {
                let res = await baseUrl.get(`v1/gift/all-gifts/admin?page=1&limit=10&sortByDate=${data.sort}&package_id=${data.baqa}`, config)
                dispatch({ type: GET_ALL_GIFTS, payload: res.data })
            }else if(data.phone !=="" && data.baqa !=="" ){
                let res = await baseUrl.get(`v1/gift/all-gifts/admin?page=1&limit=10&searchByPhone=${data.phone}&sortByDate=${data.sort}&package_id=${data.baqa}`, config)
                dispatch({ type: GET_ALL_GIFTS, payload: res.data })
            }else if(data.phone !=="" && data.baqa ==="" ){
                let res = await baseUrl.get(`v1/gift/all-gifts/admin?page=1&limit=10&searchByPhone=${data.phone}&sortByDate=${data.sort}`, config)
                dispatch({ type: GET_ALL_GIFTS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_GIFTS, payload: e.res })
            console.log(e)
        }
    }
}

// Post A New Gift
const AddNewGift = (data = {}) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/gift/{id}`, data, config)
            dispatch({ type: ADD_NEW_GIFT, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: ADD_NEW_GIFT, payload: e.res })
            console.log(e)
        }
    }
}

//Setting Gift
const SettingGift = (data = {}) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/notification_messages/single-message?message_type=someone-sent-a-gift`, config)
            dispatch({ type: SETTING_GIFT, payload: res.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: SETTING_GIFT, payload: e.res })
            // console.log(e)
        }
    }
}


//Update Settigs
const UpdateSettingGift = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/notification_messages/update?title_en=${data.titleEN}&title_ar=${data.titleAr}&message_en=${data.messageEn}&message_ar=${data.messageAr}&message_type=someone-sent-a-gift`, data, config)
            dispatch({ type: SETTING_GIFT, payload: res.data })
            console.log(res);
            dispatch(SettingGift());
        } catch (e) {
            dispatch({ type: SETTING_GIFT, payload: e.res })
            console.log(e)
        }
    }
}

//Search gift
// const SearchGift = (data) => {
//     return async (dispatch) => {
//         const config = {
//             headers: {
//                 Authorization: `Bearer ${localStorage.getItem("token")}`
//             },
//         }
//         try {
//             let res = await baseUrl.get(`v1/gift/all-gifts/admin?page=1&limit=10&searchByPhone=${data.phone}`, config)
//             dispatch({ type: GET_ALL_GIFTS, payload: res.data })
//             console.log(res);
//             dispatch(SettingGift());
//         } catch (e) {
//             dispatch({ type: GET_ALL_GIFTS, payload: e.res })
//             console.log(e)
//         }
//     }
// }




export { GetAllGifts, AddNewGift, SettingGift, UpdateSettingGift }