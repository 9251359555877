import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { IoPencil } from "react-icons/io5";
import { GetBrandsDetails, UpdateBrandData, UpdateCarModal } from '../../redux/actions/CarActions';




const UpdateBrand = ({ idCar, carNameEn, carNameAr, logo,
    page,
    searchValue,
    sort, }) => {
    const [show, setShow] = useState(false);

    const [nameAr, setNameAr] = useState(carNameAr);
    const [nameEn, setNameEn] = useState(carNameEn);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    const dispatch = useDispatch();



    const [images, setImages] = useState([]);
    const handleImageUpload = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);
    }
    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };



    const updateCarModalData = async () => {
        const formData = new FormData();
        try {
            setLoading(true);
            if (images.length > 0) {
                formData.append("name_ar", nameAr);
                formData.append("name_en", nameEn);
                formData.append("logo", images[0]);
                await dispatch(UpdateBrandData(formData, idCar))
            } else {
                formData.append("name_ar", nameAr);
                formData.append("name_en", nameEn);
                await dispatch(UpdateBrandData(formData, idCar))

            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            // onUpdate(idCar, nameAr, nameEn);
            await dispatch(GetBrandsDetails({
                pageCount: page,
                sort: sort,
                searchWord: searchValue
            }));
            setImages([]);
            handleClose();
        }
    }
    return (
        <div>
            <IoPencil size="20" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل البراند</Modal.Title>
                </Modal.Header>
                <Modal.Body>



                    <div className=''>
                        <div>
                            <img src={logo} className='w-25' alt="logo" />
                        </div>
                        <div>
                            <div className="w-100" style={{ padding: "5px", borderRadius: "10px", width: "100%" }}>
                                {images.map((image, index) => (
                                    <div>
                                        <img key={index} src={URL.createObjectURL(image)} className='w-25' alt={`Image ${index}`}
                                            style={{ borderRadius: "10px" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="file-input" style={{ padding: "20px" }}>
                            <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                            <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' onChange={(e) => setNameEn(e.target.value)} value={nameEn} placeholder='اسم البراند بالانجليزية' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />

                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameAr} onChange={(e) => setNameAr(e.target.value)} placeholder='اسم البراند بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateCarModalData} >
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }
        </div>
    )
}

export default UpdateBrand
