import React, { useState } from 'react'
import Chart from "react-apexcharts";

const SchudleBooking = () => {
    const [state, setState] = useState({
        options: {
            colors: ["#E1E3EA", "#F6C000"],
            chart: {
                id: "basic-bar",
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                },
            },
            yaxis: {
                tickAmount: 1,
                ticks: [1], 
                forceNiceScale:false,
            },
            xaxis: {
                categories: ["يناير", "فبراير", "مارس", "ابريل", "مايو", "يونيه", "يوليو", "اغسطس", "سبتمبر", "اكتوبر", "نوفمبر", "ديسمبر", ""],
            },
        },
        series: [
            {
                name: "المبيعات",
                data: [30, 40, 45, 50, 49, 30, 40, 50, 20, 40, 50, 45],
            },
            {
                name: "المشتريات",
                data: [3, 10, 35, 30, 49, 50, 20, 50, 3, 40, 35, 35],
            },
        ],
    });

    return (
        <div className='chartData p-3'>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <p className='font fs-4'>اجمالي عدد الطلبات</p>
                    <p className='font' style={{color:"#A1A5B7"}}>تم تسجيل اكثر من 700 طلب في الفترة الاخيرة</p>
                </div>
                <div className='d-flex'>
                    <button className='btn mx-3 submitButton font'>اسبوع</button>
                    <button className='btn mx-3 submitButton font'>سنه</button>
                    <button className='btn mx-3 submitButton font'>شهر</button>
                    <button className='btn mx-3 submitButton font'>يوم</button>
                </div>
            </div>
            <div>
                <Chart
                    options={state.options}
                    series={state.series}
                    type="bar"
                    width="100%"
                    backgroundColor="white"
                />
            </div>
        </div>
    )
}

export default SchudleBooking
