import React, { useEffect, useState } from 'react';
import Search from '../DashboardComponents/BookingAndSubscriptions/Search';
import Table from 'react-bootstrap/Table';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllGifts, SearchGift } from '../../redux/actions/GiftsActions';
import moment from 'moment';
import Pagination from '../../Utils/Pagination';
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import { GetAllBaqa } from '../../redux/actions/BaqaAction';
const ListsSendGifts = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchData, setSearchData] = useState("");
    const [searchValue, setSearchValue] = useState('');
    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);

    const [sort, setSort] = useState("DESC");
    const [selectpackage, setSelectPackage] = useState('');


    const [showMessageData, setShowMessageData] = useState(false);
    const handleMessageData = () => {
        setShowMessageData(!showMessageData)
    }

    const handleToggleMessage = (index) => {
        setSelectedMessageIndex(index === selectedMessageIndex ? null : index);
    };

    useEffect(() => {
        fetchAllGifts(currentPage, sort, selectpackage, searchValue);

    }, [currentPage, sort, selectpackage, dispatch, searchValue]);

    const fetchAllGifts = async (page, sort, baqa, searchValueData) => {
        await dispatch(GetAllGifts({ pageCount: page, sort: sort, baqa: baqa, phone: searchValueData }));
    };

    const gifts = useSelector(state => state.GiftReducer.AllGifts);

    const getPage = (page) => {
        setCurrentPage(page);
    };

    useEffect(() => {
        setSearchData(searchValue ? "data" : "");
    }, [searchValue]);




    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);


    // const searchPhone = async()=>{
    //     try{
    //         await dispatch(SearchGift({phone:searchValue}))
    //     }catch(e){
    //         console.log(e);
    //     }finally{

    //     }
    // }



    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setSelectPackage(e.target.value)}>
                        <option hidden> الباقات</option>
                        <option value="">الكل</option>
                        {
                            dataBaqat && dataBaqat.data ? (

                                dataBaqat.data.map((baqa, index) => {
                                    return (
                                        <option value={baqa.id} key={baqa.id}>{baqa.name} </option>
                                    )
                                })
                            ) : (null)
                        }
                    </Form.Select>
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setSort(e.target.value)}>
                        <option hidden> الترتيب تصاعدي</option>
                        <option value="DESC"> تنازلي</option>
                        <option value="ASC"> تصاعدي</option>
                    </Form.Select>
                </div>
            </div>


            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>رقم جوال المرسل</th>
                            <th>رقم جوال المرسل اليه</th>
                            <th>نوع الهديه</th>
                            <th>نص الرساله</th>
                            <th>تاريخ الارسال</th>
                            <th>الخدمات الاضافيه</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            gifts && gifts.data ? (
                                gifts.data.gifts.map((gift, index) => {
                                    const startDate = gift.subscription.order_invoice.created_at ? gift.subscription.order_invoice.created_at.split('T')[0] : 'لايوجد معاد';
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{gift.sender.phone}</td>
                                            <td>{gift.receiver.phone}</td>
                                            <td>{gift.subscription.name}</td>
                                            {/* <td>{gift.message === "" ? "الرساله فارغه" : gift.message}</td> */}
                                            <td className='showMessage' onClick={() => handleToggleMessage(index)}>
                                                <span>{selectedMessageIndex === index ? <span style={{ color: "red" }}>اغلاق الرساله</span> : 'عرض الرسالة'}</span>
                                                {selectedMessageIndex === index && (
                                                    <div className='infoShowMessage'>
                                                        <small>{gift.message}</small>
                                                    </div>
                                                )}
                                            </td>
                                            <td>{startDate}</td>
                                            <td>
                                                {gift.subscription.service && gift.subscription.service.length > 0 ? (
                                                    gift.subscription.service.map((serviceData, index) => (
                                                        <span key={serviceData.id}>{serviceData.name} </span>
                                                    ))
                                                ) : "لاتوجد خدمات اضافيه"}
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7}>لايوجد هدايا</td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                {
                    gifts && gifts.data ? (
                        selectpackage === "" && searchData === "" ? (<Pagination onPress={getPage} countsPage={gifts.data.giftsCount / 10} />) : null
                    ) : null
                }

            </div>
        </div>
    )
}

export default ListsSendGifts;
