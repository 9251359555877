import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import input from "../../Images/Group.png"
import { useDispatch, useSelector } from 'react-redux'
import { GetTermsConditions } from '../../redux/actions/PrivacyPolicy';
import ModalEditPolicy from '../Modals/ModalEditPolicy';
const Conditions = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true)
    }

    const dispatch = useDispatch();

    const fetchDataTerms = async () => {
        await dispatch(GetTermsConditions())
    }
    useEffect(() => {
        fetchDataTerms();
    }, [dispatch])

    const termsData = useSelector(state => state.PrivacyReducer.Terms);
    // if (termsData) {
    //     console.log(termsData)
    // }
    return (
        <div className='font ' >
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <p> الشروط والاحكام</p>
                <div>
                    
                </div>
            </div>

            <div className='bickerMab bg-light mt-3'>
                <div className='d-flex justify-content-between align-items-center p-3'>
                    <div>
                        <p>محتوي الصفحة</p>
                    </div>
                </div>
            </div>


            <div className='bickerMab p-3 mt-3' style={{ background: "white" }}>
                <div style={{ color: "rgba(171, 175, 177, 1)", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                    <p>الشروط والاحكام والتعليمات</p>
                </div>
                <div className='mt-2'>
                    <span>تفاصيل واجابة السؤال</span>
                </div>
                {
                    termsData && termsData.data ? (
                        termsData.data.map((data) => {
                            console.log(data);
                            return (
                                <div style={{marginTop:"10px", color: "rgba(171, 175, 177, 1)", padding: "10px", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                                    <ModalEditPolicy  titleEn={data.title_en} titleAr={data.title} descAr={data.description} descEn={data.description_en} idData={data.id} section={"terms"}/>
                                    <p className='fs-6'>{data.title}</p>
                                    <p>{data.description}</p>
                                </div>
                            )
                        })
                    ) : null
                }
            </div>
        </div>
    )
}

export default Conditions
