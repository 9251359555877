import { ADD_QA, DELETE_SUPPORT, GET_ABOUT, GET_ALL_PRIVACY, GET_ALL_QA, GET_ALL_SOCIAL, GET_ALL_SUPPORT, GET_ALL_TERMS, UPDATE_ABOUT, UPDATE_ALL_CONNECTS, UPDATE_PRIVACY, UPDATE_QA, UPDATE_SOCIAL_MEDIA, UPDATE_TERMS, UPLADO_DATA_ABOUT } from "../Type";
const initial = {
    privacy: [],
    Terms: [],
    QA: [],
    Support: [],
    UpdateConnectsData: [],
    Social: [],
    about: [],
    UpdateaboutData: [],
    uploadPhotoData: [],
    UpdateSocial: [],
    UpdateQA: [],
    UpdatePrivacyData: [],
    UpdateTerms: [],
    DeleteSupport: [],
    AddQa:[],
    loading: true,
}

const PrivacyReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_PRIVACY:
            return {
                ...state,
                privacy: action.payload,
                loading: false
            }
        case GET_ALL_TERMS:
            return {
                ...state,
                Terms: action.payload,
                loading: false
            }
        case GET_ALL_QA:
            return {
                ...state,
                QA: action.payload,
                loading: false
            }
        case GET_ALL_SUPPORT:
            return {
                ...state,
                Support: action.payload,
                loading: false
            }
        case UPDATE_ALL_CONNECTS:
            return {
                ...state,
                UpdateConnectsData: action.payload,
                loading: false
            }
        case GET_ALL_SOCIAL:
            return {
                ...state,
                Social: action.payload,
                loading: false
            }
        case GET_ABOUT:
            return {
                ...state,
                about: action.payload,
                loading: false
            }
        case UPDATE_ABOUT:
            return {
                ...state,
                UpdateaboutData: action.payload,
                loading: false
            }
        case UPLADO_DATA_ABOUT:
            return {
                ...state,
                uploadPhotoData: action.payload,
                loading: false
            }
        case UPDATE_SOCIAL_MEDIA:
            return {
                ...state,
                UpdateSocial: action.payload,
                loading: false
            }
        case UPDATE_QA:
            return {
                ...state,
                UpdateQA: action.payload,
                loading: false
            }
        case UPDATE_PRIVACY:
            return {
                ...state,
                UpdatePrivacyData: action.payload,
                loading: false
            }
        case UPDATE_TERMS:
            return {
                ...state,
                UpdateTerms: action.payload,
                loading: false
            }
        case DELETE_SUPPORT:
            return {
                ...state,
                DeleteSupport: action.payload,
                loading: false
            }
        case ADD_QA:
            return {
                ...state,
                AddQa: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default PrivacyReducer