import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapEdit from '../../Utils/MapEdit';
import { success } from '../../Utils/Toast';
import { GetBikersWorkingAtArea, GetSingleArea, UpdateAreaDataLoad } from '../../redux/actions/AreaActions';
import { useParams } from 'react-router-dom';
import MapEditArea from '../../Utils/MapEditArea';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import ModalDeleteBikerArea from '../Modals/ModalDeleteBikerArea';
import AddBikerToWorkingArea from '../Modals/AddBikerToWorkingArea';
import axios from 'axios';
const UpdateAreaData = () => {


    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [raduis, setRaduis] = useState("");

    const [radius, setRadius] = useState(1);

    const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 });


    const handleLocationSelect = (location) => {
        setSelectedLocation(location);
        // console.log(location)
    }

    const params = useParams();






    useEffect(() => {
        dispatch(GetSingleArea({
            id: params?.id
        }))
        dispatch(GetBikersWorkingAtArea({
            id: params?.id
        }))
    }, [])

    const data = useSelector(state => state.AreaReducer.singleArea)
    const bikersArea = useSelector(state => state.AreaReducer.bikersArea)

    // console.log(bikersArea)


    const [lat, setLat] = useState("")
    const [lang, setLang] = useState("")
    const [range, setRange] = useState("")

    useEffect(() => {
        if (data && data.data) {
            setLat(data.data.latitude);
            setLang(data.data.longitude);
            setName(data.data.name)
            setAddress(data.data.address)
            setRange(data.data.range)
            setRadius(data.data.range)
        }
    }, [data, data.data])







    // const UpdateAreaDatadata = async () => {
    //     try {
    //         setLoading(false)
    //         await dispatch(UpdateAreaDataLoad({
    //             latitude: selectedLocation.lat,
    //             longitude: selectedLocation.lng,
    //             range: parseInt(radius),
    //             active: true,
    //             id: params.id,
    //             name: name,
    //             address: address
    //         }))
    //     } catch (e) {
    //         console.log(e);
    //     } finally {

    //     }
    // }

    const UpdateAreaDatadata = async () => {
        // console.log(selectedLocation)
        try {
            if (selectedLocation.lat === 0 && selectedLocation.lng === 0) {
                await dispatch(UpdateAreaDataLoad({
                    latitude: lat,
                    longitude: lang,
                    range: parseInt(radius),
                    active: true,
                    id: params.id,
                    name: name,
                    address: address
                }));
            } else {
                await dispatch(UpdateAreaDataLoad({
                    latitude: selectedLocation.lat,
                    longitude: selectedLocation.lng,
                    range: parseInt(radius),
                    active: true,
                    id: params.id,
                    name: name,
                    address: address
                }));
            }

        } catch (e) {
            console.log(e);
        } finally {

        }

    }



    const resUpdates = useSelector(state => state.AreaReducer.updateDataArea)



    useEffect(() => {
        if (resUpdates) {
            if (resUpdates.statusCode === 200) {
                window.location.href = "/admin/areamanage"
            }
        }
    }, [resUpdates, resUpdates.data])



    const handleRadius = (radius) => {
        setRadius(radius);
    }






    return (
        <div className='container'>
            <div className='font'>
                <div className='flexItem font py-3'>
                    <div>
                        <p>ادارة اماكن ومناطق الخدمة</p>
                    </div>
                    <div>
                        <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={UpdateAreaDatadata}>تاكيد التعديل</button>
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='bikerEditTop'>
                        المعلومات الاساسيه
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='اسم مميز للمكان او النطاق' className='area' />
                        </div>
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder='العنوان' className='area' />
                        </div>
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            <input type="number" value={parseInt(radius)} onChange={(e) => setRaduis(e.target.value)} placeholder='محيط الدائره' className='area' />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='bikerEditTop mt-5 d-flex justify-content-between align-items-center'>
                        <div>الموظفين في  المنطقه</div>
                        <div>
                            <AddBikerToWorkingArea />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>الاسم</th>
                                    <th>الايميل</th>
                                    <th>الفون</th>
                                    <th>الحذف</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bikersArea?.data?.bikers?.map((biker, index) => {
                                        return (
                                            <tr key={biker?.user?.id}>
                                                <td>{index + 1}</td>
                                                <td>{biker?.user?.first_name} {biker?.user?.last_name}</td>
                                                <td>{biker?.user?.email}</td>
                                                <td>{biker?.user?.phone}</td>


                                                <td>
                                                    <ModalDeleteBikerArea id={biker?.biker_working_area_id} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='bikerEditTop'>
                        تحديد المكان علي الخريطة
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            {/* <MapEdit onLocationSelect={handleLocationSelect} /> */}
                            <MapEditArea onLocationSelect={handleLocationSelect} lat={lat} lang={lang} raduis={raduis} range={range}
                                onRadiusChange={handleRadius}
                                radiusData={parseInt(raduis)} />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UpdateAreaData
