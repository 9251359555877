import React, { useEffect, useState } from 'react'
import { BsFillSendFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoIosAddCircle } from "react-icons/io";
import PhoneInput from "react-phone-input-2";
import { useDispatch, useSelector } from 'react-redux'
import { GetAllNotification, GetNotificationBIKER_ARRIVED, GetNotificationBIKER_ASSIGN, GetNotificationBIKER_ON_THE_WAY, GetNotificationORDER_CANCELED, GetNotificationORDER_COMPLETED, GetNotificationORDER_START, GetNotificationUsers, SendNotificationToPerson, UpdateActivation } from '../../redux/actions/NotificationAction';
import UpdateNotify from '../Modals/UpdateNotify';
import Pagination from '../../Utils/Pagination';


const OrdersStatus = ['ORDER_START', 'ORDER_COMPLETED', 'ORDER_CANCELED', 'BIKER_ON_THE_WAY', 'BIKER_ARRIVED', "BIKER_ASSIGN"];

const NotificationComponent = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [title, setTitle] = useState('');
    const [messagText, setMessageText] = useState('');

    const [notifyUser, setNotifyUser] = useState('');
    const [phone, setPhone] = useState('');

    const handlePhone = (newPhoneNumber) => {
        setPhone(newPhoneNumber)
    }
    const [currentPage, setCurrentPage] = useState(1);





    const fetchNotify = async (page) => {
        await dispatch(GetNotificationUsers({
            pageCount:page
        }))
    }

    const notificationUsersData = useSelector(state => state.NotificationReducer.users);

    useEffect(() => {
        fetchNotify(currentPage)
    }, [dispatch , currentPage])


    //Send To Person
    const sendMessageToPerson = async () => {
        setLoading(true)
        // console.log(`+${phone}`)
        try {
            await dispatch(SendNotificationToPerson({
                title: title,
                description: messagText,
                phone: phone,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            setTitle('');
            setPhone('');
            setMessageText("")
            setLoading(false)
        }
        fetchNotify(currentPage);
    }



    const getPage = async (page) => {
        setCurrentPage(page)
    };



    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة اشعارات النظام</p>
                </div>
                <div>
                    <button className='btn' onClick={handleShow} style={{ color: "black", background: "#FECB44" }}>ارسال إشعار جديد</button>
                </div>
            </div>


            {/* Notification For Users */}
            <div>
                <div className='font notify'>
                    <p className='fs-6'>اشعارات المستخدمين</p>
                </div>
                {
                    notificationUsersData && notificationUsersData.data ? (
                        notificationUsersData.data.map((notify, index) => {
                            return (
                                <div key={notify.id} className='mt-3 font notify row justify-content-center' style={{ width: "99%", margin: "auto" }}>
                                    <div className='col-lg-10'>
                                        <p className='fs-5'>{notify.title}</p>
                                        <span style={{ color: "#A0AEC0" }}>
                                            {notify.text}
                                        </span>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }
            </div>



            {/* Modal For Notify */}
            <Modal show={show} onHide={handleClose} dir="rtl" centered className='font'>
                <Modal.Header>
                    <Modal.Title>ارسال إشعار جديد</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" style={{ background: "#EFF1F9" }} onChange={(e) => setNotifyUser(e.target.value)}>
                            <option hidden>ارسال اشعار</option>
                            <option value="اشعارات المستخدمين">ارسال اشعار للكل</option>
                            <option value="اشعارات غير المستخدمين" >ارسال اشعار لشخص</option>
                        </Form.Select>
                    </div>
                    {
                        notifyUser === "اشعارات غير المستخدمين" ? (
                            <div className='mt-4'>
                                <PhoneInput
                                    country={'sa'}
                                    value={phone}
                                    onChange={handlePhone}
                                    enableSearch={false}
                                />
                            </div>
                        ) : (null)
                    }

                    <div className='mt-3'>
                        <input type="text" value={title} onChange={(e) => { setTitle(e.target.value) }} className='w-100' placeholder='عنوان الرساله' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <span style={{ color: "#8B8D97" }}>نص الرساله</span>
                        <textarea value={messagText} onChange={(e) => { setMessageText(e.target.value) }} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px", resize: "none", width: "100%" }}>

                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={sendMessageToPerson}>
                        ارسال
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }

            {
                notificationUsersData && notificationUsersData.meta ? (<Pagination onPress={getPage} countsPage={notificationUsersData.meta.total / 10} />) : null
            }
        </div>
    )
}

export default NotificationComponent
