import React from 'react'
import { Col, Row } from "react-bootstrap";
import AdminSideBar from '../../Components/DashboardComponents/AdminSideBar';
import NavBarDashboard from '../../Components/DashboardComponents/NavBarDashboard';
import AccountsManagment from '../../Components/UserAccountsComponent/AccountsManagment';
const UsersAccounts = () => {
    return (
        <div>
            <Row className="py-3">
                <Col sm="5" xs="3" md="2" lg="2">
                    <AdminSideBar />
                </Col>
                <Col sm="12" xs="9" className='px-5' md="12" lg="10">
                    <NavBarDashboard />
                    <AccountsManagment/>
                </Col>
            </Row>
        </div>
    )
}

export default UsersAccounts
