import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoPencil } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { UpdateBanner, GetAllBanners } from '../../redux/actions/BannerAction';
import DateTime from 'react-datetime';

const UpdateBannerComponent = ({ bannerOrder, imageBanner, idData, orderBy, nameBanner, startDateInfo, endDateInfo, currentPage, sort, nameValue }) => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(nameBanner);
    const [order_by, setOrder_by] = useState(orderBy);
    const [is_popup, setIs_popup] = useState(bannerOrder);
    const [selectedDate, setSelectedDate] = useState(new Date(startDateInfo));
    const [selectedDateEnd, setSelectedDateEnd] = useState(new Date(endDateInfo));
    const [images, setImages] = useState([]);
    const [uploadError, setUploadError] = useState(null);

    useEffect(() => {
        if (show) {
            setName(nameBanner);
            setOrder_by(orderBy);
            setIs_popup(bannerOrder);
            setSelectedDate(new Date(startDateInfo));
            setSelectedDateEnd(new Date(endDateInfo));
            setImages([]);
            setUploadError(null);
        }
    }, [show, idData, nameBanner, orderBy, bannerOrder, startDateInfo, endDateInfo]);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleDateChangeEnd = (date) => {
        setSelectedDateEnd(date);
    };

    const handleImageUpload = async (event) => {
        const selectedImage = event.target.files[0];
        setImages([selectedImage]);
        setUploadError(null);
    };

    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };

    const editData = async () => {
        setLoading(true);
        const formatData = new FormData();
        try {
            formatData.append('name', name);
            formatData.append('end_time', selectedDateEnd.toISOString());
            formatData.append('start_time', selectedDate.toISOString());
            formatData.append('order_by', parseInt(order_by));
            formatData.append('is_popup', is_popup);
            if (images.length > 0) {
                formatData.append('file', images[0]);
            }
            await dispatch(UpdateBanner(formatData, idData));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
            dispatch(GetAllBanners({ page: currentPage, sort, name: nameValue }));
        }
    };

    const validDate = (current) => {
        return current.isSameOrAfter(new Date(), 'day');
    };

    return (
        <div>
            <IoPencil size="20" onClick={handleShow} cursor={"pointer"} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل الاعلان</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <div className='w-50'>
                            <img src={imageBanner} alt="data" style={{ width: "100%" }} />
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <div className="w-100" style={{ background: "#EFF1F9", padding: "20px", borderRadius: "10px", width: "40%" }}>
                                    {images.map((image, index) => (
                                        <div key={index}>
                                            <img src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                                style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="file-input" style={{ padding: "20px", textAlign: "left" }}>
                                <input type="file" id="file" accept="image/*" multiple={false} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                                <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <label>اسم الاعلان</label>
                        <input type="text" className='w-100' value={name} onChange={(e) => setName(e.target.value)} placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <Form.Select aria-label="Default select example" value={is_popup} onChange={(e) => setIs_popup(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}>
                            <option hidden>نوع الاعلان</option>
                            <option value="true">بانر اعلاني</option>
                            <option value="false">سوابر اعلاني</option>
                        </Form.Select>
                    </div>
                    <div className='mt-3'>
                        <label>ترتيب الاعلان</label>
                        <input type="number" className='w-100' value={order_by} onChange={(e) => setOrder_by(e.target.value)} placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>تاريخ البدايه </label>
                        <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} value={selectedDate} onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm"
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                        />
                    </div>
                    <div className='mt-3'>
                        <label>تاريخ الانتهاء</label>
                        <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} value={selectedDateEnd} onChange={handleDateChangeEnd} dateFormat="YYYY-MM-DD" timeFormat="HH:mm"
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={editData}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

            {loading ? (
                <div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>
            ) : null}
        </div>
    );
};

export default UpdateBannerComponent;



// correct Code without show dates immediately

// import React, { useEffect, useState } from 'react';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import { IoPencil } from "react-icons/io5";
// import { useDispatch, useSelector } from 'react-redux';
// import { CreatePhotoPackage, GetAllBaqa, UpdateBaqaData, UpdateBaqaFormat } from '../../redux/actions/BaqaAction';
// import { GetAllBanners, getSingleBanner, UpdateBanner } from '../../redux/actions/BannerAction';
// import moment from 'moment';
// import DateTime from 'react-datetime';

// const UpdateBannerComponenet = ({ bannerOrder, imageBanner, idData, orderBy, nameBanner, startDateInfo, endDateInfo, currentPage, sort, nameValue }) => {
//     const dispatch = useDispatch();

//     const [show, setShow] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [name, setName] = useState(nameBanner);
//     const [order_by, setOrder_by] = useState(orderBy);
//     const [is_popup, setIs_popup] = useState(bannerOrder);
//     const [selectedDate, setSelectedDate] = useState(new Date(startDateInfo));
//     const [selectedDateEnd, setSelectedDateEnd] = useState(new Date(endDateInfo));

//     const handleClose = () => {
//         setShow(false);
//         setImages([]);
//     };

//     const handleShow = () => {
//         setShow(true);
//     };

//     const handleDateChange = (date) => {
//         setSelectedDate(date);
//     };

//     const handleDateChangeEnd = (date) => {
//         setSelectedDateEnd(date);
//     };

//     const [images, setImages] = useState([]);
//     const [uploadError, setUploadError] = useState(null);

//     const handleImageUpload = async (event) => {
//         const selectedImage = event.target.files[0];
//         setImages([selectedImage]);
//         setUploadError(null);
//         const formData = new FormData();
//         formData.append('file', selectedImage);

//         try {
//             // await dispatch(CreatePhotoPackage(formData));
//         } catch (error) {
//             setUploadError(error.message || 'An error occurred during image upload.');
//         }
//     };

//     const handleLabelClick = (event) => {
//         event.preventDefault();
//         const fileInput = document.getElementById('file');
//         fileInput.click();
//     };

//     const editdata = async () => {
//         setLoading(true);
//         const formatData = new FormData();
//         try {
//             formatData.append('name', name);
//             formatData.append('end_time', selectedDateEnd.toISOString());
//             formatData.append('start_time', selectedDate.toISOString());
//             formatData.append('order_by', parseInt(order_by));
//             formatData.append('is_popup', is_popup);
//             formatData.append('file', images[0]);
//             await dispatch(UpdateBanner(formatData, idData));
//         } catch (e) {
//             console.log(e);
//         } finally {
//             setLoading(false);
//             handleClose();
//             dispatch(GetAllBanners({ page: currentPage, sort, name: nameValue }));
//             dispatch(GetAllBanners({ page: currentPage, sort, name: nameValue }));
//             dispatch(GetAllBanners({ page: currentPage, sort, name: nameValue }));
//             dispatch(GetAllBanners({ page: currentPage, sort, name: nameValue }));
//             dispatch(GetAllBanners({ page: currentPage, sort, name: nameValue }));
//         }
//     };

//     const validDate = (current) => {
//         return current.isSameOrAfter(new Date(), 'day');
//     };

//     return (
//         <div>
//             <IoPencil size="20" onClick={handleShow} cursor={"pointer"} />
//             <Modal show={show} onHide={handleClose} centered className='font'>
//                 <Modal.Header>
//                     <Modal.Title>تعديل الاعلان</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className=''>
//                         <div className='w-50'>
//                             <img src={imageBanner} alt="data" style={{ width: "100%" }} />
//                         </div>
//                         <div className='d-flex justify-content-between align-items-center'>
//                             <div>
//                                 <div className="w-100" style={{ background: "#EFF1F9", padding: "20px", borderRadius: "10px", width: "40%" }}>
//                                     {images.map((image, index) => (
//                                         <div key={index}>
//                                             <img src={URL.createObjectURL(image)} alt={`Image ${index}`}
//                                                 style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                             <div className="file-input" style={{ padding: "20px", textAlign: "left" }}>
//                                 <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
//                                 <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='mt-3'>
//                         <label>اسم الاعلان</label>
//                         <input type="text" className='w-100' value={name} onChange={(e) => setName(e.target.value)} placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <Form.Select aria-label="Default select example" value={is_popup} onChange={(e) => setIs_popup(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}>
//                             <option hidden>نوع الاعلان</option>
//                             <option value="true">بانر اعلاني</option>
//                             <option value="false">سوابر اعلاني</option>
//                         </Form.Select>
//                     </div>
//                     <div className='mt-3'>
//                         <label>ترتيب الاعلان</label>
//                         <input type="number" className='w-100' value={order_by} onChange={(e) => setOrder_by(e.target.value)} placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>تاريخ البدايه </label>
//                         <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} value={selectedDate} onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm"
//                             inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
//                             closeOnSelect={true}
//                         />
//                     </div>
//                     <div className='mt-3'>
//                         <label>تاريخ الانتهاء</label>
//                         <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} value={selectedDateEnd} onChange={handleDateChangeEnd} dateFormat="YYYY-MM-DD" timeFormat="HH:mm"
//                             inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
//                             closeOnSelect={true}
//                         />
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={editdata}>
//                         اضافة
//                     </button>
//                     <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
//                         تراجع
//                     </button>
//                 </Modal.Footer>
//             </Modal>

//             {loading ? (
//                 <div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                     <span className="loader"></span>
//                 </div>
//             ) : null}
//         </div>
//     );
// };

// export default UpdateBannerComponenet;
