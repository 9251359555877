import React from 'react'
import { Col, Row } from "react-bootstrap";
import AdminSideBar from '../../Components/DashboardComponents/AdminSideBar';
import NavBarDashboard from '../../Components/DashboardComponents/NavBarDashboard';
import Discount from '../../Components/DiscountComponents/Discount';
const DiscountPage = () => {
    return (
        <div>
            <Row className="py-3">
                <Col sm="5" xs="3" md="2" lg="2">
                    <AdminSideBar />
                </Col>
                <Col sm="12" xs="9" className='px-5 font' md="12" lg="10">
                    <NavBarDashboard />
                    <Discount />
                </Col>
            </Row>
        </div>
    )
}

export default DiscountPage
