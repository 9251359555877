import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BikerProfileUpdate, GetAllBikers } from '../../redux/actions/Bikers';
import Modal from 'react-bootstrap/Modal';
import { MdDelete } from "react-icons/md";
const DeleteOffDays = ({ selectID }) => {
    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    const handleUpdateProfile = async () => {
        try {
            setLoading(true);

            // Ensure selectedDays is an array


            await dispatch(BikerProfileUpdate({
                id: selectID,
                off_days: ``,
            }));
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
            handleClose();
            dispatch(GetAllBikers())
        }
    };
    return (
        <div>
            <MdDelete style={{ color: "#EB5757" }} size="20" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>الغاء عطلات دورية للبايكر</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>

                    </div>

                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleUpdateProfile}>
                        الغاء
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DeleteOffDays
