import React, { useEffect, useState } from 'react'
import ColorPickerComponent from '../../Utils/CompacColor'
import Demo from '../../Utils/CompacColor'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteColor, GetAllColors } from '../../redux/actions/Colors'
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import UpdateColors from '../Modals/UpdateColors'

const ManageColors = () => {
    const dispatch = useDispatch();
    const [filterColors, setFilterColors] = useState([]);

    const getAllColors = async () => {
        await dispatch(GetAllColors());
    }

    useEffect(() => {
        getAllColors();
    }, [])

    const colors = useSelector(state => state.ColorReducer.AllColors);

    useEffect(() => {
        setFilterColors(colors.data);
    }, [colors, colors.data])



    const DeleteColorData = async (id) => {
        try {
            await dispatch(DeleteColor({
                id,
            }))
            setFilterColors(prevColors => prevColors.filter(color => color.id !== id));

        } catch (e) {
            console.log(e);
        } finally {
            getAllColors();
        }
    }

    return (
        <div className='font'>
            <div className='addCarsColors'>
                <p>إضافة الوان السيارات</p>
                <span id="colorCar">حدد اللون علي الحقل التالي واضغط علي علامة الاضافة، يمكنك اختيار اكثر من لون للسيارات</span>
                <div>
                    <Demo />
                </div>
            </div>
            <div className='addCarsColors'>
                <h5>قائمة الالوان المختاره</h5>
                <div className='row' style={{ background: "#E5E7EB", padding: "10px", borderRadius: "10px" }}>
                    {
                        filterColors ? (
                            filterColors.map((color, index) => {
                                console.log(color);
                                return (
                                    <div className='col-lg-1' key={index}>
                                        <div style={{ width: "100%", borderRadius: "5px", height: "80px", background: `${color.hex}` }}></div>
                                        <div className='d-flex justify-content-between align-items-center py-2'>
                                            <MdDelete onClick={() => DeleteColorData(color.id)} color='#EB5757' cursor={"pointer"} />
                                            <UpdateColors idData={color.id} color={color.hex} nameArData={color.name_ar} nameEnData={color.name_en} name={color.name} />
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <div className='choiceColors'>
                                <p>لم تتم اضافة لون حتي الان</p>
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default ManageColors
