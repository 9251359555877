import React, { useState } from 'react';
import Colorful from '@uiw/react-color-colorful';
import { hsvaToHex } from '@uiw/color-convert';
import { FaPlus } from "react-icons/fa6";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { InfoMsg } from './Toast';
import { useDispatch, useSelector } from 'react-redux'
import { AddNewColor, GetAllColors } from '../redux/actions/Colors';

export default function Demo() {
    const [hsva, setHsva] = useState({ h: 0, s: 0, v: 68, a: 1 });
    const [disableAlpha, setDisableAlpha] = useState(true);
    const [hexaColor, setHexaColor] = useState('');
    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");


    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);
    //Const Modal 
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (hexaColor !== "") {
            setShow(true);
        } else {
            InfoMsg("يجب اختيار اللون")
        }
    }



    const dispatch = useDispatch();


    const AddColor = async () => {
        try {
            await dispatch(AddNewColor({
                name_ar: nameAr,
                name_en: nameEn,
                hex: hexaColor,
                order_by: 10
            }))
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            dispatch(GetAllColors())
            setHexaColor("")
            setNameAr("")
            setNameEn("")
        }
    }
    const [selectedColor, setSelectedColor] = useState({ h: 0, s: 0, v: 68, a: 1 });
    return (
        <>
            {/* <Colorful
                color={hsva}
                disableAlpha={disableAlpha}
                onChange={(color) => {
                    setHsva(color.hsva);
                    const hexa = hsvaToHex(color.hsva);
                    setHexaColor(hexa);
                }}
                style={{ width: "70vw", padding: "20px", marginTop: "15px", border: "3px dashed rgba(130, 130, 130, 1)", borderRadius: "20px" }}
            /> */}



            <Colorful
                color={selectedColor}
                disableAlpha={disableAlpha}
                onChange={(color) => {
                    setHsva(color.hsva);
                    setSelectedColor(color.hsva); // تحديث قيمة اللون المحدد عندما يتغير اللون
                    const hexa = hsvaToHex(color.hsva);
                    setHexaColor(hexa);
                }}
                style={{ width: "70vw", padding: "20px", marginTop: "15px", border: "3px dashed rgba(130, 130, 130, 1)", borderRadius: "20px" }}
            />

            <div className='d-flex justify-content-between align-items-center mt-3' style={{ width: "90%" }}>
                <div>
                    <button className='btn mt-3 shadow py-2' style={{ background: "rgba(255, 250, 236, 1)" }} onClick={handleShow}>اضف اللون <FaPlus /></button>
                </div>
                <div className=''>
                    {/* <input style={{position:"absolute" , left:"5px", padding: "8px", border: "1px solid rgba(224, 224, 224, 1)" }} value={hexaColor}/> */}
                    <input
                        style={{ position: "absolute", left: "5px", padding: "8px", border: "1px solid rgba(224, 224, 224, 1)" }}
                        value={hexaColor}
                        onChange={(e) => setHexaColor(e.target.value)} // للسماح بتحديث قيمة الـ hexaColor يدويًا
                        onClick={() => setHexaColor('')} // لمسح قيمة الـ hexaColor عند النقر على الحقل
                    />
                </div>
            </div >




            {/* Modal */}
            <Modal show={show} centered className='font' onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>اضافة الالوان</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ width: "80px", height: "80px", borderRadius: "50%", background: `${hexaColor}` }}>
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100'
                            onChange={(e) => {
                                setNameEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidTitleEn(true)
                                } else {
                                    setValidTitleEn(false);
                                }
                            }}
                            value={nameEn} placeholder='اسم اللون بالانجليزية' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {
                        validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                    }
                    <div className='mt-3'>
                        <input type="text" className='w-100'
                            onChange={(e) => {
                                setNameAr(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidTitleAr(true)
                                } else {
                                    setValidTitleAr(false);
                                }
                            }}
                            value={nameAr} placeholder='اسم اللون بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    {
                        validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={AddColor} className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} >
                        اضافة
                    </Button>
                    <Button variant="primary" onClick={handleClose} className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}






