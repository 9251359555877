import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from '../../Utils/Toast';
import { ADD_PREMISSION, CHANGE_ROLE, DELETE_USERS, GET_ALL_USERS_ADMINS, GET_ALL_USERS_CLIENTS, UPDATE_USER } from "../Type"

// v1/users?page=1&limit=10&role=CLIENT&searchByName=3aref&sortByDate=DESC

const GetAllUsersClients = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            const page = data.pageCount || 1
            if (data.role === "CLIENT") {
                let res = await baseUrl.get(`v1/users?page=${page}&limit=10&role=${data.role}&sortByDate=DESC`, config)
                dispatch({ type: GET_ALL_USERS_CLIENTS, payload: res.data })
            } else if (data.role === "ADMIN") {
                let res = await baseUrl.get(`v1/users?page=${page}&limit=10&role=${data.role}&sortByDate=DESC`, config)
                dispatch({ type: GET_ALL_USERS_ADMINS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_USERS_CLIENTS, payload: e.res })
        }
    }
}
const GetAllUsersSearch = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            if (data.role === "CLIENT") {
                let res = await baseUrl.get(`v1/users?page=1&limit=10&role=CLIENT&searchByName=${data.searchWord}&sortByDate=DESC`, config)
                dispatch({ type: GET_ALL_USERS_CLIENTS, payload: res.data })
            } else if (data.role === "ADMIN") {
                let res = await baseUrl.get(`v1/users?page=1&limit=10&role=ADMIN&searchByName=${data.searchWord}&sortByDate=DESC`, config)
                dispatch({ type: GET_ALL_USERS_ADMINS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_USERS_CLIENTS, payload: e.res })
        }
    }
}

const DeleteUser = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.roleUser === "CLIENT") {
                let res = await baseUrl.delete(`v1/users/${data.id}?role=${data.roleUser}`, config)
                console.log(res)
                dispatch({ type: DELETE_USERS, payload: res.data });
            } else if (data.roleUser === "ADMIN") {
                let res = await baseUrl.delete(`v1/users/${data.id}?role=${data.roleUser}`, config)
                console.log(res);
                dispatch({ type: DELETE_USERS, payload: res.data });
            }
        } catch (e) {
            dispatch({ type: DELETE_USERS, payload: e.res });
            if (e.response.status === 400) {
                ErrorMsg(e.response.data.message)
                const page = data.pageCount || 1
                let res = await baseUrl.get(`v1/users?page=${page}&limit=20&role=${data.role}&sortByDate=DESC`, config)
                dispatch({ type: GET_ALL_USERS_CLIENTS, payload: res.data })
            }
        }
    }
}



//Update User Profile 
const UpdateUser = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.put(`v1/users/profile/${data.id}`, data, config)
            console.log(res)
            dispatch({ type: UPDATE_USER, payload: res.data });

        } catch (e) {
            dispatch({ type: UPDATE_USER, payload: e.res });
            console.log(e);
        }
    }
}



//Change Role 
const UpdateRole = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.put(`v1/users/admin/add-role`, data, config)
            console.log(res)
            dispatch({ type: CHANGE_ROLE, payload: res.data });

            if(res.status===200){
                success(res.data.data)
            }

        } catch (e) {
            dispatch({ type: CHANGE_ROLE, payload: e.res });
            console.log(e);
            if(e.response.status===400){
                ErrorMsg(e.response.data.message)
            }
        }
    }
}


export { GetAllUsersClients, DeleteUser, GetAllUsersSearch, UpdateUser, UpdateRole }