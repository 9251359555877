import React, { useEffect } from 'react'
import { FaPencilAlt } from "react-icons/fa";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { GetVariable, UpdateVariable } from '../../redux/actions/Slots';
const SlotSettings = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [days, setDays] = useState("");
    const [daysdata, setDaysData] = useState({});

    const handleDays = () => {

    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetVariable())
    }, [dispatch])

    const variablesRes = useSelector(state => state.GetSlots.GetVar);

    const [idData, setIdData] = useState('');

    const handleVarUpdate = () => {
        if (variablesRes) {
            try {
                dispatch(UpdateVariable({
                    id:variablesRes.id,
                    booking_limit_in_days: days
                }))
            } catch (err) {
                console.log(err);
            } finally {
                handleClose();
                dispatch(GetVariable());
                setDays("");
            }
        }
    }


    return (
        <div>
            <div className='font notify'>
                <div className='penTag'>
                    <FaPencilAlt onClick={handleShow} style={{ cursor: "pointer" }} />
                </div>
                <div>
                    <p className='fs-5'>عدد الايام المتاحة للمستخدم للحجز</p>
                    <span style={{ color: "#A0AEC0" }}>وهي عدد الايام التي يستطيع المستخدم الاختيار فيما بينهم لحجز موعد غسلة جديدة - وايضاً هي عدد الايام التي تظهر للبايكر في قائمة المهام الخاثة به.</span>
                </div>
                <hr />
                <div >
                    {
                        variablesRes ? (<h4>{variablesRes.variable} ايام</h4>) : (null)
                    }
                </div>
            </div>



            <Modal show={show} centered className="font" onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>تغيير عدد الايام</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>معلومات المستخدم</span>
                    <div className='mt-3'>
                        <input type="text" value={days} onChange={(e) => setDays(e.target.value)} className='w-100' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>
                    <button onClick={handleVarUpdate} className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تعديل
                    </button>
                    <button onClick={handleClose} className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default SlotSettings
