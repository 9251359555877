import { ASSIGN_BIKER, CANCEL_ORDER, DELETE_SUBS, GET_ALL_ORDERS_ADMIN, GET_ALL_ORDERS_STATUS, GET_ALL_SUBS, GET_AVAILABEL_BIKERS_TO_ASSIGN, UPDATE_DATE_SUBS, UPDATE_SCHUDLE } from "../Type";

const initial = {
    OrdersAdmin: [],
    SubsAdmin: [],
    assignBiker: [],
    availBikers: [],
    CancelOrders: [],
    DelSubs: [],
    UpdateSubs: [],
    OrdersStatus:[],
    updateData: [],
    loading: true,
}

const OrdersAdminReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_ORDERS_ADMIN:
            return {
                ...state,
                OrdersAdmin: action.payload,
                loading: false
            }

        case ASSIGN_BIKER:
            return {
                ...state,
                assignBiker: action.payload,
                loading: false
            }
        case GET_AVAILABEL_BIKERS_TO_ASSIGN:
            return {
                ...state,
                availBikers: action.payload,
                loading: false
            }
        case CANCEL_ORDER:
            return {
                ...state,
                CancelOrders: action.payload,
                loading: false
            }
        case GET_ALL_SUBS:
            return {
                ...state,
                SubsAdmin: action.payload,
                loading: false
            }
        case DELETE_SUBS:
            return {
                ...state,
                DelSubs: action.payload,
                loading: false
            }
        case UPDATE_DATE_SUBS:
            return {
                ...state,
                UpdateSubs: action.payload,
                loading: false
            }
        case UPDATE_SCHUDLE:
            return {
                ...state,
                updateData: action.payload,
                loading: false
            }
        case GET_ALL_ORDERS_STATUS:
            return {
                ...state,
                OrdersStatus: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default OrdersAdminReducer