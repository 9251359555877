import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux'
import { GetNotifications, GetNotificationsBaqa, GetNotificationsBaqaEnd, UpdateNotifications } from '../../redux/actions/Notifications';


function SettingGracePeriod({ type, title, notifyMessageAr,
    notifyTitleAr,
    notifyMessageEn,
    notifyTitleEn,
}) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const [titleEn, setTitleEn] = useState(notifyTitleEn);
    const [titleAr, setTitleAr] = useState(notifyTitleAr);
    const [messageEn, setMessageEn] = useState(notifyMessageEn);
    const [message_Ar, setmessage_Ar] = useState(notifyMessageAr);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const dispatch = useDispatch();

    const handleUpdate = async () => {
        setLoading(true)
        if (validTitleEn === false &&
            validTitleAr === false &&
            validMessageEn === false &&
            validMessageAr === false
        ) {
            await dispatch(UpdateNotifications(
                {
                    title_en: titleEn,
                    title_ar: titleAr,
                    message_en: messageEn,
                    message_ar: message_Ar,
                    type,
                }
            ))
        }

        setLoading(false);
        handleClose();
        dispatch(GetNotifications());
        dispatch(GetNotificationsBaqaEnd())
        dispatch(GetNotificationsBaqa());
        setTitleEn('');
        setTitleAr('');
        setMessageEn('');
        setmessage_Ar('');
    }


    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);
    const [validMessageEn, setValidMessageEn] = useState(false);
    const [validMessageAr, setValidMessageAr] = useState(false);

    return (
        <>
            <FaPencilAlt onClick={handleShow} />

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='mt-4'>
                        <input
                            type="text"
                            className='w-100'
                            onChange={(e) => {
                                setTitleEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidTitleEn(true)
                                } else {
                                    setValidTitleEn(false);
                                }
                            }}
                            value={titleEn}
                            placeholder='عنوان الإشعار باللغة الإنجليزية'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                        {
                            validTitleEn === true ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                        }
                    </div>
                    <div className='mt-4'>
                        <input
                            type="text"
                            className='w-100'
                            onChange={(e) => {
                                setTitleAr(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidTitleAr(true)
                                } else {
                                    setValidTitleAr(false);
                                }
                            }}
                            value={titleAr}
                            placeholder='عنوان الإشعار باللغة العربية'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                        {
                            validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                        }
                    </div>
                    <div className='mt-4'>
                        <input
                            type="text"
                            onChange={(e) => {
                                setMessageEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidMessageEn(true)
                                } else {
                                    setValidMessageEn(false);
                                }
                            }}
                            value={messageEn}
                            className='w-100'
                            placeholder='نص الإشعار باللغة الإنجليزية'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                        {
                            validMessageEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الإنجليزية</small>) : null
                        }
                    </div>
                    <div className='mt-4'>
                        <input
                            type="text"
                            className='w-100'
                            onChange={(e) => {
                                setmessage_Ar(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidMessageAr(true)
                                } else {
                                    setValidMessageAr(false);
                                }
                            }}
                            value={message_Ar}
                            placeholder='نص الإشعار باللغة العربية'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                        {
                            validMessageAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                        }
                    </div>

                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleUpdate}>
                        تاكيد الضبط
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SettingGracePeriod;