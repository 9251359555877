import baseUrl from "../../Api/baseURL";
import { ADD_NEW_SLOT, DELETE_SLOT, GET_ALL_SLOTS, GET_VARIABLE, UPDATE_SLOT, UPDATE_VARIABLE } from "../Type";
import {ErrorMsg} from '../../Utils/Toast'


const GetAllSlots = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            // استخدام قيم البحث والترتيب الممررة من واجهة المستخدم
            if (!data) {
                let res = await baseUrl.get(`v1/slots/all-available-slots/admin?searchBySlotName=&sortByDate=DESC`, config);
                dispatch({ type: GET_ALL_SLOTS, payload: res.data.data });
            } else {
                let res = await baseUrl.get(`v1/slots/all-available-slots/admin?searchBySlotName=${data.searchBySlotName}&sortByDate=${data.sortByDate}`, config);
                dispatch({ type: GET_ALL_SLOTS, payload: res.data.data });
            }


        } catch (e) {
            dispatch({ type: GET_ALL_SLOTS, payload: e.data });
        }
    }
}


const AddNewSlot = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/slots`, data, config);
            dispatch({ type: ADD_NEW_SLOT, payload: res.data.data });
            console.log(res);
        } catch (e) {
            dispatch({ type: GET_ALL_SLOTS, payload: e.data });
            console.log(e)
            if(e.response.status === 404){
                ErrorMsg(e.response.data.message)
            }
        }
    }
}
const DeleteSlot = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/slots/${data.id}`, config);
            dispatch({ type: DELETE_SLOT, payload: res.data.data });
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_SLOT, payload: e.data });
            console.log(e)
            if(e.response.status===404){
                ErrorMsg(e.response.data.message);
                dispatch(GetAllSlots())
            }
        }
    }
}


const UpdateSlots = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/slots/update-slot`, data, config);
            dispatch({ type: UPDATE_SLOT, payload: res.data.data });
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_SLOT, payload: e.data });
        }
    }
}

const GetAvailableSlots = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if(data.date !==""){
                let res = await baseUrl.get(`v1/slots/all-available-slots/${data.date}/&working_area_id=${data?.working_area_id}`, config);
                dispatch({ type: GET_ALL_SLOTS, payload: res.data.data });
                console.log(res.data.data);
            }else{
                let res = await baseUrl.get(`v1/slots/all-available-slots/admin?searchBySlotName=&sortByDate=DESC`, config);
                dispatch({ type: GET_ALL_SLOTS, payload: res.data.data });
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SLOTS, payload: e.data });
        }
    }
}




//Get Setting Slots
const GetVariable = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/variable`, config);
            dispatch({ type: GET_VARIABLE, payload: res.data.data });
            console.log(res.data.data);
        } catch (e) {
            dispatch({ type: GET_VARIABLE, payload: e.data });
        }
    }
}
const UpdateVariable = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/variable/update/${data.id}`, data, config);
            dispatch({ type: UPDATE_VARIABLE, payload: res.data.data });
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_VARIABLE, payload: e.data });
        }
    }
}

export { GetAllSlots, AddNewSlot, DeleteSlot, UpdateSlots, GetAvailableSlots, GetVariable, UpdateVariable }
