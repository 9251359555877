import { useState } from 'react';

const useSidebar = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen((prevSidebarOpen) => !prevSidebarOpen);
    };

    return {
        sidebarOpen,
        toggleSidebar,
    };
};

export default useSidebar;

