
// import React, { useEffect, useState, useRef } from 'react';
// import { GoogleMap, LoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';
// import { useDispatch, useSelector } from 'react-redux';
// import { GetAllAreas } from '../redux/actions/AreaActions';

// const MapEditArea = ({ onLocationSelect, lat, lang, range, onRadiusChange, radiusData }) => {
//     const dispatch = useDispatch();
//     const mapRef = useRef(null);
//     const [currentMarker, setCurrentMarker] = useState(null);
//     const [currentCircle, setCurrentCircle] = useState(null);
//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const [radius, setRadius] = useState(radiusData)
//     const [circleRadius, setCircleRadius] = useState(1000); // تحديد قيمة القطر الافتراضي


//     const getAreas = async () => {
//         await dispatch(GetAllAreas());
//     }

//     useEffect(() => {
//         getAreas();
//     }, [])

//     const resAreas = useSelector(state => state.AreaReducer.AllAreas);

//     let map;

//     const [curvedPath, setCurvedPath] = useState([]);

//     const [circleOptions, setCircleOptions] = useState({
//         strokeColor: "#FF0000",
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: "#FFFFFF",
//         fillOpacity: 0.35,
//         radius: 1000,
//     });

//     const [circleInstance, setCircleInstance] = useState(null);

//     const onMapClick = (e) => {
//         const location = { lat: e.latLng.lat(), lng: e.latLng.lng() }
//         setSelectedLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
//         onLocationSelect(location);
//         setCircleOptions(prevOptions => ({
//             ...prevOptions,
//             center: location // تحديد مركز الدائرة الجديد على اللوكيشن المحدد
//         }));

//         // قم بتعيين قيمة القطر إلى قيمة قطر المدخلة عبر الـ props
//     };




//     useEffect(() => {
//         if (selectedLocation) {
//             console.log(selectedLocation)
//             new window.google.maps.Marker({
//                 position: selectedLocation,
//                 map: mapRef.current,
//                 title: 'Selected Location'
//             });

//             // إذا كانت هناك دائرة سابقة، أزلها
//             if (circleInstance) {
//                 circleInstance.setMap(null);
//             }

//             // إنشاء دائرة جديدة
//             const newCircle = new window.google.maps.Circle({
//                 center: selectedLocation,
//                 radius: parseInt(radius) * 1000,
//                 map: mapRef.current,
//                 editable: true, // تمكين تحرير الدائرة
//             });

//             // تعيين الدائرة الجديدة كدائرة حالية
//             setCircleInstance(newCircle);

//             // تحديث حجم الدائرة عند تغييرها
//             newCircle.addListener('radius_changed', () => {
//                 const newRadius = newCircle.getRadius() / 1000; // تحويل النصف إلى كيلومتر
//                 setRadius(newRadius);
//             });

//             // تحديث موقع الدائرة عند تغييره
//             newCircle.addListener('center_changed', () => {
//                 const newCenter = newCircle.getCenter();
//                 const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
//                 setSelectedLocation(newLocation);
//                 onLocationSelect(newLocation);
//             });
//         }else{
//             if(lat && lang){
//                 new window.google.maps.Marker({
//                     position: {lat: lat, lng: lang},
//                     map: mapRef.current,
//                     title: 'Located Location'
//                 });
    
//                 // إذا كانت هناك دائرة سابقة، أزلها
//                 if (circleInstance) {
//                     circleInstance.setMap(null);
//                 }
    
//                 // إنشاء دائرة جديدة
//                 const newCircle = new window.google.maps.Circle({
//                     center: {lat: lat, lng: lang},
//                     radius: parseInt(radius) * 1000,
//                     map: mapRef.current,
//                     editable: true, // تمكين تحرير الدائرة
//                 });
    
//                 // تعيين الدائرة الجديدة كدائرة حالية
//                 setCircleInstance(newCircle);
    
//                 // تحديث حجم الدائرة عند تغييرها
//                 newCircle.addListener('radius_changed', () => {
//                     const newRadius = newCircle.getRadius() / 1000; // تحويل النصف إلى كيلومتر
//                     setRadius(newRadius);
//                 });
    
//                 // تحديث موقع الدائرة عند تغييره
//                 newCircle.addListener('center_changed', () => {
//                     const newCenter = newCircle.getCenter();
//                     const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
//                     setSelectedLocation(newLocation);
//                     onLocationSelect(newLocation);
//                 });
//             }
//         }
//     }, [selectedLocation, lat, lang, radius]);


//     const initMap = () => {
//         if (resAreas && resAreas.data) {
//             mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
//                 zoom: 10,
//                 center: { lat: 24.7136, lng: 46.6753 },
//             });

//             mapRef.current.addListener('click', onMapClick);

//             new window.google.maps.Polyline({
//                 path: curvedPath,
//                 geodesic: true,
//                 strokeColor: '#FF0000',
//                 strokeOpacity: 0.8,
//                 strokeWeight: 2,
//                 map: mapRef.current,
//             });



//             if (lat && lang) {
//                 new window.google.maps.Marker({
//                     position: { lat: lat, lng: lang },
//                     title: `data`,
//                     map: mapRef.current,
//                     // editable: true,
//                 });
//                 const circleOptions = {
//                     strokeColor: "#FF0000",
//                     strokeOpacity: 0.8,
//                     strokeWeight: 2,
//                     fillColor: "#FFFFFF",
//                     fillOpacity: 0.35,
//                     radius: range * 1000,
//                     // editable: true,
//                 };
//                 new window.google.maps.Circle({
//                     center: { lat: lat, lng: lang },
//                     ...circleOptions,
//                     map: mapRef.current,
//                     // editable: true,
//                 });
//                 const bikerLocation = { lat: lat, lng: lang };
//                 mapRef.current.panTo(bikerLocation);
//                 // mapRef.current.setZoom(15);
//             }

            

//         }
//     };


    

//     const startMap = () => {
//         map = new window.google.maps.Map(document.getElementById('map'), {
//             zoom: 10,
//             center: { lat: 24.7136, lng: 46.6753 },
//         });

//         map.addListener('click', onMapClick);
//     }




//     useEffect(() => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
//         script.async = true;
//         script.defer = true;
//         script.onload = startMap;
//         document.head.appendChild(script);

//         return () => {
//             document.head.removeChild(script);
//         };
//     }, []);

//     useEffect(() => {
//         if (selectedLocation) {
//             new window.google.maps.Marker({
//                 position: selectedLocation,
//                 map: mapRef.current,
//                 title: 'Selected Location'
//             });
//         }
//     }, [selectedLocation]);

//     useEffect(() => {
//         if (typeof onRadiusChange === 'function') {
//             onRadiusChange(radius);
//         }
//     }, [radius]);

//     useEffect(() => {
//         setRadius(radiusData);
//     }, [radiusData])

//     return (
//         <div>
//             <button onClick={initMap} className='btn submitButton' style={{ width: "10%" }}>تحديث الخريطه</button>
//             <span>(قم بتحديث الخريطه لرؤية  اللوكيشن الخاص بالمكان)</span>
//             <div style={{ visibility: "hidden" }}>
//                 <label>قم بكتابة محيط المكان : </label>
//                 <input type="number" value={radiusData} onChange={(e) => setRadius(e.target.value)} placeholder='قم باختيار محيط الدائره' style={{ background: "white", padding: "10px", borderRadius: "10px", outline: "none", border: "1px solid " }} />
//             </div>
//             <div id="map" style={{ height: '500px', width: '100%' }}>
//             </div>
//         </div>
//     );
// }

// export default MapEditArea;



// الكود الصح

// import React, { useEffect, useState, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { GetAllAreas } from '../redux/actions/AreaActions';

// const MapEditArea = ({ onLocationSelect, lat, lang, range, onRadiusChange, radiusData }) => {
//     const dispatch = useDispatch();
//     const mapRef = useRef(null);
//     const [currentMarker, setCurrentMarker] = useState(null);
//     const [currentCircle, setCurrentCircle] = useState(null);
//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const [radius, setRadius] = useState(radiusData || range);
//     const [circleOptions, setCircleOptions] = useState({
//         strokeColor: "#FF0000",
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: "#FFFFFF",
//         fillOpacity: 0.35,
//     });

//     const getAreas = async () => {
//         await dispatch(GetAllAreas());
//     };

//     useEffect(() => {
//         getAreas();
//     }, []);

//     const resAreas = useSelector(state => state.AreaReducer.AllAreas);

//     const onMapClick = (e) => {
//         const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
//         setSelectedLocation(location);
//         onLocationSelect(location);

//         setCircleOptions(prevOptions => ({
//             ...prevOptions,
//             center: location,
//         }));
//     };

//     useEffect(() => {
//         if (selectedLocation || (lat && lang)) {
//             const location = selectedLocation || { lat, lng: lang };

//             // Clear previous marker and circle
//             if (currentMarker) {
//                 currentMarker.setMap(null);
//             }
//             if (currentCircle) {
//                 currentCircle.setMap(null);
//             }

//             // Create new marker
//             const marker = new window.google.maps.Marker({
//                 position: location,
//                 map: mapRef.current,
//                 title: 'Selected Location'
//             });
//             setCurrentMarker(marker);

//             // Create new circle
//             const circle = new window.google.maps.Circle({
//                 center: location,
//                 radius: parseInt(radius) * 1000,
//                 map: mapRef.current,
//                 editable: true,
//                 ...circleOptions,
//             });
//             setCurrentCircle(circle);

//             // Update radius when changed
//             circle.addListener('radius_changed', () => {
//                 const newRadius = circle.getRadius() / 1000; // Convert to kilometers
//                 setRadius(newRadius);
//                 if (typeof onRadiusChange === 'function') {
//                     onRadiusChange(newRadius);
//                 }
//             });

//             // Update location when changed
//             circle.addListener('center_changed', () => {
//                 const newCenter = circle.getCenter();
//                 const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
//                 setSelectedLocation(newLocation);
//                 onLocationSelect(newLocation);
//             });
//         }
//     }, [selectedLocation, lat, lang, radius]);

//     const initMap = () => {
//         if (resAreas && resAreas.data) {
//             mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
//                 zoom: 10,
//                 center: { lat: 24.7136, lng: 46.6753 },
//             });

//             mapRef.current.addListener('click', onMapClick);

//             if (lat && lang) {
//                 const location = { lat, lng: lang };
//                 const marker = new window.google.maps.Marker({
//                     position: location,
//                     map: mapRef.current,
//                     title: 'Located Location'
//                 });
//                 setCurrentMarker(marker);

//                 const circle = new window.google.maps.Circle({
//                     center: location,
//                     radius: parseInt(range) * 1000, // Use the range prop
//                     map: mapRef.current,
//                     editable: true,
//                     ...circleOptions,
//                 });
//                 setCurrentCircle(circle);
//                 setRadius(range);
//             }
//         }
//     };

//     useEffect(() => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
//         script.async = true;
//         script.defer = true;
//         script.onload = initMap;
//         document.head.appendChild(script);

//         return () => {
//             document.head.removeChild(script);
//         };
//     }, []);

//     useEffect(() => {
//         if (typeof onRadiusChange === 'function') {
//             onRadiusChange(radius);
//         }
//     }, [radius]);

//     useEffect(() => {
//         setRadius(radiusData);
//     }, [radiusData]);

//     return (
//         <div>
//             <button onClick={initMap} className='btn submitButton' style={{ width: "10%" }}>تحديث الخريطه</button>
//             <span>(قم بتحديث الخريطه لرؤية اللوكيشن الخاص بالمكان)</span>
//             <div style={{ visibility: "hidden" }}>
//                 <label>قم بكتابة محيط المكان : </label>
//                 <input type="number" value={radiusData} onChange={(e) => setRadius(e.target.value)} placeholder='قم باختيار محيط الدائره' style={{ background: "white", padding: "10px", borderRadius: "10px", outline: "none", border: "1px solid " }} />
//             </div>
//             <div id="map" style={{ height: '500px', width: '100%' }}>
//             </div>
//         </div>
//     );
// }

// export default MapEditArea;




import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAreas } from '../redux/actions/AreaActions';

const MapEditArea = ({ onLocationSelect, lat, lang, range, onRadiusChange, radiusData }) => {
    const dispatch = useDispatch();
    const mapRef = useRef(null);
    const [currentMarker, setCurrentMarker] = useState(null);
    const [currentCircle, setCurrentCircle] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [radius, setRadius] = useState(radiusData || range);
    const [circleOptions, setCircleOptions] = useState({
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FFFFFF",
        fillOpacity: 0.35,
    });

    const getAreas = async () => {
        await dispatch(GetAllAreas());
    };

    useEffect(() => {
        getAreas();
    }, []);

    const resAreas = useSelector(state => state.AreaReducer.AllAreas);

    const onMapClick = (e) => {
        const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setSelectedLocation(location);
        onLocationSelect(location);

        setCircleOptions(prevOptions => ({
            ...prevOptions,
            center: location,
        }));
    };

    useEffect(() => {
        if (selectedLocation || (lat && lang)) {
            const location = selectedLocation || { lat, lng: lang };

            // Clear previous marker and circle
            if (currentMarker) {
                currentMarker.setMap(null);
            }
            if (currentCircle) {
                currentCircle.setMap(null);
            }

            // Create new marker
            const marker = new window.google.maps.Marker({
                position: location,
                map: mapRef.current,
                title: 'Selected Location'
            });
            setCurrentMarker(marker);

            // Create new circle
            const circle = new window.google.maps.Circle({
                center: location,
                radius: parseInt(radius) * 1000,
                map: mapRef.current,
                editable: true,
                ...circleOptions,
            });
            setCurrentCircle(circle);

            // Update radius when changed
            circle.addListener('radius_changed', () => {
                const newRadius = circle.getRadius() / 1000; // Convert to kilometers
                setRadius(newRadius);
                if (typeof onRadiusChange === 'function') {
                    onRadiusChange(newRadius);
                }
            });

            // Update location when changed
            circle.addListener('center_changed', () => {
                const newCenter = circle.getCenter();
                const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
                setSelectedLocation(newLocation);
                onLocationSelect(newLocation);
            });
        }
    }, [selectedLocation, lat, lang, radius]);

    const initMap = () => {
        if (resAreas && resAreas.data) {
            mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: { lat: 24.7136, lng: 46.6753 },
            });

            mapRef.current.addListener('click', onMapClick);

            if (lat && lang) {
                const location = { lat, lng: lang };
                const marker = new window.google.maps.Marker({
                    position: location,
                    map: mapRef.current,
                    title: 'Located Location'
                });
                setCurrentMarker(marker);

                const circle = new window.google.maps.Circle({
                    center: location,
                    radius: parseInt(range) * 1000, // Use the range prop
                    map: mapRef.current,
                    editable: true,
                    ...circleOptions,
                });
                setCurrentCircle(circle);
                setRadius(range);
                // Pan to the location
                mapRef.current.panTo(location);
            }
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
        script.async = true;
        script.defer = true;
        script.onload = initMap;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (typeof onRadiusChange === 'function') {
            onRadiusChange(radius);
        }
    }, [radius]);

    useEffect(() => {
        setRadius(radiusData);
    }, [radiusData]);

    return (
        <div>
            <button onClick={initMap} className='btn submitButton' style={{ width: "10%" }}>تحديث الخريطه</button>
            <span>(قم بتحديث الخريطه لرؤية اللوكيشن الخاص بالمكان)</span>
            <div style={{ visibility: "hidden" }}>
                <label>قم بكتابة محيط المكان : </label>
                <input type="number" value={radiusData} onChange={(e) => setRadius(e.target.value)} placeholder='قم باختيار محيط الدائره' style={{ background: "white", padding: "10px", borderRadius: "10px", outline: "none", border: "1px solid " }} />
            </div>
            <div id="map" style={{ height: '500px', width: '100%' }}>
            </div>
        </div>
    );
}

export default MapEditArea;
