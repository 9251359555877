import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { BikerProfileUpdateData, GetAllBikers } from '../../redux/actions/Bikers';
import Form from 'react-bootstrap/Form';
import { ErrorMsg } from '../../Utils/Toast';
import { BikerProfileUpdate } from '../../redux/actions/Bikers';
import moment from 'moment';
import DateTime from 'react-datetime';
import Modal from 'react-bootstrap/Modal';
const ModalHoliday = () => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {

        setShow(true)
    }

    useEffect(() => {
        dispatch(GetAllBikers());
    }, [dispatch])
    const BikersName = useSelector(state => state.GetBikers.AllBikers)

    const [startDay, setStartDay] = useState('');
    const [endDay, setEndDay] = useState('');
    const [daysDiff, setDaysDiff] = useState(null);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateEnd, setSelectedDateEnd] = useState("");

    const handleDateChange = (date) => {
        if (date === "") {
            console.log(date);
            setSelectedDate(moment(""))
        } else {
            setSelectedDate(date.toISOString());
        }
    };


    const handleDateChangeEnd = (date) => {
        if (date === "") {
            console.log(date);
            setSelectedDateEnd(moment(""))
        } else {
            setSelectedDateEnd(date.toISOString());
        }
        // setSelectedDateEnd(moment(date));
    };


    const calculateDaysDifference = () => {
        const startDate = new Date(selectedDate);
        const endDate = new Date(selectedDateEnd);
        const date = new Date(startDay);
        // const formattedDate = new Intl.DateTimeFormat('en-GB').format(date);



        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            if (endDate <= startDate) {
                // Handle the case where end date is not after start date (e.g., show an error message)
                setDaysDiff(null);
                // ErrorMsg(`يجب اختيار يوم يبدا بعد  ${formattedDate}`)
            } else {
                const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
                const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                setDaysDiff(daysDiff + 1);
            }
        } else {
            setDaysDiff(null);
        }
    };

    useEffect(() => {
        if (selectedDate !== "" && selectedDateEnd !== "") {
            calculateDaysDifference();
        }
    }, [selectedDateEnd, selectedDate])



    const [loading, setLoading] = useState(false);
    const [selectBiker, setBiker] = useState('');




    const handleUpdateProfile = async () => {
        try {
            setLoading(true);
            await dispatch(BikerProfileUpdateData({
                id: selectBiker,
                in_active_start_date: selectedDate,
                in_active_end_date: selectedDateEnd,
            }));
            setBiker('');
            setDaysDiff('');
            document.querySelector('.mySelectClass').value = '';
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
            dispatch(GetAllBikers());
            handleDateChange("")
            handleDateChangeEnd("");
            handleClose();
        }
    };


    useEffect(() => {
        if (!loading) {

        }
    }, [loading])





    const validDate = (current) => {
        // Disable dates prior to today by comparing with the current date
        return current.isAfter(new Date().setHours(0, 0, 0, 0)); // Ensure current date is after today
    };


    return (
        <div>
            <div className='font'>
                <div className='mt-3'>
                    <div className='bikerEditTop d-flex justify-content-between align-items-center'>
                        <p>نموذج تقديم طلب الاجازة</p>
                        <button className='btn mx-3 mt-3' style={{ background: "#FECB44" }} onClick={handleShow}>اجازه للبايكر</button>
                    </div>
                    <Modal show={show} onHide={handleClose} className='font' centered>
                        <Modal.Header>
                            <Modal.Title>اضافة الاجازة للبايكر (New Slot)</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                                <div className=''>
                                    <div style={{ width: "100%" }}>
                                        <div className=''>
                                            <label>البايكر </label>
                                            <div className='w-100'>
                                                <Form.Select aria-label="Default select example" className='mySelectClass' style={{ background: "#EFF1F9" }} onClick={(e) => setBiker(e.target.value)}>
                                                    <option value="" hidden>حدد البايكر المراد اضافته</option>
                                                    {BikersName && BikersName.bikers ? (
                                                        BikersName.bikers
                                                            .filter(biker => biker.is_active === true)
                                                            .map((bikerName) => (
                                                                <option key={bikerName?.id} value={bikerName?.user_id}>
                                                                    {bikerName?.user?.first_name}
                                                                </option>
                                                            ))
                                                    ) : (null)}
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <label>تاريخ البدء</label>
                                            <DateTime
                                                className='mt-2 textInputBiker1'
                                                utc={true}
                                                onChange={handleDateChange}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                inputProps={{ readOnly: true }}
                                                closeOnSelect={true}
                                                isValidDate={validDate} // Ensure null if state is null
                                            />

                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }}>
                                        <div className=''>
                                            <label>تاريخ الانتهاء  </label>
                                            <DateTime
                                                className=' mt-2 textInputBiker2'
                                                min={selectedDate}
                                                // Ensure null if state is null
                                                utc={true}
                                                inputProps={{ readOnly: true }}
                                                closeOnSelect={false}
                                                onChange={handleDateChangeEnd}
                                                dateFormat="YYYY-MM-DD"
                                                timeFormat="HH:mm"
                                                isValidDate={validDate}
                                            // value={selectedDateEnd}
                                            />

                                        </div>
                                        <div className=''>
                                            <label> عدد الايام</label>
                                            <br></br>
                                            {
                                                daysDiff !== null ? (
                                                    <input type='text' className='w-100 mt-2 textInputBiker' value={daysDiff} readOnly />
                                                ) : (<input type='text' className='w-100 mt-2 textInputBiker' readOnly />)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='m-auto w-100'>

                            <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleUpdateProfile}>
                                اضافه
                            </button>
                            <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                                تراجع
                            </button>
                        </Modal.Footer>
                    </Modal>

                </div>


            </div>
        </div>
    )
}

export default ModalHoliday
