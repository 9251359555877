import React, { useState } from 'react'
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import input from "../../Images/Input.png"
import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
import { GetPrivacy, GetSocialMedia, GetTermsConditions, UpdatePrivacy, UpdateSocialMedia, UpdateTerms } from '../../redux/actions/PrivacyPolicy';
import { CiEdit } from "react-icons/ci";
const ModalEditPolicy = ({ titleEn,
    titleAr,
    descAr,
    descEn, idData, section }) => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();

    const [titleArData, setTitleAr] = useState(titleAr)
    const [titleEnData, setTitleEn] = useState(titleEn)
    const [descriptionAr, setDescAr] = useState(descAr)
    const [descriptionEn, setDescEn] = useState(descEn)


    const handleUpdate = async () => {
        try {
            if (section === "terms") {
                await dispatch(UpdateTerms({
                    id: idData,
                    title_ar: titleArData,
                    description_ar: descriptionAr,
                    title_en: titleEnData,
                    description_en: descriptionEn,
                }))
            } else {
                await dispatch(UpdatePrivacy({
                    id: idData,
                    title_ar: titleArData,
                    description_ar: descriptionAr,
                    title_en: titleEnData,
                    description_en: descriptionEn,
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            handleCloseEdit();
            if (section === "terms") {
                await dispatch(GetTermsConditions())
            } else {
                await dispatch(GetPrivacy())
            }
        }
    }



    return (
        <div>
            <CiEdit onClick={handleShowEdit} cursor={"pointer"} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
                <Modal.Body className='text-center py-3'>
                    <div>
                        <label>العنوان بالعربي</label>
                        <input type="text" value={titleArData} className='mt-2'
                            onChange={(e) => setTitleAr(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                    </div>
                    <div>
                        <label>العنوان بالانجليزيه</label>
                        <input type="text" value={titleEnData} className='mt-2'
                            onChange={(e) => setTitleEn(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                    </div>
                    <div>
                        <label>الوصف بالعربي</label>
                        <input type="text" value={descriptionAr} className='mt-2'
                            onChange={(e) => setDescAr(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                    </div>
                    <div>
                        <label>الوصف بالانجليزي</label>
                        <input type="text" value={descriptionEn} className='mt-2'
                            onChange={(e) => setDescEn(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط المنصه ' />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white" }} onClick={handleUpdate}>
                        تاكيد الحفظ
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalEditPolicy
