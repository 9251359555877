import React, { useEffect, useState } from 'react'
import Logo from '../../Images/logo.png'
import { IoPencil } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { cache } from '@emotion/css';
import { SettingInvoiceData, UpdateSettingInvoiceData, UploadPhoto } from '../../redux/actions/InvoiceAction';
import ModalVat from '../Modals/ModalVat';
const SettingInvoice = () => {
    const [images, setImages] = useState([]);
    const handleImageUpload = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);
    }
    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };


    const [urlData, setUrl] = useState("")

    useEffect(() => {
        const formatData = new FormData();
        if (images.length > 0) {
            formatData.append("file", images[0])
            dispatch(UploadPhoto(formatData))
        }
    }, [images])

    const pathOfImages = useSelector(state => state.InvoiceReducer.UploadPhotoData);



    useEffect(() => {
        if (pathOfImages && pathOfImages.data) {
            dispatch(UpdateSettingInvoiceData({
                logo_app: pathOfImages.data.path
            }))
            dispatch(SettingInvoiceData())
            setImages([])
        }
    }, [pathOfImages])

    const deleteData = async () => {
        try {
            await dispatch(UpdateSettingInvoiceData({
                logo_app: "string"
            }))
        } catch (e) {
            console.log(e);
        } finally {
            await dispatch(SettingInvoiceData());
            setImages([])
        }
    }


    //Dispatch To Get Settings Invoices
    const dispatch = useDispatch();
    const setting = async () => {
        try {
            await dispatch(SettingInvoiceData())
        } catch (e) {
            console.log(e);
        } finally {

        }
    }

    useEffect(() => {
        setting();
    }, [dispatch])

    const settings = useSelector(state => state.InvoiceReducer.setting);

    if (settings && settings.data) {
        console.log(settings.data.logo_app);
    }
    useEffect(() => {
        if (settings && settings.data) {
            setUrl(settings.data.logo_app)
        }
    }, [settings])

    // <tr>
    //                         <td style={{ width: "75px", height: "75px" }}>
    //                             <div style={{ backgroundImage: `url(${settings.data.logo_app})`, width: "100%", height: "100%", backgroundSize: "cover", backgroundPosition: "center" }}></div>
    //                         </td>
    //                     </tr>
    return (
        <div>
            <div className='flexItem'>
                <div className='font d-flex justify-content-between align-items-center'>
                    <div>
                        <p>شعار الشركه</p>
                        <span style={{ color: "#8B8D97" }}>سيتم عرض هذا في ملفك الشخصي وملخص الفاتورة.</span>
                    </div>
                    <div className="file-input">
                        <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                        {/* <img src={Logo} alt="Select images" onClick={handleLabelClick} /> */}
                        {
                            images.length !== 0 ? (
                                <div className="image-container">
                                    {images.map((image, index) => (
                                        <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                            style={{ width: "200px", height: "100px" }} />
                                    ))}
                                </div>
                            ) : (<div>
                                {
                                    settings && settings.data ? (
                                        <div style={{ backgroundImage: `url(${urlData})`, width: "220px", height: "220px",  borderRadius:"50%",backgroundRepeat:"no-repeat" , backgroundSize: "contain", backgroundPosition: "center" }}></div>
                                    ) : (<span>Data</span>)
                                }
                            </div>)

                        }
                    </div>



                </div>
                <div>
                    <button className='btn' onClick={handleLabelClick} style={{ color: "#828282" }}>تعديل </button>
                    <button className='btn' style={{ color: "#EB5757" }} onClick={deleteData}>حذف</button>
                </div>
            </div>



            {
                settings && settings.data ? (
                    <div className='invoiceCompany flexItem mt-4'>
                        <div>
                            <p>عنوان الشركة بالفاتورة</p>
                            <span style={{ color: "#8B8D97" }}>
                                {
                                    settings && settings.data ? (settings.data.company_address) : null
                                }
                            </span>
                        </div>
                        <div className='flexItem'>
                            <ModalVat address={settings.data.company_address} title={"تعديل العنوان بالفاتوره"} type={"address"} />
                            {/* <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                        />
                    </Form> */}
                        </div>
                    </div>
                ) : null
            }

            <div className='flexItem mt-3'>
                {
                    settings && settings.data ? (
                        <div className='invoiceCompany flexItem mt-4' style={{ width: "45%" }}>
                            <div>
                                <p>الرقم الضريبي</p>
                                <span style={{ color: "#8B8D97" }}>
                                    {
                                        settings.data.vat_number
                                    }
                                </span>
                            </div>
                            <div className='flexItem'>
                                {/* <IoPencil style={{ marginLeft: "25px" }} size="20" /> */}
                                <ModalVat vat={settings.data.vat_number} title={"تعديل الرقم الضريبي"} type={"numberVat"} />
                                {/* <Form>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                            />
                        </Form> */}
                            </div>
                        </div>
                    ) : null
                }
                {
                    settings && settings.data ? (<div className='invoiceCompany flexItem mt-4' style={{ width: "45%" }}>
                        <div>
                            <p>نسبة الضريبة بالفاتورة</p>
                            <span style={{ color: "#8B8D97" }}>
                                {
                                    settings && settings.data ? (settings.data.tax_rate * 100) : null
                                }
                            </span>
                        </div>
                        <div className='flexItem'>
                            <ModalVat vat={settings.data.vat_number} title={"تعديل نسبة الضريبه بالفاتورة"} type={"rate"} rateData={settings.data.tax_rate * 100} />
                            {/* <Form>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                            />
                        </Form> */}
                        </div>
                    </div>) : null
                }
            </div>
        </div>
    )
}

export default SettingInvoice
