import React, { useEffect, useState } from 'react'
import Map from '../DashboardComponents/Map'
import MapEdit from '../../Utils/MapEdit'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateNewArea } from '../../redux/actions/AreaActions'
import { useNavigate } from 'react-router-dom';
import { success } from '../../Utils/Toast'

const EditArea = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [radius, setRadius] = useState(1);

    const [selectedLocation, setSelectedLocation] = useState({ lat: 0, lng: 0 });


    const handleLocationSelect = (location) => {

        setSelectedLocation(location);
    }
    const handleRadius = (radius) => {
        setRadius(radius);
    }



    const addArea = async () => {
        try {
            setLoading(true);
            if (selectedLocation) {
                dispatch(UpdateNewArea({
                    latitude: selectedLocation.lat,
                    longitude: selectedLocation.lng,
                    range: parseInt(radius),
                    name: name,
                    address: address
                }))
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }


    const resUpdates = useSelector(state => state.AreaReducer.upladoedArea);

    useEffect(() => {
        if (resUpdates && loading === false) {
            if (resUpdates.statusCode === 200) {
                success(resUpdates.message)
                // navigate('/admin/areamanage')
                setTimeout(() => {
                    window.location.href = "/admin/areamanage"
                }, 1500)
            }
        }
    }, [loading, resUpdates])



    return (
        <div className='container'>
            <div className='font'>
                <div className='flexItem font py-3'>
                    <div>
                        <p>ادارة اماكن ومناطق الخدمة</p>
                    </div>
                    <div>
                        <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={addArea}>تاكيد الاضافه</button>
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='bikerEditTop'>
                        المعلومات الاساسيه
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='اسم مميز للمكان او النطاق' className='area' />
                        </div>
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} placeholder='العنوان' className='area' />
                        </div>
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            <input type="number" value={radius}  onChange={(e) => setRadius(e.target.value)} placeholder='محيط المكان' className='area' />
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='bikerEditTop'>
                        تحديد المكان علي الخريطة
                    </div>
                    <div className='p-3 mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                        <div>
                            {/* <MapEdit onLocationSelect={handleLocationSelect} onRaduis={handleRadius} /> */}

                            <MapEdit onLocationSelect={handleLocationSelect} onRadiusChange={handleRadius} radiusData={parseInt(radius)}/>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default EditArea
