import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import input from "../../Images/Group.png"
import { connect, useDispatch, useSelector } from 'react-redux'
import { AddQuestion, GetQuestionsAndAnswers, GetSupport, UpdateConnects } from '../../redux/actions/PrivacyPolicy';
import EditQA from '../Modals/ModalEditQA';
import { MdDelete } from "react-icons/md";
import Delete from '../Modals/Delete';
import AddQuModal from '../Modals/AddQuestion';


const Support = () => {
    const [show, setShow] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isEditedTwo, setIsEditedTwo] = useState(false);
    const [isEditedThree, setIsEditedThree] = useState(false);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true)
    }

    const dispatch = useDispatch();

    const fetchQA = async () => {
        await dispatch(GetQuestionsAndAnswers());
        await dispatch(GetSupport());
    }

    useEffect(() => {
        fetchQA();
    }, [dispatch])

    const qaData = useSelector(state => state.PrivacyReducer.QA);
    const Connects = useSelector(state => state.PrivacyReducer.Support);

    const [phone, setPhone] = useState("");
    const [whats, setWhats] = useState("");
    const [email, setEmail] = useState("");

    useEffect(() => {
        if (Connects && Connects.data) {
            setPhone(Connects.data.phone_number)
            setWhats(Connects.data.whatsApp_phone)
            setEmail(Connects.data.mail_us)
        }
    }, [Connects])

    const handleInputChange = () => {
        setIsEdited(true);
    };
    const handleInputChangeTwo = () => {
        setIsEditedTwo(true);
    };
    const handleInputChangeThree = () => {
        setIsEditedThree(true);
    };

    const addPhoneNumber = async (id) => {
        try {
            await dispatch(UpdateConnects({
                id: id,
                phone_number: phone
            }))
            setIsEdited(false)
            await dispatch(GetSupport());
        } catch (e) {

        }
    }
    const addEmailData = async (id) => {
        try {
            await dispatch(UpdateConnects({
                id: id,
                mail_us: email
            }))
            setIsEditedTwo(false)
            await dispatch(GetSupport());
        } catch (e) {

        }
    }
    const addWhatsApp = async (id) => {
        try {
            await dispatch(UpdateConnects({
                id: id,
                whatsApp_phone: whats
            }))
            setIsEditedThree(false)
            await dispatch(GetSupport());
        } catch (e) {

        }
    }



    return (
        <div className='font'>
            <div className='d-flex justify-content-between align-items-center mt-3'>
                <p>الدعم والاسئله الشائعه</p>
                <div>
                    <AddQuModal/>
                </div>
            </div>

            <div>
                <div className='bickerMab bg-light mt-3'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div>
                            <p>تواصل معنا</p>
                        </div>
                    </div>
                </div>


                {
                    Connects && Connects.data ? (
                        <div className='bickerMab bg-light mt-3 p-3'>
                            <div className='mt-2'>
                                <label>رقم الهاتف للتواصل</label>
                                <br />
                                <input type="text" value={phone} className='mt-2' style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رقم الهاتف للتواصل' onChange={(e) => {
                                    setPhone(e.target.value);
                                    handleInputChange();
                                }} />
                                {isEdited ? <button className='btn' style={{ fontWeight: "bolder", color: "rgba(60, 196, 65, 1)" }}
                                    onClick={() => {
                                        addPhoneNumber(Connects.data.id)
                                    }}

                                >حفظ التغييرات</button> : <button className='btn' style={{ fontWeight: "bolder", color: "rgba(168, 89, 35, 1)" }}>تعديل</button>}
                            </div>



                            <div className='mt-2'>
                                <label>ايميل التواصل</label>
                                <br />
                                <input type="text" value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        handleInputChangeTwo();
                                    }}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='ايميل التواصل' />
                                {isEditedTwo ? <button className='btn' style={{ fontWeight: "bolder", color: "rgba(60, 196, 65, 1)" }}

                                    onClick={() => {
                                        addEmailData(Connects.data.id)
                                    }}
                                >حفظ التغييرات</button> : <button className='btn' style={{ fontWeight: "bolder", color: "rgba(168, 89, 35, 1)" }}>تعديل</button>}
                            </div>



                            <div className='mt-2'>
                                <label>رقم التواصل عبر الواتساب</label>
                                <br />
                                <input type="text" value={whats}
                                    onChange={(e) => {
                                        setWhats(e.target.value);
                                        handleInputChangeThree();
                                    }}
                                    style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder='رابط الـ Api للواتساب' />
                                {isEditedThree ? <button className='btn' style={{ fontWeight: "bolder", color: "rgba(60, 196, 65, 1)" }}
                                    onClick={() => {
                                        addWhatsApp(Connects.data.id)
                                    }}
                                >حفظ التغييرات</button> : <button className='btn' style={{ fontWeight: "bolder", color: "rgba(168, 89, 35, 1)" }}>تعديل</button>}
                            </div>
                        </div>
                    ) : null
                }
            </div>




            <div>
                <div className='bickerMab bg-light mt-3'>
                    <div className='d-flex justify-content-between align-items-center p-3'>
                        <div>
                            <p>الأسئلة المتكررة</p>
                        </div>
                    </div>
                </div>


                {
                    qaData && qaData.data ? (
                        qaData.data.map((data) => {
                            // console.log(data)
                            return (
                                <div className='bickerMab p-3 mt-3' key={data.id} style={{ background: "white", position: "relative" }}>
                                    <div className="d-flex" style={{ marginLeft: "5px", position: "absolute", left: "0" }}>
                                        <EditQA idData={data.id} questionAr={data.question} answerAr={data.answer} question_en={data.question_en} answer_en={data.answer_en} />
                                        <Delete style={{ color: "rgb(235, 87, 87)" }} idData={data.id} section="support"/>
                                    </div>
                                    <div style={{ color: "rgba(171, 175, 177, 1)", background: "#f7f6fb", borderRadius: "10px", marginTop: "30px" }} className='py-2'>
                                        <p>{data.question}</p>
                                    </div>
                                    <div className='mt-2'>
                                        <span>تفاصيل واجابة السؤال</span>
                                    </div>
                                    <div style={{ color: "rgba(171, 175, 177, 1)", padding: "10px", background: "#f7f6fb", borderRadius: "10px" }} className='py-2'>
                                        <p>
                                            {data.answer}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    ) : null
                }

            </div>



            <Modal show={show} onHide={handleClose} className='font py-5' centered>
                <Modal.Body className='text-center py-3'>
                    <img src={input} alt="delete" />

                    <p className='mt-4 fs-4'>هل انت متأكد من حفظ التعديلات؟ </p>
                    <span>سيتم حفظ جميع التعديلات، وسيتم تحديثها عند تأكيد الحفظ</span>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white" }}>
                        تاكيد الحفظ
                    </button>
                    <button className='btn' onClick={handleClose} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Support
