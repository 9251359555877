import { ADD_BIKER_TO_AREA, DELETE_AREA, DELETE_BIKERS_AT_AREA, GET_ALL_AREAS, GET_BIKERS_AT_AREA, GET_SINGLE_AREA, GET_UPDATE_DATA, UPLOAD_NEW_AREA } from "../Type";
const initial = {
    Constants: [],
    upladoedArea: [],
    deletedArea: [],
    singleArea: [],
    updateDataArea: [],
    bikersArea: [],
    deleteBikerArea: [],
    bikerAdded: [],
    loading: true,
}

const AreaReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_AREAS:
            return {
                ...state,
                AllAreas: action.payload,
                loading: false
            }
        case UPLOAD_NEW_AREA:
            return {
                ...state,
                upladoedArea: action.payload,
                loading: false
            }
        case DELETE_AREA:
            return {
                ...state,
                deletedArea: action.payload,
                loading: false
            }
        case GET_SINGLE_AREA:
            return {
                ...state,
                singleArea: action.payload,
                loading: false
            }
        case GET_UPDATE_DATA:
            return {
                ...state,
                updateDataArea: action.payload,
                loading: false
            }
        case GET_BIKERS_AT_AREA:
            return {
                ...state,
                bikersArea: action.payload,
                loading: false
            }
        case DELETE_BIKERS_AT_AREA:
            return {
                ...state,
                deleteBikerArea: action.payload,
                loading: false
            }
        case ADD_BIKER_TO_AREA:
            return {
                ...state,
                bikerAdded: action.payload,
                loading: false
            }

        default:
            return state;
    }
}

export default AreaReducer