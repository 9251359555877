import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { GET_ALL_CARS, GET_ALL_BRANDS, ADD_NEW_BRAND, ADD_NEW_BRAND_CAR_MODAL, DELETE_BRAND_CAR_MODAL, UPDATE_BRAND_CAR_MODAL , GET_ALL_BRANDS_DETAILS ,DELETE_BRAND  ,UPDATE_BRAND} from '../Type'

const GetAllCars = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            // const page = data.pageCount || 1
            let res = await baseUrl.get(`v1/vehicle-brand-models/admin?page=${data.pageCount}&limit=10&sortBy=created_at%3D${data.sort}&isDeleted=false`, config)
            dispatch({ type: GET_ALL_CARS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_CARS, payload: e.res })
            console.log(e)
        }
    }
}

//Filter Car 
const FilterCar = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.lang === "en" && data.searchWord !=="") {
                let res = await baseUrl.get(`v1/vehicle-brand-models/admin?page=1&limit=10&filters=name_en%3D${data.searchWord}&sortBy=created_at%3Ddesc&isDeleted=false`, config)
                dispatch({ type: GET_ALL_CARS, payload: res.data })
            } else if (data.lang === "ar" && data.searchWord !=="") {
                let res = await baseUrl.get(`v1/vehicle-brand-models/admin?page=1&limit=10&filters=name_ar%3D${data.searchWord}&sortBy=created_at%3Ddesc&isDeleted=false`, config)
                dispatch({ type: GET_ALL_CARS, payload: res.data })
            }else if(data.lang === "ar" && data.searchWord ===""){
                let res = await baseUrl.get(`v1/vehicle-brand-models/admin?page=1&limit=10&filters=&sortBy=created_at%3Ddesc&isDeleted=false`, config)
                dispatch({ type: GET_ALL_CARS, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_CARS, payload: e.res })
            console.log(e)
        }
    }
}


//ASC , DESC
const FilterCarASCDESC = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.get(`v1/vehicle-brand-models/admin?page=${data.pageCount}&limit=10&sortBy=created_at%3D${data.sort}`, config)
            dispatch({ type: GET_ALL_CARS, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: GET_ALL_CARS, payload: e.res })
            console.log(e)
        }
    }
}

//Add Car Modal Brand
const AddCarModalBrand = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/vehicle-brand-models/create`, data, config)
            dispatch({ type: ADD_NEW_BRAND_CAR_MODAL, payload: res.data })
            console.log(res);
            if (res.status === 201) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: ADD_NEW_BRAND_CAR_MODAL, payload: e.res })
            console.log(e)
        }
    }
}
//Delet Car Modal Brand
const DeleteCarModalBrand = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/vehicle-brand-models/${data.id}`, config)
            dispatch({ type: DELETE_BRAND_CAR_MODAL, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_BRAND_CAR_MODAL, payload: e.res })
            if(e.response.status===404){
                ErrorMsg(e.response.data.message)
            }
            console.log(e)
        }
    }
}
//Update Car Modal
const UpdateCarModal = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/vehicle-brand-models/{id}`, data, config)
            dispatch({ type: UPDATE_BRAND_CAR_MODAL, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_BRAND_CAR_MODAL, payload: e.res })
            console.log(e)
        }
    }
}


//Get All Brands

const GetAllBrands = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/vehicle-brands`, config)
            dispatch({ type: GET_ALL_BRANDS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_BRANDS, payload: e.res })
            console.log(e)
        }
    }
}
//AddNewBrand

const AddNewBrandData = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/vehicle-brands/create`, data, config)
            dispatch({ type: ADD_NEW_BRAND, payload: res.data })
            console.log(res);
            if (res.status === 201) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: ADD_NEW_BRAND, payload: e.res })
            console.log(e)
        }
    }
}
const GetBrandsDetails = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if(data.searchWord ===""){
                let res = await baseUrl.get(`v1/vehicle-brands?page=${data.pageCount}&limit=10&sortBy=created_at%3D${data.sort}&isDeleted=false`, config)
                dispatch({ type: GET_ALL_BRANDS_DETAILS, payload: res.data })
                console.log(res);
                if (res.status === 201) {
                    success(res.data.message)
                }
            }else{
                let res = await baseUrl.get(`v1/vehicle-brands?page=${data.pageCount}&limit=10&filters=name_ar%3D${data.searchWord}&sortBy=created_at%3D${data.sort}&isDeleted=false`, config)
                dispatch({ type: GET_ALL_BRANDS_DETAILS, payload: res.data })
                console.log(res);
                if (res.status === 201) {
                    success(res.data.message)
                }
            }
        } catch (e) {
            dispatch({ type: GET_ALL_BRANDS_DETAILS, payload: e.res })
            console.log(e)
        }
    }
}


const DeleteBrandData = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/vehicle-brands/${data.id}`, config)
            dispatch({ type: DELETE_BRAND, payload: res.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_BRAND, payload: e.res })
            if(e.response.status===404){
                ErrorMsg(e.response.data.message)
            }
            console.log(e)
        }
    }
}

const UpdateBrandData = (data , id) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/vehicle-brands/edit/${id}`,data, config)
            dispatch({ type: UPDATE_BRAND, payload: res.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_BRAND, payload: e.res })
            if(e.response.status===404){
                ErrorMsg(e.response.data.message)
            }
            console.log(e)
        }
    }
}



export { GetAllCars, GetAllBrands, AddNewBrandData, AddCarModalBrand, DeleteCarModalBrand, UpdateCarModal, FilterCar, FilterCarASCDESC  , GetBrandsDetails , DeleteBrandData , UpdateBrandData}