import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { IoStar } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import { ChangeActive, GetAllReviews } from '../../redux/actions/RateAction';
import ReactStars from 'react-rating-stars-component';
import Pagination from '../../Utils/Pagination';
import DeleteReview from '../Modals/DeleteReview';
import baseUrl from '../../Api/baseURL';
const Rate = () => {
    //Default Data Search
    const [searchValue, setSearchValue] = useState('');
    const [rateData, setRateData] = useState(0);
    const [reviewsData, setReviewsData] = useState([]);
    const [sort, setSort] = useState("DESC");
    const [currentPage, setCurrentPage] = useState(1);
    const [rate, setRate] = useState("");


    const handleSearch = async () => {
        if (searchValue.trim() !== '') {
            setSearchValue(searchValue);
        }
        try {
            setCurrentPage(1)
            await getAllReviews(sort, currentPage, searchValue, rate)
        } catch (e) {

        }
    };

    const [showMessageData, setShowMessageData] = useState(false);


    //Get Reviews
    const dispatch = useDispatch();
    const getAllReviews = async (sort, currentPage, searchValue, rate) => {
        await dispatch(GetAllReviews({
            sort: sort,
            pageCount: currentPage,
            searchValue: searchValue,
            rate: rate
        }))
    }

    useEffect(() => {
        getAllReviews(sort, currentPage, searchValue, rate);
    }, [dispatch, sort, currentPage, rate])

    const reviews = useSelector(state => state.RateReducer.reviews);
    useEffect(() => {
        if (reviews) {
            setReviewsData(reviews.data || []);
        }
    }, [reviews]);


    const updateActiveRate = async (active, id) => {
        try {
            await dispatch(ChangeActive({
                active: !active,
                id: id
            }))
            setReviewsData(prevState => prevState.map(review => {
                if (review.id === id) {
                    return { ...review, is_active: !active };
                }
                return review;
            }));
        } catch (e) {
            console.log(e);
        } finally {

        }
    }


    const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
    const handleToggleMessage = (index) => {
        setSelectedMessageIndex(index === selectedMessageIndex ? null : index);
    };


    // if (reviews) {
    //     console.log(reviews)
    // }


    const getPage = (page) => {
        setCurrentPage(page);
        getAllReviews(sort, page, searchValue, rate);
    };


    const deleteData = async () => {
        try {

        } catch (e) {
            console.log(e);
        } finally {

            await dispatch(GetAllReviews({
                sort: sort,
                pageCount: currentPage,
                searchValue: searchValue,
                rate: rate
            }))
        }
    }




    const [subscriptionDataMap, setSubscriptionDataMap] = useState({});



    useEffect(() => {
        const getSubscriptionData = async (id) => {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Accept-Language": 'ar'
                },
            }
            try {
                const response = await baseUrl.get(`v1/subscription/${id}`, config);
                // Update subscription data map with subscription ID as key
                setSubscriptionDataMap(prevState => ({
                    ...prevState,
                    [id]: response.data
                }));
            } catch (error) {
                console.error('There was a problem with your fetch operation:', error);
            }
        };

        // Iterate over each review and fetch subscription data
        if (reviewsData) {
            reviewsData.forEach(review => {
                getSubscriptionData(review.order.subscription_id);
            });
        }
    }, [reviewsData]);



    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة التقيميات</p>
                </div>
            </div>
            <div className='font w-75'>
                <div className='flexItem justify-content-start mt-4'>
                    <div className='mx-3 search-container' style={{ width: "40%" }}>
                        <input
                            type="search"
                            className='form-control search-input'
                            name="search"
                            id="search"
                            placeholder='ابحث عن.....'
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <FaSearch className="search-icon" onClick={handleSearch} />
                    </div>
                    <div className='mx-3' style={{ width: "25%" }}>
                        <Form.Select aria-label="Default select example" onChange={(e) => { setRate(e.target.value) }}>
                            <option hidden> نجوم</option>
                            <option value="">عرض كل التقييمات</option>
                            <option value="1"> 1 نجوم</option>
                            <option value="2"> 2 نجوم</option>
                            <option value="3"> 3 نجوم</option>
                            <option value="4"> 4 نجوم</option>
                            <option value="5"> 5 نجوم</option>
                        </Form.Select>
                    </div>
                    <div className='mx-3' style={{ width: "25%" }}>
                        <Form.Select aria-label="Default select example" onChange={(e) => { setSort(e.target.value) }}>
                            <option hidden>الترتيب تنازلي</option>
                            <option value="DESC">تنازلي</option>
                            <option value="ASC">تصاعدي</option>
                        </Form.Select>
                    </div>
                </div>
            </div>

            {/* Table */}
            <div className='mt-3 font'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>رقم الطلب</th>
                            <th>الباقة/نوع الطلب</th>
                            <th>اسم المستخدم</th>
                            <th>رقم الجوال</th>
                            <th style={{ textAlign: "center" }}>التقييم</th>
                            <th>نص التعليق</th>
                            <th>تاريخ التقييم</th>
                            {/* <th colSpan={2}>اخفاء التعليق/حذف</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reviewsData ? (
                                reviewsData.map((review, index) => {
                                    return (
                                        <tr key={review.id}>
                                            <td>{index + 1}</td>
                                            <td>{
                                                review.order ? (
                                                    review.order.number
                                                ) : null

                                            }</td>
                                            <td>{subscriptionDataMap[review.order.subscription_id]?.name}</td>

                                            <td>
                                                {review.order.customer.user.first_name} {review.order.customer.user.last_name}</td>
                                            <td>{review.order.customer.user.phone}</td>
                                            <td>
                                                <ReactStars
                                                    value={review.rate}
                                                    edit={false}
                                                />
                                            </td>
                                            <td className='showMessage' onClick={() => handleToggleMessage(index)}>
                                                <span>{selectedMessageIndex === index ? <span style={{ color: "red" }}>اغلاق الرساله</span> : 'عرض الرسالة'}</span>
                                                {selectedMessageIndex === index && (
                                                    <div className='infoShowMessage'>
                                                        <small>{review.comment}</small>
                                                    </div>
                                                )}
                                            </td>
                                            <td>{review.created_at}</td>
                                            {/* <td className='d-flex justify-content-between align-items-center'>
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        checked={review.is_active}
                                                        onChange={() => { updateActiveRate(review.is_active, review.id) }}
                                                    />
                                                </Form>
                                                <DeleteReview idData={review.id} searchValue={searchValue} rate={rate} sort={sort} page={currentPage} />
                                            </td> */}
                                        </tr>
                                    )
                                })
                            ) : (<tr>
                                <td colSpan={"10"}></td>
                            </tr>)
                        }

                    </tbody>
                </Table>

                {reviews && reviews.meta && (
                    <Pagination onPress={getPage} countsPage={reviews.meta.total / 10} />
                )}
            </div>
        </div>
    )
}

export default Rate
