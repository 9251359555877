import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import PhoneInput from "react-phone-input-2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ListsSendGifts from './ListsSendGifts';
import GiftSetting from './GifftSettings'
import { useState } from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { ErrorMsg, success } from '../../Utils/Toast'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { GetAllBaqa } from '../../redux/actions/BaqaAction';
import { GetAllServices } from '../../redux/actions/ServicesAction';
import { AddNewGift, GetAllGifts } from '../../redux/actions/GiftsActions';
const GiftComponent = () => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [washCount, setWashCount] = useState("");
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);

    const giftType = (e) => {
        const selectedId = e.target.value;
        const selectedBaqa = dataBaqat.data.find(baqa => baqa.id === selectedId);
        if (selectedBaqa) {
            console.log(selectedBaqa)
            setTypeGift(selectedId);
            setWashCount(selectedBaqa.wash_count)
        }
    }

    const handleServiceChange = (e) => {
        const serviceId = e.target.value;
        setService(e.target.value);
        // console.log(e.target.value)
        if (!selectedServices.includes(serviceId)) {
            setSelectedServices([...selectedServices, serviceId]);
        }
    };


    const handleRemoveService = (serviceIdToRemove) => {
        const updatedServices = selectedServices.filter(serviceId => serviceId !== serviceIdToRemove);
        setSelectedServices(updatedServices);
    };



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [phoneNumber, setPhoneNumber] = useState('');
    const handleOnChange = (value, country, e, formattedValue) => {

        setPhoneNumber(value);
    };


    const [typeGift, setTypeGift] = useState('');
    const [service, setService] = useState('');
    const [textMessage, setTextMessage] = useState('');



    const [error, setError] = useState('');
    const handlePhoneNumberChange = (value, country, e, formattedValue) => {
        // التحقق من أن الرقم يبدأ بـ "9665" ويتكون من 8 أرقام
        if (/^9665\d{8}$/.test(value)) {
            setPhoneNumber(value);
            setError('');
        } else {
            setError('رقم الهاتف غير صحيح، يرجى إدخال رقم هاتف صحيح يبدأ بـ "9665" ويتكون من 8 أرقام.');
        }
    };



    const dispatch = useDispatch();
    const fetchAllBaqa = async () => {
        await dispatch(GetAllBaqa());
    }
    const getServices = async () => {
        await dispatch(GetAllServices());
    }


    useEffect(() => {
        fetchAllBaqa();
        getServices();
    }, [])


    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);
    const resultServices = useSelector(state => state.ServicesReducer.allServices)

    useEffect(() => {
        if (resultServices && resultServices.data) {
            setServices(resultServices.data);
        }
    }, [resultServices]);


    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }


    const addNewGiftData = async () => {
        setLoading(true);
        try {
            setLoading(true);
            if (error === "" && typeGift !== "") {
                await dispatch(AddNewGift({
                    package_id: typeGift,
                    user_id: adminLogIn.id,
                    services: selectedServices,
                    receiver_phone_number: `+${phoneNumber}`,
                    message: textMessage
                }))
            } else {
                ErrorMsg('فقد في البيانات')
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            if (error === "" && typeGift !== "") {
                handleClose();
                setTypeGift('');
                setService('');
                setTextMessage('');
                setPhoneNumber('');
                setSelectedServices([]);
            }
            dispatch(GetAllGifts({ pageCount: 1, sort: "DESC", baqa: "", phone: "" }));
        }
    }

    return (
        <div className=''>
            <div className='flexItem font py-3'>
                <div>
                    <p>الهدايا</p>
                </div>
                <div>
                    <button className='btn' onClick={handleShow} style={{ color: "black", background: "#FECB44" }}>اضافة هدية جديدة</button>
                </div>
            </div>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 tabContainer"
            >
                <Tab eventKey="home" className='font' title="قائمة الهدايا المرسلة ">
                    <ListsSendGifts />
                </Tab>
                <Tab eventKey="profile" className='font' title="اعدادات قالب الهدية">
                    <GiftSetting />
                </Tab>
            </Tabs>



            {/* Modal For Add Gift */}


            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة هدية جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='mt-4'>
                        <PhoneInput
                            country={'sa'}
                            countryCodeEditable={false}
                            disableDropdown={true}
                            value={phoneNumber}
                            onChange={handlePhoneNumberChange}
                        />
                        {error && <small style={{ color: 'red' }}>{error}</small>}
                    </div>

                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" onChange={giftType} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>نوع الهديه</option>
                            {
                                dataBaqat && dataBaqat.data ? (

                                    dataBaqat.data.map((baqa, index) => {
                                        return (
                                            <option value={baqa.id} key={baqa.id}>{baqa.name}</option>
                                        )
                                    })
                                ) : (null)
                            }
                        </Form.Select>
                    </div>
                    {
                        washCount === 1 ? (
                            <div className='mt-4'>
                                <Form.Select aria-label="Default select example" onChange={handleServiceChange} style={{ background: "#EFF1F9" }}>
                                    <option value="" hidden>الخدمات الاضافيه</option>
                                    {
                                        services ? (
                                            services.map((service, index) => {
                                                return (
                                                    <option value={service.id} key={service.id}>{service.name}</option>
                                                )
                                            })
                                        ) : (null)
                                    }
                                </Form.Select>
                            </div>
                        ) : null
                    }

                    {selectedServices.length > 0 && (
                        <div className="mt-4 d-flex">
                            {selectedServices.map((serviceId, index) => (
                                <p className='mx-1' key={index} style={{ background: "#FFFAEC", padding: "10px" }}>
                                    {services.find(service => service.id === serviceId).name}
                                    <IoCloseSharp onClick={() => handleRemoveService(serviceId)} />
                                </p>
                            ))}
                        </div>
                    )}
                    <div className='mt-4 w-100' onChange={(e) => setTextMessage(e.target.value)}>
                        <textarea cols={"57"} rows={"2"} className='textMessage w1-00' placeholder='نص الرساله' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}>

                        </textarea>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={addNewGiftData}>
                        ارسال
                    </button>
                    <button className='btn' onClick={handleClose} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}  >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>


            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }
        </div>
    )
}

export default GiftComponent





