import React, { useEffect, useState } from 'react'
import { FaPencilAlt } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux'
import { SettingGift, UpdateSettingGift } from '../../redux/actions/GiftsActions';
import Modal from 'react-bootstrap/Modal';
import { ErrorMsg } from '../../Utils/Toast';
const GiftSttings = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);

  useEffect(() => {
    fetchAllSettings();
  }, [])

  const fetchAllSettings = async () => {
    await dispatch(SettingGift());
  }

  const settings = useSelector(state => state.GiftReducer.settingGift);

  const [validTitleEn, setValidTitleEn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validTitleAr, setValidTitleAr] = useState(false);
  const [validMessageEn, setValidMessageEn] = useState(false);
  const [validMessageAr, setValidMessageAr] = useState(false);
  const [titleAr, setTitleAr] = useState('');
  const [titleEN, setTitleEn] = useState('');
  const [messageAr, setMessageAr] = useState('');
  const [messageEn, setMessageEn] = useState('');


  useEffect(() => {
    if (settings && settings.data) {
      setTitleAr(settings.data.title_ar);
      setTitleEn(settings.data.title_en);
      setMessageAr(settings.data.message_ar);
      setMessageEn(settings.data.message_en);
    }
  }, [settings])





  const updateNotify = async () => {
    setLoading(true);
    try {
      if (validTitleEn === false &&
        validTitleAr === false &&
        validMessageEn === false &&
        validMessageAr === false) {
        await dispatch(UpdateSettingGift({
          titleAr,
          titleEN,
          messageAr,
          messageEn,
        }))
      } else {
        ErrorMsg('بيانات خاطئه');
      }

    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false);
      if (validTitleEn === false &&
        validTitleAr === false &&
        validMessageEn === false &&
        validMessageAr === false) {
        handleCloseEdit();
      }
    }
  }

  return (
    <div>
      <div className='font notify'>
        <p className='fs-5'>قالب الرسالة</p>
      </div>
      <div className='mt-3 font notify'>
        <div className='penTag' style={{ cursor: "pointer" }}><FaPencilAlt onClick={handleShowEdit} /></div>
        <div>
          <p className='fs-5'>عنوان الرسالة يظهر هنا</p>
          <div style={{ color: "#A0AEC0" }}>
            {
              settings.data ? (
                <div>
                  <p> {settings.data.message_ar} </p>
                  <p>  {settings.data.message_en} </p>
                </div>
              ) : ('لايوجد رساله ')
            }
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
        <Modal.Header>
          <Modal.Title>تعديل اشعار الهديه  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mt-3'>
            <input type="text" className='w-100' value={titleAr} onChange={(e) => {
              setTitleAr(e.target.value);
              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                setValidTitleAr(true)
              } else {
                setValidTitleAr(false);
              }
            }} placeholder='العنوان  بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
          </div>
          {
            validTitleAr === true ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
          }
          <div className='mt-3'>
            <input type="text" className='w-100' value={titleEN} onChange={(e) => {
              setTitleEn(e.target.value);
              if (/[\u0600-\u06FF]/.test(e.target.value)) {
                setValidTitleEn(true)
              } else {
                setValidTitleEn(false);
              }
            }} placeholder='العنوان  بالانجليزيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
          </div>
          {
            validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
          }
          {/* Messages*/}
          <div className='mt-3'>
            <input type="text" className='w-100' value={messageAr} onChange={(e) => {
              setMessageAr(e.target.value);
              if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                setValidMessageAr(true)
              } else {
                setValidMessageAr(false);
              }
            }} placeholder='نص الرساله  بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
          </div>
          {
            validMessageAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
          }
          <div className='mt-3'>
            <input type="text" className='w-100' value={messageEn} onChange={(e) => {
              setMessageEn(e.target.value);
              if (/[\u0600-\u06FF]/.test(e.target.value)) {
                setValidMessageEn(true)
              } else {
                setValidMessageEn(false);
              }
            }} placeholder='نص الرساله  بالانجليزيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
          </div>
          {
            validMessageEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
          }
        </Modal.Body>
        <Modal.Footer className='m-auto w-100'>

          <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateNotify}>
            اضافه
          </button>
          <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleCloseEdit}>
            تراجع
          </button>
        </Modal.Footer>
      </Modal>
      {/* Modal */}
      {
        loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <span className="loader"></span>
        </div>) : null
      }
    </div>
  )
}

export default GiftSttings
