import React from 'react'
import { Col, Row } from "react-bootstrap";
import AdminSideBar from '../../Components/DashboardComponents/AdminSideBar';
import NavBarDashboard from '../../Components/DashboardComponents/NavBarDashboard';
import Cars from '../../Components/CarsComponents/Cars';
import ManageCars from '../../Components/CarsComponents/ManageCars';
const CarsDetailsPage = () => {
    return (
        <div>
            <Row className="py-3">
                <Col sm="5" xs="3" md="2" lg="2">
                    <AdminSideBar />
                </Col>
                <Col sm="12" xs="9" className='px-5' md="12" lg="10">
                    <NavBarDashboard />
                    <ManageCars />
                </Col>
            </Row>
        </div>
    )
}

export default CarsDetailsPage
