import { ADD_NEW_BRAND, ADD_NEW_BRAND_CAR_MODAL, DELETE_BRAND_CAR_MODAL, GET_ALL_BRANDS, GET_ALL_CARS, UPDATE_BRAND_CAR_MODAL , GET_ALL_BRANDS_DETAILS , DELETE_BRAND , UPDATE_BRAND } from "../Type";
const initial = {
    ModalCars: [],
    BrandsCars: [],
    AddBrand: [],
    BrandCarModal: [],
    DeleteBrandCarModal: [],
    UpdateCarModalData: [],
    AllBrandsDetails: [],
    DeleteBrand:[],
    UpdateBrand:[],
    loading: true,
}

const CarReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_CARS:
            return {
                ...state,
                ModalCars: action.payload,
                loading: false
            }
        case GET_ALL_BRANDS:
            return {
                ...state,
                BrandsCars: action.payload,
                loading: false
            }
        case ADD_NEW_BRAND:
            return {
                ...state,
                AddBrand: action.payload,
                loading: false
            }
        case ADD_NEW_BRAND_CAR_MODAL:
            return {
                ...state,
                BrandCarModal: action.payload,
                loading: false
            }
        case DELETE_BRAND_CAR_MODAL:
            return {
                ...state,
                DeleteBrandCarModal: action.payload,
                loading: false
            }
        case UPDATE_BRAND_CAR_MODAL:
            return {
                ...state,
                UpdateCarModalData: action.payload,
                loading: false
            }
        case GET_ALL_BRANDS_DETAILS:
            return {
                ...state,
                AllBrandsDetails: action.payload,
                loading: false
            }
        case DELETE_BRAND:
            return {
                ...state,
                DeleteBrand: action.payload,
                loading: false
            }
        case UPDATE_BRAND:
            return {
                ...state,
                UpdateBrand: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default CarReducer