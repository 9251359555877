import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SpecificUserPoint } from '../../redux/actions/PointsAction';

const AllEarnPoints = () => {
    const dispatch = useDispatch();
    let params = useParams();

    console.log(params.id)

    useEffect(() => {
        dispatch(SpecificUserPoint({
            id: params.id
        }))
    }, [dispatch]);


    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [points, setPoints] = useState('');





    const getPointData = useSelector(state => state.PointsReducer.specificPoint);



    useEffect(()=>{
        if(getPointData && getPointData.data){
            setName(getPointData.data[0].user.name)
            setPhone(getPointData.data[0].user.phone)
            setPoints(getPointData.data[0].user.total_points)
        }
    } , [getPointData])

    return (
        <div className='font'>
            <div className='mt-3'>
                <p><span style={{ color: "#BDBDBD" }}>ادارة النقاط &gt;</span>عرض جميع نقاط المستخدمين</p>
            </div>

            {/* Div All Data */}
            <div className='mt-4 infoData' style={{ background: "white" }}>
                <div>
                    <label>الاسم الاول : </label>
                    <input type='text' className='w-100 mt-2 textInputBiker' value={name} readOnly/>
                </div>
                <div className='mt-3 d-flex justify-content-between align-items-center'>
                    <div className='mt-1' style={{ width: "45%" }}>
                        <label>رقم الجوال : </label>
                        <input type='text' className='w-100 mt-2 textInputBiker' value={phone} readOnly/>
                    </div>
                    <div className='mt-1' style={{ width: "45%" }}>
                        <label>اجمالي النقاط المكتسبه : </label>
                        <input type='number' className='w-100 mt-2 textInputBiker' value={points} readOnly/>
                    </div>
                </div>
            </div>
            {/* Div All Data */}


            {/* Earn Points */}
            <div className='bikerEditTop mt-4'>
                اجمالي النقاط المكتسبه
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>النقاط المكتسبة</th>
                            <th>نوع الغسلة</th>
                            <th>تاريخ الغسلة</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getPointData && getPointData.data ? (
                                getPointData.data.map((dataPoint, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{dataPoint.points} نقطه</td>
                                            <td>{dataPoint.type}</td>
                                            <td>{dataPoint.created_at}</td>
                                        </tr>
                                    )
                                })
                            ) : null
                        }
                    </tbody>
                </Table>
            </div>
            {/* Earn Points */}
        </div>
    )
}

export default AllEarnPoints
