import React, { useEffect, useState } from 'react'
import { FaPencilAlt } from "react-icons/fa";
import SettingGracePeriod from '../../Modals/SettingGracePeriod';
import SettingMessage from '../../Modals/SettingMessage';
import { useDispatch, useSelector } from 'react-redux'
import { GetNotifications, GetNotificationsBaqa, GetNotificationsBaqaEnd, UpdateActiveNotifications } from '../../../redux/actions/Notifications';
import Form from 'react-bootstrap/Form';
import { GetConstantsApp } from '../../../redux/actions/ConstantsApp';
const SettingBooking = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleNotify = async () => {
        try {
            setLoading(true)
            await dispatch(GetNotifications())
            await dispatch(GetNotificationsBaqa())
            await dispatch(GetConstantsApp())
            await dispatch(GetNotificationsBaqaEnd())
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        handleNotify();
    }, [])


    const notifyMessage = useSelector(state => state.NotifyReducer.Notify)
    const notifyMessagebaqa = useSelector(state => state.NotifyReducer.NotifyBaqa)
    const notifyMessagebaqaEnd = useSelector(state => state.NotifyReducer.NotifyBaqaEnd)
    const AppConstants = useSelector(state => state.ConstantsApp.Constants)



    // console.log(notifyMessagebaqaEnd);



    const handleSwitchChange = async (typeNotify, activeNotify) => {
        setLoading(true);
        await dispatch(UpdateActiveNotifications({
            type: typeNotify,
            active: !activeNotify
        }))
        dispatch(GetNotifications());
        dispatch(GetNotificationsBaqa());
        await dispatch(GetNotificationsBaqaEnd())
    }


    return (
        <div>
            {
                notifyMessage && notifyMessage.data ? (
                    <div className='font notify'>
                        <div className='penTag d-flex justify-content-between align-items-center' style={{ width: "10%" }}>
                            <div>
                                <SettingGracePeriod type={notifyMessage.data.type}
                                    notifyMessageAr={notifyMessage.data.message_ar}
                                    notifyTitleAr={notifyMessage.data.title_ar}
                                    notifyMessageEn={notifyMessage.data.message_en}
                                    notifyTitleEn={notifyMessage.data.title_en}
                                    title="ضبط نص الاشعار اعادة الجدولة" />
                            </div>
                            <div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch`}
                                        checked={notifyMessage.data.is_active}
                                        onClick={() => handleSwitchChange(notifyMessage.data.type, notifyMessage.data.is_active)}
                                    />
                                </Form>
                            </div>
                        </div>
                        <div>
                            <p className='fs-5'>ضبط نص الاشعار اعادة الجدولة</p>
                            <span style={{ color: "#A0AEC0" }}>هي الرسالة التي يتم ارسالها للمستخدمين عند اعادة جدولة طلب محدد مسبقاً بسبب مشكلة تقنية أو حادث حدث للبايكر اثناء زيارة العميل - أو جدولة عامة</span>
                        </div>
                        <hr />
                        <div>

                            <div>
                                <h4>{notifyMessage.data.title_ar}</h4>
                                <span style={{ color: "#A0AEC0" }}>{notifyMessage.data.message_ar}</span>
                            </div>

                        </div>
                    </div>
                ) : null

            }
            {
                notifyMessagebaqa && notifyMessagebaqa.data ? (
                    <div className='mt-3 font notify'>

                        <div className='penTag d-flex justify-content-between align-items-center' style={{ width: "10%" }}>
                            <div>
                                <SettingGracePeriod type={notifyMessagebaqa.data.type}
                                    notifyMessageAr={notifyMessagebaqa.data.message_ar}
                                    notifyTitleAr={notifyMessagebaqa.data.title_ar}
                                    notifyMessageEn={notifyMessagebaqa.data.message_en}
                                    notifyTitleEn={notifyMessagebaqa.data.title_en}
                                    title="ضبط نص رسالة تمديد صلاحية الباقة ( الاشتراك )" />
                            </div>
                            <div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch`}
                                        checked={notifyMessagebaqa.data.is_active}
                                        onClick={() => handleSwitchChange(notifyMessagebaqa.data.type, notifyMessagebaqa.data.is_active)}
                                    />
                                </Form>
                            </div>
                        </div>
                        <div>
                            <p className='fs-5'>ضبط نص رسالة تمديد صلاحية الباقة ( الاشتراك )</p>
                            <span style={{ color: "#A0AEC0" }}>هي الرسالة التي يتم ارسالها للمستخدمين عند اتمديد صلاحية الباقة ( الاشتراك )</span>
                        </div>
                        <hr />
                        <div>
                            {
                                notifyMessagebaqa && notifyMessagebaqa.data ? (
                                    <div>
                                        <h4>{notifyMessagebaqa.data.title_ar}</h4>
                                        <span style={{ color: "#A0AEC0" }}>{notifyMessagebaqa.data.message_ar}</span>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                ) : null

            }

{
                notifyMessagebaqaEnd && notifyMessagebaqaEnd.data ? (
                    <div className='mt-3 font notify'>

                        <div className='penTag d-flex justify-content-between align-items-center' style={{ width: "10%" }}>
                            <div>
                                <SettingGracePeriod type={notifyMessagebaqaEnd.data.type}
                                    notifyMessageAr={notifyMessagebaqaEnd.data.message_ar}
                                    notifyTitleAr={notifyMessagebaqaEnd.data.title_ar}
                                    notifyMessageEn={notifyMessagebaqaEnd.data.message_en}
                                    notifyTitleEn={notifyMessagebaqaEnd.data.title_en}
                                    title="ضبط نص رسالة انتهاء صلاحية الباقة ( الاشتراك )" />
                            </div>
                            <div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id={`custom-switch`}
                                        checked={notifyMessagebaqaEnd.data.is_active}
                                        onClick={() => handleSwitchChange(notifyMessagebaqaEnd.data.type, notifyMessagebaqaEnd.data.is_active)}
                                    />
                                </Form>
                            </div>
                        </div>
                        <div>
                            <p className='fs-5'>ضبط نص رسالة انتهاء صلاحية الباقة ( الاشتراك )</p>
                            <span style={{ color: "#A0AEC0" }}>هي الرسالة التي يتم ارسالها للمستخدمين عند انتهاء صلاحية الباقة ( الاشتراك )</span>
                        </div>
                        <hr />
                        <div>
                            {
                                notifyMessagebaqaEnd && notifyMessagebaqaEnd.data ? (
                                    <div>
                                        <h4>{notifyMessagebaqaEnd.data.title_ar}</h4>
                                        <span style={{ color: "#A0AEC0" }}>{notifyMessagebaqaEnd.data.message_ar}</span>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                ) : null

            }


            {
                AppConstants && AppConstants.data ? (
                    <div className='mt-3 font notify'>
                        <div className='penTag'>
                            <SettingMessage />
                        </div>
                        <div>
                            <p className='fs-5'>ضبط المدة المحددة للسماح بإعادة الجدولة للغسلة </p>
                            <span style={{ color: "#A0AEC0" }}>وهي عدد الايام التي يستطيع المستخدم الاختيار فيما بينهم لحجز موعد غسلة جديدة - وايضاً هي عدد الايام التي تظهر للبايكر في قائمة المهام الخاثة به.</span>
                        </div>
                        <hr />
                        <div>
                            <p className='fs-5'>{AppConstants.data.reschadule_time} <span style={{ color: "#A0AEC0" }}>ساعه</span></p>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default SettingBooking
