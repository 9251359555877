
// import React, { useEffect, useState, useRef } from 'react';
// import { GoogleMap, LoadScript, Marker, Polyline, Circle } from '@react-google-maps/api';
// import { useDispatch, useSelector } from 'react-redux';
// import { GetAllAreas } from '../redux/actions/AreaActions';

// const MapEdit = ({ onLocationSelect, onRadiusChange, radiusData }) => {
//     const dispatch = useDispatch();
//     const mapRef = useRef(null);

//     const [radius, setRadius] = useState(radiusData)
//     const [selectedLocation, setSelectedLocation] = useState(null);
//     const [circleRadius, setCircleRadius] = useState(1000);

//     const getAreas = async () => {
//         await dispatch(GetAllAreas());
//     }

//     useEffect(() => {
//         getAreas();
//     }, [])

//     const resAreas = useSelector(state => state.AreaReducer.AllAreas);

//     let map;

//     const [curvedPath, setCurvedPath] = useState([]);

//     const [circleOptions, setCircleOptions] = useState({
//         strokeColor: "#FF0000",
//         strokeOpacity: 0.8,
//         strokeWeight: 2,
//         fillColor: "#FFFFFF",
//         fillOpacity: 0.35,
//         radius: 1000,
//     });

//     const onMapClick = (e) => {
//         const location = { lat: e.latLng.lat(), lng: e.latLng.lng() }
//         setSelectedLocation(location);
//         onLocationSelect(location);
//     };

//     const initMap = () => {
//         if (resAreas && resAreas.data) {
//             mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
//                 zoom: 10,
//                 center: { lat: 24.7136, lng: 46.6753 },
//             });



//             mapRef.current.addListener('click', onMapClick);

//             new window.google.maps.Polyline({
//                 path: curvedPath,
//                 geodesic: true,
//                 strokeColor: '#FF0000',
//                 strokeOpacity: 0.8,
//                 strokeWeight: 2,
//                 map: mapRef.current,
//             });

//             resAreas.data && resAreas.data.forEach((area, index) => {
//                 new window.google.maps.Marker({
//                     position: { lat: area.latitude, lng: area.longitude },
//                     title: `${area.name}`,
//                     map: mapRef.current,
//                 });

//                 const circleOptions = {
//                     strokeColor: "#FF0000",
//                     strokeOpacity: 0.8,
//                     strokeWeight: 2,
//                     fillColor: "#FFFFFF",
//                     fillOpacity: 0.35,
//                     radius: area.range * 1000,
//                 };


//                 new window.google.maps.Circle({
//                     center: { lat: area.latitude, lng: area.longitude },
//                     ...circleOptions,
//                     map: mapRef.current,
//                 });
//                 const bikerLocation = { lat: area.latitude, lng: area.longitude };
//                 mapRef.current.panTo(bikerLocation);
//             });
//         }
//     };

//     const startMap = () => {
//         map = new window.google.maps.Map(document.getElementById('map'), {
//             zoom: 10,
//             center: { lat: 24.7136, lng: 46.6753 },
//             gestureHandling: "none"
//         });

//         map.addListener('click', onMapClick);
//     }

//     useEffect(() => {
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
//         script.async = true;
//         script.defer = true;
//         script.onload = startMap;
//         document.head.appendChild(script);

//         return () => {
//             document.head.removeChild(script);
//         };
//     }, []);

//     useEffect(() => {
//         if (resAreas && resAreas.data) {
//             const newPath = resAreas.data.map(area => ({ lat: area.latitude, lng: area.longitude }));
//             setCurvedPath(newPath);
//         }
//     }, [resAreas]);






//     const [circleInstance, setCircleInstance] = useState(null);



//     // useEffect(() => {
//     //     if (selectedLocation) {
//     //         new window.google.maps.Marker({
//     //             position: selectedLocation,
//     //             map: mapRef.current,
//     //             title: 'Selected Location'
//     //         });
//     //         if (circleInstance) {
//     //             circleInstance.setMap(null);
//     //         }
//     //         const newCircle = new window.google.maps.Circle({
//     //             center: selectedLocation,
//     //             radius: parseInt(radius) * 1000,
//     //             map: mapRef.current,
//     //         });
//     //         setCircleInstance(newCircle);
//     //     }
//     // }, [selectedLocation, radius]);

//     useEffect(() => {
//         if (selectedLocation) {
//             new window.google.maps.Marker({
//                 position: selectedLocation,
//                 map: mapRef.current,
//                 title: 'Selected Location'
//             });

//             // إذا كانت هناك دائرة سابقة، أزلها
//             if (circleInstance) {
//                 circleInstance.setMap(null);
//             }

//             // إنشاء دائرة جديدة
//             const newCircle = new window.google.maps.Circle({
//                 center: selectedLocation,
//                 radius: parseInt(radius) * 1000,
//                 map: mapRef.current,
//                 editable: true, // تمكين تحرير الدائرة
//             });

//             // تعيين الدائرة الجديدة كدائرة حالية
//             setCircleInstance(newCircle);

//             // تحديث حجم الدائرة عند تغييرها
//             newCircle.addListener('radius_changed', () => {
//                 const newRadius = newCircle.getRadius() / 1000; // تحويل النصف إلى كيلومتر
//                 setRadius(newRadius);
//             });

//             // تحديث موقع الدائرة عند تغييره
//             newCircle.addListener('center_changed', () => {
//                 const newCenter = newCircle.getCenter();
//                 const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
//                 setSelectedLocation(newLocation);
//                 onLocationSelect(newLocation);
//             });
//         }
//     }, [selectedLocation]);

//     useEffect(() => {
//         if (typeof onRadiusChange === 'function') {
//             onRadiusChange(radius);
//         }
//     }, [radius]);

//     useEffect(() => {
//         setRadius(radiusData);
//     }, [radiusData])


//     return (
//         <div>
//             <button onClick={initMap} className='btn submitButton' style={{ width: "10%" }}>تحديث الخريطه</button>
//             <span>(قم بتحديث الخريطه لرؤية الاماكن الموجوده بالفعل)</span>


//             <div style={{ visibility: "hidden" }}>
//                 <label>قم بكتابة محيط المكان : </label>
//                 <input type="number" value={radiusData} onChange={(e) => setRadius(e.target.value)} placeholder='قم باختيار محيط الدائره' style={{ background: "white", padding: "10px", borderRadius: "10px", outline: "none", border: "1px solid " }} />
//             </div>
//             <div id="map" style={{ marginTop: "10px", height: '500px', width: '100%' }}>
//             </div>
//         </div>
//     );
// }

// export default MapEdit;









import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAreas } from '../redux/actions/AreaActions';

const MapEdit = ({ onLocationSelect, onRadiusChange, radiusData }) => {
    const dispatch = useDispatch();
    const mapRef = useRef(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [radius, setRadius] = useState(radiusData);
    const [circleInstance, setCircleInstance] = useState(null);
    const [markerInstance, setMarkerInstance] = useState(null);

    const getAreas = async () => {
        await dispatch(GetAllAreas());
    };

    useEffect(() => {
        getAreas();
    }, []);

    const resAreas = useSelector(state => state.AreaReducer.AllAreas);

    const onMapClick = (e) => {
        const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
        setSelectedLocation(location);
        onLocationSelect(location);
    };

    const initMap = () => {
        if (resAreas && resAreas.data) {
            mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 10,
                center: { lat: 24.7136, lng: 46.6753 },
            });

            mapRef.current.addListener('click', onMapClick);

            resAreas.data.forEach(area => {
                new window.google.maps.Marker({
                    position: { lat: area.latitude, lng: area.longitude },
                    title: area.name,
                    map: mapRef.current,
                });

                new window.google.maps.Circle({
                    center: { lat: area.latitude, lng: area.longitude },
                    radius: area.range * 1000,
                    map: mapRef.current,
                    strokeColor: "#FF0000",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: "#FFFFFF",
                    fillOpacity: 0.35,
                });
            });
        }
    };

    const startMap = () => {
        mapRef.current = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: { lat: 24.7136, lng: 46.6753 },
            gestureHandling: "none"
        });

        mapRef.current.addListener('click', onMapClick);
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
        script.async = true;
        script.defer = true;
        script.onload = startMap;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (resAreas && resAreas.data) {
            const newPath = resAreas.data.map(area => ({ lat: area.latitude, lng: area.longitude }));
            // setCurvedPath(newPath);
        }
    }, [resAreas]);

    useEffect(() => {
        if (selectedLocation) {
            // Remove previous marker and circle
            if (markerInstance) {
                markerInstance.setMap(null);
            }
            if (circleInstance) {
                circleInstance.setMap(null);
            }

            // Create new marker
            const newMarker = new window.google.maps.Marker({
                position: selectedLocation,
                map: mapRef.current,
                title: 'Selected Location'
            });
            setMarkerInstance(newMarker);

            // Create new circle
            const newCircle = new window.google.maps.Circle({
                center: selectedLocation,
                radius: parseInt(radius) * 1000,
                map: mapRef.current,
                editable: true,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#FFFFFF",
                fillOpacity: 0.35,
            });
            setCircleInstance(newCircle);

            // Update radius when changed
            newCircle.addListener('radius_changed', () => {
                const newRadius = newCircle.getRadius() / 1000; // Convert to kilometers
                setRadius(newRadius);
                if (typeof onRadiusChange === 'function') {
                    onRadiusChange(newRadius);
                }
            });

            // Update location when changed
            newCircle.addListener('center_changed', () => {
                const newCenter = newCircle.getCenter();
                const newLocation = { lat: newCenter.lat(), lng: newCenter.lng() };
                setSelectedLocation(newLocation);
                onLocationSelect(newLocation);
            });
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (typeof onRadiusChange === 'function') {
            onRadiusChange(radius);
        }
    }, [radius]);

    useEffect(() => {
        setRadius(radiusData);
    }, [radiusData]);

    return (
        <div>
            <button onClick={initMap} className='btn submitButton' style={{ width: "10%" }}>تحديث الخريطه</button>
            <span>(قم بتحديث الخريطه لرؤية الاماكن الموجوده بالفعل)</span>

            <div style={{ visibility: "hidden" }}>
                <label>قم بكتابة محيط المكان : </label>
                <input type="number" value={radiusData} onChange={(e) => setRadius(e.target.value)} placeholder='قم باختيار محيط الدائره' style={{ background: "white", padding: "10px", borderRadius: "10px", outline: "none", border: "1px solid " }} />
            </div>
            <div id="map" style={{ marginTop: "10px", height: '500px', width: '100%' }}>
            </div>
        </div>
    );
}

export default MapEdit;
