import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { CancelOrderReschudle, GetAllOrdersAdmin, GetSpesificOrder } from '../../redux/actions/OrdersAdmin';

const CancelOrder = ({ orderId, pagePag, pageCount, sort, status, ordersCount, searchKey, onSendData }) => {

    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);

    const cancelOrder = async () => {
        try {
            // console.log(orderId);
            setLoading(false);
            setLoader(true);
            onSendData(loader)

            await dispatch(CancelOrderReschudle({
                id: orderId,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(true);
            if (searchKey === "") {
                await dispatch(GetAllOrdersAdmin({
                    pageCount: pageCount,
                    sort: sort,
                    status: status,
                    ordersCount: parseInt(ordersCount)
                }));
            } else {
                await dispatch(GetSpesificOrder({
                    searchValue: searchKey,
                    sort,
                    pageCount: pageCount,
                    ordersCount: parseInt(ordersCount)
                }))
            }
            handleCloseEdit();
            setLoader(false);
            onSendData(false)

        }
    }
    return (
        <div>
            <button onClick={handleShowEdit} style={{ color: "#EB5757", background: "#EB575733" }} className='btn'>إلغاء الطلب</button>
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>الغاء الطلب  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد الغاء الطلب</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' onClick={cancelOrder} style={{ width: "45%", background: "#FECB44", color: "black" }}>
                        تاكيد الالغاء
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

            {
                loader ? (
                    <div className="loader-overlay">
                        <span className="loader"></span>
                    </div>
                ) : null
            }
        </div>
    )
}

export default CancelOrder
