// import React, { useEffect } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// const LocationStart = ({ onCoordinatesChange }) => {
//     let map;
//     let marker = null;

//     const initMap = () => {
//         map = new window.google.maps.Map(document.getElementById('map'), {
//             zoom: 10,
//             center: { lat: 24.7136, lng: 46.6753 },
//         });

//         map.addListener('click', (event) => {
//             const clickedLat = event.latLng.lat();
//             const clickedLng = event.latLng.lng();

//             // Remove existing marker if present
//             if (marker) {
//                 marker.setMap(null); // Remove from map
//             }

//             // Create new marker at the clicked location
//             marker = new window.google.maps.Marker({
//                 position: { lat: clickedLat, lng: clickedLng },
//                 map,
//             });

//             if (typeof onCoordinatesChange === 'function') {
//                 onCoordinatesChange({ lat: clickedLat, lng: clickedLng });
//             }
//         });
//     };

//     useEffect(() => {
//         // Load the Google Maps API script dynamically
//         const script = document.createElement('script');
//         script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=geometry`;
//         script.async = true;
//         script.defer = true;
//         script.onload = initMap;
//         document.head.appendChild(script);

//         return () => {
//             // Cleanup the script tag to avoid memory leaks
//             document.head.removeChild(script);
//         };
//     }, []);

//     return (
//         <div>
//             <div id="map" style={{ height: '500px', width: '100%' }}>
//                 {/* The map will be rendered here */}
//             </div>
//         </div>
//     );
// };

// export default LocationStart;
import React, { useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const LocationStart = ({ onCoordinatesChange }) => {
    let map;
    let marker = null;
    const searchInputRef = useRef(null);

    const initMap = () => {
        map = new window.google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            center: { lat: 24.7136, lng: 46.6753 },
        });

        map.addListener('click', (event) => {
            const clickedLat = event.latLng.lat();
            const clickedLng = event.latLng.lng();

            // Remove existing marker if present
            if (marker) {
                marker.setMap(null); // Remove from map
            }

            // Create new marker at the clicked location
            marker = new window.google.maps.Marker({
                position: { lat: clickedLat, lng: clickedLng },
                map,
            });

            if (typeof onCoordinatesChange === 'function') {
                onCoordinatesChange({ lat: clickedLat, lng: clickedLng });
            }
        });

        // Enable Places API for search functionality
        const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                console.log("Place not found");
                return;
            }

            const location = place.geometry.location;
            const lat = location.lat();
            const lng = location.lng();

            // Set map center to the selected location
            map.setCenter(location);

            // Remove existing marker if present
            if (marker) {
                marker.setMap(null); // Remove from map
            }

            // Create new marker at the selected location
            marker = new window.google.maps.Marker({
                position: { lat, lng },
                map,
                title: place.name
            });

            // Call callback with selected coordinates
            if (typeof onCoordinatesChange === 'function') {
                onCoordinatesChange({ lat, lng });
            }
        });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_API_KEY}&libraries=places`;
        script.async = true;
        script.defer = true;
        script.onload = initMap;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div>
            <input
                ref={searchInputRef}
                type="text"
                placeholder="ابحث عن مكان..."
                style={{ width: '100%', padding: '8px', marginBottom: '10px' }}
            />
            <div id="map" style={{ height: '500px', width: '100%' }}></div>
        </div>
    );
};

export default LocationStart;
