import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SlotAvailable from './SlotAvailable';
import SlotSettings from './SlotSettings';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { AddNewSlot, GetAllSlots } from '../../redux/actions/Slots';
import { GetAllBikers } from '../../redux/actions/Bikers';
import { ErrorMsg } from '../../Utils/Toast';
import DateTime from 'react-datetime';
const Slot = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setStartDay("");
        setEndDay("");
        setDaysDiff(null)
        setShow(false)
    }
    const handleShow = () => {

        setShow(true)
    }

    //timeData
    const [selectedTime, setSelectedTime] = useState('');
    const [modifiedTime, setModifiedTime] = useState('');
    const [selectedAmPm, setSelectedAmPm] = useState('');
    const [timeFact, setTimeFact] = useState('');
    const [activeStartDate, setActiveStartDate] = useState("");
    const [activeEndDate, setActiveEndDate] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateEnd, setSelectedDateEnd] = useState("");

    const handleDateChange = (date) => {
        // console.log(date.toISOString());
        setSelectedDate(date.toISOString());
    };
    const handleDateChangeEnd = (date) => {
        // console.log(date.toISOString());
        setSelectedDateEnd(date.toISOString());
    };


    const handleTimeChange = (e) => {
        const inputTime = e.target.value;
        setTimeFact(inputTime);
        const dataParsed = new Date(`2000-01-01T${inputTime}`);

        // الحصول على الوقت بنظام 12 ساعة
        const formattedTime12Hours = dataParsed.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        // طباعة الوقت بنظام 12 ساعة
        setTimeFact(formattedTime12Hours);

        const parsedTimedate = new Date(`2000-01-01T${inputTime}`)
        const formattedSelectedTime = parsedTimedate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
        setSelectedTime(convertTo24HourFormat(formattedSelectedTime).replace(/:/, '.'));

        const parsedTime = new Date(`2000-01-01T${inputTime}`);
        parsedTime.setMinutes(parsedTime.getMinutes() + 90);
        const formattedModifiedTime = parsedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })

        setModifiedTime(convertTo24HourFormat(formattedModifiedTime).replace(/:/, '.'))

        const amPm = parsedTime.toLocaleTimeString([], { hour12: true, hour: '2-digit' }).slice(-2);
        setSelectedAmPm(amPm);
    };

    //Add New Slot
    const [loading, setLoading] = useState(false)


    const dispatch = useDispatch();

    const postNewSlot = async () => {
        // console.log(selectedTime);
        // console.log(modifiedTime);
        // console.log(timeFact);

        try {
            setLoading(true);
            if (selectedDate !== "" && selectedDateEnd !== "") {
                await dispatch(AddNewSlot({
                    start_time: parseFloat(selectedTime),
                    end_time: parseFloat(modifiedTime),
                    name: `${timeFact}`,
                    in_active_start_date: selectedDate,
                    in_active_end_date: selectedDateEnd,
                }))
            } else {
                await dispatch(AddNewSlot({
                    start_time: parseFloat(selectedTime),
                    end_time: parseFloat(modifiedTime),
                    name: `${timeFact}`,
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
            dispatch(GetAllSlots());
            setStartDay("");
            setEndDay("");
            setSelectedDate("")
            setSelectedDateEnd("")
        }
    }

    useEffect(() => {
        dispatch(GetAllBikers());
    }, [dispatch])
    const BikersName = useSelector(state => state.GetBikers.AllBikers)
    const convertTo24HourFormat = (time12) => {
        let [time, period] = time12.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);

        if (period.toUpperCase() === 'PM' && hours < 12) {
            hours += 12;
        } else if (period.toUpperCase() === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };


    //Slots Holiday
    const [startDay, setStartDay] = useState('');
    const [endDay, setEndDay] = useState('');
    const [daysDiff, setDaysDiff] = useState(null);

    const calculateDaysDifference = () => {
        const startDate = new Date(selectedDate);
        const endDate = new Date(selectedDateEnd);
        const date = new Date(startDay);
        // const formattedDate = new Intl.DateTimeFormat('en-GB').format(date);



        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
            if (endDate <= startDate) {
                // Handle the case where end date is not after start date (e.g., show an error message)
                setDaysDiff(null);
                // ErrorMsg(`يجب اختيار يوم يبدا بعد  ${formattedDate}`)
            } else {
                const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
                const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                setDaysDiff(daysDiff + 1);
            }
        } else {
            setDaysDiff(null);
        }
    };

    useEffect(() => {
        if (selectedDate !== "" && selectedDateEnd !== "") {
            calculateDaysDifference();
        }
    }, [selectedDateEnd, selectedDate])





    const validDate = (current) => {
        // Disable dates prior to today by comparing with the current date
         return current.isSameOrAfter(new Date(), 'day');
    };
    var adminLogIn = {};

    if (localStorage.getItem('user')) {
        adminLogIn = JSON.parse(localStorage.getItem('user'));
    }

    return (
        <div className='font'>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة الاوقات (Slots)</p>
                </div>
                <div>
                    <button onClick={handleShow} className='btn' style={{ color: "black", background: "#FECB44" }}>اضافة وقت جديد</button>
                </div>
            </div>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 tabContainer"
            >
                <Tab eventKey="home" className='font' title="قائمة الاوقات المتاحة">
                    <SlotAvailable />
                </Tab>
                {
                    adminLogIn && adminLogIn.premessions ? (
                        adminLogIn.premessions.map((pre) => {
                            return (
                                pre === "app-constants" ? (
                                    <Tab eventKey="profile" className='font' title="اعدادات ادارة الاوقات">
                                        <SlotSettings />
                                    </Tab>
                                ) : null
                            )
                        })
                    ) : null
                }

            </Tabs>








            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>اضافة وقت جديد (New Slot)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <input type="time" className='w-100' onChange={handleTimeChange} min="2005-01-01" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <div className='mt-3'>
                            <div className='mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                                <div className=''>
                                    <div style={{ width: "100%" }}>
                                        <div className=''>
                                            <label>البايكر </label>
                                            <div className='mt-1'>
                                                <Form.Select disabled={true} aria-label="Default select example" className='mySelectClass' style={{ background: "#EFF1F9" }}>
                                                    <option value="" hidden>حدد البايكر المراد اضافته</option>
                                                    {BikersName && BikersName.bikers ? (
                                                        BikersName.bikers
                                                            .filter(biker => biker.is_active === true)
                                                            .map((bikerName) => (
                                                                <option key={bikerName.id} value={bikerName.user_id}>
                                                                    {bikerName.user.first_name}
                                                                </option>
                                                            ))
                                                    ) : (null)}
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className='mt-2'>
                                            <label>تاريخ  بداية الاخفاء</label>
                                            <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm"
                                                inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                closeOnSelect={true} // إغلاق المنتقي تلقائيًا عند اختيار التاريخ
                                            />
                                            {/* <input type='date' className='w-100 mt-2 textInputBiker' value={startDay} onChange={(e) => setStartDay(e.target.value)} /> */}
                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }} className='mt-1'>
                                        <div className='mt-2'>
                                            <label>تاريخ نهاية الاخفاء  </label>
                                            <DateTime className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleDateChangeEnd} dateFormat="YYYY-MM-DD" timeFormat="HH:mm"
                                                inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                closeOnSelect={true}
                                            />
                                            {/* <input type='date' className='w-100 mt-2 textInputBiker' value={endDay} onChange={(e) => setEndDay(e.target.value)} /> */}
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <label> عدد الايام</label>
                                        {
                                            daysDiff !== null ? (
                                                <input type='text' className='w-100 mt-2 textInputBiker' value={daysDiff} readOnly />
                                            ) : (<input type='text' className='w-100 mt-2 textInputBiker' readOnly />)
                                        }
                                    </div>
                                </div>
                                {/* <button className='btn mx-3 mt-3' style={{ background: "#FECB44" }}>تاكيد الطلب</button> */}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={postNewSlot}>
                        اضافه
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default Slot
