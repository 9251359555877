import baseUrl from "../../Api/baseURL";
import { ErrorMsg } from "../../Utils/Toast";
import { CREATE_SERVICES, DELETE_SERVICE, GET_ALL_SERVICES, UPDATE_SERVICE } from '../Type'

const GetAllServices = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language":'ar'
            },
        }
        try {
            let res = await baseUrl.get('v1/service/all-service?all=true', config)

            dispatch({ type: GET_ALL_SERVICES, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_SERVICES, payload: e.res })
            console.log(e)
        }
    }
}

const SearchService = (data) => {
    // console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language":'ar'
            },
        }
        try {
            if(data.name!==""){
                let res = await baseUrl.get(`v1/service/all-service?all=true&name=${data.name}`, config)
                dispatch({ type: GET_ALL_SERVICES, payload: res.data })
            }else{
                let res = await baseUrl.get('v1/service/all-service?all=true', config)

            dispatch({ type: GET_ALL_SERVICES, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_SERVICES, payload: e.res })
            console.log(e)
        }
    }
}
const CreateServices = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post('v1/service/create-service', data, config)

            dispatch({ type: CREATE_SERVICES, payload: res.data })
        } catch (e) {
            dispatch({ type: CREATE_SERVICES, payload: e.res })
            if (e.response.status === 422) {
                ErrorMsg(e.response.data.message)
            }
        }
    }
}

const DeleteService = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/service/${data.id}/delete-service`, config)

            dispatch({ type: DELETE_SERVICE, payload: res.data })
        } catch (e) {
            dispatch({ type: DELETE_SERVICE, payload: e.res })

        }
    }
}


const UpdateService = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/service/update-service`, data, config)

            dispatch({ type: UPDATE_SERVICE, payload: res.data })
        } catch (e) {
            dispatch({ type: UPDATE_SERVICE, payload: e.res })

        }
    }
}

export { GetAllServices, CreateServices, DeleteService, SearchService, UpdateService }