// import React, { useState } from 'react'
// import { MdDelete } from "react-icons/md";
// import Modal from 'react-bootstrap/Modal';
// import { useDispatch, useSelector } from 'react-redux'
// import input from "../../Images/Input.png"
// import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
// import { AddQuestionData, GetPrivacy, GetQuestionsAndAnswers, GetSocialMedia, GetTermsConditions, UpdatePrivacy, UpdateSocialMedia, UpdateTerms } from '../../redux/actions/PrivacyPolicy';
// import { CiEdit } from "react-icons/ci";
// const AddQuModal = ({ titleEn,
//     titleAr,
//     descAr,
//     descEn, idData, section }) => {
//     const [showEdit, setShowEdit] = useState(false);
//     const [loading, setLoading] = useState(false);

//     const handleCloseEdit = () => setShowEdit(false);
//     const handleShowEdit = () => setShowEdit(true);
//     const dispatch = useDispatch();

//     const [titleArData, setTitleAr] = useState()
//     const [titleEnData, setTitleEn] = useState()
//     const [descriptionAr, setDescAr] = useState()
//     const [descriptionEn, setDescEn] = useState()


//     const handleUpdate = async () => {
//         try {

//             await dispatch(AddQuestionData({
//                 question_ar: titleArData,
//                 answer_ar: descriptionAr,
//                 question_en: titleEnData,
//                 answer_en: descriptionEn,
//             }))

//         } catch (e) {
//             console.log(e);
//         } finally {
//             handleCloseEdit();
//             await dispatch(GetQuestionsAndAnswers());
//         }
//     }



//     return (
//         <div>
//             {/* <CiEdit onClick={handleShowEdit} cursor={"pointer"} /> */}
//             <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={handleShowEdit}>اضف سؤال اخر</button>
//             <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
//                 <Modal.Body className='text-center py-3'>
//                     <div>
//                         <label>السؤال بالعربي</label>
//                         <input type="text" value={titleArData} className='mt-2'
//                             onChange={(e) => setTitleAr(e.target.value)}
//                             style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div>
//                         <label>الاجابه بالعربي</label>
//                         <input type="text" value={descriptionAr} className='mt-2'
//                             onChange={(e) => setDescAr(e.target.value)}
//                             style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div>
//                         <label>السؤال بالانجليزيه</label>
//                         <input type="text" value={titleEnData} className='mt-2'
//                             onChange={(e) => setTitleEn(e.target.value)}
//                             style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div>
//                         <label>الاجابه بالانجليزي</label>
//                         <input type="text" value={descriptionEn} className='mt-2'
//                             onChange={(e) => setDescEn(e.target.value)}
//                             style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer className='m-auto w-100'>

//                     <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white" }} onClick={handleUpdate}>
//                         تاكيد الحفظ
//                     </button>
//                     <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
//                         تراجع
//                     </button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     )
// }

// export default AddQuModal






import React, { useState } from 'react';
import { MdDelete } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import input from "../../Images/Input.png";
import { DeleteCarModalBrand, GetAllCars } from '../../redux/actions/CarActions';
import { AddQuestionData, GetPrivacy, GetQuestionsAndAnswers, GetSocialMedia, GetTermsConditions, UpdatePrivacy, UpdateSocialMedia, UpdateTerms } from '../../redux/actions/PrivacyPolicy';
import { CiEdit } from "react-icons/ci";

const AddQuModal = () => {
    const [showEdit, setShowEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [titleArData, setTitleAr] = useState('');
    const [titleEnData, setTitleEn] = useState('');
    const [descriptionAr, setDescAr] = useState('');
    const [descriptionEn, setDescEn] = useState('');
    const [error, setError] = useState('');

    const handleCloseEdit = () => {
        setShowEdit(false);
        // Reset error message
        setError('');
    };
    const handleShowEdit = () => setShowEdit(true);
    const dispatch = useDispatch();

    const handleUpdate = async () => {

        try {
            await dispatch(AddQuestionData({
                question_ar: titleArData,
                answer_ar: descriptionAr,
                question_en: titleEnData,
                answer_en: descriptionEn,
            }));
        } catch (e) {
            console.log(e);
        } finally {
            handleCloseEdit();
            await dispatch(GetQuestionsAndAnswers());
            setTitleAr("")
            setTitleEn("")
            setDescAr("")
            setDescEn("")
        }
    };

    return (
        <div>
            {/* <CiEdit onClick={handleShowEdit} cursor={"pointer"} /> */}
            <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={handleShowEdit}>اضف سؤال اخر</button>
            <Modal show={showEdit} onHide={handleCloseEdit} className='font py-5' centered>
                <Modal.Body className='text-center py-3'>
                    <div>
                        <label>السؤال بالعربي</label>
                        <input type="text" value={titleArData} className='mt-2'
                            onChange={(e) => setTitleAr(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div>
                        <label>الاجابه بالعربي</label>
                        <input type="text" value={descriptionAr} className='mt-2'
                            onChange={(e) => setDescAr(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div>
                        <label>السؤال بالانجليزيه</label>
                        <input type="text" value={titleEnData} className='mt-2'
                            onChange={(e) => setTitleEn(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div>
                        <label>الاجابه بالانجليزي</label>
                        <input type="text" value={descriptionEn} className='mt-2'
                            onChange={(e) => setDescEn(e.target.value)}
                            style={{ background: "#EFF1F9", width: "80%", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "rgba(60, 196, 65, 1)", color: "white" }} onClick={handleUpdate}>
                        تاكيد الحفظ
                    </button>
                    <button className='btn' onClick={handleCloseEdit} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default AddQuModal;
