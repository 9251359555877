import { GET_ALL_INVOICES , DOWNLOAD_INVOICE  , GET_SETTINGS_INVOICE, GET_SETTINGS_INVOICE_UPDATE, UPLADO_DATA} from "../Type";
const initial = {
    invoices: [],
    Download:[],
    setting:[],
    Updatesetting:[],
    loading: true,
}

const InvoiceReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_ALL_INVOICES:
            return {
                ...state,
                invoices: action.payload,
                loading: false
            }
        case DOWNLOAD_INVOICE:
            return {
                ...state,
                Download: action.payload,
                loading: false
            }
        case GET_SETTINGS_INVOICE:
            return {
                ...state,
                setting: action.payload,
                loading: false
            }
        case GET_SETTINGS_INVOICE_UPDATE:
            return {
                ...state,
                Updatesetting: action.payload,
                loading: false
            }
        case UPLADO_DATA:
            return {
                ...state,
                UploadPhotoData: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default InvoiceReducer