import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import DateTime from 'react-datetime';
import { GetAllSlots, UpdateSlots } from '../../redux/actions/Slots';
import { useDispatch } from 'react-redux';
import { GET_ALL_SLOTS } from '../../redux/Type';
const ModalUpdateSlot = ({
    slotId,
    time,
    period,
    endData,
    startDate,
    startTime,
    endTime

}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);



    // Handle Slot
    const [selectedTime, setSelectedTime] = useState('');
    const [modifiedTime, setModifiedTime] = useState('');
    const [selectedAmPm, setSelectedAmPm] = useState('');
    const [idData, setId] = useState('');
    const [timeFact, setTimeFact] = useState('')
    const [ActiveStart, setActiveStart] = useState('')
    const [ActiveEnd, setActiveEnd] = useState('')


    const handleTimeChange = (e) => {
        const inputTime = e.target.value;
        setTimeFact(inputTime);
        const dataParsed = new Date(`2000-01-01T${inputTime}`);

        // الحصول على الوقت بنظام 12 ساعة
        const formattedTime12Hours = dataParsed.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        // طباعة الوقت بنظام 12 ساعة
        setTimeFact(formattedTime12Hours);

        const parsedTimedate = new Date(`2000-01-01T${inputTime}`)
        const formattedSelectedTime = parsedTimedate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
        setSelectedTime(convertTo24HourFormat(formattedSelectedTime).replace(/:/, '.'));

        const parsedTime = new Date(`2000-01-01T${inputTime}`);
        parsedTime.setMinutes(parsedTime.getMinutes() + 90);
        const formattedModifiedTime = parsedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })

        setModifiedTime(convertTo24HourFormat(formattedModifiedTime).replace(/:/, '.'))

        const amPm = parsedTime.toLocaleTimeString([], { hour12: true, hour: '2-digit' }).slice(-2);
        setSelectedAmPm(amPm);
    };

    const convertTo24HourFormat = (time12) => {
        let [time, period] = time12.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);

        if (period.toUpperCase() === 'PM' && hours < 12) {
            hours += 12;
        } else if (period.toUpperCase() === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };


    const validDate = (current) => {
        // Disable dates prior to today by comparing with the current date
        return current.isSameOrAfter(new Date(), 'day');
    };



    const [startDay, setStartDay] = useState(new Date(startDate));
    const [endDay, setEndDay] = useState(new Date(endData));
    const [daysDiff, setDaysDiff] = useState(null);

    const calculateDaysDifference = () => {
        const startDate = new Date(startDay);
        const endDate = new Date(endDay);
        const date = new Date(startDay);
        const formattedDate = new Intl.DateTimeFormat('en-GB').format(date);



        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {

            const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            if (endDate <= startDate) {
                setDaysDiff(daysDiff);
            } else {
                setDaysDiff(daysDiff + 1);
            }

        } else {
            setDaysDiff(null);
        }
    };

    useEffect(() => {
        if (endDay !== "" && startDay !== "") {
            calculateDaysDifference();
        }
    }, [startDay, endDay])

    const handleStartDate = (date) => {
        setStartDay(date.toISOString());
        console.log(date.toISOString())
    }
    const handleEndtDate = (date) => {
        setEndDay(date.toISOString());
        console.log(date.toISOString())
    }



    const dispatch = useDispatch();

    const cancelDates = ()=>{
        dispatch(UpdateSlots({
            id: idData,
            in_active_start_date: null,
            in_active_end_date: null,
        }))
        handleClose();
        setStartDay("");
        setEndDay("");
        setDaysDiff(null)
        dispatch(GetAllSlots());
        dispatch(GetAllSlots());
        dispatch(GetAllSlots());
    }



    
    const updateSlot = async () => {
        try {
            // setLoading(true);
            
            if (startDay !== "" && endDay !== "" && selectedTime !== "" && modifiedTime !== "") {
                dispatch(UpdateSlots({
                    id: slotId,
                    start_time: parseFloat(selectedTime) || parseFloat(startTime),
                    end_time: parseFloat(modifiedTime) || parseFloat(endTime),
                    name: `${timeFact}`,
                    in_active_start_date: startDay,
                    in_active_end_date: endDay,
                }))
            } else if (selectedTime !== "" && modifiedTime !== "" && startDay === "" && endDay === "") {
                dispatch(UpdateSlots({
                    id: idData,
                    start_time: parseFloat(selectedTime) || parseFloat(startTime),
                    end_time: parseFloat(modifiedTime) || parseFloat(endTime),
                    name: `${timeFact}` || `${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`,
                }))
            } else if (selectedTime === "" && modifiedTime === "" && startDay !== "" && endDay !== "") {
                dispatch(UpdateSlots({
                    id: slotId,
                    in_active_start_date: startDay,
                    in_active_end_date: endDay,
                }))
            }else if(endDay !=="" && startDay === "" && selectedTime === "" && modifiedTime === ""   ){
                dispatch(UpdateSlots({
                    id: slotId,
                    in_active_end_date: endDay,
                }))
            }else if(endDay ==="" && startDay !== "" && selectedTime === "" && modifiedTime === "" ){
                dispatch(UpdateSlots({
                    id: slotId,
                    in_active_start_date: startDay,
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            // setLoading(false);
            handleClose();

            dispatch(GetAllSlots());

            dispatch(GetAllSlots());
            dispatch(GetAllSlots());
        }
    }
    const handleShow = () => {
        console.log(time)
        setShow(true);
    };



    return (
        <div>
            <button onClick={handleShow}>edit</button>
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>تعديل وقت</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <p>{time} {period}</p>
                        {/* <p>{startTime} {endTime}</p> */}

                        <input type="time" className='w-100' onChange={handleTimeChange} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <div className='mt-3'>
                            <div className='mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                                <div className=''>
                                    <div style={{ width: "100%" }}>
                                        <div className='mt-2'>

                                            {
                                                ActiveStart !== "1970-01-01T00:00:00.000Z" ? (<label>Hidden Start Date : {(ActiveStart)}</label>) : (
                                                    <label>تاريخ  بدايةالاخفاء : لايوجد تاريخ بدايةالاخفاء</label>
                                                )
                                            }
                                            <DateTime
                                                inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                closeOnSelect={true}
                                                value={startDay}
                                                className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleStartDate} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />

                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }} className='mt-1'>
                                        <div className='mt-2'>
                                            {
                                                ActiveEnd !== "1970-01-01T00:00:00.000Z" ? (<label>Hidden End Date : : {(ActiveEnd)}</label>) : (
                                                    <label>  تاريخ نهاية الاخفاء:  لايوجد تاريخ  نهاية الاخفاء</label>
                                                )
                                            }
                                            <DateTime
                                                inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                closeOnSelect={true}
                                                value={endDay}
                                                className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleEndtDate} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <label> عدد الايام</label>
                                        {
                                            daysDiff !== null ? (
                                                <input type='text' className='w-100 mt-2 textInputBiker' value={daysDiff} readOnly />
                                            ) : (<input type='text' className='w-100 mt-2 textInputBiker' readOnly />)
                                        }
                                    </div>

                                    <div className="mt-3">
                                        <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={cancelDates}>Cancel Hidden Dates</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateSlot}>
                        تعديل
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalUpdateSlot
