import baseUrl from "../../Api/baseURL";
import { ErrorMsg, success } from "../../Utils/Toast";
import { ADD_BIKER_TO_AREA, DELETE_AREA, DELETE_BIKERS_AT_AREA, GET_ALL_AREAS, GET_BIKERS_AT_AREA, GET_SINGLE_AREA, GET_UPDATE_DATA, UPDATE_AREA, UPLOAD_NEW_AREA } from '../Type'

//get All Areas
const GetAllAreas = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get('v1/addresses/working-area', config)

            dispatch({ type: GET_ALL_AREAS, payload: res.data })
        } catch (e) {
            dispatch({ type: GET_ALL_AREAS, payload: e.res })
            console.log(e)
        }
    }
}

//Add New Area
const UpdateNewArea = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post('v1/addresses/working-area', data, config)

            dispatch({ type: UPLOAD_NEW_AREA, payload: res.data })
        } catch (e) {
            dispatch({ type: UPLOAD_NEW_AREA, payload: e.res })
        }
    }
}

//Delete Area
const DeleteArea = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/addresses/working-area/${data.id}`, config)
            dispatch({ type: DELETE_AREA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_AREA, payload: e.res })
            console.log(e);
        }
    }
}



//Update Area
const UpdateArea = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/addresses/working-area`, data, config)
            dispatch({ type: UPDATE_AREA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_AREA, payload: e.res })
            console.log(e);
        }
    }
}

//Get Information Single Area
const GetSingleArea = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/addresses/working-area/${data.id}`, config)
            dispatch({ type: GET_SINGLE_AREA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: GET_SINGLE_AREA, payload: e.res })
            console.log(e);
        }
    }
}


//Get Bikers Working in area
const GetBikersWorkingAtArea = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/addresses/biker-working-area/${data?.id}`, config)
            dispatch({ type: GET_BIKERS_AT_AREA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: GET_BIKERS_AT_AREA, payload: e.res })
            console.log(e);
        }
    }
}
//Delete Bikers Working in area
const DeleteBikersWorkingAtArea = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/biker/remove-biker-from-working-area/${data?.biker_working_area_id}`, config)
            dispatch({ type: DELETE_BIKERS_AT_AREA, payload: res.data })
            console.log(res);
            if (res?.status === 200) {
                success(`تم حذف البايكر بنجاح`)
            }
        } catch (e) {
            dispatch({ type: DELETE_BIKERS_AT_AREA, payload: e.res })
            console.log(e);
            ErrorMsg(`هناك مشكله ما`)
        }
    }
}

//Update Area
const UpdateAreaDataLoad = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/addresses/working-area`, data, config)
            dispatch({ type: GET_UPDATE_DATA, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: GET_UPDATE_DATA, payload: e.res })
            console.log(e);
        }
    }
}

// Add Biker To Working area
const AddBikerToWorkingAreaId = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/biker/assign-biker-to-working-area`, data, config)
            dispatch({ type: ADD_BIKER_TO_AREA, payload: res.data })
            console.log(res);
            if (res?.data?.data) {
                success(res?.data?.message)
            }
        } catch (e) {
            dispatch({ type: ADD_BIKER_TO_AREA, payload: e.res })
            console.log(e);
            if(e?.response?.data){
                ErrorMsg(e.response?.data?.message)
            }
        }
    }
}

export { GetAllAreas, UpdateNewArea, DeleteArea, UpdateArea, GetSingleArea, UpdateAreaDataLoad, GetBikersWorkingAtArea, DeleteBikersWorkingAtArea, AddBikerToWorkingAreaId }