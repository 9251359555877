import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountManager from './AccountManager';
import AppUserAccounts from './AppUserAccounts';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch, useSelector } from 'react-redux'
import { Add_NewBiker } from '../../redux/actions/Bikers';
import { ErrorMsg } from '../../Utils/Toast';
import { GetAllUsersClients } from '../../redux/actions/Users';
import Pagination from '../../Utils/Pagination';
const AccountsManagment = () => {
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true)
    }

    const [currentPage, setCurrentPage] = useState(1);

    //Set data 
    const [role, setRole] = useState('');
    const [nameFirst, setNameFirst] = useState('');
    const [nameEnd, setNameEnd] = useState('');
    const [phone, setPhone] = useState('');
    const [pass, setPass] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);


    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target ? e.target.value : ''; // Check if e.target exists
        const isValid = /^\+9665\d{8}$/.test(inputValue);

        setPhone(inputValue);
        setIsValidPhoneNumber(isValid);
    };




    const handleUsers = async () => {
        try {
            setLoading(true);
            if (!nameEnd || !nameFirst || !phone || !email || !isValidPhoneNumber) {
                ErrorMsg('Data Missed')
                setLoading(true)
                return;
            }
            if (role === "ADMIN") {
                await dispatch(Add_NewBiker({
                    first_name: nameFirst,
                    last_name: nameEnd,
                    email: email,
                    phone: phone,
                    password: pass,
                    role: role,
                }))
            }else if(role === "CLIENT"){
                await dispatch(Add_NewBiker({
                    first_name: nameFirst,
                    last_name: nameEnd,
                    email: email,
                    phone: phone,
                    // password: pass,
                    role: role,
                }))
            }
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
            // if(role === "ADMIN"){
            //     dispatch(GetAllUsersClients({ role: "ADMIN", pageCount: 1 }));
            // }else{
            //     dispatch(GetAllUsersClients({ role: "CLIENT", pageCount: 1 }));
            // }
            const roleToPass = role === "ADMIN" ? "ADMIN" : "CLIENT";
            const currentPage = 1;
            dispatch(GetAllUsersClients({ role: roleToPass, pageCount: currentPage }));
        }


    }


    const resAddUser = useSelector(state => state.GetBikers.addBikers);



    useEffect(() => {
        if (loading === false) {
            if (resAddUser) {
                if (resAddUser.statusCode === 201) {
                    handleClose();
                    setRole('');
                    setNameFirst('');
                    setNameEnd('');
                    setPhone('');
                    setPass('');
                    setEmail('');
                }
            }
        }
    }, [loading])








    return (
        <div>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة حسابات المستخدمين</p>
                </div>
                <div>
                    <button onClick={handleShow} className='btn' style={{ color: "black", background: "#FECB44" }}>اضافة مستخدم جديد</button>
                </div>
            </div>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3 tabContainer"
            >
                <Tab eventKey="home" className='font' title="حسابات مدير النظام">
                    <AccountManager />
                </Tab>
                <Tab eventKey="contact" className='font' title="حسابات مستخدمي التطبيق">
                    <AppUserAccounts />
                </Tab>
            </Tabs>



            {/* Modal For Add Users Or Admins */}

            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة مستخدم جديد</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>معلومات المستخدم</span>
                    <div className='mt-4'>
                        <Form.Select aria-label="Default select example" value={role} onChange={(e) => setRole(e.target.value)} style={{ background: "#EFF1F9" }}>
                            <option hidden>صلاحية (Role)</option>
                            <option value="ADMIN">ادمن</option>
                            <option value="CLIENT">مستخدم</option>
                        </Form.Select>
                    </div>


                    <div className='mt-3'>
                        <input type="text" className='w-100' placeholder='اسم الاول' onChange={(e) => setNameFirst(e.target.value)} value={nameFirst} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' placeholder='الاسم الثاني' onChange={(e) => setNameEnd(e.target.value)} value={nameEnd} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>



                    <div className="mt-3">

                    </div>
                    <input
                        type='text'
                        className='w-100 mt-2 textInputBiker'
                        value={phone}
                        onChange={handlePhoneNumberChange}
                        style={{ borderWidth: "2px", borderColor: isValidPhoneNumber ? 'green' : 'red' }}
                        placeholder='ادخل رقم الهاتف'
                        defaultValue="+966"
                    />

                    {!isValidPhoneNumber && (
                        <p style={{ color: 'red' }}>
                            الرجاء إدخال رقم هاتف صحيح (
                            يبدأ بـ {phone.startsWith("+") ? "9665" : "+"} ويتكون من 8 أرقام)
                        </p>
                    )}

                    <div className='mt-3'>
                        <input type="email" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder="البريد الالكتروني" className='form-control' value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    {
                        role === "ADMIN" ? (
                            <div className='mt-3'>
                                <input type="password" style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} placeholder="كلمة السر" className='form-control' value={pass} onChange={(e) => setPass(e.target.value)} />
                            </div>
                        ) : null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={handleUsers}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AccountsManagment
