// import React, { useEffect, useState } from 'react';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import { IoPencil, IoCloseSharp } from 'react-icons/io5';
// import { useDispatch, useSelector } from 'react-redux';
// import { AddNewServiceToPackageData, CreatePhotoPackage, DeletePackageServicesData, GetAllBaqa, GetSinglePackage, UpdateBaqaData, UpdateBaqaFormat, UpdateCountService } from '../../redux/actions/BaqaAction';

// const UpdateBaqa = ({ idData, name, washCount, background, points, days, order, priceSingle, desc, totalPrice }) => {
//     const dispatch = useDispatch();
//     const [show, setShow] = useState(false);
//     const [services, setServices] = useState([]);
//     const [selectedServices, setSelectedServices] = useState([]);
//     const [service, setService] = useState('');
//     const [servicesToDelete, setServicesToDelete] = useState([]); // State to hold services marked for deletion

//     const handleClose = () => {
//         setShow(false);
//         setImages([]);
//         dispatch(GetAllBaqa());
//         setSelectedServices([]);
//         setId("");
//         setVal("");
//     };
//     const handleShow = () => {
//         setShow(true);
//     };

//     const [images, setImages] = useState([]);
//     const [uploadError, setUploadError] = useState(null);

//     const handleImageUpload = async (event) => {
//         const selectedImage = event.target.files[0];
//         setImages([selectedImage]);
//         setUploadError(null);
//         const formData = new FormData();
//         formData.append('file', selectedImage);

//         try {
//             await dispatch(CreatePhotoPackage(formData));
//         } catch (error) {
//             setUploadError(error.message || 'An error occurred during image upload.');
//         }
//     };

//     const handleLabelClick = (event) => {
//         event.preventDefault();
//         const fileInput = document.getElementById('file');
//         fileInput.click();
//     };

//     useEffect(() => {
//         if (show) {
//             dispatch(UpdateBaqaData({ id: idData }));
//         }
//     }, [show]);

//     const dataUpload = useSelector(state => state.BaqaReducer.UpdateData);

//     const [nameAr, setNameAr] = useState('');
//     const [nameEn, setNameEn] = useState('');
//     const [washSingle, setPriceWashSingle] = useState('');
//     const [total_price_package, setTotal_Price_Package] = useState('');
//     const [wash_count, setWash_Count] = useState('');
//     const [description_ar, setDescription_ar] = useState('');
//     const [description_en, setDescription_en] = useState('');
//     const [expiry_date_in_days, setExpiry_date_in_days] = useState('');
//     const [buy_by_points, setBuy_by_points] = useState('');
//     const [order_by, setorder_by] = useState('');
//     const [backgroundData, setBack] = useState('');
//     const [loader, setLoader] = useState(false);
//     const [packageServices, setPackageServices] = useState([]);
//     const [countPackage , setCountAddPackage] = useState();

//     useEffect(() => {
//         if (dataUpload && dataUpload.data) {
//             setNameAr(dataUpload.data.name);
//             setNameEn(dataUpload.data.name_en);
//             setPriceWashSingle(dataUpload.data.price_wash_single);
//             setTotal_Price_Package(dataUpload.data.total_price_package);
//             setWash_Count(dataUpload.data.wash_count);
//             setDescription_ar(dataUpload.data.description);
//             setDescription_en(dataUpload.data.description_en);
//             setExpiry_date_in_days(dataUpload.data.expiry_date_in_days);
//             setBuy_by_points(dataUpload.data.buy_by_points);
//             setorder_by(dataUpload.data.order_by);
//             setBack(dataUpload.data.background_url);
//             setPackageServices(dataUpload.data.package_service);
//         }
//     }, [dataUpload]);

//     const editdata = async () => {
//         const formData = new FormData();
//         setLoader(true);
//         try {
//             formData.append('name_ar', nameAr);
//             formData.append('name_en', nameEn);
//             formData.append('is_active', `true`);
//             formData.append('order_by', parseInt(order_by));
//             formData.append('price_wash_single', parseFloat(washSingle).toFixed(2));
//             formData.append('wash_count', parseFloat(wash_count));
//             formData.append('total_price_package', parseFloat((wash_count) * (washSingle)).toFixed(2));
//             formData.append('description_ar', description_ar || "");
//             formData.append('description_en', description_en);
//             formData.append('expiry_date_in_days', parseInt(expiry_date_in_days));
//             formData.append('file', images[0]);
//             await dispatch(UpdateBaqaFormat(formData, idData));
//             await editCountServices(idservice, parseInt(val));
//             if (service !== "") {
//                 addNewServiceToPackage();
//             }
//             for (const serviceId of servicesToDelete) {
//                 await dispatch(DeletePackageServicesData({ package_service_id: serviceId }));
//             }
//         } catch (e) {
//             console.log(e);
//         } finally {
//             handleClose();
//             dispatch(GetAllBaqa());
//             setImages([]);
//             setService('');
//             setLoader(false);
//         }
//     };

//     const resultServices = useSelector(state => state.ServicesReducer.allServices);

//     useEffect(() => {
//         if (resultServices && resultServices.data) {
//             setServices(resultServices.data);
//         }
//     }, [resultServices]);

//     const handleServiceChange = (e) => {
//         const serviceId = e.target.value;
//         const serviceCount = 0;
//         setService(e.target.value);
//         if (!selectedServices.some(service => service.id === serviceId)) {
//             setSelectedServices([...selectedServices, { package_id: idData, service_id: serviceId, service_count: parseInt(serviceCount), is_active: true }]);
//         }
//     };

//     const handleRemoveService = (serviceIdToRemove) => {
//         const updatedServices = selectedServices.filter(service => service.id !== serviceIdToRemove);
//         setSelectedServices(updatedServices);
//     };

//     const handleCountChange = (e, index) => {
//         const { value } = e.target;
//         setCountAddPackage(e.target.value);
//         const updatedServices = [...selectedServices];
//         updatedServices[index].service_count = parseInt(value);
//         setSelectedServices(updatedServices);
//     };

//     const [val, setVal] = useState("");
//     const [idservice, setId] = useState("");

//     const handleCountChangeExistData = async (e, index, id) => {
//         const { value } = e.target;
//         const updatedPackageServices = [...packageServices];
//         updatedPackageServices[index].service_count = parseInt(value);
//         setPackageServices(updatedPackageServices);
//         setVal(value);
//         setId(id);
//     };

//     const addNewServiceToPackage = async () => {
//         try {
//             await dispatch(AddNewServiceToPackageData({ package_services: selectedServices }));
//         } catch (e) {
//             console.log(e);
//         }
//     };

//     const editCountServices = async (id, serviceCount) => {
//         try {
//             await dispatch(UpdateCountService({ id: id, service_count: serviceCount, is_active: true }));
//         } catch (e) {
//             console.log(e);
//         }
//     };

//     const markServiceForDeletion = (idServices) => {
//         setServicesToDelete([...servicesToDelete, idServices]);
//     };

//     return (
//         <div>
//             <IoPencil size="20" onClick={handleShow} />
//             <Modal show={show} onHide={handleClose} centered className='font'>
//                 <Modal.Header>
//                     <Modal.Title>تعديل الباقه</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className=''>
//                         <div className='w-50'>
//                             <img src={backgroundData} alt="data" style={{ width: "100%" }} />
//                         </div>
//                         <div className='d-flex justify-content-between align-items-center'>
//                             <div>
//                                 <div className="w-100" style={{ background: "#EFF1F9", padding: "20px", borderRadius: "10px", width: "40%" }}>
//                                     {images.map((image, index) => (
//                                         <div>
//                                             <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
//                                                 style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                             <div className="file-input" style={{ padding: "20px", textAlign: "left" }}>
//                                 <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
//                                 <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className='mt-3'>
//                         <label>اسم الباقة بالعربي</label>
//                         <input type="text" className='w-100' value={nameAr} onChange={(e) => setNameAr(e.target.value)} placeholder=' اسم الباقة بالعربي' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label> اسم الباقة بالانجليزي</label>
//                         <input type="text" className='w-100' value={nameEn} onChange={(e) => setNameEn(e.target.value)} placeholder=' اسم الباقة بالانجليزي' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label> وصف الباقة بالعربيه</label>
//                         <input type="text" className='w-100' placeholder='وصف الباقة بالعربيه' value={description_ar} onChange={(e) => setDescription_ar(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>وصف الباقة بالانجليزيه </label>
//                         <input type="text" className='w-100' placeholder='وصف الباقة بالانجليزيه' value={description_en} onChange={(e) => setDescription_en(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>ترتيب الباقه  </label>
//                         <input type="number" min="1" className='w-100' placeholder='ترتيب الباقه' value={order_by} onChange={(e) => setorder_by(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>صلاحية الباقه</label>
//                         <input type="number" min="1" className='w-100' value={expiry_date_in_days} onChange={(e) => setExpiry_date_in_days(e.target.value)} placeholder='صلاحية الباقه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>اضافة خدمة اضافيه اخري</label>
//                         <Form.Select aria-label="Default select example" onChange={handleServiceChange} style={{ background: "#EFF1F9" }}>
//                             <option value="" hidden>الخدمات الاضافيه</option>
//                             {
//                                 services ? (
//                                     services.map((service, index) => {
//                                         return (
//                                             <option value={service.id} key={service.id}>{service.name_en}</option>
//                                         )
//                                     })
//                                 ) : (null)
//                             }
//                         </Form.Select>
//                     </div>
//                     {selectedServices.length > 0 && (
//                         <div className="mt-4 my-3">
//                             {selectedServices.map((service, index) => (
//                                 <div key={index} className="d-flex align-items-center">
//                                     <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
//                                         {services.find(item => item.id === service.service_id)?.name_en}
//                                     </p>
//                                     <input
//                                         type="number"
//                                         min="1"
//                                         value={service.service_count}
//                                         onChange={(e) => handleCountChange(e, index)}
//                                         style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "8px", borderRadius: "5px", marginRight: "5px" }}
//                                     />
//                                     <IoCloseSharp onClick={() => handleRemoveService(service.id)} />
//                                 </div>
//                             ))}
//                         </div>
//                     )}
//                     <div className='mt-3'>
//                         <label>الخدمات الاضافيه</label>
//                         {
//                             packageServices && packageServices.length > 0 ? (
//                                 packageServices.map((packageIndex, index) => (
//                                     <div key={index} className="d-flex justify-content-between align-items-center">
//                                         <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
//                                             {packageIndex.service.name_en}
//                                             <input
//                                                 type="number"
//                                                 min="1"
//                                                 value={packageIndex.service_count}
//                                                 onChange={(e) => handleCountChangeExistData(e, index, packageIndex.id)}
//                                                 style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "8px", borderRadius: "5px", marginRight: "5px" }}
//                                             />
//                                         </p>
//                                         <IoCloseSharp onClick={() => markServiceForDeletion(packageIndex.id)} />
//                                     </div>
//                                 ))
//                             ) : (<p>لا توجد خدمات اضافيه لهذه الباقه</p>)
//                         }
//                     </div>
//                     <div className='mt-3'>
//                         <label>حدد عدد الغسلات </label>
//                         <input type="number" min="1" className='w-100' placeholder='حدد عدد الغسلات' value={wash_count} onChange={(e) => setWash_Count(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>سعر الغسلة الواحدة</label>
//                         <input type="number" min="1" className='w-100' placeholder='سعر الغسلة الواحدة' value={washSingle} onChange={(e) => setPriceWashSingle(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <label>إجمالي سعر الباقة</label>
//                         <input type="number" min="1" className='w-100' placeholder='إجمالي سعر الباقة' readOnly value={parseFloat((wash_count) * (washSingle)).toFixed(2)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={editdata}>
//                         اضافة
//                     </button>
//                     <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
//                         تراجع
//                     </button>
//                 </Modal.Footer>
//             </Modal>
//             {
//                 loader ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
//                     <span className="loader"></span>
//                 </div>) : null
//             }
//         </div>
//     );
// };

// export default UpdateBaqa;




import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { IoPencil, IoCloseSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { AddNewServiceToPackageData, CreatePhotoPackage, DeletePackageServicesData, GetAllBaqa, UpdateBaqaData, UpdateBaqaFormat, UpdateCountService } from '../../redux/actions/BaqaAction';

const UpdateBaqa = ({ idData, name, washCount, background, points, days, order, priceSingle, desc, totalPrice }) => {
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [services, setServices] = useState([]);
    const [selectedServices, setSelectedServices] = useState([]);
    const [service, setService] = useState('');
    const [servicesToDelete, setServicesToDelete] = useState([]); // State to hold services marked for deletion

    const handleClose = () => {
        setShow(false);
        setImages([]);
        dispatch(GetAllBaqa());
        setSelectedServices([]);
        setId("");
        setVal("");
    };

    const handleShow = () => {
        setShow(true);
    };

    const [images, setImages] = useState([]);
    const [uploadError, setUploadError] = useState(null);

    const handleImageUpload = async (event) => {
        const selectedImage = event.target.files[0];
        setImages([selectedImage]);
        setUploadError(null);
        const formData = new FormData();
        formData.append('file', selectedImage);

        try {
            await dispatch(CreatePhotoPackage(formData));
        } catch (error) {
            setUploadError(error.message || 'An error occurred during image upload.');
        }
    };

    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };

    useEffect(() => {
        if (show) {
            dispatch(UpdateBaqaData({ id: idData }));
        }
    }, [show]);

    const dataUpload = useSelector(state => state.BaqaReducer.UpdateData);

    const [nameAr, setNameAr] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [washSingle, setPriceWashSingle] = useState('');
    const [total_price_package, setTotal_Price_Package] = useState('');
    const [wash_count, setWash_Count] = useState('');
    const [description_ar, setDescription_ar] = useState('');
    const [description_en, setDescription_en] = useState('');
    const [expiry_date_in_days, setExpiry_date_in_days] = useState('');
    const [buy_by_points, setBuy_by_points] = useState('');
    const [order_by, setorder_by] = useState('');
    const [backgroundData, setBack] = useState('');
    const [loader, setLoader] = useState(false);
    const [packageServices, setPackageServices] = useState([]);
    const [countAddPackage, setCountAddPackage] = useState(0);

    useEffect(() => {
        if (dataUpload && dataUpload.data) {
            setNameAr(dataUpload.data.name);
            setNameEn(dataUpload.data.name_en);
            setPriceWashSingle(dataUpload.data.price_wash_single);
            setTotal_Price_Package(dataUpload.data.total_price_package);
            setWash_Count(dataUpload.data.wash_count);
            setDescription_ar(dataUpload.data.description);
            setDescription_en(dataUpload.data.description_en);
            setExpiry_date_in_days(dataUpload.data.expiry_date_in_days);
            setBuy_by_points(dataUpload.data.buy_by_points);
            setorder_by(dataUpload.data.order_by);
            setBack(dataUpload.data.background_url);
            setPackageServices(dataUpload.data.package_service);
        }
    }, [dataUpload]);

    const editdata = async () => {
        const formData = new FormData();
        setLoader(true);
        try {
            formData.append('name_ar', nameAr);
            formData.append('name_en', nameEn);
            formData.append('is_active', `true`);
            formData.append('order_by', parseInt(order_by));
            formData.append('price_wash_single', parseFloat(washSingle).toFixed(2));
            formData.append('wash_count', parseFloat(wash_count));
            formData.append('total_price_package', parseFloat((wash_count) * (washSingle)).toFixed(2));
            formData.append('description_ar', description_ar || "");
            formData.append('description_en', description_en);
            formData.append('expiry_date_in_days', parseInt(expiry_date_in_days));
            formData.append('file', images[0]);
            await dispatch(UpdateBaqaFormat(formData, idData));
            await editCountServices(idservice, parseInt(val));
            if (service !== "") {
                addNewServiceToPackage();
            }
            for (const serviceId of servicesToDelete) {
                await dispatch(DeletePackageServicesData({ package_service_id: serviceId }));
            }
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            dispatch(GetAllBaqa());
            setImages([]);
            setService('');
            setLoader(false);
        }
    };

    const resultServices = useSelector(state => state.ServicesReducer.allServices);

    useEffect(() => {
        if (resultServices && resultServices.data) {
            setServices(resultServices.data);
        }
    }, [resultServices]);

    const handleServiceChange = (e) => {
        const serviceId = e.target.value;
        const serviceCount = 0;
        setService(e.target.value);
        if (!selectedServices.some(service => service.id === serviceId)) {
            setSelectedServices([...selectedServices, { package_id: idData, service_id: serviceId, service_count: parseInt(serviceCount), is_active: true }]);
        }
    };

    const handleRemoveService = (serviceIdToRemove) => {
        const updatedServices = selectedServices.filter(service => service.id !== serviceIdToRemove);
        setSelectedServices(updatedServices);
    };

    const handleCountChange = (e, index) => {
        const { value } = e.target;
        setCountAddPackage(e.target.value);
        const updatedServices = [...selectedServices];
        updatedServices[index].service_count = parseInt(value);
        setSelectedServices(updatedServices);
    };

    const [val, setVal] = useState("");
    const [idservice, setId] = useState("");

    const handleCountChangeExistData = async (e, index, id) => {
        const { value } = e.target;
        const updatedPackageServices = [...packageServices];
        updatedPackageServices[index].service_count = parseInt(value);
        setPackageServices(updatedPackageServices);
        setVal(value);
        setId(id);
    };

    const addNewServiceToPackage = async () => {
        try {
            await dispatch(AddNewServiceToPackageData({ package_services: selectedServices }));
        } catch (e) {
            console.log(e);
        }
    };

    const editCountServices = async (id, serviceCount) => {
        try {
            await dispatch(UpdateCountService({ id: id, service_count: serviceCount, is_active: true }));
        } catch (e) {
            console.log(e);
        }
    };

    const markServiceForDeletion = (idServices) => {
        setServicesToDelete([...servicesToDelete, idServices]);
        setPackageServices(packageServices.filter(service => service.id !== idServices));
    };

    return (
        <div>
            <IoPencil size="20" onClick={handleShow} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل الباقه</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <div className='w-50'>
                            <img src={backgroundData} alt="data" style={{ width: "100%" }} />
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <div className="w-100" style={{ background: "#EFF1F9", padding: "20px", borderRadius: "10px", width: "40%" }}>
                                    {images.map((image, index) => (
                                        <div key={index}>
                                            <img src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                                style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="file-input" style={{ padding: "20px", textAlign: "left" }}>
                                <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                                <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                            </div>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <label>اسم الباقة بالعربي</label>
                        <input type="text" className='w-100' value={nameAr} onChange={(e) => setNameAr(e.target.value)} placeholder=' اسم الباقة بالعربي' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label> اسم الباقة بالانجليزي</label>
                        <input type="text" className='w-100' value={nameEn} onChange={(e) => setNameEn(e.target.value)} placeholder=' اسم الباقة بالانجليزي' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label> وصف الباقة بالعربيه</label>
                        <input type="text" className='w-100' placeholder='وصف الباقة بالعربيه' value={description_ar} onChange={(e) => setDescription_ar(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>وصف الباقة بالانجليزيه </label>
                        <input type="text" className='w-100' placeholder='وصف الباقة بالانجليزيه' value={description_en} onChange={(e) => setDescription_en(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>ترتيب الباقه  </label>
                        <input type="number" min="1" className='w-100' placeholder='ترتيب الباقه' value={order_by} onChange={(e) => setorder_by(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>صلاحية الباقه</label>
                        <input type="number" min="1" className='w-100' value={expiry_date_in_days} onChange={(e) => setExpiry_date_in_days(e.target.value)} placeholder='صلاحية الباقه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>اضافة خدمة اضافيه اخري</label>
                        <Form.Select aria-label="Default select example" onChange={handleServiceChange} style={{ background: "#EFF1F9" }}>
                            <option value="" hidden>الخدمات الاضافيه</option>
                            {
                                services ? (
                                    services.map((service, index) => {
                                        return (
                                            <option value={service.id} key={service.id}>{service.name_en}</option>
                                        )
                                    })
                                ) : (null)
                            }
                        </Form.Select>
                    </div>
                    {selectedServices.length > 0 && (
                        <div className="mt-4 my-3">
                            {selectedServices.map((service, index) => (
                                <div key={index} className="d-flex align-items-center">
                                    <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
                                        {services.find(item => item.id === service.service_id)?.name_en}
                                    </p>
                                    <input
                                        type="number"
                                        min="1"
                                        value={service.service_count}
                                        onChange={(e) => handleCountChange(e, index)}
                                        style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "8px", borderRadius: "5px", marginRight: "5px" }}
                                    />
                                    <IoCloseSharp onClick={() => handleRemoveService(service.id)} />
                                </div>
                            ))}
                        </div>
                    )}
                    <div className='mt-3'>
                        <label>الخدمات الاضافيه</label>
                        {
                            packageServices && packageServices.length > 0 ? (
                                packageServices.map((packageIndex, index) => (
                                    <div key={index} className="d-flex justify-content-between align-items-center">
                                        <p className='mx-1' style={{ background: "#FFFAEC", padding: "10px" }}>
                                            {packageIndex.service.name_en}
                                            <input
                                                type="number"
                                                min="1"
                                                value={packageIndex.service_count}
                                                onChange={(e) => handleCountChangeExistData(e, index, packageIndex.id)}
                                                style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "8px", borderRadius: "5px", marginRight: "5px" }}
                                            />
                                        </p>
                                        <IoCloseSharp onClick={() => markServiceForDeletion(packageIndex.id)} />
                                    </div>
                                ))
                            ) : (<p>لا توجد خدمات اضافيه لهذه الباقه</p>)
                        }
                    </div>
                    <div className='mt-3'>
                        <label>حدد عدد الغسلات </label>
                        <input type="number" min="1" className='w-100' placeholder='حدد عدد الغسلات' value={wash_count} onChange={(e) => setWash_Count(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>سعر الغسلة الواحدة</label>
                        <input type="number" min="1" className='w-100' placeholder='سعر الغسلة الواحدة' value={washSingle} onChange={(e) => setPriceWashSingle(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>إجمالي سعر الباقة</label>
                        <input type="number" min="1" className='w-100' placeholder='إجمالي سعر الباقة' readOnly value={parseFloat((wash_count) * (washSingle)).toFixed(2)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={editdata}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
            {
                loader ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }
        </div>
    );
};

export default UpdateBaqa;
