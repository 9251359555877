


import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { AddCarModalBrand, GetAllBrands, GetAllCars } from '../../redux/actions/CarActions';
import { MdCatchingPokemon } from 'react-icons/md';
import Select from 'react-select'

const AddNewBrand = () => {
    const [show, setShow] = useState(false);
    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [brandId, setBrandId] = useState("");
    const [searchTerm, setSearchTerm] = useState(""); // حالة محلية لتخزين قيمة البحث

    const handleClose = () => {
        setShow(false);
        resetFields();
    }

    const resetFields = () => {
        setNameAr("");
        setNameEn("");
        setBrandId("");
        setValidTitleEn(false);
        setValidTitleAr(false);
    }

    const handleShow = () => {
        setShow(true)
    }

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(GetAllBrands());
    }, []);

    const brands = useSelector(state => state.CarReducer.BrandsCars);
    const brandsData = brands.data || [];

    const handleBrandChange = (event) => {
        const selectedBrandId = event.target.value;
        setBrandId(selectedBrandId);
        const selectedBrand = brandsData.find(brand => brand.id === selectedBrandId);
        setSelectedBrand(selectedBrand);
    };

    const [selectedBrand, setSelectedBrand] = useState(null);
    const [loading, setLoading] = useState(false);
    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);

    const AddCarBrandModal = async () => {
        try {
            setLoading(true);
            await dispatch(AddCarModalBrand({
                name_ar: nameAr,
                name_en: nameEn,
                vehicle_brand_id: brandId
            }));
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
            resetFields();
            setSelectedBrand(null)
            await dispatch(GetAllCars({
                pageCount: 1,
                sort: "DESC"
            }))
        }
    }



    const isArabic = (text) => {
        return /[\u0600-\u06FF]/.test(text);
    }

    const isEnglish = (text) => {
        return /^[a-zA-Z\s]*$/.test(text);
    }



    // Search inside dropdown menu 
    const options = brandsData.map(brand => ({
        value: brand.id, // قيمة المعرف للعلامة التجارية
        label: isArabic(searchTerm) ? brand.name_ar : brand.name, // العنوان المعروض (بالعربية إذا كانت اللغة البحث عربية)
        logo: brand.logo // رابط الشعار لعرض الصورة إذا لزم الأمر
    }));
    return (
        <div>
            <button onClick={handleShow} className='btn mx-2' style={{ color: "black", background: "#FECB44" }}>اضافة سياره جديدة</button>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة سياره جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>معلومات البراند</span>
                    {/* <div className='mt-3'>
                        <Form.Control
                            type="text"
                            placeholder="ابحث عن البراند"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div> */}


                    <div>
                        <Select
                            options={options} // استخدام قائمة الخيارات هنا
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    setBrandId(selectedOption.value); // تحديد معرف البراند عند تغيير الاختيار
                                    const selectedBrand = brandsData.find(brand => brand.id === selectedOption.value);
                                    setSelectedBrand(selectedBrand); // تحديد البراند المختار للعرض
                                } else {
                                    setBrandId(""); // إذا تم إلغاء تحديد الخيار
                                    setSelectedBrand(null);
                                }
                            }}
                        />
                    </div>


                    <div className='mt-3'>
                        {/* <Form.Select aria-label="Default select example" value={brandId} className='mySelectClass' style={{ background: "#EFF1F9" }} onChange={handleBrandChange}>
                            <option value="" hidden>حدد البراند</option>
                            {brandsData.filter(brand => {
                                if (isArabic(searchTerm)) {
                                    return brand.name_ar.toLowerCase().includes(searchTerm.toLowerCase());
                                }
                                else if (isEnglish(searchTerm)) {
                                    return brand.name.toLowerCase().includes(searchTerm.toLowerCase());
                                }
                                else {
                                    return brand.name.toLowerCase().includes(searchTerm.toLowerCase())
                                }
                            }).map(brand => (
                                <option key={brand.id} value={brand.id} className="brandOption">
                                    <img src={`${brand.logo}`} alt="img" />
                                    {isArabic(searchTerm) ? brand.name_ar : brand.name}
                                </option>
                            ))}
                        </Form.Select> */}

                        {selectedBrand && (
                            <img src={selectedBrand.logo} alt={selectedBrand.name} />
                        )}
                    </div>
                    <div className='mt-3'>
                        <input
                            type="text"
                            className='w-100'
                            onChange={(e) => {
                                setNameEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidTitleEn(true);
                                } else {
                                    setValidTitleEn(false);
                                }
                            }}
                            value={nameEn}
                            placeholder='اسم مودل بالانجليزية'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                    </div>
                    {validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null}
                    <div className='mt-3'>
                        <input
                            type="text"
                            className='w-100'
                            onChange={(e) => {
                                setNameAr(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidTitleAr(true);
                                } else {
                                    setValidTitleAr(false);
                                }
                            }}
                            value={nameAr}
                            placeholder='اسم مودل بالعربيه'
                            style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                        />
                    </div>
                    {validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null}
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={AddCarBrandModal}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddNewBrand;



