import baseUrl from "../../Api/baseURL";
import { ErrorMsg } from "../../Utils/Toast";
import { GET_ALL_CODES, ADD_CODE, DELETE_CODE, UPDATE_CODE  , GET_SINGLE_CODE , UPDATE_MAIN_CODE} from '../Type'

//get All Areas
const GetAllPromoCodes = (data) => {
    // console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.search === "" || data.search === undefined) {
                let res = await baseUrl.get(`v1/promo-code/admin?page=${data.page}&limit=10&sortBy=created_at%3D${data.sort}&isDeleted=false`, config)
                dispatch({ type: GET_ALL_CODES, payload: res.data })
                // console.log(res);
            } else if (data.search !== "") {
                let res = await baseUrl.get(`v1/promo-code/admin?page=1&limit=10&filters=code%3D${data.search}&sortBy=created_at%3D${data.sort}`, config)
                dispatch({ type: GET_ALL_CODES, payload: res.data })
            }
        } catch (e) {
            dispatch({ type: GET_ALL_CODES, payload: e.res })
            console.log(e)
        }
    }
}

//Add Promo code
const AddPromoCode = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.post(`v1/promo-code/create-new-promo-code`, data, config)
            dispatch({ type: ADD_CODE, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: ADD_CODE, payload: e.res })
            console.log(e)
            if (e.response.status === 422) {
                ErrorMsg(e.response.data.message)
            }else{
                ErrorMsg(e.response.data.message)
            }
        }
    }
}


//Delete Promo Code
const DeletePromoCode = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/promo-code/main/${data.id}`, config)
            dispatch({ type: DELETE_CODE, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_CODE, payload: e.res })
            console.log(e)
        }
    }
}
//Delete Promo Code
const DeleteSingleCode = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.delete(`v1/promo-code/delete/${data.id}`, config)
            dispatch({ type: DELETE_CODE, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: DELETE_CODE, payload: e.res })
            console.log(e)
        }
    }
}
//Update Promo Code
const UpdateCode = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/promo-code/edit`, data, config)
            dispatch({ type: UPDATE_CODE, payload: res.data })
            console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_CODE, payload: e.res })
            console.log(e)
        }
    }
}

//Get Single Promo Code
const GetSinglePromoCode = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.get(`v1/promo-code/admin?page=1&limit=10&filters=id%3D${data.id}`, config)
            dispatch({ type: GET_SINGLE_CODE, payload: res.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: GET_SINGLE_CODE, payload: e.res })
            console.log(e)
        }
    }
}

//Edit Main Code
const EditMainCode = (data) => {
    console.log(data);
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            let res = await baseUrl.put(`v1/promo-code/main/${data.id}`, data,config)
            dispatch({ type: UPDATE_MAIN_CODE, payload: res.data })
            // console.log(res);
        } catch (e) {
            dispatch({ type: UPDATE_MAIN_CODE, payload: e.res })
            console.log(e)
        }
    }
}

export { GetAllPromoCodes, AddPromoCode, DeletePromoCode, UpdateCode, GetSinglePromoCode , EditMainCode , DeleteSingleCode}