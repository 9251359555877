import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { IoEllipseSharp, IoPencil } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux'
import { MdModeEdit } from "react-icons/md";
import { GetNotificationBIKER_ARRIVED, GetNotificationBIKER_ASSIGN, GetNotificationBIKER_ON_THE_WAY, GetNotificationORDER_CANCELED, GetNotificationORDER_COMPLETED, GetNotificationORDER_START, UpdateNotifyData } from '../../redux/actions/NotificationAction';
const UpdateNotify = ({ type, messageAr, messageEN, titleAr,
    titleEn }) => {
    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);

    const [titleArChange, setTitlAr] = useState(titleAr)
    const [titleEnChange, setTitlEn] = useState(titleEn)
    const [messageArChange, setMessageAr] = useState(messageAr)
    const [messageENChange, setMessageEN] = useState(messageEN);

    const [validTitleEn, setValidTitleEn] = useState(false);
    const [validTitleAr, setValidTitleAr] = useState(false);
    const dispatch = useDispatch();


    const updateDataNotify = async () => {
        try {
            await dispatch(UpdateNotifyData({
                title_en: titleEnChange,
                title_ar: titleArChange,
                message_en: messageENChange,
                message_ar: messageArChange,
                message_type: type,
            }))
        } catch (e) {
            console.log(e);
        } finally {
            handleCloseEdit();
            if (type === "ORDER_START") {
                await dispatch(GetNotificationORDER_START())

            } else if (type === "ORDER_COMPLETED") {
                await dispatch(GetNotificationORDER_COMPLETED())

            } else if (type === "ORDER_CANCELED") {
                await dispatch(GetNotificationORDER_CANCELED())

            } else if (type === "BIKER_ON_THE_WAY") {
                await dispatch(GetNotificationBIKER_ON_THE_WAY())

            } else if (type === "BIKER_ARRIVED") {
                await dispatch(GetNotificationBIKER_ARRIVED())

            } else if (type === "BIKER_ASSIGN") {
                await dispatch(GetNotificationBIKER_ASSIGN())

            }
        }
    }
    return (
        <div>
            <MdModeEdit size={"20"} color='#6B7280' onClick={handleShowEdit} cursor={"pointer"} />
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>تعديل الاشعار من النوع {type}  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>عنوان الاشعار بالعربي</label>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={titleArChange}
                            onChange={(e) => {
                                setTitlAr(e.target.value);
                                if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                                    setValidTitleAr(true)
                                } else {
                                    setValidTitleAr(false);
                                }
                            }}
                            placeholder='اسم الخدمه بالعربيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {/* {
                        validTitleAr ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه العربية</small>) : null
                    } */}
                    <div className='mt-3'>
                        <label>عنوان الاشعار باالانجليزيه</label>
                        <input type="text" className='w-100'
                            value={titleEnChange}
                            onChange={(e) => {
                                setTitlEn(e.target.value);
                                if (/[\u0600-\u06FF]/.test(e.target.value)) {
                                    setValidTitleEn(true)
                                } else {
                                    setValidTitleEn(false);
                                }
                            }}
                            placeholder='اسم الخدمه بالانجليزيه' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    {/* {
                        validTitleEn ? (<small style={{ color: "red" }}>يجب ان يكون النص باللغه الانجليزيه</small>) : null
                    } */}
                    <div className='mt-3'>
                        <label>عنوان النص بالعربيه</label>
                        <input type="text" className='w-100'
                            value={messageArChange}
                            onChange={(e) => setMessageAr(e.target.value)}
                            placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>عنوان النص بالانجليزيه</label>
                        <input type="text" className='w-100'
                            value={messageENChange}
                            onChange={(e) => setMessageEN(e.target.value)}
                            placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateDataNotify}>
                        اضافه
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleCloseEdit}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdateNotify
