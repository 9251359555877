import React, { useEffect, useState } from 'react'
import { IoPencil } from "react-icons/io5";
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal';
import { EditMainCode, GetAllPromoCodes, GetSinglePromoCode, UpdateCode } from '../../redux/actions/PromoCodeAction';
import Form from 'react-bootstrap/Form';
import { GetAllBaqa } from '../../redux/actions/BaqaAction';
import { IoCloseSharp } from "react-icons/io5";

import DateTime from 'react-datetime';
const UpdatePromoCode = ({ idData, currentPage,
    sort, searchWord, discountData, packageID, StartTime,
    EndTime }) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [shouldUpdateCode, setShouldUpdateCode] = useState(false); // Flag to control updateCodeCom

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true);
    }


    const [selectedDate, setSelectedDate] = useState();
    const [selectedDateEnd, setSelectedDateEnd] = useState();
    const [selectBaqa, setSelectBaqa] = useState(null);

    useEffect(() => {
        if (show) {
            dispatch(GetSinglePromoCode({
                id: idData
            }))
        }
    }, [dispatch, show])

    const getInfoSingleCode = useSelector(state => state.PromoCodesReducer.SingleCode);

    useEffect(() => {
        if (getInfoSingleCode && getInfoSingleCode.data) {
            setNameCode(getInfoSingleCode.data[0].code)
            setCurrentUses(getInfoSingleCode.data[0].current_uses)
            setSelectedDateEnd(new Date(getInfoSingleCode.data[0].end_time))
            setSelectedDate(new Date(getInfoSingleCode.data[0].start_time))
            setMaxUsers(getInfoSingleCode.data[0].max_use_by_users)
            setPackagesData(getInfoSingleCode.data[0].promo_code_packages)
            // console.log(getInfoSingleCode.data[0]);
        }
    }, [getInfoSingleCode])


    const handleBaqaAdd = async (e) => {
        const serviceId = e.target.value;
        setSelectBaqa(e.target.value);
        // console.log(e.target.value)
        if (!selectedBaqat.includes(serviceId)) {
            setSelectedBaqat([...selectedBaqat, serviceId]);
        }
    }
    const handleRemoveService = (serviceIdToRemove) => {
        const updatedServices = selectedBaqat.filter(serviceId => serviceId !== serviceIdToRemove);
        setSelectedBaqat(updatedServices);
    };

    const [baqaDiscounts, setBaqaDiscounts] = useState({});

    const handleBaqaDiscountChange = (serviceId, discount) => {
        setBaqaDiscounts({ ...baqaDiscounts, [serviceId]: parseInt(discount) });

    };

    const handleDateChange = (date) => {
        // console.log(date.toISOString());
        setSelectedDate(date);
    };
    const handleDateChangeEnd = (date) => {
        // console.log(date.toISOString());
        setSelectedDateEnd(date);
    };




    const [nameCode, setNameCode] = useState("");
    const [currentUses, setCurrentUses] = useState(0);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [maxByUsers, setMaxUsers] = useState(0);
    const [packagesData, setPackagesData] = useState([]);




    // if(getInfoSingleCode){
    //     console.log(getInfoSingleCode);
    // }






    const fetchAllBaqa = async () => {
        await dispatch(GetAllBaqa());
    }



    useEffect(() => {
        fetchAllBaqa();
    }, [dispatch])

    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);


    const [selectedBaqat, setSelectedBaqat] = useState('');
    const [discount, setDiscount] = useState(discountData);
    const [packageId, setSelectPackageID] = useState('');
    const [dis, setDis] = useState('');
    const [mainId, setMainID] = useState('');
    const [index, setIndex] = useState('');
    const [indexDis, setDisIndex] = useState('');


    // const handleBaqa = async (e) => {
    //     setSelectedBaqat(e.target.value);
    // }



    const updateCodeCom = async () => {
        const discountToUpdate = dis ? parseFloat(dis / 100) : parseFloat(packagesData[parseInt(indexDis)].discount);
        const packageToUpdate = packageId ? packageId : packagesData[parseInt(index)].package_id;
        const mainIdUpdate = mainId ? mainId : packagesData[parseInt(index)].id;

        try {
            await dispatch(UpdateCode({
                package_id: packageToUpdate,
                id: mainIdUpdate,
                discount: discountToUpdate
            }))

        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            setSelectPackageID("")
            setDis("")
            setMainID("")
            await dispatch(GetAllPromoCodes({ page: currentPage, sort: sort, search: searchWord }));
        }
    }



    const handleBaqa = async (e, index, baqaDataId) => {
        const selectedPackageId = e.target.value;
        // console.log(selectedPackageId);
        setSelectPackageID(selectedPackageId)
        setMainID(baqaDataId)
        setDisIndex(index);
        const updatedPackagesData = [...packagesData];
        updatedPackagesData[index].package_id = selectedPackageId;
        setPackagesData(updatedPackagesData);

        setShouldUpdateCode(true);
    }

    // const handleDiscountChange = (e, index) => {
    //     setIndex(index)
    //     const updatedPackagesData = [...packagesData];
    //     setDis(e.target.value);
    //     updatedPackagesData[index].discount = e.target.value;
    //     setPackagesData(updatedPackagesData);

    //     setShouldUpdateCode(true);
    // }

    const handleDiscountChange = (e, index) => {
        setIndex(index);
        const updatedPackagesData = [...packagesData];
        setDis(e.target.value);
        const newDiscount = parseFloat(e.target.value) / 100;
        updatedPackagesData[index].discount = newDiscount;
        setPackagesData(updatedPackagesData);

        setShouldUpdateCode(true);
    };

    // if (packagesData) {
    //     console.log(packagesData);
    // }



    const editmainCode = async () => {
        const promoCodePackageRequest = selectedBaqat.length > 0 ? (
            selectedBaqat.map(pkg => ({
                package_id: pkg,
                discount: (baqaDiscounts[pkg] / 100)
            }))
        ) : null
        // console.log(promoCodePackageRequest);
        try {
            await dispatch(EditMainCode({
                id: idData,
                start_time: selectedDate.toISOString() || getInfoSingleCode.data[0].start_time,
                end_time: selectedDateEnd.toISOString() || getInfoSingleCode.data[0].end_time,
                code: nameCode,
                promoCodePackageRequest: promoCodePackageRequest,
                max_use_by_users: parseInt(maxByUsers)
            }))
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            await dispatch(GetAllPromoCodes({ page: currentPage, sort: sort, search: searchWord }));
            if (shouldUpdateCode) {
                updateCodeCom(); // Update code if necessary
            }
            setShouldUpdateCode(false);
            setSelectBaqa(null)
            setSelectedBaqat('');
        }
    }


    const validDate = (current) => {
        // return current.isAfter(selectedDate);
        return current.isSameOrAfter(new Date(), 'day');
    };

    return (
        <div>
            <IoPencil size="20" onClick={handleShow} cursor={"pointer"} />
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل كود الخصم</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <label>اسم الكوبون</label>
                        <input type="text" className='w-100'
                            value={nameCode}
                            onChange={(e) => setNameCode(e.target.value)}
                            placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <label>عدد المستخدمين الحاليين</label>
                        <input type="text" className='w-100'
                            readOnly
                            disabled
                            value={currentUses}
                            onChange={(e) => setCurrentUses(e.target.value)}
                            placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <label>عدد مرات الاستخدام	</label>
                        <input type="text" className='w-100'
                            value={maxByUsers}
                            onChange={(e) => setMaxUsers(e.target.value)}
                            placeholder='' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <label>تاريخ بداية الكوبون</label>

                        <DateTime className='w-100 mt-2 textInputBiker'
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                            utc={false} value={selectedDate} isValidDate={validDate} onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                    </div>
                    <div className='mt-3'>
                        <label>تاريخ نهاية الكوبون</label>

                        <DateTime className='w-100 mt-2 textInputBiker' utc={false}
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                            value={selectedDateEnd} isValidDate={validDate} onChange={handleDateChangeEnd} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                    </div>
                    <div className='mt-3'>
                        <p>الباقات</p>
                        {
                            packagesData && packagesData.length ? (
                                packagesData.map((baqaData, index) => {
                                    return (
                                        <div className='mt-3' key={index}>
                                            <Form.Select aria-label="Default select example" className='mt-3' value={baqaData.package_id} onChange={(e) => handleBaqa(e, index, baqaData.id)} style={{ background: "#EFF1F9" }}>
                                                <option hidden> الباقات</option>
                                                {
                                                    dataBaqat && dataBaqat.data ? (
                                                        dataBaqat.data.map((baqa, index) => {
                                                            return (
                                                                <option value={baqa.id} key={baqa.id}>{baqa.name} </option>
                                                            )
                                                        })
                                                    ) : (null)
                                                }
                                            </Form.Select>

                                            <input
                                                type="number"
                                                value={baqaData.discount * 100} // Multiply by 100 to display correctly
                                                onChange={(e) => handleDiscountChange(e, index)}
                                                className='w-100 mt-2'
                                                placeholder="النسبه المئويه للخصم"
                                                style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}
                                            />
                                        </div>
                                    )
                                })
                            ) : null
                        }

                    </div>


                    <div className='mt-4'>
                        <label>اضافة باقه جديده للكوبون</label>

                        <Form.Select aria-label="Default select example" value={selectBaqa} onChange={handleBaqaAdd} style={{ background: "#EFF1F9" }}>
                            <option hidden> الباقات</option>
                            {
                                dataBaqat && dataBaqat.data ? (
                                    dataBaqat.data.map((baqa, index) => {
                                        return (
                                            <option value={baqa.id} key={baqa.id}>{baqa.name} </option>
                                        )
                                    })
                                ) : (null)
                            }
                        </Form.Select>
                        {selectedBaqat.length > 0 && (
                            <div className="mt-4">
                                {selectedBaqat.map((serviceId, index) => (
                                    <div className=" mx-1 my-2" key={index} style={{ background: "#FFFAEC", padding: "10px", borderRadius: "10px" }}>
                                        <p className="mb-0">{dataBaqat.data.find(baqa => baqa.id === serviceId).name}</p>
                                        <input
                                            type="number"
                                            min="0"
                                            value={baqaDiscounts[serviceId] || ""}
                                            onChange={(e) => handleBaqaDiscountChange(serviceId, e.target.value)}
                                            className="mx-2"
                                            placeholder="Discount"
                                        />
                                        <IoCloseSharp onClick={() => handleRemoveService(serviceId)} />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={editmainCode}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default UpdatePromoCode
