import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { FaSearch } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { DeleteBanner, GetAllBanners, PostBanner, UpdateBannerActive } from '../../redux/actions/BannerAction';
import Pagination from '../../Utils/Pagination';
import DeleteBannerCom from '../Modals/DeleteBanner';
import UpdateBannerComponenet from '../Modals/UpdateBanner';
import { ErrorMsg } from '../../Utils/Toast';
import moment from 'moment';
import DateTime from 'react-datetime';
const Ads = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState("DESC");
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [order_by, setOrder_by] = useState('');

    const [nameBaner, setNameBanner] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [is_popup, setIs_popup] = useState('');

    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }

    //Upload Images
    const [images, setImages] = useState([]);
    const handleImageUpload = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);
    }
    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };


    //Manage Date with Time For Banners
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedDateEnd, setSelectedDateEnd] = useState("");

    const handleDateChange = (date) => {
        // console.log(date.toISOString());
        setSelectedDate(date.toISOString());
    };
    const handleDateChangeEnd = (date) => {
        // console.log(date.toISOString());
        setSelectedDateEnd(date.toISOString());
    };



    //

    const dispatch = useDispatch();

    const [filterBanners, setFilterBanners] = useState([]);

    const fetchBanners = async (page, sort, searchValue) => {
        if (searchValue === "") {
            await dispatch(GetAllBanners({ page, sort, name: searchValue }));
        } else {
            await dispatch(GetAllBanners({ page: 1, sort, name: searchValue }));
        }
    }

    useEffect(() => {
        fetchBanners(currentPage, sort, searchValue);
    }, [dispatch, sort, currentPage, searchValue])

    const banners = useSelector(state => state.BannerReducer.AllBanners);

    useEffect(() => {
        if (banners && banners.data) {
            setFilterBanners(banners)
        }
    }, [banners, banners.data]);

    // add New Banner
    const postNewBanner = async () => {
        const formatData = new FormData();
        setLoading(true)
        try {
            if (nameBaner !== "" && selectedDate !== "" &&
                selectedDateEnd !== "" && images.length > 0 && order_by !== "") {
                formatData.append('name', nameBaner)
                formatData.append('end_time', selectedDateEnd)
                formatData.append('start_time', selectedDate)
                formatData.append('order_by', parseInt(order_by))
                formatData.append('is_popup', is_popup)
                formatData.append('file', images[0])
                await dispatch(PostBanner(formatData))
                setNameBanner('')
                setStartDate('')
                setEndDate('')
                setIs_popup('');
                setOrder_by('');
                setImages([]);
            } else {
                if (nameBaner === "") {
                    ErrorMsg("اسم الاعلان مطلوب");
                    return;
                }

                if (selectedDate === "") {
                    ErrorMsg("تاريخ بداية الاعلان مطلوب");
                    return;
                }

                if (selectedDateEnd === "") {
                    ErrorMsg("تاريخ نهاية الاعلان مطلوب");
                    return;
                }

                if (images.length === 0) {
                    ErrorMsg("يرجى اختيار صورة للاعلان");
                    return;
                }

                if (order_by === "") {
                    ErrorMsg("حقل ترتيب الاعلان مطلوب");
                    return;
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            if (nameBaner !== "" && selectedDate !== "" && selectedDateEnd !== "" && images.length > 0 && order_by !== "") {
                dispatch(GetAllBanners({ page: currentPage, sort, name: "" }));
                handleClose();
            }
        }
    }



    const getPage = (page) => {
        setCurrentPage(page);
        if (searchValue === "") {
            fetchBanners(page, sort);
        } else {
            fetchBanners(page, sort, searchValue);
        }
    };


    const changeActiveBanner = async (id, active) => {
        const formatData = new FormData();

        formatData.append('is_active', !active)
        await dispatch(UpdateBannerActive(formatData, id));
        fetchBanners(currentPage, sort, searchValue);
    }


    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;


    console.log(is_popup)


    const validDate = (current) => {
        // Allow today and future dates
        return current.isSameOrAfter(new Date(), 'day');
    };


    return (
        <div className='font'>
            <div className='flexItem font py-3'>
                <div>
                    <p>ادارة الاعلانات</p>
                </div>
                <div>
                    <button className='btn' style={{ color: "black", background: "#FECB44" }} onClick={handleShow}>اضافة بنر اعلاني</button>
                </div>
            </div>

            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={(e) => setSort(e.target.value)}>
                        <option hidden> الترتيب تنازلي</option>
                        <option value="DESC"> تنازلي</option>
                        <option value="ASC"> تصاعدي</option>
                    </Form.Select>
                </div>
            </div>
            {/* Table */}
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>صورة الاعلان</th>
                            <th>اسم الاعلان</th>
                            <th>نوع الاعلان</th>
                            <th>تايخ انشاء الاعلان </th>
                            <th>تاريخ بداية الاعلان</th>
                            <th>تاريخ نهاية الاعلان</th>
                            <th>حالة الاعلان</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filterBanners && filterBanners.data ? (

                                filterBanners.data.map((banner, index) => {
                                    // console.log(banner)
                                    const startDate = banner.start_time ? banner.start_time.split('T')[0] : 'لايوجد معاد';
                                    const endDate = banner.end_time ? banner.end_time.split('T')[0] : 'لايوجد معاد';
                                    const createdAt = banner.created_at ? banner.created_at.split('T')[0] : 'لايوجد معاد';
                                    return (
                                        <tr key={index} style={{ lineHeight: "50px" }}>
                                            <td>{index + 1}</td>

                                            <td style={{ width: "75px", height: "75px" }}>
                                                {/* <div style={{ backgroundImage: `url(${banner.image})`, width: "100%", height: "100%", backgroundSize: "cover", backgroundPosition: "center" }}></div> */}
                                                <img className='w-100' src={banner.image} alt="data" />
                                            </td>

                                            <td>{banner.name}</td>
                                            {/* <td>{banner.id}</td>  */}
                                            <td>
                                                {
                                                    banner.order === 1 ? (<span>بانر اعلاني</span>) : (<span>سوابر اعلاني</span>)
                                                }
                                            </td>
                                            <td>{createdAt}</td>
                                            <td>{startDate}</td>
                                            <td>{endDate}</td>
                                            <td>
                                                {
                                                    banner.is_active === true ? (<td>نشط</td>) : (<td>غير نشط</td>)
                                                }
                                            </td>
                                            <td className='mt-3'>
                                                <Form.Check
                                                    className='mt-3'
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked={banner.is_active}
                                                    onClick={() => { changeActiveBanner(banner.id, banner.is_active) }}
                                                />
                                            </td>
                                            <td>
                                                <UpdateBannerComponenet bannerOrder={banner.order === 1 ? ("true") : ("false")} imageBanner={banner.image} idData={banner.id} orderBy={banner.order_by} nameBanner={banner.name} startDateInfo={banner.start_time} endDateInfo={banner.end_time} currentPage={currentPage} sort={sort} nameValue={searchValue} />
                                            </td>
                                            <td>
                                                <DeleteBannerCom idData={banner.id} currentPage={currentPage} sort={sort} nameValue={searchValue} />
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (<tr>
                                <td colSpan={12}>لا توجد اعلانات</td>
                            </tr>)
                        }
                    </tbody>
                </Table>
            </div>


            {/* Modal */}
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة بانر اعلاني</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>ارفع صورة البانر</span>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <div className="w-100" style={{ background: "#EFF1F9", padding: "20px", borderRadius: "10px", width: "40%" }}>
                                {images.map((image, index) => (
                                    <div>
                                        <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                            style={{ width: "50px", height: "50px", borderRadius: "10px" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="file-input" style={{ padding: "20px", textAlign: "left" }}>
                            <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                            <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100'
                            value={nameBaner}
                            onChange={(e) => setNameBanner(e.target.value)}
                            placeholder='اسم الاعلان' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <Form.Select aria-label="Default select example" value={is_popup} onChange={(e) => setIs_popup(e.target.value)} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }}>
                            <option hidden>نوع الاعلان</option>
                            <option value="false">سوابر  اعلاني</option>
                            <option value="true">بانر اعلاني</option>

                        </Form.Select>
                    </div>

                    <div className='mt-3'>
                        <input type="number"
                            value={order_by}
                            onChange={(e) => setOrder_by(e.target.value)}
                            placeholder='ترتيب الاعلان'
                            className='w-100' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>


                    <span style={{ color: "#8B8D97" }}>تاريخ بداية ونهاية الاعلان</span>
                    <div className='mt-3'>
                        <DateTime
                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                            closeOnSelect={true}
                            className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleDateChange} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />

                        {/* <DateTime className='w-100 mt-2 textInputBiker' isValidDate={validDate}  dateFormat="YYYY-MM-DD" timeFormat="HH:mm" /> */}
                    </div>
                    <div className='mt-3'>
                        <DateTime className='w-100 mt-2 textInputBiker' isValidDate={validDate} onChange={handleDateChangeEnd} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={postNewBanner}>
                        نشر الاعلان
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>

            {
                loading ? (<div style={{ position: "absolute", top: "0", left: "0", right: "0", bottom: "0", background: "rgba(255,255,255,0.3)", zIndex: "5000", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <span className="loader"></span>
                </div>) : null
            }


            {filterBanners && filterBanners.meta && (
                <Pagination onPress={getPage} countsPage={filterBanners.meta.total / 10} />
            )}
        </div>
    )
}

export default Ads
