import baseUrl from "../../Api/baseURL";
import { success } from "../../Utils/Toast";
import { GET_ALL_INVOICES, DOWNLOAD_INVOICE, GET_SETTINGS_INVOICE, GET_SETTINGS_INVOICE_UPDATE, UPLADO_DATA } from '../Type'

//get All Areas
const GetAllInvoices = (data) => {
    // console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {
            if (data.searchValue === "") {
                let res = await baseUrl.get(`v1/order-invoice/admin?page=${data.pageCount}&limit=10&sortBy=created_at%3D${data.sortType}&isDeleted=false`, config)
                dispatch({ type: GET_ALL_INVOICES, payload: res.data })
            } else if (data.searchValue !== "") {
                let res = await baseUrl.get(`v1/order-invoice/admin?page=${data.pageCount}&limit=10&filters=invoice_number%3D${data.searchValue}&sortBy=created_at%3D${data.sortType}&isDeleted=false`, config)
                dispatch({ type: GET_ALL_INVOICES, payload: res.data })
            }
            // console.log(res)
        } catch (e) {
            dispatch({ type: GET_ALL_INVOICES, payload: e.res })
            console.log(e)
        }
    }
}

const DonwloadInvoice = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            },
        }
        try {

            let res = await baseUrl.get(`v1/order-invoice/${data.id}/download`, config)
            dispatch({ type: DOWNLOAD_INVOICE, payload: res.data })

            console.log(res)
        } catch (e) {
            dispatch({ type: DOWNLOAD_INVOICE, payload: e.res })
            console.log(e)
        }
    }
}


const SettingInvoiceData = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.get(`v1/app-constants`, config)
            dispatch({ type: GET_SETTINGS_INVOICE, payload: res.data })

            console.log(res)
        } catch (e) {
            dispatch({ type: GET_SETTINGS_INVOICE, payload: e.res })
            console.log(e)
        }
    }
}
const UpdateSettingInvoiceData = (data) => {
    console.log(data)
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.put(`v1/app-constants`, data, config)
            dispatch({ type: GET_SETTINGS_INVOICE_UPDATE, payload: res.data })

            console.log(res)
            if (res.status === 200) {
                success(res.data.message)
            }
        } catch (e) {
            dispatch({ type: GET_SETTINGS_INVOICE_UPDATE, payload: e.res })
            console.log(e)
        }
    }
}

const UploadPhoto = (data) => {
    return async (dispatch) => {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Accept-Language": "ar"
            },
        }
        try {

            let res = await baseUrl.post(`v1/storage`, data, config)
            dispatch({ type: UPLADO_DATA, payload: res.data })
            console.log(res)
        } catch (e) {
            dispatch({ type: UPLADO_DATA, payload: e.res })
            console.log(e)
        }
    }
}



export { GetAllInvoices, DonwloadInvoice, SettingInvoiceData, UpdateSettingInvoiceData, UploadPhoto }