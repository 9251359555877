import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux'
import { CreateServices } from '../../redux/actions/ServicesAction';
import { ErrorMsg } from '../../Utils/Toast';
import { IoPencil } from "react-icons/io5";
import { SettingInvoiceData, UpdateSettingInvoiceData } from '../../redux/actions/InvoiceAction';



const ModalVat = ({ vat, title, type ,rateData , address}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [vatNumber, setVatNumber] = useState(vat)
    const [rate, setRate] = useState(rateData)
    const [addressData, setAddress] = useState(address)

    const dispatch = useDispatch();


    const updateData = async () => {
        try {
            if(type==="numberVat"){
                await dispatch(UpdateSettingInvoiceData({
                    vat_number: vatNumber
                }))
            }else if(type==="rate"){
                await dispatch(UpdateSettingInvoiceData({
                    tax_rate: parseFloat(rate)/100
                }))
            }else if(type==="address"){
                await dispatch(UpdateSettingInvoiceData({
                    company_address: addressData
                }))
            }
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
            await dispatch(SettingInvoiceData())
        }
    }



    // console.log(vatNumber)


    return (
        <div>
            {/* Modal */}
            <IoPencil style={{ marginLeft: "25px" }} size="20" onClick={handleShow} cursor={"pointer"} />
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <p className='fs-4'>{title}</p>
                </Modal.Header>
                <Modal.Body>
                    {
                        type === "numberVat" ? (
                            <input type='number' className='w-100'
                                value={vatNumber}
                                onChange={(e) => setVatNumber(e.target.value)}
                                style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                        ) : type === "rate" ? (
                            <input type='number' className='w-100'
                                value={rate}
                                onChange={(e) => setRate(e.target.value)}
                                style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                        ) : type==="address" ? (
                            <input type='text' className='w-100'
                                value={addressData}
                                onChange={(e) => setAddress(e.target.value)}
                                style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                        ):null
                    }
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateData}>
                        اضافه
                    </button>
                    <button className='btn' onClick={handleClose} style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalVat
