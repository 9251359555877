import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { IoPencil } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { DeleteSlot, GetAllSlots, GetAvailableSlots, UpdateSlots } from '../../redux/actions/Slots';
import Form from 'react-bootstrap/Form';
import { FaSearch } from "react-icons/fa";
import { GET_ALL_SLOTS } from '../../redux/Type';
import Modal from 'react-bootstrap/Modal';
import { format } from 'date-fns';
import { ErrorMsg } from '../../Utils/Toast';
import moment from 'moment';
import DateTime from 'react-datetime';
import ModalUpdateSlot from './ModalUpdateSlot';
const SlotAvailable = () => {
    //Modal
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const [timeSlot, setTimeSlot] = useState("");
    const [period, setPeriod] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [selectedDate, setSelectedDate] = useState('');
    const [startDateWithoutDate, setStartDateWithoutDate] = useState("")
    const [endDateWithoutDate, setEndDateWithoutDate] = useState("")

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    }

    const handleShow = (id, time, period, end, start, startTime, endTime) => {
        console.log(time)
        setShow(true);
        setTimeSlot(time);
        setPeriod(period)
        setId(id);
        setSelectedDate("");
        setActiveEnd(new Date(end))
        setActiveStart(new Date(start));
        setStartDateWithoutDate(start)
        setEndDateWithoutDate(end)
        setStartTime(startTime)
        setEndTime(endTime)
    };


    const handleStartDate = (date) => {
        setStartDay(date.toISOString());
    }
    const handleEndtDate = (date) => {
        setEndDay(date.toISOString());
    }




    //Search
    const [searchValue, setSearchValue] = useState('');
    const [sort, setSort] = useState('ASC');
    const [loading, setLoading] = useState(false);

    // دالة للتعامل مع تغيير قيمة البحث
    const handleSearch = () => {
        // يمكنك إرسال القيم المحددة إلى الـ API
        dispatch(GetAllSlots({
            searchBySlotName: searchValue,
            sortByDate: sort,
        }));
    };
    const handleSort = (e) => {
        // يمكنك إرسال القيم المحددة إلى الـ API
        dispatch(GetAllSlots({
            searchBySlotName: searchValue,
            sortByDate: e.target.value,
        }));
    };






    const dispatch = useDispatch();

    useEffect(() => {
        // التحميل الأولي عند تحميل الصفحة
        dispatch(GetAllSlots());
    }, [dispatch]);
    const allSlots = useSelector(state => state.GetSlots.Get_Slots);


    //Handle Delete Slot
    const deleteSlot = async (id) => {
        try {
            setLoading(true);
            dispatch(DeleteSlot({
                id: idData,
            }))
            const updatedSlots = allSlots.filter(slot => slot.id !== idData);
            dispatch({ type: GET_ALL_SLOTS, payload: updatedSlots });
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleCloseEdit();
        }
    }








    //Handle Slots
    const [selectedTime, setSelectedTime] = useState('');
    const [modifiedTime, setModifiedTime] = useState('');
    const [selectedAmPm, setSelectedAmPm] = useState('');
    const [idData, setId] = useState('');
    const [timeFact, setTimeFact] = useState('')
    const [ActiveStart, setActiveStart] = useState('')
    const [ActiveEnd, setActiveEnd] = useState('')


    const handleTimeChange = (e) => {
        const inputTime = e.target.value;
        setTimeFact(inputTime);
        const dataParsed = new Date(`2000-01-01T${inputTime}`);

        // الحصول على الوقت بنظام 12 ساعة
        const formattedTime12Hours = dataParsed.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

        // طباعة الوقت بنظام 12 ساعة
        setTimeFact(formattedTime12Hours);

        const parsedTimedate = new Date(`2000-01-01T${inputTime}`)
        const formattedSelectedTime = parsedTimedate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
        setSelectedTime(convertTo24HourFormat(formattedSelectedTime).replace(/:/, '.'));

        const parsedTime = new Date(`2000-01-01T${inputTime}`);
        parsedTime.setMinutes(parsedTime.getMinutes() + 90);
        const formattedModifiedTime = parsedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })

        setModifiedTime(convertTo24HourFormat(formattedModifiedTime).replace(/:/, '.'))

        const amPm = parsedTime.toLocaleTimeString([], { hour12: true, hour: '2-digit' }).slice(-2);
        setSelectedAmPm(amPm);
    };

    const convertTo24HourFormat = (time12) => {
        let [time, period] = time12.split(' ');
        let [hours, minutes] = time.split(':');
        hours = parseInt(hours);

        if (period.toUpperCase() === 'PM' && hours < 12) {
            hours += 12;
        } else if (period.toUpperCase() === 'AM' && hours === 12) {
            hours = 0;
        }

        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    };




    const updateSlot = async () => {
        try {
            setLoading(true);

            if (startDay !== "" && endDay !== "" && selectedTime !== "" && modifiedTime !== "") {
                dispatch(UpdateSlots({
                    id: idData,
                    start_time: parseFloat(selectedTime) || parseFloat(startTime),
                    end_time: parseFloat(modifiedTime) || parseFloat(endTime),
                    name: `${timeFact}`,
                    in_active_start_date: startDay,
                    in_active_end_date: endDay,
                }))
            } else if (selectedTime !== "" && modifiedTime !== "" && startDay === "" && endDay === "") {
                dispatch(UpdateSlots({
                    id: idData,
                    start_time: parseFloat(selectedTime) || parseFloat(startTime),
                    end_time: parseFloat(modifiedTime) || parseFloat(endTime),
                    name: `${timeFact}` || `${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`,
                }))
            } else if (selectedTime === "" && modifiedTime === "" && startDay !== "" && endDay !== "") {
                dispatch(UpdateSlots({
                    id: idData,
                    in_active_start_date: startDay,
                    in_active_end_date: endDay,
                }))
            } else if (endDay !== "" && startDay === "" && selectedTime === "" && modifiedTime === "") {
                dispatch(UpdateSlots({
                    id: idData,
                    // start_time: parseFloat(selectedTime) || parseFloat(startTime),
                    // end_time: parseFloat(modifiedTime) || parseFloat(endTime),
                    // name: `${timeFact}`,
                    // in_active_start_date: startDay,
                    in_active_end_date: endDay,
                }))
            } else if (endDay === "" && startDay !== "" && selectedTime === "" && modifiedTime === "") {
                dispatch(UpdateSlots({
                    id: idData,
                    // start_time: parseFloat(selectedTime) || parseFloat(startTime),
                    // end_time: parseFloat(modifiedTime) || parseFloat(endTime),
                    // name: `${timeFact}`,
                    in_active_start_date: startDay,
                    // in_active_end_date: endDay,
                }))
            }
            const updatedSlots = allSlots.map(slot =>
                slot.id === idData
                    ? { ...slot, start_time: parseInt(selectedTime), end_time: parseInt(modifiedTime), name: `${timeFact} ${selectedAmPm}`, in_active_start_date: startDay, in_active_end_date: endDay }
                    : slot
            );

            // // Update the local state with the modified data
            dispatch({ type: GET_ALL_SLOTS, payload: updatedSlots });
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
            handleClose();
            setStartDay("");
            setEndDay("");
            setDaysDiff(null)
            dispatch(GetAllSlots());

            dispatch(GetAllSlots());
            dispatch(GetAllSlots());
        }
    }




    const handleSlotAvail = (e) => {
        dispatch(GetAvailableSlots({
            date: e.target.value,
        }))
        console.log(e.target.value);
    }

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(':');
        let period = 'AM';
        let hours12 = parseInt(hours);

        if (hours12 >= 12) {
            period = 'PM';
            if (hours12 > 12) {
                hours12 -= 12;
            }
        }

        if (hours12 === 0) {
            hours12 = 12;
        }

        return `${hours12}:${minutes} ${period}`;
    };



    const [showEdit, setShowEdit] = useState(false);

    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (id) => {
        setShowEdit(true)
        setId(id);
    }


    const handleChange = (id, active) => {
        try {
            setLoading(true);
            dispatch(UpdateSlots({
                id: id,
                is_active: !active
            }))
            const updatedSlots = allSlots.map(slot =>
                slot.id === id
                    ? { ...slot, is_active: !active }
                    : slot
            );

            // Update the local state with the modified data
            dispatch({ type: GET_ALL_SLOTS, payload: updatedSlots });
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const currentDate = new Date();
    const formattedDate = format(currentDate, 'yyyy-MM-dd');







    //Slots Holiday
    const [startDay, setStartDay] = useState(ActiveStart);
    const [endDay, setEndDay] = useState(ActiveEnd);
    const [daysDiff, setDaysDiff] = useState(null);

    const calculateDaysDifference = () => {
        const startDate = new Date(startDay);
        const endDate = new Date(endDay);
        const date = new Date(startDay);
        const formattedDate = new Intl.DateTimeFormat('en-GB').format(date);



        if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {

            const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
            const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
            if (endDate <= startDate) {
                setDaysDiff(daysDiff);
            } else {
                setDaysDiff(daysDiff + 1);
            }

        } else {
            setDaysDiff(null);
        }
    };

    useEffect(() => {
        if (endDay !== "" && startDay !== "") {
            calculateDaysDifference();
        }
    }, [startDay, endDay])

    const validDate = (current) => {
        // Disable dates prior to today by comparing with the current date
        return current.isSameOrAfter(new Date(), 'day');
    };


    const cancelDates = () => {
        dispatch(UpdateSlots({
            id: idData,
            in_active_start_date: null,
            in_active_end_date: null,
        }))
        setLoading(false);
        handleClose();
        setStartDay("");
        setEndDay("");
        setDaysDiff(null)
        dispatch(GetAllSlots());
        dispatch(GetAllSlots());
        dispatch(GetAllSlots());
    }



    return (
        <div>
            <div className='flexItem justify-content-start mt-4'>
                <div className='mx-3 search-container'>
                    <input
                        type="search"
                        className='form-control search-input'
                        name="search"
                        id="search"
                        placeholder='ابحث عن.....'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <FaSearch className="search-icon" onClick={handleSearch} />
                </div>
                <div className='mx-3'>
                    <Form.Select aria-label="Default select example" onChange={handleSort}>
                        <option hidden> ترتيب الاوقات</option>
                        <option value="ASC">تصاعدي</option>
                        <option value="DESC">تنازلي</option>
                    </Form.Select>
                </div>
                <div className='mx-3'>
                    <input type="date" name="availableDate" min={formattedDate} onChange={handleSlotAvail} style={{ background: "white", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                </div>
            </div>
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>الوقت</th>
                            <th>التوقيت</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {allSlots ? (
                            allSlots.map((slot, index) => {
                                // console.log(slot)
                                let timeFormatted = slot.name;
                                if (slot.name.includes(':')) {
                                    timeFormatted = convertTo12HourFormat(slot.name);
                                }
                                const [hours, minutes, period] = timeFormatted.split(/:|\s/);
                                const formattedHours = parseInt(hours) < 10 ? hours.padStart(2, '0') : hours;
                                const time = `${formattedHours}:${minutes}`;
                                return (
                                    <tr key={slot.id}>
                                        <td>{(index + 1).toString().padStart(2, '0')}</td>
                                        <td>{time}</td>
                                        <td>{period}</td>
                                        <td>
                                            <Form>
                                                <Form.Check
                                                    type="switch"
                                                    id={`custom-switch`}
                                                    checked={slot.is_active}
                                                    onClick={() => handleChange(slot.id, slot.is_active)}
                                                />
                                            </Form>
                                        </td>
                                        <td><IoPencil size="20" cursor={"pointer"} onClick={() => handleShow(slot.id, time, period, slot.in_active_end_date, slot.in_active_start_date, slot.start_time, slot.end_time)} /></td>

                                        <td><MdDelete style={{ color: "#EB5757" }} size="20" cursor={"pointer"} onClick={() => handleShowEdit(slot.id)} /></td>
                                    </tr>
                                );
                            })
                        ) : (null)}


                    </tbody>
                </Table>
            </div>


            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Header>
                    <Modal.Title>تعديل وقت</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mt-3'>
                        <p>{timeSlot} {period}</p>
                        {/* <p>{startTime} {endTime}</p> */}

                        <input type="time" className='w-100' onChange={handleTimeChange} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <div className='mt-3'>
                            <div className='mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
                                <div className=''>
                                    <div style={{ width: "100%" }}>
                                        <div className='mt-2'>

                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Hidden Start Date</div>
                                                {
                                                    startDateWithoutDate === null ? (null) : (
                                                        <DateTime
                                                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                            closeOnSelect={true}
                                                            value={ActiveStart}
                                                            className='w-75  textInputBiker showOnly' utc={false} isValidDate={validDate} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                                                    )
                                                }


                                            </div>


                                            <DateTime
                                                inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                closeOnSelect={true}
                                                className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleStartDate} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />

                                        </div>
                                    </div>
                                    <div style={{ width: "100%" }} className='mt-1'>
                                        <div className='mt-2'>

                                            <div className='d-flex justify-content-between align-items-center'>
                                                <div>Hidden End Date</div>
                                                {
                                                    endDateWithoutDate === null ? (null) : (
                                                        <DateTime
                                                            inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                            closeOnSelect={true}
                                                            value={ActiveEnd}
                                                            className='w-75  textInputBiker showOnly' utc={false} isValidDate={validDate} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                                                    )
                                                }

                                            </div>
                                            <DateTime
                                                inputProps={{ readOnly: true }} // منع التعديل المباشر على الحقل
                                                closeOnSelect={true}
                                                className='w-100 mt-2 textInputBiker' utc={false} isValidDate={validDate} onChange={handleEndtDate} dateFormat="YYYY-MM-DD" timeFormat="HH:mm" />
                                        </div>
                                    </div>
                                    <div className='mt-2'>
                                        <label> عدد الايام</label>
                                        {
                                            daysDiff !== null ? (
                                                <input type='text' className='w-100 mt-2 textInputBiker' value={daysDiff} readOnly />
                                            ) : (<input type='text' className='w-100 mt-2 textInputBiker' readOnly />)
                                        }
                                    </div>

                                    <div className="mt-3">
                                        <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={cancelDates}>Cancel Hidden Dates</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateSlot}>
                        تعديل
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Modal Delete */}
            <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
                <Modal.Header>
                    <Modal.Title>الغاء الورقت  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2>هل تريد الغاء هذا الوقت</h2>
                </Modal.Body>
                <Modal.Footer className='m-auto w-100'>

                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={deleteSlot}>
                        تاكيد الالغاء
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleCloseEdit}>
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SlotAvailable


// import React, { useEffect, useState } from 'react';
// import Search from '../DashboardComponents/BookingAndSubscriptions/Search';
// import Table from 'react-bootstrap/Table';
// import { IoPencil } from "react-icons/io5";
// import { MdDelete } from "react-icons/md";
// import { useDispatch, useSelector } from 'react-redux';
// import { DeleteSlot, GetAllSlots, GetAvailableSlots, UpdateSlots } from '../../redux/actions/Slots';
// import Form from 'react-bootstrap/Form';
// import { FaSearch } from "react-icons/fa";
// import { GET_ALL_SLOTS } from '../../redux/Type';
// import Modal from 'react-bootstrap/Modal';
// import { format } from 'date-fns';
// import DateTime from 'react-datetime';

// const SlotAvailable = () => {
//     // Modal
//     const [show, setShow] = useState(false);
//     const [showEdit, setShowEdit] = useState(false);
//     const [loading, setLoading] = useState(false);

//     const [timeSlot, setTimeSlot] = useState("");
//     const [period, setPeriod] = useState("");
//     const [startTime, setStartTime] = useState("");
//     const [endTime, setEndTime] = useState("");
//     const [selectedDate, setSelectedDate] = useState('');
//     const [selectedTime, setSelectedTime] = useState('');
//     const [modifiedTime, setModifiedTime] = useState('');
//     const [selectedAmPm, setSelectedAmPm] = useState('');
//     const [idData, setId] = useState('');
//     const [timeFact, setTimeFact] = useState('');
//     const [ActiveStart, setActiveStart] = useState('');
//     const [ActiveEnd, setActiveEnd] = useState('');
//     const [startDay, setStartDay] = useState('');
//     const [endDay, setEndDay] = useState('');
//     const [daysDiff, setDaysDiff] = useState(null);

//     const [searchValue, setSearchValue] = useState('');
//     const [sort, setSort] = useState('ASC');

//     const dispatch = useDispatch();
//     const allSlots = useSelector(state => state.GetSlots.Get_Slots);

//     useEffect(() => {
//         // Initial loading of slots
//         dispatch(GetAllSlots());
//     }, [dispatch]);

//     const handleClose = () => {
//         setShow(false);
//         resetModalState();
//     };

//     const handleCloseEdit = () => {
//         setShowEdit(false);
//         resetModalState();
//     };

//     const resetModalState = () => {
//         setTimeSlot('');
//         setPeriod('');
//         setStartTime('');
//         setEndTime('');
//         setSelectedDate('');
//         setSelectedTime('');
//         setModifiedTime('');
//         setSelectedAmPm('');
//         setId('');
//         setTimeFact('');
//         setActiveStart('');
//         setActiveEnd('');
//         setStartDay('');
//         setEndDay('');
//         setDaysDiff(null);
//     };

//     const handleShow = (id, time, period, end, start, startTime, endTime) => {
//         setShow(true);
//         setTimeSlot(time);
//         setPeriod(period);
//         setId(id);
//         setActiveStart(new Date(start));
//         setActiveEnd(new Date(end));
//         setStartTime(startTime);
//         setEndTime(endTime);
//     };

//     const handleShowEdit = (id) => {
//         setShowEdit(true);
//         setId(id);
//     };

//     const handleDateChange = (e) => {
//         setSelectedDate(e.target.value);
//     };

//     const handleStartDate = (date) => {
//         setStartDay(date.toISOString());
//     };

//     const handleEndDate = (date) => {
//         setEndDay(date.toISOString());
//     };

//     const handleSearch = () => {
//         dispatch(GetAllSlots({
//             searchBySlotName: searchValue,
//             sortByDate: sort,
//         }));
//     };

//     const handleSort = (e) => {
//         dispatch(GetAllSlots({
//             searchBySlotName: searchValue,
//             sortByDate: e.target.value,
//         }));
//     };

//     const deleteSlot = async (id) => {
//         try {
//             setLoading(true);
//             dispatch(DeleteSlot({ id }));
//             const updatedSlots = allSlots.filter(slot => slot.id !== id);
//             dispatch({ type: GET_ALL_SLOTS, payload: updatedSlots });
//         } catch (e) {
//             console.log(e);
//         } finally {
//             setLoading(false);
//             handleCloseEdit();
//         }
//     };

//     const handleSlotAvail = (e) => {
//         dispatch(GetAvailableSlots({ date: e.target.value }));
//     };

//     const handleTimeChange = (e) => {
//         const inputTime = e.target.value;
//         setTimeFact(inputTime);
//         const dataParsed = new Date(`2000-01-01T${inputTime}`);
//         const formattedTime12Hours = dataParsed.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//         setTimeFact(formattedTime12Hours);

//         const parsedTimedate = new Date(`2000-01-01T${inputTime}`);
//         const formattedSelectedTime = parsedTimedate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//         setSelectedTime(convertTo24HourFormat(formattedSelectedTime).replace(/:/, '.'));

//         const parsedTime = new Date(`2000-01-01T${inputTime}`);
//         parsedTime.setMinutes(parsedTime.getMinutes() + 90);
//         const formattedModifiedTime = parsedTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });

//         setModifiedTime(convertTo24HourFormat(formattedModifiedTime).replace(/:/, '.'));

//         const amPm = parsedTime.toLocaleTimeString([], { hour12: true, hour: '2-digit' }).slice(-2);
//         setSelectedAmPm(amPm);
//     };

//     const convertTo24HourFormat = (time12) => {
//         let [time, period] = time12.split(' ');
//         let [hours, minutes] = time.split(':');
//         hours = parseInt(hours);

//         if (period.toUpperCase() === 'PM' && hours < 12) {
//             hours += 12;
//         } else if (period.toUpperCase() === 'AM' && hours === 12) {
//             hours = 0;
//         }

//         return `${hours.toString().padStart(2, '0')}:${minutes}`;
//     };

//     const updateSlot = async () => {
//         try {
//             setLoading(true);
//             const updatePayload = {
//                 id: idData,
//                 start_time: parseFloat(selectedTime) || parseFloat(startTime),
//                 end_time: parseFloat(modifiedTime) || parseFloat(endTime),
//                 name: timeFact || `${startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}`,
//                 in_active_start_date: startDay,
//                 in_active_end_date: endDay,
//             };

//             dispatch(UpdateSlots(updatePayload));

//             const updatedSlots = allSlots.map(slot =>
//                 slot.id === idData
//                     ? { ...slot, ...updatePayload }
//                     : slot
//             );

//             dispatch({ type: GET_ALL_SLOTS, payload: updatedSlots });
//         } catch (e) {
//             console.log(e);
//         } finally {
//             setLoading(false);
//             handleClose();
//             dispatch(GetAllSlots());
//         }
//     };

//     const handleChange = (id, active) => {
//         try {
//             setLoading(true);
//             dispatch(UpdateSlots({
//                 id,
//                 is_active: !active,
//             }));
//             const updatedSlots = allSlots.map(slot =>
//                 slot.id === id ? { ...slot, is_active: !active } : slot
//             );
//             dispatch({ type: GET_ALL_SLOTS, payload: updatedSlots });
//         } catch (e) {
//             console.log(e);
//         } finally {
//             setLoading(false);
//         }
//     };

//     const currentDate = new Date();
//     const formattedDate = format(currentDate, 'yyyy-MM-dd');

//     const calculateDaysDifference = () => {
//         const startDate = new Date(startDay);
//         const endDate = new Date(endDay);

//         if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
//             const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
//             const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
//             setDaysDiff(endDate <= startDate ? daysDiff : daysDiff + 1);
//         } else {
//             setDaysDiff(null);
//         }
//     };

//     useEffect(() => {
//         if (endDay && startDay) {
//             calculateDaysDifference();
//         }
//     }, [startDay, endDay]);

//     const validDate = (current) => {
//         return current.isSameOrAfter(new Date(), 'day');
//     };

//     const cancelDates = () => {
//         dispatch(UpdateSlots({
//             id: idData,
//             in_active_start_date: null,
//             in_active_end_date: null,
//         }));
//         setLoading(false);
//         handleClose();
//         dispatch(GetAllSlots());
//     };

//     const convertTo12HourFormat = (time24) => {
//         const [hours, minutes] = time24.split(':');
//         let period = 'AM';
//         let hours12 = parseInt(hours);

//         if (hours12 >= 12) {
//             period = 'PM';
//             if (hours12 > 12) {
//                 hours12 -= 12;
//             }
//         }

//         if (hours12 === 0) {
//             hours12 = 12;
//         }

//         return `${hours12}:${minutes} ${period}`;
//     };

//     return (
//         <div>
//             <div className='flexItem justify-content-start mt-4'>
//                 <div className='mx-3 search-container'>
//                     <input
//                         type="search"
//                         className='form-control search-input'
//                         name="search"
//                         id="search"
//                         placeholder='ابحث عن.....'
//                         value={searchValue}
//                         onChange={(e) => setSearchValue(e.target.value)}
//                     />
//                     <FaSearch className="search-icon" onClick={handleSearch} />
//                 </div>
//                 <div className='mx-3'>
//                     <Form.Select aria-label="Default select example" onChange={handleSort}>
//                         <option hidden> ترتيب الاوقات</option>
//                         <option value="ASC">تصاعدي</option>
//                         <option value="DESC">تنازلي</option>
//                     </Form.Select>
//                 </div>
//                 <div className='mx-3'>
//                     <input type="date" name="availableDate" min={formattedDate} onChange={handleSlotAvail} style={{ background: "white", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                 </div>
//             </div>
//             <div className='mt-3'>
//                 <Table bordered>
//                     <thead>
//                         <tr>
//                             <th>#</th>
//                             <th>الوقت</th>
//                             <th>التوقيت</th>
//                             <th></th>
//                             <th></th>
//                             <th></th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {allSlots ? (
//                             allSlots.map((slot, index) => {
//                                 let timeFormatted = slot.name;
//                                 if (slot.name.includes(':')) {
//                                     timeFormatted = convertTo12HourFormat(slot.name);
//                                 }
//                                 const [hours, minutes, period] = timeFormatted.split(/:|\s/);
//                                 const formattedHours = parseInt(hours) < 10 ? hours.padStart(2, '0') : hours;
//                                 const time = `${formattedHours}:${minutes}`;
//                                 return (
//                                     <tr key={slot.id}>
//                                         <td>{(index + 1).toString().padStart(2, '0')}</td>
//                                         <td>{time}</td>
//                                         <td>{period}</td>
//                                         <td>
//                                             <Form>
//                                                 <Form.Check
//                                                     type="switch"
//                                                     id={`custom-switch`}
//                                                     checked={slot.is_active}
//                                                     onClick={() => handleChange(slot.id, slot.is_active)}
//                                                 />
//                                             </Form>
//                                         </td>
//                                         <td><IoPencil size="20" cursor={"pointer"} onClick={() => handleShow(slot.id, time, period, slot.in_active_end_date, slot.in_active_start_date, slot.start_time, slot.end_time)} /></td>
//                                         <td><MdDelete style={{ color: "#EB5757" }} size="20" cursor={"pointer"} onClick={() => handleShowEdit(slot.id)} /></td>
//                                     </tr>
//                                 );
//                             })
//                         ) : (null)}
//                     </tbody>
//                 </Table>
//             </div>

//             <Modal show={show} onHide={handleClose} className='font' centered>
//                 <Modal.Header>
//                     <Modal.Title>تعديل وقت</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <div className='mt-3'>
//                         <p>{timeSlot} {period}</p>
//                         <input type="time" className='w-100' onChange={handleTimeChange} style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
//                     </div>
//                     <div className='mt-3'>
//                         <div className='mt-3' style={{ background: "white", padding: "10px", borderRadius: "10px" }}>
//                             <div>
//                                 <div style={{ width: "100%" }}>
//                                     <div className='mt-2'>
//                                         <div className='d-flex justify-content-between align-items-center'>
//                                             <div> Hidden Start Date : </div>
//                                             <div>
//                                                 <DateTime
//                                                     inputProps={{ readOnly: true }}
//                                                     closeOnSelect={true}
//                                                     value={ActiveStart}
//                                                     className='w-100  textInputBiker showOnly'
//                                                     utc={false}
//                                                     isValidDate={validDate}
//                                                     // onChange={handleStartDate}
//                                                     dateFormat="YYYY-MM-DD"
//                                                     timeFormat="HH:mm"
//                                                 />
//                                             </div>
//                                         </div>
//                                         <DateTime
//                                             inputProps={{ readOnly: true }}
//                                             closeOnSelect={true}
//                                             // value={ActiveStart}
//                                             className='w-100 mt-2 textInputBiker'
//                                             utc={false}
//                                             isValidDate={validDate}
//                                             onChange={handleStartDate}
//                                             dateFormat="YYYY-MM-DD"
//                                             timeFormat="HH:mm"
//                                         />
//                                     </div>
//                                 </div>
//                                 <div style={{ width: "100%" }} className='mt-1'>
//                                     <div className='mt-2'>
//                                         <div className='d-flex justify-content-between align-items-center'>
//                                             <div> Hidden End Date : </div>
//                                             <div>
//                                                 <DateTime
//                                                     inputProps={{ readOnly: true }}
//                                                     closeOnSelect={true}
//                                                     value={ActiveEnd}
//                                                     className='w-100 mt-2 textInputBiker showOnly'
//                                                     utc={false}
//                                                     isValidDate={validDate}
//                                                     // onChange={handleEndDate}
//                                                     dateFormat="YYYY-MM-DD"
//                                                     timeFormat="HH:mm"
//                                                 />
//                                             </div>
//                                         </div>

//                                         <DateTime
//                                             inputProps={{ readOnly: true }}
//                                             closeOnSelect={true}
//                                             className='w-100 mt-2 textInputBiker'
//                                             utc={false}
//                                             isValidDate={validDate}
//                                             onChange={handleEndDate}
//                                             dateFormat="YYYY-MM-DD"
//                                             timeFormat="HH:mm"
//                                         />
//                                     </div>
//                                 </div>
//                                 <div className='mt-2'>
//                                     <label>عدد الايام</label>
//                                     <input type='text' className='w-100 mt-2 textInputBiker' value={daysDiff || ''} readOnly />
//                                 </div>
//                                 <div className="mt-3">
//                                     <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={cancelDates}>Cancel Hidden Dates</button>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </Modal.Body>
//                 <Modal.Footer className='m-auto w-100'>
//                     <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={updateSlot}>
//                         تعديل
//                     </button>
//                     <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose}>
//                         تراجع
//                     </button>
//                 </Modal.Footer>
//             </Modal>

//             <Modal show={showEdit} onHide={handleCloseEdit} className='font' centered>
//                 <Modal.Header>
//                     <Modal.Title>الغاء الورقت</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     <h2>هل تريد الغاء هذا الوقت</h2>
//                 </Modal.Body>
//                 <Modal.Footer className='m-auto w-100'>
//                     <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={() => deleteSlot(idData)}>
//                         تاكيد الالغاء
//                     </button>
//                     <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleCloseEdit}>
//                         تراجع
//                     </button>
//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// }

// export default SlotAvailable;
