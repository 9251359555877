import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { PiMinusCircle } from "react-icons/pi";
import { MdDelete } from "react-icons/md";
import Pagination from '../../Utils/Pagination';
import Modal from 'react-bootstrap/Modal'
import { CompactPicker } from 'react-color';
import AddNewBrand from '../Modals/AddNewBrand';
import { useDispatch, useSelector } from 'react-redux'
import { AddNewBrandData, DeleteCarModalBrand, FilterCar, FilterCarASCDESC, GetAllBrands, GetAllCars } from '../../redux/actions/CarActions';
import { ErrorMsg } from '../../Utils/Toast';
import UpdateModalCar from '../Modals/UpdateModalCar';
import { FaSearch } from "react-icons/fa";
import DeleteCars from '../Modals/DeleteCars';




const Cars = () => {

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameAr, setNameAr] = useState("");
    const [nameEn, setNameEn] = useState("");
    const [sort, setSort] = useState('DESC');
    const [currentPage, setCurrentPage] = useState(1);
    const handleClose = () => {
        setShow(false)
    }
    const handleShow = () => {
        setShow(true)
    }


    const dispatch = useDispatch();

    const getAllModalsCar = async (sort, page) => {
        await dispatch(GetAllCars({
            pageCount: page,
            sort: sort
        }))
    }

    useEffect(() => {
        getAllModalsCar(sort, currentPage)
    }, [sort, currentPage])


    const carModals = useSelector(state => state.CarReducer.ModalCars)

    const [cars, setCars] = useState([]);


    useEffect(() => {
        setCars(carModals.data)
    }, [carModals && carModals.data])



    const getPage = async (page) => {
        setCurrentPage(page)
        await dispatch(GetAllCars({
            pageCount: page
        }));
    };




    const [images, setImages] = useState([]);
    const handleImageUpload = (event) => {
        const selectedImages = Array.from(event.target.files);
        setImages(selectedImages);
    }
    const handleLabelClick = (event) => {
        event.preventDefault();
        const fileInput = document.getElementById('file');
        fileInput.click();
    };




    const UploadNewBrand = async () => {
        const formData = new FormData();
        try {
            if (nameAr !== "" && nameEn !== "" && images.length !== 0) {
                setLoading(true);
                formData.append("name_ar", nameAr);
                formData.append("name_en", nameEn);
                formData.append("logo", images[0]); // استخدام الصورة الأولى
                await dispatch(AddNewBrandData(formData));
                await dispatch(GetAllBrands())
            } else {
                ErrorMsg("Data Missed")
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false)
            handleClose();
            setNameAr("");
            setNameEn("");
            setImages([]);
            await dispatch(GetAllBrands())
        }
    }





    const handleUpdate = (id, newNameAr, newNameEn) => {
        setCars(prevCars => prevCars.map(car => {
            if (car.id === id) {
                return { ...car, name_ar: newNameAr, name_en: newNameEn };
            } else {
                return car;
            }
        }));
    };


    //Handle Search
    const [searchValue, setSearchValue] = useState('');
    const [lang, setLang] = useState('');

    const handleSearchInputChange = (e) => {
        const searchWord = e.target.value

        // Check if the first character is an English letter
        if (/^[a-zA-Z]/.test(searchWord)) {
            setLang('en');
        } else {
            setLang('ar');
        }

        // You can perform additional actions if needed
        setSearchValue(searchWord);
    }

    const searchCar = async (e) => {
        await dispatch(FilterCar({
            searchWord: searchValue,
            lang: lang,
        }))
    }







    const updateCars = (updatedCars) => {
        setCars(updatedCars);
    };


    return (
        <div className='font'>
            <div>
                <div className='flexItem justify-content-start mt-4'>
                    <div className='mx-3 search-container'>
                        <input
                            type="search"
                            className='form-control search-input'
                            name="search"
                            id="search"
                            placeholder='البحث '
                            value={searchValue}
                            onChange={handleSearchInputChange}
                        />
                        <FaSearch className="search-icon" onClick={searchCar} />
                    </div>
                    <div className='mx-3'>
                        <Form.Select aria-label="Default select example" value={sort} onChange={(e) => setSort(e.target.value)}>
                            <option hidden>تاريخ الانشاء</option>
                            <option value="ASC">تصاعدي</option>
                            <option value="DESC">تنازلي</option>
                            {/* <option value="3">Three</option> */}
                        </Form.Select>
                    </div>
                </div>
            </div>

            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        <tr className='text-center'>
                            <th>#</th>
                            <th>صورة السيارة</th>
                            <th style={{ textAlign: "center" }}>موديل السيارة</th>
                            <th>البراند</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {
                            cars ? (
                                cars.map((car, index) => {
                                    // console.log(car)
                                    if (car.deleted_at === null) {
                                        return (
                                            <tr key={car.id} className='text-center'>
                                                {/* <td>{car.id}</td> */}
                                                <td className='' style={{ width: "10%" }}>{index + 1}</td>
                                                <td className=' text-right' style={{ width: "10%", height: "5px" }}>
                                                    <img className='w-100 ' src={car.brand.logo} alt="car" style={{ objectFit: 'contain', height: "50px" }} />
                                                </td>
                                                {/* <td>{car.brand.name_en}</td> */}
                                                <td>{car.name_ar}</td>
                                                <td style={{ textAlign: "center" }}>{car.brand.name_ar}</td>
                                                <td>
                                                    <UpdateModalCar idCar={car.id} carNameEn={car.name_en} carNameAr={car.brand.name_ar} onUpdate={handleUpdate} />
                                                </td>
                                                {/* <td><MdDelete style={{ color: "#EB5757", cursor: "pointer" }} size="20" onClick={() => handleDelete(car.id)} /></td> */}
                                                <td>
                                                    <DeleteCars carId={car.id} updateCars={updateCars} />
                                                </td>
                                            </tr>
                                        )
                                    }else{
                                        return null
                                    }
                                })
                            ) : (null)
                        }
                    </tbody>
                </Table>
            </div>


            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>اضافة سيارة جديدة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>معلومات سيارة</span>
                    <div className=''>
                        <div>
                            <div className="w-100" style={{ padding: "5px", borderRadius: "10px", width: "100%" }}>
                                {images.map((image, index) => (
                                    <div>
                                        <img key={index} src={URL.createObjectURL(image)} alt={`Image ${index}`}
                                            style={{ width: "400px", borderRadius: "10px" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="file-input" style={{ padding: "20px" }}>
                            <input type="file" id="file" accept="image/*" multiple={false} max={`4`} onChange={handleImageUpload} style={{ visibility: "hidden" }} />
                            <button className='btn' style={{ color: "#828282", background: "#FFFAEC" }} onClick={handleLabelClick}>رفع الصورة</button>
                        </div>
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameAr} onChange={(e) => setNameAr(e.target.value)} placeholder='اسم البراند بالعربي' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <input type="text" className='w-100' value={nameEn} onChange={(e) => setNameEn(e.target.value)} placeholder='اسم البراند بالانجليزية' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={UploadNewBrand}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>





            {
                carModals && carModals.meta ? (<Pagination onPress={getPage} countsPage={carModals.meta.total / 10} />) : null
            }
        </div>
    )
}

export default Cars
