import React, { useEffect, useState } from 'react'
import Search from '../DashboardComponents/BookingAndSubscriptions/Search'
import Table from 'react-bootstrap/Table';
import { MdOutlineModeEditOutline } from "react-icons/md";
import { RiDeleteBinFill } from "react-icons/ri";
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux'
import { SettingsPointData, UpdateRedeemePoints } from '../../redux/actions/PointsAction'
import { GetAllBaqa } from '../../redux/actions/BaqaAction';

const ManagePoints = () => {
    const [showMessageData, setShowMessageData] = useState(false);
    const [bikerPoints, setBikerPoints] = useState("");
    const [clientPoint, setClientPoints] = useState("");
    const handleMessageData = () => {
        setShowMessageData(!showMessageData)
    }

    const [show, setShow] = useState(false);
    const [packageName, setPackageName] = useState("");
    const handleClose = () => {
        setShow(false)
    }
    const handleShow = async (redeemoPoint, packageName, bikerpo, clientpo) => {
        setShow(true);
        setRedeemePoints(redeemoPoint);
        setPackageName(packageName)
        setBikerPoints(bikerpo);
        setClientPoints(clientpo);
        await dispatch(GetAllBaqa());
    }

    const dispatch = useDispatch();

    const [redeemePoints, setRedeemePoints] = useState();

    const fetchSettingPoint = async () => {
        await dispatch(SettingsPointData())
    }
    useEffect(() => {
        fetchSettingPoint()
    }, [])

    const settings = useSelector(state => state.PointsReducer.Setting);

    const UpdateRedemePoints = async () => {
        try {
            await dispatch(UpdateRedeemePoints({
                redeemable_points: parseInt(redeemePoints),
                reward_package: selectBaqa,
                biker_wash_point: parseInt(bikerPoints),
                client_wash_point: parseInt(clientPoint)
            }))
        } catch (e) {
            console.log(e);
        } finally {
            handleClose();
        }
    }
    const [selectBaqa, setSelectBaqa] = useState(null);
    const dataBaqat = useSelector(state => state.BaqaReducer.Baqa);
    return (
        <div>
            {/* <Search /> */}
            <div className='mt-3'>
                <Table bordered>
                    <thead>
                        {/* <tr>
                            <th>#</th>
                            <th>النقاط المطلوبة للاستحقاق </th>
                            <th>نوع الجائزة</th>
                            <th>تاريخ التحديث</th>
                            <th></th>
                        </tr> */}
                        <tr>
                            <th>#</th>
                            <th>نقاط البايكر</th>
                            <th>نقاط العملاء</th>
                            <th>النقاط المطلوبة للاستحقاق </th>
                            <th>نوع الجائزة</th>
                            <th>تاريخ التحديث</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            settings && settings.data ? (
                                <tr>
                                    <td>1</td>
                                    <td>{settings.data.biker_points} نقطة</td>
                                    <td>{settings.data.client_points} نقطة</td>
                                    <td>{settings.data.redeem_points} نقطة</td>
                                    {
                                        settings.data.reward_package ? (<td>{settings.data.reward_package.name}</td>) : null
                                    }
                                    {
                                        settings.data.reward_package ? (<td>{settings.data.reward_package.updated_at}</td>) : null
                                    }
                                    <td ><MdOutlineModeEditOutline style={{ color: "#6B7280", cursor: "pointer" }} size={"20"} onClick={() => handleShow(settings.data.redeem_points, settings.data.reward_package.name, settings.data.biker_points, settings.data.client_points)} /></td>
                                </tr>
                            ) : null
                        }
                    </tbody>
                </Table>
            </div>



            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>تعديل النقاط المطلوبة للاستحقاق</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{ color: "#8B8D97" }}>النقاط المطلوبة والمحددة التي عند وصول المستخدم اليها يستطيع الاستبدال</span>

                    <div className='mt-3'>
                        <label>النقاط المطلوبة للاستحقاق	</label>
                        <input type="number" min="1" value={redeemePoints} onChange={(e) => setRedeemePoints(e.target.value)} className='w-100' placeholder='النقاط المطلوبة للإستحقاق ( 800 نقطة )' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>

                    <div className='mt-3'>
                        <label>نقاط البايكرز</label>
                        <input type="number" min="1" value={bikerPoints} onChange={(e) => setBikerPoints(e.target.value)} className='w-100' placeholder='النقاط المطلوبة للإستحقاق ( 800 نقطة )' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <div className='mt-3'>
                        <label>نقاط المستخدمين</label>
                        <input type="number" min="1" value={clientPoint} onChange={(e) => setClientPoints(e.target.value)} className='w-100' placeholder='النقاط المطلوبة للإستحقاق ( 800 نقطة )' style={{ background: "#EFF1F9", outline: "none", border: "none", padding: "15px", borderRadius: "10px" }} />
                    </div>
                    <p className='mt-2'>الباقه المختاره : {packageName}</p>
                    <Form.Select aria-label="Default select example" style={{ background: "#EFF1F9" }} className='mt-4' value={selectBaqa} onChange={(e) => setSelectBaqa(e.target.value)}>
                        <option hidden> الباقات</option>
                        {
                            dataBaqat && dataBaqat.data ? (

                                dataBaqat.data.map((baqa, index) => {
                                    // console.log(baqa.id)
                                    return (
                                        <option value={baqa.id} key={baqa.id}>{baqa.name} </option>
                                    )
                                })
                            ) : (null)
                        }
                    </Form.Select>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn' style={{ width: "45%", background: "#FECB44", color: "black" }} onClick={UpdateRedemePoints}>
                        اضافة
                    </button>
                    <button className='btn' style={{ width: "45%", background: "#FFFAEC", color: "black", border: "1px solid #FECB44" }} onClick={handleClose} >
                        تراجع
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ManagePoints
